import React from 'react'
import { Crud } from '../../../../classes/NewCrud'
import { getProcessoDTO } from '../../../../dtos/processo/ProcessoDTO'
import ModeloProcessoService from '../../../../services/processo/ModeloProcessoService'
import DadosPrincipaisModeloProcesso from './main'
import { ModeloProcessoValidator } from './validator'
import AccordionContainer from '../../../../components/layout/AccordionContainer'
import AccordionPage from '../../../../components/layout/AccordionPage'
import DadosEtapas from './etapasEpendencias/ListWithModal'

const ModeloProcessoForm = ({ form, edicaoHabilitada, camposObrigatorios, isNew }) => {
  return (
    <AccordionContainer>
      <AccordionPage header="Informações Principais" active>
        <DadosPrincipaisModeloProcesso
          form={form}
          edicaoHabilitada={edicaoHabilitada}
        />
      </AccordionPage>
      <AccordionPage header="Etapas e pendências" active>
        <DadosEtapas
          form={form}
          edicaoHabilitada={edicaoHabilitada}
        />
      </AccordionPage>
    </AccordionContainer>
  )
}

const ModeloProcessoCrud = (props) => {
  return (
    <Crud
      {...props}
      formContent={ModeloProcessoForm}
      valida={ModeloProcessoValidator}
      modelGetDTO={getProcessoDTO}
      service={ModeloProcessoService}
      resource="modelo-processo"
      primarykeyname="codigo_prc"
      formTitle="Modelo de Processo"
    />
  )
}

export default ModeloProcessoCrud
