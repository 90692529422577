import * as Yup from 'yup'

const obrigarCampo = (value, parent, mensagem) => {
  if (!parent || typeof parent.obrigarCampos === 'undefined') {
    return true
  }
  return !(parent.isCompra && parent.isLojaPaga && parent.isPix && parent.obrigarCampos && !value) || false
}

export function TedPixValidator (props) {
  const validatorBancoConta = !props.isVenda && props.isLojaPaga && props.isPix ? {
    banco: Yup.object().shape({
      codigo_ban: Yup.number()
        .nullable()
        .test('testeObrigarCampos', 'O banco não foi selecionado!', function (value) {
          const retorno = obrigarCampo(value, this.parent, 'O banco não foi selecionado!')
          return retorno
        })
    })
  } : {}

  return Yup.object().shape({
    veiculo: Yup.object().shape({
      codigo_vei: Yup.number()
        .nullable()
        .required('O veículo não foi selecionado!')
    }),

    ...(validatorBancoConta),

    numeroBanco: Yup.number()
      .nullable()
      .test('testeObrigarCampos', 'O número do banco não foi informado!', function (value) {
        return obrigarCampo(value, this.parent, 'O número do banco não foi informado!')
      }),
    numeroAgencia: Yup.number()
      .nullable()
      .test('testeObrigarCampos', 'O número da agência não foi informado!', function (value) {
        return obrigarCampo(value, this.parent, 'O número da agência não foi informado!')
      }),
    numeroConta: Yup.number()
      .nullable()
      .test('testeObrigarCampos', 'O número da conta não foi informado!', function (value) {
        return obrigarCampo(value, this.parent, 'O número da conta não foi informado!')
      }),
    titular: Yup.string()
      .nullable()
      .test('testeObrigarCampos', 'O titular não foi informado!', function (value) {
        return obrigarCampo(value, this.parent, 'O titular não foi informado!')
      }),
    valor: Yup.number()
      .nullable()
      .required('O valor não foi informado!')
      .min(0.001, 'O valor deve ser maior que zero!'),
    dataEmissao: Yup.date()
      .nullable()
      .required('A data da operação não foi informada!')
  })
}
