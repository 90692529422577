import React from 'react'
import { formatDocument } from '../../../helpers/formaters'

const PessoaAutocompleteTemplate = (option) => {
  return (
    <span>
      <b>{option.codigo_pes}</b> - <b>{option.nomraz_pes}</b>{option.cgccpf_pes ? ` - ${formatDocument(option.cgccpf_pes)}` : ''}
    </span>
  )
}

export default PessoaAutocompleteTemplate
