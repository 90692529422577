import { classNames } from 'primereact/utils'
import React, { useEffect, useState } from 'react'
import TextInput from '../../../../../components/inputs/TextInput'
import FieldErrorMessage from '../../../../../components/utils/FieldErrorMessage'
import RequiredLabel from '../../../../../components/utils/RequiredLabel'
import { useValidateInput } from '../../../../../helpers/useValidateInput'
import AutoCompleteInput from '../../../../../components/inputs/AutoCompleteInput'
import PessoaAutocompleteTemplate from '../../../../../components/inputs/options/PessoaAutocompleteTemplate'
import { PessoaFisica } from '../../../../cadastro/pessoa/fisica/PessoaFisica'
import { PessoaJuridica } from '../../../../cadastro/pessoa/juridica/PessoaJuridica'
import PessoaService from '../../../../../services/cadastro/pessoa/PessoaService'
import { PessoaModel } from '../../../../../models/cadastro/pessoa/PessoaModel'
import { formatDocument } from '../../../../../helpers/formaters'
import VendedoresService from '../../../../../services/cadastro/pessoa/VendedoresService'
import { VendedorModel } from '../../../../../models/cadastro/pessoa/VendedorModel'
import ModeloProcessoService from '../../../../../services/processo/ModeloProcessoService'
import SelectInput from '../../../../../components/inputs/SelectInput'
import CommonHelper from '../../../../../helpers/CommonHelper'
import { showErrorMessage, showWarnMessage } from '../../../../../components/utils/Message'

const DadosPrincipaisProcessoUso = ({ form, edicaoHabilitada, camposObrigatorios }) => {
  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)

  // * Constantes
  const [sugestaoCliente, setSugestaoCliente] = useState([])
  const [sugestaoAtendente, setSugestaoAtendente] = useState([])
  const [processos, setProcessos] = useState([])

  // * Functions para Autocomplete Cliente
  async function onSelectCliente (e) {
    await form.setFieldValue('cliente', e.value)
    await form.setFieldTouched('cliente.codigo_pes')
  }
  function handleChangeCliente (event) {
    form.setFieldValue('cliente', { nomraz_pes: event.target.value })
  }
  async function sugerirCliente ({ query }) {
    try {
      const responsaveis = await PessoaService.filterAutocomplete(query)

      if (responsaveis.length <= 0) {
        showWarnMessage('Cliente não encontrado!')
      }
      setSugestaoCliente(responsaveis)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um cliente!')
    }
  }

  // * Functions para Autocomplete Atendente
  async function onSelectAtendente (e) {
    await form.setFieldValue('atendente', e.value)
    await form.setFieldTouched('atendente.codigo_ven')
  }
  function handleChangeAtendente (event) {
    form.setFieldValue('atendente', { nomraz_pes: event.target.value })
  }
  async function sugerirAtendente ({ query }) {
    try {
      // * Utiliza VendedorService que é a mesma coisa
      const atendentesService = new VendedoresService()
      const atendentes = await atendentesService.filterAutoComplete(`nomraz_pes=${query}`)

      if (atendentes.length <= 0) {
        showWarnMessage('Atendente não encontrado!')
      }
      setSugestaoAtendente(atendentes)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um atendente!')
    }
  }

  // * Realiza busca no primeiro render
  useEffect(async () => {
    await getProcessos()
  }, [])

  // * Gets para Selects Inpúts
  const getProcessos = async () => {
    try {
      await ModeloProcessoService.filterAutocomplete().then((data) => setProcessos(data))
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao buscar os processos')
    }
  }

  return (
    <div className="formgrid grid">
      <div className="field col-12 md:col-6">
        <RequiredLabel label="Processo" valid={isFormFieldValid('processo.codigo_prc')} />
        <SelectInput
          value={ form.values?.processo?.codigo_prc && form.values?.processo }
          options={processos}
          onChange={(e) => {
            form.setFieldValue('processo', e.target.value || {})
            form.validateField('processo.codigo_prc')
          }}
          form={form}
          name="processo"
          placeholder="Selecione o processo"
          filter={CommonHelper.isDesktop()}
          optionLabel="descri_prc"
          dataKey="codigo_prc"
          className={classNames({ 'p-invalid': isFormFieldValid('processo.codigo_prc') }, 'inputfield w-full')}
          noFloatLabel
          disabled={!edicaoHabilitada}
          onBlur={() => form.setFieldTouched('processo.codigo_prc')}
        />
        <FieldErrorMessage message={getFormErrorMessage('processo.codigo_prc')} />
      </div>
      <div className="field col-6 md:col-4">
        <TextInput
          label="Veículo"
          placeholder="Veículo"
          name="veiprc_pru"
          className={classNames({ 'p-invalid': isFormFieldValid('veiprc_pru') }, 'inputfield w-full')}
          value={form.values.veiprc_pru}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          onBlur={() => form.setFieldTouched('veiprc_pru')}
        />
      </div>
      <div className="field col-6 md:col-2">
        <TextInput
          placeholder="Negociação"
          label="Negociação"
          name="negprc_pru"
          className={classNames({ 'p-invalid': isFormFieldValid('negprc_pru') }, 'inputfield w-full')}
          value={form.values.negprc_pru}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          onBlur={() => form.setFieldTouched('negprc_pru')}
        />
      </div>

      <div className="field col-12 md:col-6">
        <AutoCompleteInput
          // * AutoComplete Props Padrões
          label="Cliente"
          name="cliente.codigo_pes"
          field="nomraz_pes"
          placeholder="Nome do cliente"
          disabled={!edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}

          // * Template pro campo valor
          itemTemplate={PessoaAutocompleteTemplate}
          value={form.values.cliente && form.values.cliente?.nomraz_pes}

          // * Controle do texto e sugestões
          onSelect={async (e) => await onSelectCliente(e)}
          minLength={1}
          suggestions={sugestaoCliente}
          completeMethod={sugerirCliente}
          onChange={handleChangeCliente}

          // * RegisterModal
          form={form}
          selected={form.values.cliente}
          onSaveModal={() => {}}
          onDeleteModal={async () => await onSelectCliente({})}
          ComponenteCadastro={[
            {
              label: 'Pessoa física',
              component: PessoaFisica,
              header: form.values.cliente?.codigo_pes ? 'Pessoa Física - ' + form.values.cliente?.codigo_pes : 'Cadastro de pessoa física'
            },
            {
              label: 'Pessoa jurídica',
              component: PessoaJuridica,
              header: form.values.cliente?.codigo_pes ? 'Pessoa jurídica - ' + form.values.cliente?.codigo_pes : 'Cadastro de pessoa jurídica'
            }
          ]}
          componenteCadastroProps={{ removerBotaoExcluir: true }}

          // * FiltroModal
          filtrotitle="Pesquisa de cliente"
          semGetInicial
          service={PessoaService}
          model={PessoaModel}
          columns={[
            { campo: 'codigo_pes', nome: 'Código' },
            { campo: 'nomraz_pes', nome: 'Nome' },
            { campo: 'cgccpf_pes', nome: 'CPF/CNPJ', format: formatDocument }
          ]}

          // * RegisterModal && FiltroModal
          primarykeyname="codigo_pes"
        />
      </div>

      <div className="field col-12 md:col-6">
        <AutoCompleteInput
          // * AutoComplete Props Padrões
          label="Atendente"
          name="atendente.codigo_ven"
          field="nomraz_pes"
          placeholder="Nome do atendente"
          disabled={!edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}

          // * Template pro campo valor
          itemTemplate={PessoaAutocompleteTemplate}
          value={form.values.atendente && form.values.atendente?.nomraz_pes}

          // * Controle do texto e sugestões
          onSelect={async (e) => await onSelectAtendente(e)}
          minLength={1}
          suggestions={sugestaoAtendente}
          completeMethod={sugerirAtendente}
          onChange={handleChangeAtendente}

          // * RegisterModal
          form={form}
          selected={form.values.atendente}
          onSaveModal={() => {}}
          onDeleteModal={async () => await onSelectAtendente({})}
          ComponenteCadastro={[
            {
              label: 'Pessoa física',
              component: PessoaFisica,
              header: form.values.atendente?.codigo_ven ? 'Pessoa Física - ' + form.values.atendente?.codigo_ven : 'Cadastro de pessoa física'
            },
            {
              label: 'Pessoa jurídica',
              component: PessoaJuridica,
              header: form.values.atendente?.codigo_ven ? 'Pessoa jurídica - ' + form.values.atendente?.codigo_ven : 'Cadastro de pessoa jurídica'
            }
          ]}
          componenteCadastroProps={{ removerBotaoExcluir: true }}

          // * FiltroModal
          filtrotitle="Pesquisa de atendente"
          semGetInicial
          service={VendedoresService}
          model={VendedorModel}
          columns={[
            { campo: 'codigo_ven', nome: 'Código' },
            { campo: 'nomraz_pes', nome: 'Nome' }
          ]}

          // * RegisterModal && FiltroModal
          primarykeyname="codigo_ven"
        />
      </div>

      <div className="field col-12 ">
        <TextInput
          placeholder="Observação"
          label="Observação"
          name="observ_pru"
          className={classNames({ 'p-invalid': isFormFieldValid('observ_pru') }, 'inputfield w-full')}
          value={form.values.observ_pru}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          onBlur={() => form.setFieldTouched('observ_pru')}
        />
      </div>

    </div>
  )
}

export default DadosPrincipaisProcessoUso
