import ServiceBase from '../../../base/ServiceBase'

export default class PixGeradoService {
  static async gerarPix (dadosPix) {
    return await ServiceBase.postBase('financeiro/integracao/gerarPIX', dadosPix)
  }

  static async cancelarPix (codigoNegociacaoVeiculos, codigosPix) {
    const params = new URLSearchParams()
    params.append('codigosPix', codigosPix.join(','))

    return await ServiceBase.deleteBase(
      `financeiro/integracao/pix-gerado/${codigoNegociacaoVeiculos}?${params.toString()}`
    )
  }

  static async devolverPix (codPIX) {
    return await ServiceBase.putBase(`financeiro/integracao/devolverPix/${codPIX}`)
  }

  static async listarPix (emp, nve) {
    return await ServiceBase.getBase(`financeiro/integracao/getDadosPixByNve/${emp}/${nve}`)
  }

  static async getPixByNveESituacao (codNve, codSit) {
    const params = { codNve, codSit }
    const queryString = new URLSearchParams(params).toString()
    return await ServiceBase.getBase(`financeiro/integracao/getDadosPixByNveESituacao?${queryString}`)
  }
}
