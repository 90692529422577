import { InputText } from 'primereact/inputtext'
import React from 'react'
// https://www.npmjs.com/package/react-input-mask
import InputMask from 'react-input-mask'
import CommonHelper from '../../helpers/CommonHelper'
import AppButton from '../layout/AppButton'
import InputBase from './base/InputBase'

class TextInput extends InputBase {
  getMaskInput () {
    const { iconLeft, camposObrigatorios, ...inputTextProps } = this.props

    return (
      <InputMask
        {...inputTextProps}
        value={inputTextProps.value || ''}
        onChange={(e) => {
          inputTextProps.onChange(
            inputTextProps.mask === "aaa-9*99"
             ? CommonHelper.clearDigitsSpecialCharsExceptLetters(e.target.value)
             : CommonHelper.clearDigitsSpecialChars(e.target.value)
          )
        }}
        onBlur={this.onBlur}
        className={this.getInputClassName()}
      >
        {inputProps => <InputText {...inputProps} className={this.getInputClassName()} disabled={this.props.disabled} ref={this.props.referencia} />}
      </InputMask>
    )
  }

  getTextInput () {
    const { iconLeft, camposObrigatorios, ...inputTextProps } = this.props
    return (

      iconLeft ? (
        <div className="p-inputgroup">
          <InputText
            ref={this.props.referencia}
            {...inputTextProps}
            value={inputTextProps.value || ''}
            onChange={(e) => inputTextProps.onChange(e)}
            onBlur={this.onBlur}
            className={this.getInputClassName()}
          />
          <AppButton
            type="button"
            icon={`pi ${iconLeft}`}
            onClick={() => inputTextProps.onClick()}
          />
        </div>
      )
        : <InputText
          {...inputTextProps}
          ref={this.props.referencia}
          value={inputTextProps.value || ''}
          onChange={(e) => inputTextProps.onChange(e)}
          onBlur={this.onBlur}
          className={this.getInputClassName()}
        />
    )
  }

  renderInput () {
    return this.props.mask ? this.getMaskInput() : this.getTextInput()
  }
}

export default TextInput
