import ServiceBase from '../../base/ServiceBase'

export default class EtapaResponsabilidade {
  async getAll (query, page, perPage) {
    return await ServiceBase.getBase('etapa_responsabilidade')
  }

  static async getAll (query, page, perPage) {
    return await ServiceBase.getBase('etapa_responsabilidade')
  }
}
