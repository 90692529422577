import * as Yup from 'yup'

export const ProcessoUsoValidator = Yup.object().shape({
  processo: Yup.object()
    .shape({
      codigo_prc: Yup.number()
        .nullable()
        .required('Campo processo é obrigatório!')
    })
    .nullable(),
  cliente: Yup.object()
    .shape({
      codigo_pes: Yup.number()
        .nullable()
        .required('Campo cliente é obrigatório!')
    })
    .nullable(),
  atendente: Yup.object()
    .shape({
      codigo_ven: Yup.number()
        .nullable()
        .required('Campo atendente é obrigatório!')
    })
    .nullable()
})
