import React, { useEffect, useState } from 'react'
import DateInput from '../../../../../components/inputs/DateInput'
import NumberInput from '../../../../../components/inputs/NumberInput'
import SelectInput from '../../../../../components/inputs/SelectInput'
import TextAreaInput from '../../../../../components/inputs/TextAreaInput'
import TextInput from '../../../../../components/inputs/TextInput'
import CommonHelper from '../../../../../helpers/CommonHelper'
import { getStore } from '../../../../../redux/store'
import EstadosService from '../../../../../services/cadastro/pessoa/EstadosService'
import VeiculoService from '../../../../../services/cadastro/veiculo/VeiculoService'

const DadosAdicionaisVei = ({ form, edicaoHabilitada }) => {
  const [estados, setEstados] = useState([])
  const [cambios, setCambios] = useState([])
  const [cores, setCores] = useState([])

  useEffect(() => {
    requisicoesIniciais()
  }, [])

  async function requisicoesIniciais() {
    await Promise.all([
      getEstadosPorPais(getStore().codigoBrasil),
      getCambios(),
      getCores()
    ])
  }

  const getCores = async () => {
    try {
      await VeiculoService.getAllColors().then(cores => setCores(cores))
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao tentar listar as cores')
    }
  }

  const getCambios = async () => {
    try {
      await VeiculoService.getAllCambios().then(cambios => setCambios(cambios))
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao tentar listar os cambios')
    }
  }

  const getEstadosPorPais = async (id) => {
    try {
      await EstadosService.getAllByCountry(id).then(estados => setEstados(estados))
    } catch (e) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar os estados por pais!')
    }
  }

  const handleChangeProduto = (e) => {
    form.setFieldValue(`produto.${e.target.name}`, e.target.value)
  }

  return (
    <div className="formgrid grid">
      <div className="field col-12 sm:col-8 md:col-4">
        <TextInput
          label={window.innerWidth < 1266 ? 'Cap. e pot.' : 'Capacidade e potência'}
          placeholder="Capacidade e potência"
          name="cappot_vei"
          value={form.values.cappot_vei}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          maxLength="30"
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 sm:col-4 md:col-2">
        <TextInput
          label="Número do motor"
          placeholder="Número do motor"
          name="nummot_vei"
          value={form.values.nummot_vei}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          maxLength="30"
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 sm:col-12 md:col-6">
        <TextInput
          label="Características do modelo"
          placeholder="Características do modelo"
          name="carmod_vei"
          value={form.values.carmod_vei}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          maxLength="60"
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 sm:col-6 md:col-3 disabled-input">
        <SelectInput
          value={form.values.cambio}
          options={cambios}
          onChange={form.handleChange}
          label="Tipo de câmbio"
          name="cambio"
          filter={CommonHelper.isDesktop()}
          optionLabel="descri_cam"
          dataKey="codigo_cam"
          noFloatLabel
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
          showClear={form.values.cambio?.codigo_cam}
        />
      </div>
      <div className="field col-12 sm:col-6 md:col-3">
        <SelectInput
          value={form.values?.corInterna}
          options={cores}
          onChange={(e) => {
            form.setFieldValue('corInterna', e.target.value)
          }}
          name="corInterna.codigo_cor"
          label="Cor Interna"
          filter={CommonHelper.isDesktop()}
          optionLabel="descri_cor"
          dataKey="codigo_cor"
          noFloatLabel
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
          showClear={form.values.corInterna?.codigo_cor}
        />
      </div>
      <div className="field col-12 sm:col-7 md:col-4">
        <TextInput
          label="Cidade"
          placeholder="Cidade"
          name="cidade_vei"
          value={form.values.cidade_vei}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          maxLength={100}
          className="inputfield w-full" />
      </div>
      <div className="field col-12 sm:col-5 md:col-2 disabled-input">
        <SelectInput
          value={form.values.estado}
          options={estados}
          onChange={form.handleChange}
          label="Estado"
          name="estado"
          filter={CommonHelper.isDesktop()}
          optionLabel="descri_uf"
          dataKey="codigo_uf"
          noFloatLabel
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
          showClear={form.values.estado?.codigo_uf}
        />
      </div>
      
      
      <div className="field col-6 sm:col-3 md:col-1">
        <label className="label">Portas</label>
        <NumberInput
          placeholder="Portas"
          name="qtdpor_vei"
          value={form.values.qtdpor_vei}
          onChange={(e) => form.setFieldValue('qtdpor_vei', e.value)}
          disabled={!edicaoHabilitada}
          mode="decimal"
          useGrouping={false}
          min="0"
          className="inputfield w-full"
        />
      </div>
      <div className="field col-6 sm:col-3 md:col-1">
        <NumberInput
          label="Válvulas"
          placeholder="Válvulas"
          value={form.values.qtdval_vei}
          onChange={(e) => form.setFieldValue('qtdval_vei', e.value)}
          disabled={!edicaoHabilitada}
          min="0"
          className="inputfield w-full"
        />
      </div>
      <div className="field col-6 sm:col-3 md:col-2">
        <NumberInput
          label="Peso bruto"
          placeholder="Peso bruto"
          value={form.values.produto.pesbru_pro}
          onChange={(e) => form.setFieldValue('produto.pesbru_pro', e.value)}
          disabled={!edicaoHabilitada}
          min="0"
          className="inputfield w-full"
        />
      </div>
      <div className="field col-6 sm:col-3 md:col-2">
        <NumberInput
          label="Peso líquido"
          placeholder="Peso líquido"
          value={form.values.produto.pesliq_pro}
          onChange={(e) => form.setFieldValue('produto.pesliq_pro', e.value)}
          disabled={!edicaoHabilitada}
          min="0"
          className="inputfield w-full"
        />
      </div>
      
      <div className="field col-12 sm:col-6 md:col-3">
        <TextInput
          label="Placa anterior"
          placeholder="Placa anterior"
          name="plaant_vei"
          value={form.values.plaant_vei}
          onChange={(value) => form.setFieldValue('plaant_vei', value)}
          disabled={!edicaoHabilitada}
          mask="aaa-9*99"
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 sm:col-6 md:col-3">
        <SelectInput
          value={form.values.estadoAnterior}
          options={estados}
          onChange={form.handleChange}
          label="Estado da placa anterior"
          name="estadoAnterior"
          filter={CommonHelper.isDesktop()}
          optionLabel="descri_uf"
          dataKey="codigo_uf"
          noFloatLabel
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
          showClear={form.values.estadoAnterior?.codigo_uf}
        />
      </div>
      <div className="field col-12 sm:col-4 md:col-3">
        <DateInput
          label="Data da venda"
          name="datven_vei"
          disabled={!edicaoHabilitada}
          value={form.values.datven_vei}
          onChange={form.handleChange}
          className="inputfield w-full" />
      </div>
      <div className="field col-12 sm:col-4 md:col-3">
        <DateInput
          label="Garantia de "
          name="datinigar_vei"
          disabled={!edicaoHabilitada}
          value={form.values.datinigar_vei}
          onChange={form.handleChange}
          className="inputfield w-full" />
      </div>
      <div className="field col-12 sm:col-4 md:col-3">
        <DateInput
          label="Garantia até "
          name="datfimgar_vei"
          disabled={!edicaoHabilitada}
          value={form.values.datfimgar_vei}
          onChange={form.handleChange}
          className="inputfield w-full" />
      </div>
      <div className="field col-12 sm:col-12 md:col-6">
        <TextAreaInput
          placeholder="Observações"
          label="Observações"
          name="obs_vei"
          value={form.values.obs_vei}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          autoResize
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 sm:col-12 md:col-6">
        <TextAreaInput
          placeholder="Observações da nota"
          label="Observações da nota"
          name="obsnot_pro"
          value={form.values.produto.obsnot_pro}
          onChange={handleChangeProduto}
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
        />
      </div>
      {/* ///////////////////////////////////////// */}
      <div className="field col-12 md:col-6 separator">
        <TextInput
          label="Inserido por"
          placeholder="Inserido por"
          name="nomraz_pes"
          value={form.values.produto.usuarioInsercao?.nomraz_pes}
          disabled="disabled"
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-6 separator">
        <DateInput
          label="Inserido em"
          placeholder="Inserido em"
          name="datreg_pro"
          value={form.values.produto.datreg_pro}
          disabled="disabled"
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-6">
        <TextInput
          label="Alterado por"
          placeholder="Alterado por"
          name="nomraz_pes"
          value={form.values.produto.usuarioAlteracao?.nomraz_pes}
          disabled="disabled"
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-6">
        <DateInput
          label="Alterado em"
          placeholder="Alterado em"
          name="ultalt_pro"
          value={form.values.produto.ultalt_pro}
          disabled="disabled"
          className="inputfield w-full"
        />
      </div>
    </div>
  )
}

export default DadosAdicionaisVei
