import ServiceBase from '../../base/ServiceBase'

export default class AcompanhamentoService {
  static async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`acompanhamento?${query}&page=${page}&perPage=${perPage}`)
  }

  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`acompanhamento?${query}&page=${page}&perPage=${perPage}`)
  }

  static async filter (query, page, perPage) {
    return await ServiceBase.getBase(`acompanhamento?${query}&page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`acompanhamento?${query}&page=${page}&perPage=${perPage}`)
  }
}
