import React from 'react'
import { Crud } from '../../../../classes/NewCrud'
import { getTipoEtapaDTO } from '../../../../dtos/processo/TipoEtapaDTO'
import TipoEtapaService from '../../../../services/processo/TipoEtapaService'
import DadosPrincipaisTipoEtapa from './main'
import { TipoEtapaValidator } from './validator'
import AccordionContainer from '../../../../components/layout/AccordionContainer'
import AccordionPage from '../../../../components/layout/AccordionPage'

const TipoEtapaForm = ({ form, edicaoHabilitada, camposObrigatorios, isNew }) => {
  return (
    <AccordionContainer>
      <AccordionPage header="Informações Principais" active>
        <DadosPrincipaisTipoEtapa
          form={form}
          edicaoHabilitada={edicaoHabilitada}
        />
      </AccordionPage>
    </AccordionContainer>
  )
}

const TipoEtapaCrud = (props) => {
  return (
    <Crud
      {...props}
      formContent={TipoEtapaForm}
      valida={TipoEtapaValidator}
      modelGetDTO={getTipoEtapaDTO}
      service={TipoEtapaService}
      resource="tipo-etapa-processo"
      primarykeyname="codigo_tet"
      formTitle="Tipo de Etapa"
    />
  )
}

export default TipoEtapaCrud
