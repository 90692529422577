
export const getProcessoUsoDTO = (data = {}) => {
  return {
    ...data,
    codigo_pru: data.codigo_pru || null,
    veiprc_pru: data.veiprc_pru || '',
    negprc_pru: data.negprc_pru || '',
    observ_pru: data.observ_pru || '',
    cliente: {
      codigo_pes: data?.pessoa?.codigo_pes,
      nomraz_pes: data?.pessoa?.nomraz_pes,
      cgccpf_pes: data?.pessoa?.cgccpf_pes
    },
    atendente: {
      codigo_ven: data?.atendente?.codigo_pes,
      nomraz_pes: data?.atendente?.nomraz_pes
    },
    processo: {
      codigo_prc: data?.processo?.codigo_prc,
      descri_prc: data?.processo?.descri_prc,
      ativo_prc: data?.processo?.ativo_prc
    },
    status: {
      codigo_spr: data?.status?.codigo_spr,
      descri_spr: data?.status?.descri_spr
    },
    codate_pru: data?.atendente?.codigo_ven,
    codpes_pru: data?.pessoa?.codigo_pes
  }
}

export const postProcessoUsoDTO = (data = {}) => {
  return {
    ...data,
    cliente: {
      codigo_pes: data?.cliente?.codigo_pes,
      nomraz_pes: data?.cliente?.nomraz_pes,
      cgccpf_pes: data?.cliente?.cgccpf_pes
    },
    atendente: {
      codigo_ven: data?.atendente?.codigo_ven,
      nomraz_pes: data?.atendente?.nomraz_pes
    },
    processo: {
      codigo_prc: data?.processo?.codigo_prc,
      descri_prc: data?.processo?.descri_prc
    },
    codate_pru: data?.atendente?.codigo_ven,
    codprc_pru: data?.processo?.codigo_prc,
    codpes_pru: data?.cliente?.codigo_pes
  }
}
