import { classNames } from 'primereact/utils'
import React, { useEffect, useState } from 'react'
import SelectInput from '../../../../../components/inputs/SelectInput'
import FieldErrorMessage from '../../../../../components/utils/FieldErrorMessage'
import { showErrorMessage } from '../../../../../components/utils/Message'
import RequiredLabel from '../../../../../components/utils/RequiredLabel'
import { useValidateInput } from '../../../../../helpers/useValidateInput'
import ClasseICMSProdutoService from '../../../../../services/cadastro/produto/ClasseICMSProdutoService'
import ClassificacaoFiscalService from '../../../../../services/cadastro/produto/ClassificacaoFiscalService'
import GrupoProdutoService from '../../../../../services/cadastro/produto/GrupoProdutoService'
import OrigemProdutoService from '../../../../../services/cadastro/produto/OrigemProdutoService'
import ConfigService from '../../../../../services/configuracao/ConfigService'

const DadosFiscaisVei = ({ form, edicaoHabilitada, isNew }) => {
  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)
  const [classesICMS, setClassesICMS] = useState([])
  const [gruposProduto, setGruposProduto] = useState([])
  const [origensProduto, setOrigensProduto] = useState([])
  const [classificacoesFiscais, setClassificacoesFiscais] = useState([])
  const [substituicaoTributaria, setSubstituicaoTributaria] = useState(false)
  const [classificacaoFiscalSelecionada] = useState(form.values.produto.classificacaoFiscal)
  const [pisCofins, setPisCofins] = useState('')

  useEffect(async () => {
    await getClassesICMS()
    await getGruposProduto()
    await getOrigensProduto()
    await getClassificacoesFiscais()

    if (!isNew)
      await getPisCofinsAndSubstituicaoTributaria()
  }, [])

  const getClassificacoesFiscais = async () => {
    try {
      const classificacoesFiscais = await ClassificacaoFiscalService.getAll()
      setClassificacoesFiscais(classificacoesFiscais)
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao tentar listar as classificações fiscais')
    }
  }

  const getOrigensProduto = async () => {
    try {
      const origensProduto = await OrigemProdutoService.getAll()
      setOrigensProduto(origensProduto)
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao tentar listar as origens dos produtos')
    }
  }

  const getClassesICMS = async () => {
    try {
      const classesICMS = await ClasseICMSProdutoService.getAll()

      if (isNew && !form.values?.classeICMS) {
        let classeICMS

        if (form.values.codtiv_vei === 'n') {
          classeICMS = await ConfigService.getValor(1392)
        } else {
          classeICMS = await ConfigService.getValor(1023)
        }

        const classeICMSFind = classesICMS?.find((valor) => {
          return valor.codigo_cla === classeICMS
        })
        await form.setFieldValue('produto.codcla_pro', classeICMS)
        await form.setFieldValue('classeICMS', classeICMSFind)
      }

      setClassesICMS(classesICMS)
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao tentar listar as classes de ICMS')
    }
  }

  const getGruposProduto = async () => {
    try {
      const gruposProduto = await GrupoProdutoService.getAll()
      setGruposProduto(gruposProduto)
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao tentar listar os grupos dos produtos')
    }
  }

  const getPisCofinsAndSubstituicaoTributaria = async () => {
    try {
      const { substituicaoTributaria, pisCofins } = await ClassificacaoFiscalService.getSubstituicaoTributariaPisCofins({
        ncm_clf: classificacaoFiscalSelecionada?.ncm_clf || '',
        codigo_clf: classificacaoFiscalSelecionada?.codigo_clf || ''
      })

      setSubstituicaoTributaria(substituicaoTributaria)
      setPisCofins(pisCofins)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar a substituição tributaria!')
    }
  }

  const handleChangeOrigemProduto = (e) => {
    const origemProduto = e.target.value
    form.setFieldValue('produto.codorp_pro', origemProduto?.codigo_orp)
    form.setFieldValue('origemProduto', origemProduto)
  }

  const handleChangeClasseICMS = (e) => {
    const classeICMS = e.target.value
    form.setFieldValue('produto.codcla_pro', classeICMS?.codigo_cla)
    form.setFieldValue('classeICMS', classeICMS)
  }

  const handleChangeGrupoProduto = (e) => {
    const grupoProduto = e.target.value
    form.setFieldValue('produto.codgrp_pro', grupoProduto?.codigo_grp)
    form.setFieldValue('grupoProduto', grupoProduto)
  }

  const handleChangeClassificacaoFiscal = async (e) => {
    try {
      const classificacaoFiscal = e.target.value

      form.setFieldValue('produto.codclf_pro', classificacaoFiscal?.codigo_clf)
      form.setFieldValue('classificacaoFiscal', classificacaoFiscal)

      const { substituicaoTributaria, pisCofins } = await ClassificacaoFiscalService.getSubstituicaoTributariaPisCofins({
        ncm_clf: classificacaoFiscal?.ncm_clf,
        codigo_clf: classificacaoFiscal?.codigo_clf
      })

      setSubstituicaoTributaria(substituicaoTributaria)
      setPisCofins(pisCofins)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar a substituição tributaria!')
    }
  }

  const origemItemTemplate = (option) => {
    return (
      <div className="selectinput-option-container">
        {option.descri_orp}
      </div>
    )
  }

  return (
    <div className="formgrid grid">
      <div className="field col-12 md:col-6">
        <RequiredLabel label="Classe de ICMS" valid={isFormFieldValid('produto.codcla_pro')} />
        <SelectInput
          value={form.values.classeICMS}
          options={classesICMS}
          onChange={handleChangeClasseICMS}
          name="classeICMS"
          optionLabel="descri_cla"
          dataKey="codigo_cla"
          noFloatLabel
          disabled={!edicaoHabilitada}
          className={classNames({ 'p-invalid': isFormFieldValid('produto.codcla_pro') }, 'inputfield w-full')}
          onHide={() => form.setFieldTouched('produto.codcla_pro')}
        />
        <FieldErrorMessage message={getFormErrorMessage('produto.codcla_pro')} />
      </div>
      <div className="field col-12 md:col-6">
        <SelectInput
          value={form.values.grupoProduto}
          options={gruposProduto}
          onChange={handleChangeGrupoProduto}
          label="Grupo"
          name="grupoProduto"
          optionLabel="descri_grp"
          dataKey="codigo_grp"
          noFloatLabel
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
          showClear={form.values.grupoProduto?.codigo_grp}
        />
      </div>
      <div className="field col-12 md:col-6">
        <SelectInput
          value={form.values.origemProduto}
          options={origensProduto}
          onChange={handleChangeOrigemProduto}
          itemTemplate={origemItemTemplate}
          label="Origem"
          name="origemProduto"
          optionLabel="descri_orp"
          dataKey="codigo_orp"
          noFloatLabel
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
          panelClassName="overflowing-values"
        />
      </div>
      <div className="field col-12 md:col-6">
        <label className="label">Classificação fiscal</label>
        <SelectInput
          value={form.values.classificacaoFiscal}
          options={classificacoesFiscais}
          onChange={handleChangeClassificacaoFiscal}
          name="classificacaoFiscal"
          optionLabel={option => `${option.ncm_clf} - ${option.descri_clf}`}
          dataKey="ncm_clf"
          filter
          noFloatLabel
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
          onBlur={() => form.setFieldTouched('produto.codclf_pro')}
          showClear={form.values.classificacaoFiscal?.ncm_clf}
        />
      </div>
      <div className="col-12 md:col-12" style={{ textAlign: 'right', paddingRight: 12 }}>
        <p style={{ margin: 0 }}>
          Substituição tributária: {substituicaoTributaria ? 'Sim' : 'Não'}.
          PIS/COFINS: {pisCofins}
        </p>
      </div>
    </div>
  )
}

export default DadosFiscaisVei
