import { classNames } from 'primereact/utils'
import React from 'react'
import TextInput from '../../../../../components/inputs/TextInput'
import FieldErrorMessage from '../../../../../components/utils/FieldErrorMessage'
import RequiredLabel from '../../../../../components/utils/RequiredLabel'
import { useValidateInput } from '../../../../../helpers/useValidateInput'
import { Checkbox } from 'primereact/checkbox'

const DadosPrincipaisModeloProcesso = ({ form, edicaoHabilitada }) => {
  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)

  return (
    <div className="formgrid grid">
      <div className="field col-12 md:col-10">
        <RequiredLabel label="Descrição" valid={isFormFieldValid('descri_prc')} />
        <TextInput
          placeholder="Descrição"
          name="descri_prc"
          className={classNames({ 'p-invalid': isFormFieldValid('descri_prc') }, 'inputfield w-full')}
          value={form.values.descri_prc}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          onBlur={() => form.setFieldTouched('descri_prc')}
        />
        <FieldErrorMessage message={getFormErrorMessage('descri_prc')} />
      </div>

      <div className="checkbox-container col-12 md:col-2 flex justify-content-center">
        <Checkbox
          value={form.values.ativo_prc}
          onChange={(e) => {
            form.setFieldValue('ativo_prc', !e.target.value)
          }}
          checked={form.values.ativo_prc}
          name="ativo_prc"
          disabled={!edicaoHabilitada}
        />
        <label htmlFor="ativo_prc" className="p-checkbox-label mb-0 pl-2">Processo ativo?</label>
      </div>

    </div>
  )
}

export default DadosPrincipaisModeloProcesso
