import './_aprovacaoNegociacao.scss'

import { classNames } from 'primereact/utils'
import { stringify } from 'query-string'
import React from 'react'
import List from '../../../../classes/List'
import AppButton from '../../../../components/layout/AppButton'
import Container from '../../../../components/layout/Container'
import DataList from '../../../../components/layout/DataList'
import Page from '../../../../components/layout/Page'
import Confirm from '../../../../components/utils/Confirm'
import { showErrorMessage, showSuccessMessage } from '../../../../components/utils/Message'
import PreferencesButton from '../../../../components/utils/PreferencesButton'
import TablePreferences from '../../../../components/utils/TablePreferences'
import CommonHelper from '../../../../helpers/CommonHelper'
import { setFilterConfig } from '../../../../helpers/FilterConfigs'
import { formatDate } from '../../../../helpers/formaters'
import { createColumns, getColunasHabilitadas } from '../../../../helpers/tableConfigs'
import { NegociacaoModel } from '../../../../models/vendas/negociacao/NegociacaoModel'
import { generateMenuSideBar } from '../../../../redux/actions'
import { dispatch } from '../../../../redux/store'
import MenuWebService from '../../../../services/cadastro/pessoa/MenuWebService'
import PermissaoService from '../../../../services/cadastro/pessoa/PermissaoService'
import UsuarioService from '../../../../services/cadastro/pessoa/UsuarioService'
import AprovacaoNegociacaoService from '../../../../services/vendas/negociacao/AprovacaoNegociacaoService'

export class AprovacaoNegociacaoList extends List {
  constructor (props) {
    super(
      props,
      NegociacaoModel,
      AprovacaoNegociacaoService,
      'negociacao',
      'codigo_neg'
    )

    this.state = {
      ...this.state,
      visiblePreferencesModal: false,
      expandedRows: null,
      negociacao: {},
      confirmaAprovarNeg: false,
      codNegParaAprovar: null,
      permissaoParaAprovarNegociacoes: false,
      possuiAcessoLucroVendas: false
    }

    this.setColumns(createColumns([
      { field: 'codigo_neg', header: 'Número', width: '10%' },
      //{ field: 'nomeEmpresa', header: 'Empresa', width: '15%' },
      { field: 'consig_nve', header: 'Próprio / Consignado', width: '15%', customBody: this.getTipoVeiculoProprioConsignado },
      { field: 'descri_vei', header: 'Veículo', width: '20%' },
      { field: 'operac_neg', header: 'Operação', width: '20%', customBody: this.getOperacaoNeg, customClassName: this.getOperacaoNegClassName.bind(this) },
      { field: 'nomeCliente', header: 'Cliente', width: '20%' },
      { field: 'nomeVendedor', header: 'Vendedor', width: '15%' },
      { field: 'datfin_neg', header: 'Fechamento', width: '10%', customBody: this.getDataFechamentoNegociacao }
    ]))
  }

  async componentDidMount () {
    await super.componentDidMount()

    const permissaoParaAprovarNegociacoes = await PermissaoService.getByCodigo('12')
    await this.getVisaoLucroVenda()
    this.setState({ permissaoParaAprovarNegociacoes })
  }

  getTipoVeiculoProprioConsignado (data) {
    return data.consig_nve ? 'Consignado' : 'Próprio'
  }

  async getVisaoLucroVenda () {
    let possuiAcessoLucroVendas = await MenuWebService.getMenuLucroVenda()
    this.setState({ possuiAcessoLucroVendas })
  }

  getOperacaoNeg (data) {
    let operacaoNeg = 'Compra'

    switch (data.operac_neg) {
    case 2:
      operacaoNeg = 'Venda'
      break
    case 5:
      if (data.status_neg === 3) {
        operacaoNeg = 'Consignação (Pagamento)'
      } else {
        operacaoNeg = 'Consignação (Entrada)'
      }

      break
    case 98:
      operacaoNeg = 'Troca'
      break
    }

    return operacaoNeg
  }

  getOperacaoNegClassName (data) {
    return `operacaoneg-${this.getOperacaoNeg(data)}`
  }

  getDataFechamentoNegociacao (data) {
    return formatDate(data.datfin_neg)
  }

  handleAprovarButtonClick = async () => {
    await this.verificaPermissaoParaAprovarNegComManutencoesEmAberto()
  }

  aprovarNegociacao = async () => {
    try {
      await AprovacaoNegociacaoService.aprovarNegociacao(this.state.codNegParaAprovar)

      //dispatch(await atualizarQuantidadeNegociacoesPendenteAprovacao())
      dispatch(generateMenuSideBar())

      await this.getAll()

      showSuccessMessage('Negociação aprovada com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar aprovar a negociação')
    }
  }

  verificaPermissaoParaAprovarNegComManutencoesEmAberto = async () => {
    try {
      const pedirConfirmacaoAprovarNegComManutencaoEmAberto = await AprovacaoNegociacaoService
        .verificaPermissaoParaAprovarNegComManutencoesEmAberto(this.state.codNegParaAprovar)

      if (pedirConfirmacaoAprovarNegComManutencaoEmAberto) {
        this.showCustomConfirm(
          'Confirmação de aprovação',
          'Existem manutenções em aberto para os veículos vendidos. Confirmar aprovar a negociação mesmo assim?',
          this.verificaPermissaoParaAprovarNegComOrdemDeServicoEmAberto
        )
      } else {
        this.verificaPermissaoParaAprovarNegComOrdemDeServicoEmAberto()
      }
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar aprovar a negociação')
    }
  }

  verificaPermissaoParaAprovarNegComOrdemDeServicoEmAberto = async () => {
    try {
      const pedirConfirmacaoAprovarNegComOrdemDeServicoEmAberto = await AprovacaoNegociacaoService
        .verificaPermissaoParaAprovarNegComOrdemDeServicoEmAberto(this.state.codNegParaAprovar)

      if (pedirConfirmacaoAprovarNegComOrdemDeServicoEmAberto) {
        this.showCustomConfirm(
          'Confirmação de aprovação',
          'Existem ordem de serviço em aberto para os veículos vendidos. Confirmar aprovar a negociação mesmo assim?',
          this.aprovarNegociacao
        )
      } else {
        this.aprovarNegociacao()
      }
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar aprovar a negociação')
    }
  }

  async showLucroDeVendasReportByNegociacao (negociacao) {
    const empresasAcessoUsuarioLogado = await UsuarioService.getEmpresasAcessoUsuarioLogado()

    const selectedOptionsEmpresas = {
      0: {
        checked: true,
        partialChecked: false
      }
    }

    empresasAcessoUsuarioLogado.forEach((empresa, idx) => {
      selectedOptionsEmpresas[`0-${idx}`] = {
        checked: true,
        partialChecked: false
      }
    })

    const form = {
      dataNegociacaoFinal: negociacao.data_neg,
      dataNegociacaoInicial: negociacao.data_neg,
      empresas: empresasAcessoUsuarioLogado.map(empresa => empresa.codigo_pes),
      negociacao: negociacao,
      tipoNeg: 'Todos',
      tipoVeiculo: 'Veículos novos e usados',
      tipoVenda: 'Vendas diretas e normais',
      vendaNormalRepasse: 'Vendas normais e repasses',
      selectedOptionsEmpresas
    }

    const filters = {
      empresas: form.empresas,
      negociacao: form.negociacao?.codigo_neg,
      dataNegociacaoInicial: CommonHelper.dateToAmerican(new Date(form.dataNegociacaoInicial)),
      dataNegociacaoFinal: CommonHelper.dateToAmerican(new Date(form.dataNegociacaoFinal)),
      tipoNeg: form.tipoNeg,
      tipoVeiculo: form.tipoVeiculo,
      tipoVenda: form.tipoVenda,
      vendaNormalRepasse: form.vendaNormalRepasse
    }

    const queryFilters = stringify(filters, { skipNull: true, skipEmptyString: true, encode: false })

    const filterConfig = { form, queryFilters }

    setFilterConfig('RelatórioDeLucroDeVendas', filterConfig)

    window.location.href = 'lucro-de-venda'
  }

  render () {
    const { rows, totalRecords, first } = this.state
    const columns = this.columns
    const rowExpansionTemplate = (data) => {
      return (
        <div className={'aprovacao-negociacao row-expansion grid justify-content-' + (CommonHelper.isDesktop() ? 'end' : 'center')}>
          {(data.operac_neg === 2 && this.state.possuiAcessoLucroVendas) && (
            <AppButton
              label="Ver lucratividade"
              onClick={async () => {
                await this.showLucroDeVendasReportByNegociacao(data)
              }}
              className={(CommonHelper.isDesktop() ? 'mr-3' : 'mb-3') + ' aprovacao-negociacao button'}
            />
          )}
          {this.state.permissaoParaAprovarNegociacoes && (
            <AppButton
              label="Aprovar"
              onClick={() => this.setState({ codNegParaAprovar: data.codigo_neg }, this.handleAprovarButtonClick)}
              className={(CommonHelper.isDesktop() ? 'mr-3' : 'mb-3') + ' aprovacao-negociacao button'}
            />
          )}
          <AppButton
            label="Visualizar negócio"
            onClick={() => this.onView(data)}
            className="aprovacao-negociacao button"
          />
        </div>
      )
    }

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Aprovação</h4>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: this.state.opcaoSelecionada === 't' }, 'filter-option')}>
              <span className="option-label">Pendentes</span>
              <div className="option-quantity">{this.state.totalRecords}</div>
            </div>
          </div>
          <div className="table-preferences">
            <PreferencesButton
              onClick={() => this.setState({ visiblePreferencesModal: true })}
            />
          </div>
        </div>
        <Container>
          <DataList
            data={this.state.list}
            selected={this.state.selected}
            rows={rows}
            onSelect={this.onSelect}
            onRowClick={(e) => this.handleRowExpansion(e.data)}
            totalRecords={totalRecords}
            first={first}
            onPage={this.onPageChange}
            page={this.state.page}
            showFilter={true}
            filterPlaceholder="Procurar negócio pendente de aprovação"
            filterName="descricaoFiltro"
            responsive={true}
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
            expandedRows={this.state.expandedRows}
            rowExpansionTemplate={rowExpansionTemplate}
            customMaxHeight={'unset'}
          >
            {getColunasHabilitadas(columns, 'aprovacaoNegociacao')}
          </DataList>
        </Container>
        <TablePreferences
          title="Preferências da tabela de negócios"
          tableName="aprovacaoNegociacao"
          columns={columns}
          visible={this.state.visiblePreferencesModal}
          hide={() => this.setState({ visiblePreferencesModal: false })}
        />
        <Confirm
          visible={this.state.visibleConfirm}
          onCancel={() => this.cancelConfirm()}
          onConfirm={() => {
            this.cancelConfirm()
            this.state.onConfirmCallback()
          }}
          title={this.state.confirmTitle}
          description={this.state.confirmDescription}
        />
      </Page>
    )
  }
}

export default AprovacaoNegociacaoList
