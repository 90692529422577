import React, { Component } from 'react'
import CommonHelper from '../../../helpers/CommonHelper'
import { getFilterConfig } from '../../../helpers/FilterConfigs'
import Loading from '../Loading'
import ReportComponent from './ReportComponent'

class ReportBase extends Component {
  constructor (props, reportTitle, reportService, reportFiltroModalComponent, reportFooterComponent, gerarXML = true) {
    super(props)

    this.reportService = new reportService()

    this.state = {
      loading: true,
      columns: null,
      grouping: null,
      reportData: null,
      totalizadores: null,
      reportTitle,
      reportFooterComponent,
      reportFiltroModalComponent,
      gerarXML
    }
  }

  async componentDidMount () {
    await this.loadReportData()
  }

  async loadReportData () {
    const reportFilterConfig = getFilterConfig(CommonHelper.formatStringToPascalCase(this.state.reportTitle))

    let sqlData

    if (reportFilterConfig) {
      sqlData = await this.reportService.filter(reportFilterConfig.queryFilters)
    } else {
      sqlData = await this.reportService.getAll()
    }

    this.setReportData(sqlData)
  }

  componentDidUpdate () {
    if (this.isAllRequiredParametersOKAndStillLoading()) {
      this.stopLoading()
    }
  }

  isAllRequiredParametersOKAndStillLoading () {
    const state = this.state
    return state.columns && state.reportData && state.loading
  }

  stopLoading () {
    this.setState({ loading: false })
  }

  setColumns (columns) {
    this.setState({ columns })
  }

  setGrouping (grouping) {
    this.setState({ grouping })
  }

  setReportData (reportData) {
    this.setState({ reportData })
  }

  setLegendaReport (legendas) {
    const legendaReport = legendas.map(legenda => `• ${legenda}`)

    this.setState({ legendaReport })
  }

  setTotalizadores (totalizadores) {
    this.setState({ totalizadores })
  }

  render () {
    const state = this.state

    return !state.loading ? (
      <ReportComponent
        reportData={state.reportData}
        setReportData={(reportData) => this.setReportData(reportData)}
        reportTitle={state.reportTitle}
        columns={state.columns}
        grouping={state.grouping}
        legendaReport={state.legendaReport}
        totalizadores={state.totalizadores}
        ReportFiltroModalComponent={state.reportFiltroModalComponent}
        ReportFooterComponent={state.reportFooterComponent}
        gerarXML={state.gerarXML}
      />
    ) : (
      <>
        {state.reportData && (
          <Loading />
        )}
      </>
    )
  }
}

export default ReportBase
