import { formatCpf, formatPhone } from '../../../../helpers/formaters'

import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'
import React from 'react'
import List from '../../../../classes/List'
import Container from '../../../../components/layout/Container'
import DataList from '../../../../components/layout/DataList'
import Page from '../../../../components/layout/Page'
import PreferencesButton from '../../../../components/utils/PreferencesButton'
import TablePreferences from '../../../../components/utils/TablePreferences'
import CommonHelper from '../../../../helpers/CommonHelper'
import { createColumns, getColunasHabilitadas } from '../../../../helpers/tableConfigs'
import { PessoaFisicaModel } from '../../../../models/cadastro/pessoa/PessoaFisicaModel'
import PessoaFisicaService from '../../../../services/cadastro/pessoa/PessoaFisicaService'

export class PessoaFisicaList extends List {
  constructor (props) {
    super(
      props,
      PessoaFisicaModel,
      PessoaFisicaService,
      'pessoa-fisica',
      'codigo_pef'
    )

    this.state = {
      ...this.state,
      visiblePreferencesModal: false
    }

    this.setColumns(createColumns([
      { field: 'codigo_pef', header: 'Código', width: '6%' },
      { field: 'nomraz_pes', header: 'Nome', width: '30%' },
      { field: 'cgccpf_pes', header: 'CPF', width: '13,75%', customBody: this.getCpf },
      { field: 'telcel_pes', header: 'Telefone', width: '13,75%', customBody: this.getTelefone },
      { field: 'cidade_pes', header: 'Cidade', width: '13,75%' },
      { field: 'uf_pes', header: 'Estado', width: '6%' },
      { field: 'datins_pes', header: 'Criado em', width: '13,75%', customBody: this.getDataInsercao }
    ]))
  }

  getCpf (data) {
    return formatCpf(data.cgccpf_pes)
  }

  getTelefone (data) {
    return formatPhone(data.telcom_pes) || (formatPhone(data.telcel_pes) ? formatPhone(data.telcel_pes) : formatPhone(data.telres_pes))
  }

  getDataInsercao (data) {
    return CommonHelper.dateToBrazilian(data.datins_pes)
  }

  render () {
    const { rows, totalRecords, first } = this.state

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Pessoas Físicas</h4>
          <div className="page-header-buttons">
            <Button
              className="mr-2"
              label={CommonHelper.isDesktop() && 'Novo'}
              icon="pi pi-plus"
              onClick={this.onNew}
            />
          </div>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: this.state.opcaoSelecionada === 't' }, 'filter-option')}>
              <span className="option-label">Todas</span>
              <div className="option-quantity">
                {this.state.totalRecords}
              </div>
            </div>
          </div>
          <div className="table-preferences">
            <PreferencesButton
              onClick={() => this.setState({ visiblePreferencesModal: true })}
            />
          </div>
        </div>
        <Container>
          <DataList
            data={this.state.list}
            selected={this.state.selected}
            rows={rows}
            onSelect={this.onSelect}
            totalRecords={totalRecords}
            first={first}
            onPage={this.onPageChange}
            page={this.state.page}
            onRowSelect={(e) => this.onView(e.data)}
            showFilter={true}
            filterPlaceholder="Procurar pessoas físicas"
            filterName="descricaoFiltro"
            responsive={true}
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
            customMaxHeight={'unset'}
          >
            {getColunasHabilitadas(this.columns, 'pessoaf')}
          </DataList>
        </Container>
        <TablePreferences
          title="Preferências da tabela de pessoa física"
          tableName="pessoaf"
          columns={this.columns}
          visible={this.state.visiblePreferencesModal}
          hide={() => this.setState({ visiblePreferencesModal: false })}
        />
      </Page>
    )
  }
}
