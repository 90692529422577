import { transformAll } from '@overgear/yup-ast'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import BotoesHeaderForm from '../components/layout/BotoesHeaderForm'
import Container from '../components/layout/Container'
import Page from '../components/layout/Page'
import { baseForm } from '../components/utils/BaseForm'
import Confirm from '../components/utils/Confirm'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../components/utils/Message'
import CommonHelper from '../helpers/CommonHelper'

export function Crud ({
  match,
  onSaveModal,
  onDeleteModal,
  codigoSelecionado,
  onHide,
  service,
  modelGetDTO,
  primarykeyname,
  numeroKeyName,
  resource,
  formContent,
  formTitle,
  valida,
  urlBack,
  HeaderComponent,
  removeBotoesHeader = false,
  manterSemExcluir = false,
  removerBotaoExcluir = false,
  manterEmEdicaoAposInsertNew = false
}) {
  // !ESTADOS PARA O CRUD!

  // Controle de criação/edição e validação do form
  // Controle se é form criação, controle se terá botão de excluir
  // Controle dos botões de cancelar/salvar e excluir/editar
  // Objeto validator para validação dos campos obrigatórios
  const id = match?.params?.id
  const [isNew, setIsNew] = useState(!id)
  const [semExcluir, setSemExcluir] = useState(removerBotaoExcluir)
  const [edicaoHabilitada, setEdicaoHabilitada] = useState(false)
  const [validator, setValidator] = useState({})
  const [camposObrigatorios, setCamposObrigatorios] = useState(null)

  // Controle do actions para o BotoesHeaderForm > DropdownMenu
  // Controle do header (Titúlo) do crud
  const [actions, setActions] = useState(null)
  const [header, setHeader] = useState(null)

  // Aparece ou não o botão Ações
  // Ao lado dos botões de cancelar/salvar e excluir/editar
  const [apenasBotaoAcoes, setApenasBotaoAcoes] = useState(false)

  const [removerBotoesHeader, setRemoverBotoesHeader] = useState(true)

  // Controle dos modais de exclusão e cancelar edição
  const [visibleExcluirModal, setVisibleExcluirModal] = useState(false)
  const [visibleCancelarModal, setVisibleCancelarModal] = useState(false)

  // Controle de loading do GetOne e Save
  const [loading, setLoading] = useState(true)

  // Variáveis
  const modelService = new service()
  const FormContent = formContent
  const history = useHistory()

  // Armazena o valor default do modelo
  // Ou armazena valor vindo do modelo
  // Este estado é somente atualizado após edição ou criação de algum modelo
  const [defaultValuesForm, setDefaultValuesForm] = useState(modelGetDTO() || null)

  // Objeto de controle do crud
  // (valores, error, handles, reset, etc)
  const form = baseForm({
    initialValues: modelGetDTO(),
    validationSchema: validator
  })

  // !FUNÇÕES! //

  // * Busca modelo no banco
  // Seta valores para o form
  // Atualiza valor default para o cancelar
  async function getModel (id) {
    try {
      const model = modelGetDTO(await modelService.get(id))
      await form.setValues(model)
      setDefaultValuesForm(model)
      return model
    } catch (e) {
      showErrorMessage(e.message || 'Erro ao obter informações')
    }
  }

  // Busca campos obrigatórios, se campos tiverem valores objeto,
  // busca dentro do valor objeto do campo, e por assim vai.
  function getCamposObrigatoriosRecursively (fields, camposObrigatorios, parentFieldName) {
    for (const fieldName of Object.getOwnPropertyNames(fields)) {
      if (fields[fieldName].fields) {
        let newParentFieldName = fieldName

        if (parentFieldName) {
          newParentFieldName = `${parentFieldName}.${fieldName}`
        }

        getCamposObrigatoriosRecursively(fields[fieldName].fields, camposObrigatorios, newParentFieldName)
        continue
      }

      if (parentFieldName) {
        camposObrigatorios.push(`${parentFieldName}.${fieldName}`)
        continue
      }

      camposObrigatorios.push(fieldName)
    }
  }

  // Realiza validação dos campos do form
  async function getFormValidator () {
    try {
      let formValidatorAST, formValidator
      if (valida) {
        setValidator(valida)
        formValidatorAST = valida
        formValidator = valida
      } else {
        setValidator(formValidator)
        formValidatorAST = await modelService.getFormValidator()
        formValidator = transformAll(formValidatorAST)
      }
      const camposObrigatorios = []
      getCamposObrigatoriosRecursively(formValidator.fields, camposObrigatorios)

      setCamposObrigatorios(camposObrigatorios)
      setLoading(false)
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado!')
    }
  }

  // Função que submita de fato o Save
  async function onSubmit (data) {
    try {
      const savedObject = await modelService.save(data)

      if (onSaveModal) {
        await onSaveModal(savedObject)
        onHide()
      } else if (!!savedObject[primarykeyname]) {
        history.push(`/${history.location.pathname.split('/')[1]}/${savedObject[primarykeyname]}`)
      }

      await getModel(savedObject[primarykeyname])

      if (!manterEmEdicaoAposInsertNew) setIsNew(false)
      if (!manterSemExcluir) setSemExcluir(false)
      setEdicaoHabilitada(false)
      showSuccessMessage('Registro salvo com sucesso!')

      return savedObject[primarykeyname]
    } catch (e) {
      showErrorMessage(e.message || 'Erro ao salvar registro')
    }
  }

  // Valida form e mostra erros nos campos
  // ou executa submit
  async function onSave (data) {
    form.handleSubmit()

    const errors = await form.validateForm(data)

    if (Object.keys(errors).length !== 0) {
      const errorMessage = typeof Object.values(errors)[0] !== 'string'
        ? Object.values(Object.values(errors)[0])[0]
        : Object.values(errors)[0]
      showWarnMessage(errorMessage || 'Campos obrigatórios não preenchidos!')
      return
    } else {
      return await onSubmit(data)
    }
  }

  // Deleta registro
  async function onDelete () {
    try {
      await modelService.delete(form.values[primarykeyname])

      if (onDeleteModal) {
        onDeleteModal()
        onHide()
      } else {
        history.push(`/${urlBack ?? resource}`)
      }

      showSuccessMessage('Registro deletado com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar excluir o registro')
    }
  }

  // A cada troca de "url/:id",
  // confere se é edição ou criação
  useEffect(async () => {
    if (id || codigoSelecionado) {
      // Busca no banco o modelo se for edição,
      // informa que não é registro novo,
      // habilita edição dos campos
      await getModel(id || codigoSelecionado)
      setIsNew(false)
      if (manterEmEdicaoAposInsertNew && isNew) {
        setEdicaoHabilitada(true)
      } else {
        setEdicaoHabilitada(false)
      }
    } else {
      // Se for criação, retira botão excluir,
      // salva valor padrão,
      // habilita que é registro novo,
      // habilita edição dos campos
      setSemExcluir(true)
      setDefaultValuesForm(modelGetDTO())
      setIsNew(true)
      setEdicaoHabilitada(true)
      await form.setValues(modelGetDTO())
    }
    // Chama validator para armazenar campos obrigatórios
    await getFormValidator()

    setRemoverBotoesHeader(false)
  }, [id, codigoSelecionado])

  // Campos preenchidos para os modais de
  // confirmar exclusão e atenção cancelar edição
  const confirmConfigs = [
    // Confirmar Exclusão
    {
      visible: visibleExcluirModal,
      onConfirm: () => onDelete(),
      onCancel: () => setVisibleExcluirModal(false),
      title: 'Confirmação',
      description: 'Deseja realmente excluir este registro?'
    },
    // Cancelar Edição
    {
      visible: visibleCancelarModal,
      onConfirm: async () => {
        await form.setValues(defaultValuesForm)
        setEdicaoHabilitada(false)
        setVisibleCancelarModal(false)
        await form.validateForm(defaultValuesForm)
      },
      onCancel: () => setVisibleCancelarModal(false),
      title: 'Atenção',
      description: 'Deseja cancelar as edições feitas?'
    }
  ]

  // !RENDER PAGE!

  return (
    <Page>
      {!loading && (
        <Container col="12">
          <div className={`flex form-header justify-content-${onHide ? 'end' : 'between'} ${(!CommonHelper.isDesktop() && actions) ? 'align-items-end' : ''}`}>
            {!onHide && (
              <>
                {!!HeaderComponent
                  ? <HeaderComponent form={form} pk={form.values[numeroKeyName || primarykeyname]}/>
                  : header
                    ? (<>{header}</>)
                    : (
                      <h1>{formTitle} {form.values[numeroKeyName || primarykeyname]
                        ? `- ${form.values[numeroKeyName || primarykeyname]}`
                        : ''}</h1>
                    )}
              </>
            )}
            <BotoesHeaderForm
              isNew={isNew}
              removeBotoesHeader={removerBotoesHeader || removeBotoesHeader}
              actions={actions}
              semExcluir={semExcluir}
              apenasBotaoAcoes={apenasBotaoAcoes}
              setEdicaoHabilitada={setEdicaoHabilitada}
              onSave={onSave}
              form={form}
              setVisibleCancelarModal={setVisibleCancelarModal}
              setVisibleExcluirModal={setVisibleExcluirModal}
              edicaoHabilitada={edicaoHabilitada}
            />
          </div>
          <div className="grid pt-3">
            <FormContent
              form={form}
              edicaoHabilitada={edicaoHabilitada}
              setEdicaoHabilitada={(edicaoHabilitada) => setEdicaoHabilitada(edicaoHabilitada)}
              camposObrigatorios={camposObrigatorios}
              isNew={isNew}
              setActions={setActions}
              setSemExcluir={setSemExcluir}
              setHeader={setHeader}
              setFooter={() => { }}
              onSave={onSave}
              refreshForm={getModel}
              setApenasBotaoAcoes={setApenasBotaoAcoes}
            />
          </div>
        </Container>
      )}
      {confirmConfigs.map((config, index) => (
        <Confirm
          key={`confirm-modal-${config.title}-${index}`}
          visible={config.visible}
          onConfirm={config.onConfirm}
          onCancel={config.onCancel}
          title={config.title}
          description={config.description}
        />
      ))}
    </Page>
  )
}
