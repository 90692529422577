import { server } from '../../helpers/server'
import ServiceBase from '../base/ServiceBase'

export default class ImagemService {
  static async getImages (nomkey_img, codkey_img, database) {
    if (database) {
      return await ServiceBase.getBase(`/landing-veiculo/${nomkey_img}/${codkey_img}` + (database ? `/${database}` : ''))
    }
    return await ServiceBase.getBase(`/image/${nomkey_img}/${codkey_img}`)
  }

  static async uploadImage (file, codkey_img, nomkey_img, nomtab_img) {
    try {
      const blob = await fetch(file.objectURL).then((r) => r.blob())
      const fileConverted = new File([blob], file.name, { type: file.type })

      const fd = new FormData()
      fd.append('image_name', file.name)
      fd.append('image', fileConverted)

      const response = await server().post(
        `image/${nomtab_img}/${nomkey_img}/${codkey_img}`,
        fd
      )

      return response.data
    } catch (error) {
      throw Error(error.response?.data?.message)
    }
  }

  static async uploadMultiImages (files, codkey_img, nomkey_img, nomtab_img) {
    const convertFileListToArray = Array.from(files)
    const uploadPromises = []

    for (const file of convertFileListToArray) {
      try {
        const blob = await fetch(file.objectURL).then((r) => r.blob())
        const fileConverted = new File([blob], file.name, { type: file.type })

        const fd = new FormData()
        fd.append('image_name', file.name)
        fd.append('image', fileConverted)

        uploadPromises.push(
          server().post(
            `image/${nomtab_img}/${nomkey_img}/${codkey_img}`,
            fd
          )
        )
      } catch (error) {
        throw Error(error.response?.data?.message)
      }
    }

    const results = await Promise.all(uploadPromises)

    return results.flatMap(array => array.data)
  }

  static async deleteImages (imagens) {
    await ServiceBase.postBase('image/deleteImages', { imagens })
  }

  static async deleteAllImages (nomkey_img, codkey_img, ignore_keyarq_img) {
    return await ServiceBase.postBase('image/delete-all', { nomkey_img, codkey_img, ignore_keyarq_img })
  }
}
