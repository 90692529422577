import React, { useState, useEffect } from 'react'
import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'
import Container from '../../../components/layout/Container'
import DataList from '../../../components/layout/DataList'
import Page from '../../../components/layout/Page'
import PreferencesButton from '../../../components/utils/PreferencesButton'
import TablePreferences from '../../../components/utils/TablePreferences'
import { getOrcamentoBalcaoDTO } from '../../../dtos/vendas/orcamentoBalcao/OrcamentoBalcaoDTO'
import CommonHelper from '../../../helpers/CommonHelper'
import { formatToCurrency } from '../../../helpers/formaters'
import { createColumns, getColunasHabilitadas } from '../../../helpers/tableConfigs'
import OrcamentoBalcaoService from '../../../services/vendas/orcamentoBalcao/OrcamentoBalcaoService'
import useList from '../../../hook/useList'

const getDataInsercao = (data) => {
  const date = new Date(data.dahins_opv)
  return CommonHelper.dateTimeToBrazilian(date)
}

const getValorTotalOrcamentoBalcao = (data) => {
  return formatToCurrency(data.totliq_opv)
}

const opcoesTab = [
  'EmAberto',
  'Reservado',
  'EnviadoCaixa',
  'Faturado',
  'Cancelado',
  'quantidadeTotal'
]

const opcoesFilter = [
  { field: 'EmAberto', value: 'a' },
  { field: 'Reservado', value: 'r' },
  { field: 'EnviadoCaixa', value: 'e' },
  { field: 'Faturado', value: 'f' },
  { field: 'Cancelado', value: 'c' },
  { field: 'quantidadeTotal', value: 't' }
]

export const OrcamentoBalcaoList = () => {
  const columns = createColumns([
    { field: 'numero_opv', header: 'Número', width: '16%' },
    { field: 'nomeCliente', header: 'Cliente', width: '16%' },
    { field: 'nomeConsultor', header: 'Consultor', width: '16%' },
    { field: 'dahins_opv', header: 'Criada em', width: '16%', customBody: getDataInsercao },
    { field: 'totliq_opv', header: 'Valor total', width: '16%', customBody: getValorTotalOrcamentoBalcao },
    { field: 'descri_sop', header: 'Situação', width: '16%' }
  ])

  const {
    state,
    setState,
    onPageChange,
    onSelect,
    onView,
    handleChangeFilter,
    onFilter,
    onNew
  } = useList(
    getOrcamentoBalcaoDTO,
    OrcamentoBalcaoService,
    'orcamentoBalcao',
    'codigo_opv',
    undefined,
    'a',
    {
      semGetInicial: true,
      columns
    }
  )

  const [visiblePreferencesModal, setVisiblePreferencesModal] = useState(false)

  useEffect(() => {
    if (!state.semGetInicial) onSelectOption(opcoesFilter.find((ob) => state.filter.codsop_opv === ob.value)?.field || 'EmAberto')
  }, [state.semGetInicial])

  const onSelectOption = async (option) => {
    const valor = opcoesFilter.find((ob) => option === ob.field).value
    await setState((prevState) => {
      return ({
        ...prevState,
        opcaoSelecionada: option,
        filter: { ...prevState.filter, codsop_opv: valor }
      })
    })
    await onFilter({ codsop_opv: valor })
  }

  return (
    <Page>
      <div className="flex justify-content-between align-items-center page-header">
        <h4>Orçamentos Balcão</h4>
        <div className="page-header-buttons">
          <Button className="mr-2" label={CommonHelper.isDesktop() && 'Novo'} icon="pi pi-plus" onClick={onNew} />
        </div>
      </div>

      <div className="table-options-container">
        <div className="table-options">
          {opcoesTab.map((option) => (
            <div
              key={option}
              className={classNames({ active: state.opcaoSelecionada === option }, 'filter-option')}
              onClick={async () => await onSelectOption(option)}
            >
              <span className="option-label">{CommonHelper.getStatusLabel(option) ?? 'teste'}</span>
              <div className="option-quantity">{
                option !== 'quantidadeTotal'
                  ? state[`total${option}`]
                  : state[option]
              }</div>
            </div>
          ))}
        </div>
        <div className="table-preferences">
          <PreferencesButton onClick={() => setVisiblePreferencesModal(true)} />
        </div>
      </div>

      <Container>
        <DataList
          data={state.list}
          selected={state.selected}
          rows={state.rows}
          onSelect={onSelect}
          totalRecords={state.totalRecords}
          first={state.first}
          onPage={onPageChange}
          page={state.page}
          onRowSelect={(e) => onView(e.data)}
          filterPlaceholder="Procurar orçamentos balcão"
          filterName="descricaoFiltro"
          filterOnChange={handleChangeFilter}
          onFilter={onFilter}
          responsive
          showFilter
          customMaxHeight={'unset'}
        >
          {getColunasHabilitadas(columns, 'orcamentoBalcao')}
        </DataList>
      </Container>

      <TablePreferences
        title="Preferências da tabela de orçamento balcão"
        tableName="orcamentoBalcao"
        columns={columns}
        visible={visiblePreferencesModal}
        hide={() => setVisiblePreferencesModal(false)}
      />
    </Page>
  )
}
