import ServiceBase from '../../base/ServiceBase'

export default class ProcessoUsoReportService {
  async getAll () {
    return await ServiceBase.getBase('processo-uso-report')
  }

  async filter (query) {
    return await ServiceBase.getBase(`processo-uso-report?${query}`)
  }
}
