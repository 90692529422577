import { formatDateToAmerican, formatDateToInputDate } from '../../../helpers/formaters'
import { getStore } from '../../../redux/store'
import { getPecaBalcaoDTO, postPecaBalcaoDTO } from './PecaBalcaoDTO'

export const getOrcamentoBalcaoDTO = (data = {}) => {
  let proximaData = new Date()
  // let diasConfig3384 = ConfigService.getValor(3384).then((resp) => resp)
  let usuarioSeparacao

  proximaData.setDate(proximaData.getDate() + 1)
  proximaData = proximaData.toDateString()

  if (data.tituloOpv && data.tituloOpv.length > 0) {
    data.tituloOpv.forEach((top, index) => {
      data.tituloOpv[index].datven_top = formatDateToInputDate(top.datven_top)
    })
  }

  if (data.pecasBalcao && data.pecasBalcao.length > 0) {
    data.pecasBalcao.forEach((peo, index) => {
      data.pecasBalcao[index] = getPecaBalcaoDTO(peo)
      if (peo.usuarioSeparacaoPeca) {
        usuarioSeparacao = peo
        return
      }
    })
  }
  return {
    usuent_opv: data.usuent_opv || null,
    codtra_opv: data.codtra_opv || null,
    codapp_opv: data.codapp_opv || null,
    codigo_opv: data.codigo_opv || null,
    numero_opv: data.numero_opv || null,
    codemp_opv: data.codemp_opv || getStore().empresaLogada.codigo_emp,
    codsop_opv: data.codsop_opv || 'a',
    vndsemcon_opv: data.vndsemcon_opv ? true : false,
    valfre_opv: data.valfre_opv || 0.00,
    valseg_opv: data.valseg_opv || 0.00,
    valoud_opv: data.valoud_opv || 0.00,
    acrcpg_opv: data.acrcpg_opv || 0.00,
    obs_opv: data.obs_opv || '',
    obsnof_opv: data.obsnof_opv || '',
    cliente: data.cliente || {},
    consultor: data.consultor || {},
    conveniado: data.conveniado || {},
    tipoPreco: data.tipoPreco || {},
    modalidadeVenda: data.modalidadeVenda || null,
    situacaoOrcamento: data.situacaoOrcamento || {},
    tipomidia: data.tipoMidia || {},
    intermediador: data.intermediadorDeVendas || null,
    pecasBalcao: data.pecasBalcao || [],
    usuarioInsercao: data.usuarioInsercao || null,
    usuarioSeparacao: usuarioSeparacao || null,
    dahins_opv: data.dahins_opv || null,
    usuarioCancelamento: data.usuarioCancelamento || null,
    dahcan_opv: data.dahcan_opv || null,
    usuarioUltimaAlteracao: data.usuarioUltimaAlteracao || null,
    dahalt_opv: data.dahalt_opv || null,
    usuarioQueEntregouPecas: data.usuarioQueEntregouPecas || null,
    usuarioEnviouCaixa: data.usuarioEnviouCaixa || null,
    dahent_opv: data.dahent_opv || null,
    enviadoParaCaixa: data.envecf_opv || null,
    dahenv_opv: data.dahenv_opv || null,
    orcamentoSeparado: data.issepa_opv || null,
    datval_opv: data.datval_opv ? formatDateToInputDate(data.datval_opv) : data.datval_opv ? formatDateToInputDate(data.datval_opv) : null,
    ispres_opv: !!data.ispres_opv ? true : false,
    codmcv_opv: data.codmcv_opv || null,
    marca: data.marca || {},
    transporte: data.transporte || {},
    transportadora: data.transporte?.transportadora || {},
    //transporte: { transportadora: (data.transporte && data.transporte.transportadora) || {} },

    modalidadeFrete: data.transporte?.responsavelFreteNfe || {},
    estado: data.estado || {},
    totcop_opv: data.totcop_opv || 0,
    codcop_opv: data.codcop_opv,
    condicaoPagamento: data.condicaoPagamento || {},
    tituloOpv: data.tituloOpv || [],
    valent_opv: data.valent_opv || 0,
    priven_opv: data.priven_opv ? formatDateToAmerican(data.priven_opv) : null
  }
}

export const postOrcamentoBalcaoDTO = (data) => {
  if (data.tituloOpv && data.tituloOpv.length > 0) {
    data.tituloOpv.forEach((top, index) => {
      data.tituloOpv[index].datven_top = formatDateToAmerican(top.datven_top)
    })
  }
  if (data.pecasBalcao && data.pecasBalcao.length > 0) {
    data.pecasBalcao.forEach((peo, index) => {
      data.pecasBalcao[index] = postPecaBalcaoDTO(peo)
    })
  }

  return {
    codigo_opv: data.codigo_opv || null,
    codemp_opv: data.codemp_opv || getStore().empresaLogada.codigo_emp,
    codsop_opv: data.codsop_opv || null,
    vndsemcon_opv: data.vndsemcon_opv || false,
    valfre_opv: data.valfre_opv || 0.00,
    valseg_opv: data.valseg_opv || 0.00,
    valoud_opv: data.valoud_opv || 0.00,
    acrcpg_opv: data.acrcpg_opv || 0.00,
    obs_opv: data.obs_opv || '',
    obsnof_opv: data.obsnof_opv || '',
    codtim_opv: data.tipomidia?.codigo_tim || null,
    cliente: data.cliente || {},
    codcli_opv: data.cliente.codigo_pes,
    consultor: data.consultor || {},
    codcon_opv: data.consultor.codigo_pes,
    conveniado: data.conveniado || {},
    convenpes_opv: data.conveniado?.codigo_pes || null,
    codint_opv: data?.intermediador?.pessoa.codigo_pes || null,
    tipoPreco: data.tipoPreco || {},
    codtpr_opv: data.tipoPreco.codigo_tpr,
    modalidadeVenda: data.modalidadeVenda || {},
    codmov_opv: data.modalidadeVenda?.codigo_mov || null,
    plavei_opv: data.plavei_opv || '',
    envecf_opv: data.envecf_opv ? 1 : 0,
    datval_opv: data.datval_opv ? formatDateToAmerican(data.datval_opv) : null,
    ispres_opv: data.ispres_opv ? 1 : 0,
    codmcv_opv: data.codmcv_opv || null,
    pecasBalcao: data.pecasBalcao || [],
    transporte: data.transporte || {},
    codcop_opv: data.codcop_opv,
    totcop_opv: data.totcop_opv,
    tituloOpv: data.tituloOpv || [],
    valent_opv: data.valent_opv,
    priven_opv: data.priven_opv ? formatDateToAmerican(data.priven_opv) : null
  }
}
