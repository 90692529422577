import React from 'react'
import { Crud } from '../../../../classes/NewCrud'
import CorForm from '../../../../forms/cadastro/cor/CorForm'
import { getCorDTO } from '../../../../models/cadastro/veiculo/CorModel'
import CorService from '../../../../services/cadastro/veiculo/CorService'
import { CorValidator } from './CorValidator'

export default function Cor (props) {
  return (
    <Crud
      {...props}
      formContent={CorForm}
      valida={CorValidator}
      modelGetDTO={getCorDTO}
      service={CorService}
      resource="cor"
      urlBack="cores"
      primarykeyname="codigo_cor"
      formTitle="Cor"
    />
  )
}
