import * as Yup from 'yup'

export const CorValidator = Yup.object().shape({
  descri_cor: Yup.string()
    .nullable()
    .required('Campo descrição é obrigatório!'),
  corDenatran: Yup.object().shape({
    codigo_cde: Yup.number()
      .nullable()
      .required('Campo Cor DENATRAN é obrigatório!')
  })
})
