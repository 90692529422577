
export const getTipoEtapaDTO = (data = {}) => {
  return {
    ...data,
    codigo_tet: data.codigo_tet || null,
    descri_tet: data.descri_tet || ''
  }
}

export const postTipoEtapaDTO = (data = {}) => {
  return {
    ...data,
    codigo_tet: data.codigo_tet || null,
    descri_tet: data.descri_tet || ''
  }
}
