import { classNames } from 'primereact/utils'
import React, { useEffect, useState } from 'react'
import CheckboxInput from '../../../../components/inputs/CheckboxInput'
import TextAreaInput from '../../../../components/inputs/TextAreaInput'
import TextInput from '../../../../components/inputs/TextInput'
import PessoaAutocompleteTemplate from '../../../../components/inputs/options/PessoaAutocompleteTemplate'
import SearchInput from '../../../../components/inputs/searchInput/SearchInput'
import { baseForm } from '../../../../components/utils/BaseForm'
import FieldErrorMessage from '../../../../components/utils/FieldErrorMessage'
import { showErrorMessage, showWarnMessage } from '../../../../components/utils/Message'
import Modal from '../../../../components/utils/Modal'
import RequiredLabel from '../../../../components/utils/RequiredLabel'
import { getReservaDTO } from '../../../../dtos/cadastro/veiculo/ReservaDTO'
import { formatDocument } from '../../../../helpers/formaters'
import { useValidateInput } from '../../../../helpers/useValidateInput'
import { PessoaModel } from '../../../../models/cadastro/pessoa/PessoaModel'
import { VendedorModel } from '../../../../models/cadastro/pessoa/VendedorModel'
import PessoaService from '../../../../services/cadastro/pessoa/PessoaService'
import VendedoresService from '../../../../services/cadastro/pessoa/VendedoresService'
import { PessoaFisica } from '../../../../views/cadastro/pessoa/fisica/PessoaFisica'
import { PessoaJuridica } from '../../../../views/cadastro/pessoa/juridica/PessoaJuridica'
import { ReservaVeiculoValidator } from './ReservaVeiculoValidator'

const ReservaVeiculoModal = ({ visible, hide, reserva, visualizarReserva, handleReserva }) => {
  const [sugestaoVendedor, setSugestaoVendedor] = useState([])
  const [sugestaoPessoas, setSugestaoPessoas] = useState([])

  const handleSubmitForm = () => {
    handleReserva(form)
    hide()
  }

  const form = baseForm({
    initialValues: getReservaDTO(),
    onSubmit: handleSubmitForm,
    validationSchema: ReservaVeiculoValidator
  })

  useEffect(async () => {
    if (reserva) {
      await form.setValues(getReservaDTO(reserva))

      if (reserva.visualizarCamposAutoGestor) {
        await form.setFieldValue('styleFieldsAutoGestor', '')
      } else {
        await form.setFieldValue('styleFieldsAutoGestor', 'hidden')
      }

      form.setTouched({})
    }
  }, [reserva])

  const sugerirVendedor = async () => {
    try {
      const vendedoresService = new VendedoresService()
      const vendedores = await vendedoresService.filterAutoComplete(`nomraz_pes=${form.values.vendedor.nomraz_pes}`)

      if (vendedores.length <= 0) {
        showWarnMessage('Vendedor não encontrado!')
      }
      setSugestaoVendedor(vendedores)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um vendedor!')
    }
  }

  const sugerirPessoas = async () => {
    try {
      const pessoas = await PessoaService.filterAutocomplete(form.values.pessoa.nomraz_pes)

      if (pessoas.length <= 0) {
        showWarnMessage('Pessoa não encontrada!')
      }
      setSugestaoPessoas(pessoas)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir uma pessoa!')
    }
  }

  const handleChangeVendedor = (e) => {
    form.setFieldValue('vendedor', e.target.value)
    form.setFieldValue('vendedor.nomraz_pes', e.target.value)
  }

  const handleChangePessoas = (e) => {
    form.setFieldValue('pessoa', e.target.value)
    form.setFieldValue('pessoa.nomraz_pes', e.target.value)
  }

  function onSaveModalPessoa (pessoa) {
    onSelectPessoa({ value: pessoa.pessoa })
  }

  const onSelectVendedor = async (e) => {
    await form.setFieldValue('vendedor', e.value)
  }

  const onSelectPessoa = async (e) => {
    await form.setFieldValue('pessoa', e.value)
    await form.setFieldValue('clienteAutogestor', e.value.codaug_pes)
  }

  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)

  return (
    <Modal
      header="Reserva de veículo"
      btnSalvar={!visualizarReserva ? form.submitForm : undefined}
      width="35"
      visible={visible}
      onHide={hide}
    >
      <div className="formgrid grid">
        <div className="field col-12">
          <label className="label">Veículo</label>
          <TextAreaInput
            value={form.values.descricaoVeiculo}
            className="inputfield w-full"
            disabled={true}
          />
        </div>
        <div className="field col-12">
          <SearchInput
            //AutoComplete
            field="nomraz_pes"
            label="Vendedor"
            value={form.values.vendedor && form.values.vendedor.nomraz_pes}
            placeholder="Nome do Vendedor"
            minLength='1'
            disabled={visualizarReserva}
            onSelect={async (e) => { await onSelectVendedor(e); form.setFieldTouched('vendedor.codigo_pes') }}
            className={classNames({ 'p-invalid': isFormFieldValid('vendedor.codigo_pes') }, 'inputfield w-full')}
            onBlur={() => form.setFieldTouched('vendedor.codigo_pes')}
            suggestions={sugestaoVendedor}
            completeMethod={sugerirVendedor}
            onChange={handleChangeVendedor}
            isFormFieldValid={isFormFieldValid('vendedor.codigo_pes')}
            // FiltroModal
            filtrotitle="Pesquisa de vendedor"
            service={VendedoresService}
            model={VendedorModel}
            primarykeyname="codigo_ven"
            columns={[
              { campo: 'codigo_ven', nome: 'Código' },
              { campo: 'nomraz_pes', nome: 'Nome' }
            ]}
          />
          <FieldErrorMessage message={getFormErrorMessage('vendedor.codigo_pes')} />
        </div>
        <div className="field col-12" style={{ position: 'relative' }}>
          <SearchInput
            // * AutoComplete Props Padrões
            label="Cliente"
            name="pessoa.codigo_pes"
            field="nomraz_pes"
            placeholder="Nome do cliente"
            disabled={visualizarReserva}

            // * Form Control
            className={classNames({ 'p-invalid': isFormFieldValid('pessoa.codigo_pes') }, 'inputfield w-full')}
            isFormFieldValid={isFormFieldValid('pessoa.codigo_pes')}
            onBlur={() => form.setFieldTouched('pessoa.codigo_pes')}

            // * Template pro campo valor
            itemTemplate={PessoaAutocompleteTemplate}
            value={form.values.pessoa && form.values.pessoa.nomraz_pes}

            // * Selecionar e sugesstions
            onSelect={async (e) => {
              await onSelectPessoa(e)
              form.setFieldTouched('pessoa.codigo_pes')
            }}
            minLength={1}
            onChange={handleChangePessoas}
            completeMethod={sugerirPessoas}
            suggestions={sugestaoPessoas}

            // * RegisterModal
            form={form}
            selected={form.values.pessoa}
            onSaveModal={onSaveModalPessoa}
            onDeleteModal={async () => await onSelectPessoa({})}
            ComponenteCadastro={[
              {
                label: 'Pessoa física',
                component: PessoaFisica,
                header: form.values.pessoa.codigo_pes ? 'Pessoa Física - ' + form.values.pessoa.codigo_pes : 'Cadastro de pessoa física'
              },
              {
                label: 'Pessoa jurídica',
                component: PessoaJuridica,
                header: form.values.pessoa.codigo_pes ? 'Pessoa jurídica - ' + form.values.pessoa.codigo_pes : 'Cadastro de pessoa jurídica'
              }
            ]}

            // * FiltroModal
            filtrotitle="Pesquisa de pessoa"
            service={PessoaService}
            model={PessoaModel}
            columns={[
              { campo: 'codigo_pes', nome: 'Código' },
              { campo: 'nomraz_pes', nome: 'Nome' },
              { campo: 'cgccpf_pes', nome: 'CPF/CNPJ', format: formatDocument }
            ]}

            // * RegisterModal && FiltroModal
            primarykeyname="codigo_pes"
          />
          <FieldErrorMessage message={getFormErrorMessage('pessoa.codigo_pes')} />
        </div>
        <div className="field col-12">
          <RequiredLabel label="Motivo" isFormFieldValid={isFormFieldValid('motivoReserva')} />
          <TextAreaInput
            value={form.values.motivoReserva}
            name="motivoReserva"
            onChange={form.handleChange}
            className={classNames({ 'p-invalid': isFormFieldValid('motivoReserva') }, 'inputfield w-full')}
            onBlur={() => form.setFieldTouched('motivoReserva')}
            disabled={visualizarReserva}
          />
          <FieldErrorMessage getFormErrorMessage={getFormErrorMessage('motivoReserva')} />
        </div>
        <div className="field col-6">
          <TextInput
            label={form.values.styleFieldsAutoGestor !== 'hidden' ? 'Cliente AutoGestor' : ''}
            value={form.values.clienteAutogestor}
            name="clienteAutogestor"
            onChange={form.handleChange}
            className={classNames({ 'p-invalid': isFormFieldValid('motivoReserva') }, 'inputfield w-full ', form.values.styleFieldsAutoGestor)}
            onBlur={() => form.setFieldTouched('clienteAutogestor')}
            disabled={visualizarReserva}
          />
          <FieldErrorMessage getFormErrorMessage={getFormErrorMessage('motivoReserva')} />
        </div>
        <div className="field col-12">
          <CheckboxInput
            containerStyle={form.values.styleFieldsAutoGestor}
            label="Remover anúncios no AutoGestor"
            value={form.values.removerAnuncios}
            checked={form.values.removerAnuncios}
            name="removerAnuncios"
            onBlur={() => form.setFieldTouched('removerAnuncios')}
            onChange={(e) => form.setFieldValue('removerAnuncios', e.checked)}
            disabled={visualizarReserva}
          />
          <FieldErrorMessage getFormErrorMessage={getFormErrorMessage('removerAnuncios')} />
        </div>
        <div>
          <span className={classNames('field col-12 text-500 font-italic text-sm ', form.values.styleFieldsAutoGestor)}>
            {'Quando não informado o código do cliente no AutoGestor, será atribuido por padrão o primeiro cliente cadastrado no AutoGestor!'}
            <br/>
            {'Se não for informado o código do vendedor no AutoGestor em seu cadastro ou o veículo não estiver integrado com o AutoGestor, não será realizada a Pré-venda no AutoGestor!'}
          </span>
        </div>
      </div>
    </Modal>
  )
}

export default ReservaVeiculoModal
