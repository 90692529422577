import React from 'react'
import { Crud } from '../../../classes/NewCrud'
import { getEstoqueDTO } from '../../../dtos/vendas/estoque/EstoqueDTO'
import ConsultaEstoqueForm from '../../../forms/vendas/consultaEstoque/consultaEstoqueFormPages/ConsultaEstoqueForm'
import { OrcamentoBalcaoValidator } from '../../../forms/vendas/orcamentoBalcao/OrcamentoBalcaoValidator'
import OrcamentoBalcaoService from '../../../services/vendas/orcamentoBalcao/OrcamentoBalcaoService'

export function ConsultaEstoque (props) {
  return (
    <Crud
      formContent={ConsultaEstoqueForm}
      modelGetDTO={getEstoqueDTO}
      service={OrcamentoBalcaoService}
      valida={OrcamentoBalcaoValidator}
      removeBotoesHeader={true}
    />
  )
}
