import { classNames } from 'primereact/utils'
import React from 'react'
import TextInput from '../../../../../components/inputs/TextInput'
import FieldErrorMessage from '../../../../../components/utils/FieldErrorMessage'
import RequiredLabel from '../../../../../components/utils/RequiredLabel'
import { useValidateInput } from '../../../../../helpers/useValidateInput'

const DadosPrincipaisTipoEtapa = ({ form, edicaoHabilitada }) => {
  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)

  return (
    <div className="formgrid grid">
      <div className="field col-12">
        <RequiredLabel label="Descrição" valid={isFormFieldValid('descri_tet')} />
        <TextInput
          placeholder="Descrição"
          name="descri_tet"
          className={classNames({ 'p-invalid': isFormFieldValid('descri_tet') }, 'inputfield w-full')}
          value={form.values.descri_tet}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          onBlur={() => form.setFieldTouched('descri_tet')}
        />
        <FieldErrorMessage message={getFormErrorMessage('descri_tet')} />
      </div>
    </div>
  )
}

export default DadosPrincipaisTipoEtapa
