import React, { useState } from 'react'
import AutoCompleteInput from '../../../../../components/inputs/AutoCompleteInput'
import CheckboxInput from '../../../../../components/inputs/CheckboxInput'
import PessoaAutocompleteTemplate from '../../../../../components/inputs/options/PessoaAutocompleteTemplate'
import { showWarnMessage } from '../../../../../components/utils/Message'
import Modal from '../../../../../components/utils/Modal'
import { formatDocument } from '../../../../../helpers/formaters'
import { PessoaModel } from '../../../../../models/cadastro/pessoa/PessoaModel'
import PessoaService from '../../../../../services/cadastro/pessoa/PessoaService'
import { PessoaFisica } from '../../../../../views/cadastro/pessoa/fisica/PessoaFisica'
import { PessoaJuridica } from '../../../../../views/cadastro/pessoa/juridica/PessoaJuridica'

function ModalPessoaConvenio ({
  visible,
  onHide,
  selected,
  edicaoHabilitada,
  insertOrUpdate,
  camposObrigatorios,
  setSelected,
  form,
  edicao
}) {
  // * Estado e função para sugerir pessoas para o autocomplete
  const [sugestaoConveniado, setSugestaoConveniado] = useState([])
  async function sugerirConveniado () {
    try {
      const conveniado = await PessoaService.filterAutocomplete(selected)

      if (conveniado.length <= 0) {
        showWarnMessage('Cliente não encontrado!')
      }

      setSugestaoConveniado(conveniado)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um cliente!')
    }
  }

  // * Exclui conveniado da listagem e fecha modal
  function excluirConveniado () {
    if (!edicao?.codigo_pes) {
      showWarnMessage('Selecione um conveniado da listagem!')
      return false
    }

    const listagemConveniados = form?.values?.conveniados
    const conveniadoIgual = listagemConveniados?.filter(
      conveniado => conveniado.codigo_pes === edicao.codigo_pes
    )

    for (const conveniado of conveniadoIgual) {
      const indexConveniado = listagemConveniados.indexOf(conveniado)
      listagemConveniados.splice(indexConveniado, 1)
    }

    onHide()
  }

  // * Chama função do pai de inserir ou editar
  function adicionarConveniado () {
    if (!selected.codigo_pes) {
      showWarnMessage('Selecione um cliente!')
      return false
    }
    insertOrUpdate()
  }

  // * Seta string ou objeto
  function handleChangeOrSelectConveniado (e) {
    // * valor em string para função sugerirConveniados
    if (typeof e.value === 'string') {
      setSelected(e.value)
      return
    }

    // * Confere se o objeto ja existe na listagem
    // * Seta objeto ja existente ou altera para novo
    const listagemConveniados = form?.values?.conveniados
    const conveniadoJaEstaNaListagem = listagemConveniados?.find(
      conveniado => conveniado.codigo_pes === e.value.codigo_pes
    )

    setSelected(
      !!conveniadoJaEstaNaListagem
        ? conveniadoJaEstaNaListagem
        : {
          codigo_pes: e.value.codigo_pes,
          nomraz_pes: e.value.nomraz_pes,
          ativo_cvp: !!edicao?.ativo_cvp
        }
    )
  }

  // * Altera o campo ATIVO do conveniado selecionado
  function onChangeAtivo (e) {
    setSelected(
      (prevSelected) => ({ ...prevSelected, ativo_cvp: e.target.checked })
    )
  }

  return (
    <>
      <Modal
        visible={visible}
        onHide={onHide}
        header={'Pessoa'}
        btnSalvarLabel={!!edicao ? 'Atualizar conveniado' : 'Adicionar conveniado'}
        icon={!!edicao ? '' : 'pi pi-plus'}
        width={40}
        btnSalvar={(edicaoHabilitada) ? adicionarConveniado : undefined}
        btnExcluir={(edicaoHabilitada && !!edicao) ? excluirConveniado : undefined}
      >
        <div className='md:flex'>
          <div className="field col-12 md:col-10">
            <AutoCompleteInput
              // * AutoComplete Props Padrões
              label="Conveniado"
              name="conveniado.codigo_pes"
              field="nomraz_pes"
              placeholder="Nome do conveniado"
              disabled={!edicaoHabilitada}
              camposObrigatorios={camposObrigatorios}

              // * Template pro campo valor
              itemTemplate={PessoaAutocompleteTemplate}
              value={typeof selected === 'string' ? selected : selected?.nomraz_pes }

              // * Controle do texto e sugestões
              onSelect={handleChangeOrSelectConveniado}
              minLength={1}
              onChange={handleChangeOrSelectConveniado}
              completeMethod={sugerirConveniado}
              suggestions={sugestaoConveniado}

              // * RegisterModal
              form={form}
              selected={typeof selected === 'string' ? null : selected}
              onSaveModal={(conveniado) => handleChangeOrSelectConveniado({ value: conveniado })}
              onDeleteModal={() => handleChangeOrSelectConveniado({})}
              ComponenteCadastro={[
                {
                  label: 'Pessoa física',
                  component: PessoaFisica,
                  header: selected?.codigo_pes
                    ? `Pessoa Física - ${selected?.codigo_pes}`
                    : 'Cadastro de pessoa física'
                },
                {
                  label: 'Pessoa jurídica',
                  component: PessoaJuridica,
                  header: selected?.codigo_pes
                    ? `Pessoa jurídica - ${selected?.codigo_pes}`
                    : 'Cadastro de pessoa jurídica'
                }
              ]}

              // * FiltroModal
              filtrotitle="Pesquisa de conveniado"
              service={PessoaService}
              model={PessoaModel}
              columns={[
                { campo: 'codigo_pes', nome: 'Código' },
                { campo: 'nomraz_pes', nome: 'Nome' },
                { campo: 'cgccpf_pes', nome: 'CPF/CNPJ', format: formatDocument }
              ]}

              // * RegisterModal && FiltroModal
              primarykeyname="codigo_pes"
            />
          </div>
          <div className="field col-12 md:col-2 field checkbox-container">
            <CheckboxInput
              label={'Ativo?'}
              checked={
                typeof selected === 'string'
                  ? !!edicao
                    ? !!edicao?.ativo_cvp
                    : false
                  : !!selected?.ativo_cvp
              }
              onChange={onChangeAtivo}
              disabled={!edicaoHabilitada}

            />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalPessoaConvenio
