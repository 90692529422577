
export const getPendenciaDTO = (data = {}) => {
  return {
    ...data,
    descri_etp: data.descri_etp || '',
    exiarq_etp: !!data.exiarq_etp ? !!data.exiarq_etp : false,
    perign_etp: !!data.perign_etp ? !!data.perign_etp : false,
    origem_anexo: data.origem_anexo || {}
  }
}

export const getPendenciaUsoDTO = (data = {}) => {
  return {
    ...data,
    descri_tpu: data.descri_tpu || '',
    exiarq_tpu: !!data.exiarq_tpu ? !!data.exiarq_tpu : false,
    perign_tpu: !!data.perign_tpu ? !!data.perign_tpu : false,
    origem_anexo: data.origem_anexo || {},
    status_pendencia: data.status_pendencia || {},
    descri_concluido: data.descri_concluido || '',
    att_por: data.att_por || '',
    att_data: data.att_data || ''
  }
}

export const postPendenciaUsoDTO = (data = {}) => {
  return {
    ...data,
    descri_tpu: data.descri_tpu || '',
    exiarq_tpu: !!data.exiarq_tpu ? !!data.exiarq_tpu : false,
    perign_tpu: !!data.perign_tpu ? !!data.perign_tpu : false,
    origem_anexo: data.origem_anexo || {},
    status_pendencia: data.status_pendencia || {},
    descri_concluido: data.descri_concluido || '',
    att_por: data.att_por || '',
    att_data: data.att_data || ''
  }
}

export const postPendenciaDTO = (data = {}) => {
  return {
    ...data,
    descri_are: data?.origem_anexo?.descri_are || ''
  }
}
