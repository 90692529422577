import { postTipoEtapaDTO } from '../../../dtos/processo/TipoEtapaDTO'
import ServiceBase from '../../base/ServiceBase'

export default class TipoEtapaService {
  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`tipo_etapa?${query}&page=${page}&perPage=${perPage}`)
  }

  static async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`tipo_etapa?${query}&page=${page}&perPage=${perPage}`)
  }

  static async filterAutocomplete (query, page, perPage) {
    return await ServiceBase.getBase('tipo_etapa')
  }

  async save (tipo_etapa) {
    return await ServiceBase.postBase('tipo_etapa/', postTipoEtapaDTO(tipo_etapa))
  }

  static async save (tipo_etapa) {
    return await ServiceBase.postBase('tipo_etapa/', postTipoEtapaDTO(tipo_etapa))
  }

  async get (codigo) {
    return await ServiceBase.getBase(`tipo_etapa/${codigo}`)
  }

  static async get (codigo) {
    return await ServiceBase.getBase(`tipo_etapa/${codigo}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`tipo_etapa?${query}&page=${page}&perPage=${perPage}`)
  }

  static async filter (query, page, perPage) {
    return await ServiceBase.getBase(`tipo_etapa?${query}&page=${page}&perPage=${perPage}`)
  }

  async delete (codigo) {
    return await ServiceBase.deleteBase(`tipo_etapa/${codigo}`)
  }

  static async delete (codigo) {
    return await ServiceBase.deleteBase(`tipo_etapa/${codigo}`)
  }
}
