import ServiceBase from '../../base/ServiceBase'

export default class MenuWebService {
  static async getMenuWeb (loading = false) {
    return await ServiceBase.getBase('/menuWeb', loading)
  }

  static async getMenuLucroVenda () {
    return await ServiceBase.getBase('/menuWeb/acessoLucroVenda')
  }
}
