import ServiceBase from '../../base/ServiceBase'

export default class ArquivoReferenteService {
  async getAll (query, page, perPage) {
    return await ServiceBase.getBase('arquivo_referente')
  }

  static async getAll (query, page, perPage) {
    return await ServiceBase.getBase('arquivo_referente')
  }
}
