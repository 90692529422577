import React, { useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import CheckboxInput from '../../../../components/inputs/CheckboxInput'
import DataListSimples from '../../../../components/layout/DataListSimples/DataListSimples'
import { formatDateTime } from '../../../../helpers/formaters'
import { createColumns } from '../../../../helpers/tableConfigs'

export default function OrcamentoOSList ({ orcamentoOS, aRetirar, setARetirar, reservada, setReservada, aplicada, setAplicada }) {
  const [oSOrcamentoSelecionado, setOsOrcamentoSelecionada] = useState([])
  const history = useHistory()

  const columns = createColumns([
    {
      field: 'tipoorigem',
      header: 'ORÇAMENTO/O.S',
      customBody: formatOrigem,
      sortable: false
    },
    {
      field: 'numeroorigem',
      header: 'NÚMERO',

      sortable: false
    },
    {
      field: 'situacaoorigem',
      header: 'SITUAÇÃO',

      sortable: false
    },
    {
      field: 'cliente',
      header: 'CLIENTE',
      width: '20%',
      sortable: false
    },
    {
      field: 'datahora',
      header: 'DATA',
      customBody: formatData,

      sortable: false
    },
    {
      field: 'consultor',
      header: 'CONSULTOR',

      sortable: false
    },
    {
      field: 'quantidade',
      header: 'QUANTIDADE',

      sortable: false
    },
    {
      field: 'qtdapl_pos',
      header: 'APLICADA',
      sortable: false
    },
    {
      field: 'qtdres_pre',
      header: 'RESERVADA',
      sortable: false
    }
  ])

  function formatOrigem (data) {
    const origem = { vendabalcao: 'Orçamento', ordemservico: 'O.S', pedidoecommerce: 'E-Commerce' }
    return origem[data.tipoorigem]
  }
  function formatData (data) {
    return formatDateTime(data.datahora)
  }

  return (
    <>

      <div className="md:flex col-12 md:col-span-4">
        <div className="field col-12 md:col-2 md:w-auto">
          <p className='text-gray-700 '>Situações:</p>
        </div>
        <div className="field col-12 md:col-4 md:align-content-center md:w-auto">
          <CheckboxInput label={'Á retirar'} checked={aRetirar} onChange={ () => setARetirar(state => !state)}
          />
        </div>
        <div className="field col-12 md:col-4 md:align-content-center md:w-auto">
          <CheckboxInput label={'Reservada'} checked={reservada} onChange={ () => setReservada(state => !state)}
          />
        </div>
        <div className="field col-12 md:col-4 md:align-content-center md:w-auto">
          <CheckboxInput label={'Aplicada'} checked={aplicada} onChange={ () => setAplicada(state => !state)}
          />
        </div>
      </div>
      <DataListSimples
        value={orcamentoOS}
        columns={columns}
        select={oSOrcamentoSelecionado}
        widthTotalizador='w-9'
        onSelect={ e => {
          setOsOrcamentoSelecionada(e.value)
          if (e.value.tipoorigem === 'vendabalcao') history.push('/orcamentoBalcao/' + e.value.codigoorigem)
        }}
        totalizadores={[
          {
            key: 'quantidade',
            label: 'Total quantidade'
          },
          {
            key: 'qtdapl_pos',
            label: 'Total aplicada/reservada'
          },
          {
            key: 'qtdres_pre',
            label: 'Total reservada'
          }
        ]}

      />
    </>
  )
}
