import ServiceBase from '../../base/ServiceBase'

export default class GerenciamentoEstoqueVeiculoService {
  static async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`gerenciamentoestoqueveiculo?page=${page}&perPage=${perPage}`)
  }

  static async filter (query, page, perPage) {
    return await ServiceBase.getBase(`gerenciamentoestoqueveiculo?${query}&page=${page}&perPage=${perPage}`)
  }

  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`gerenciamentoestoqueveiculo?page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`gerenciamentoestoqueveiculo?${query}&page=${page}&perPage=${perPage}`)
  }
}
