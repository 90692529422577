import React from 'react'
import DateInput from '../../../../components/inputs/DateInput'
import EmpresasAcessoTreeSelect from '../../../../components/inputs/empresasAcessoTreeSelect/EmpresasAcessoTreeSelect'
import SearchInput from '../../../../components/inputs/searchInput/SearchInput'
import { showErrorMessage, showWarnMessage } from '../../../../components/utils/Message'
import ReportFiltroModalBase from '../../../../components/utils/report/ReportFiltroModalBase'
import CommonHelper from '../../../../helpers/CommonHelper'
import { VendedorModel } from '../../../../models/cadastro/pessoa/VendedorModel'
import { PessoaModel } from '../../../../models/cadastro/pessoa/PessoaModel'
import { getStore } from '../../../../redux/store'
import VendedoresService from '../../../../services/cadastro/pessoa/VendedoresService'
import ProcessoUsoReportService from '../../../../services/reports/ProcessoUsoReportService'
import { getProcessoUsoDTO } from '../../../../dtos/processo/ProcessoUsoDTO'
import AcompanhamentoService from '../../../../services/processo/AcompanhamentoService'
import SancesTreeSelect from '../../../../components/inputs/TreeSelect'
import ModeloProcessoService from '../../../../services/processo/ModeloProcessoService'
import PessoaService from '../../../../services/cadastro/pessoa/PessoaService'
import TextInput from '../../../../components/inputs/TextInput'
import StatusPendenciaService from '../../../../services/processo/StatusPendenciaService'

class ProcessoUsoReportFiltroModal extends ReportFiltroModalBase {
  constructor (props) {
    super(props, ProcessoUsoReportService)

    this.state = {
      ...this.state,
      sugestaoProcessos: [],
      sugestaoAtendentes: [],
      empresas: [],
      processos: [],
      statusPendencia: [],
      descri_veiculo: ''
    }
  }

  async componentDidMount () {
    super.setFormInitialValues({
      dataCadastroProcessoInicial: CommonHelper.lastMonthToDate(),
      dataCadastroProcessoFinal: new Date(),
      empresas: [getStore().empresaLogada.codigo_emp]
    })

    super.setLegendaConfigs([
      {
        filtroName: 'empresas',
        texto: 'Código das empresas',
        formatFunction: this.getDescricaoEmpresasSelecionadas.bind(this)
      },
      {
        filtroName: 'processos',
        texto: 'Código dos modelos de processos',
        formatFunction: this.getDescricaoProcessosSelecionadas.bind(this)
      },
      {
        filtroName: 'statusPendencia',
        texto: 'Código dos status das pendências',
        formatFunction: this.getDescricaoStatusPendenciaSelecionadas.bind(this)
      },
      {
        filtroName: 'processouso',
        texto: 'Processo número'
      },
      {
        filtroName: 'descri_veiculo',
        texto: 'Descrição veículo'
      },
      {
        filtroName: 'atendente',
        texto: 'Atendente',
        formatFunction: () => this.props.form.atendente.nomraz_pes
      },
      {
        filtroName: 'cliente',
        texto: 'Cliente',
        formatFunction: () => this.props.form.cliente.nomraz_pes
      },
      {
        filtroName: 'dataCadastroProcessoInicial',
        texto: 'Processos com data de cadastro posterior a',
        formatFunction: CommonHelper.dateToBrazilian
      },
      {
        filtroName: 'dataCadastroProcessoFinal',
        texto: 'Processos com data de cadastro anterior a',
        formatFunction: CommonHelper.dateToBrazilian
      }
    ])
  }

  getDescricaoEmpresasSelecionadas () {
    const { form } = this.props

    let descricao = ''

    form.empresas.forEach((empresa, idx) => {
      descricao += empresa

      if (idx !== form.empresas.length - 1) {
        descricao += ', '
      }
    })

    return descricao
  }

  getDescricaoProcessosSelecionadas () {
    const { form } = this.props

    let descricao = ''

    form.processos.forEach((processo, idx) => {
      descricao += processo

      if (idx !== form.processos.length - 1) {
        descricao += ', '
      }
    })

    return descricao
  }

  getDescricaoStatusPendenciaSelecionadas () {
    const { form } = this.props

    let descricao = ''

    form.statusPendencia.forEach((processo, idx) => {
      descricao += processo

      if (idx !== form.statusPendencia.length - 1) {
        descricao += ', '
      }
    })

    return descricao
  }

  setFilters () {
    const { form } = this.props

    this.filters = {
      empresas: form.empresas,
      processos: form.processos,
      statusPendencia: form.statusPendencia,
      processouso: form.processouso?.codigo_pru || null,
      atendente: form.atendente?.codigo_ven || null,
      cliente: form.cliente?.codigo_pes || null,
      descri_veiculo: form.descri_veiculo || '',
      dataCadastroProcessoInicial: CommonHelper.dateToAmerican(form.dataCadastroProcessoInicial),
      dataCadastroProcessoFinal: CommonHelper.dateToAmerican(form.dataCadastroProcessoFinal)
    }
  }

  async sugerirProcesso ({ query }) {
    try {
      const { data } = await AcompanhamentoService.filter(`descricaoFiltro=${query}`)

      if (data.length <= 0) {
        showWarnMessage('Processo não encontrado!')
      }

      this.setState({ sugestaoProcessos: data })
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um processo!')
    }
  }

  async sugerirAtendente ({ query }) {
    try {
      const vendedoresService = new VendedoresService()
      const vendedores = await vendedoresService.filterAutoComplete(`nomraz_pes=${query}`)

      if (vendedores.length <= 0) {
        showWarnMessage('Atendente não encontrado!')
      }

      this.setState({ sugestaoAtendentes: vendedores })
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um atendente!')
    }
  }

  filtroInputs () {
    const { form } = this.props

    return (
      <>
        <div className="field col-12">
          <EmpresasAcessoTreeSelect
            label="Empresas"
            selectedOptions={form.selectedOptionsEmpresas}
            changeSelectedOptions={(selectedOptions) => this.setFormValue('selectedOptionsEmpresas', selectedOptions)}
            changeSelectedEmpresas={(empresas) => this.setFormValue('empresas', empresas.map(empresa => empresa.codigo_pes))}
          />
        </div>
        <div className='field col-12 md:col-6'>
          <SancesTreeSelect
            label="Processos"
            groupLabel="Processos"
            campoChildrenLabel="descri_prc"
            modelService={ModeloProcessoService}
            placeholder="Selecione os processos"
            comecaSemNada
            selectedOptions={form.selectedOptionsProcessos}
            changeSelectedOptions={async (selectedOptions) => {
              this.setFormValue('selectedOptionsProcessos', selectedOptions)
            }}
            changeSelectedDados={async (processos) => {
              this.setFormValue('processos', processos.map(processo => processo.codigo_pes))
            }}
          />
        </div>
        <div className="field col-12 md:col-6">
          <SearchInput
            field="codigo_pru"
            name="processouso"
            label="Código do Processo"
            minLength={1}
            value={form.processouso?.descri_pru || form.processouso}
            className="inputfield w-full"
            placeholder="Código do processo"
            onSelect={(e) => super.setFormValue('processouso', e.value)}
            suggestions={this.state.sugestaoProcessos}
            completeMethod={(e) => this.sugerirProcesso(e)}
            onChange={(e) => super.handleChange(e)}
            itemTemplate={(data) => `${data.codigo_pru} - ${data.descri_pru}`}
            // FiltroModal
            filtrotitle="Pesquisa do processo"
            service={AcompanhamentoService}
            model={getProcessoUsoDTO}
            primarykeyname="codigo_pru"
            columns={[
              { campo: 'codigo_pru', nome: 'Código' },
              { campo: 'nome_empresa', nome: 'Empresa' },
              { campo: 'descri_prc', nome: 'Processo' },
              { campo: 'nome_cliente', nome: 'Cliente' },
              { campo: 'nome_atendente', nome: 'Atendente' },
              { campo: 'descri_situacao', nome: 'Situação' }
            ]}
          />
        </div>
        <div className="field col-12 md:col-6">
          <SearchInput
            //AutoComplete
            field="nomraz_pes"
            name="atendente"
            label="Atendente"
            value={form.atendente?.nomraz_pes || form.atendente}
            placeholder="Nome do atendente"
            className="inputfield w-full"
            suggestions={this.state.sugestaoAtendentes}
            onSelect={(e) => super.setFormValue('atendente', e.value)}
            completeMethod={(e) => this.sugerirAtendente(e)}
            onChange={(e) => super.handleChange(e)}
            itemTemplate={(data) => `${data.codigo_ven} - ${data.nomraz_pes}`}
            // FiltroModal
            filtrotitle="Pesquisa de atendente"
            service={VendedoresService}
            model={VendedorModel}
            primarykeyname="codigo_ven"
            columns={[
              { campo: 'codigo_ven', nome: 'Código' },
              { campo: 'nomraz_pes', nome: 'Nome' }
            ]}
          />
        </div>
        <div className="field col-12 md:col-6">
          <SearchInput
            //AutoComplete
            field="nomraz_pes"
            name="cliente"
            label="Cliente"
            value={form.cliente?.nomraz_pes || form.cliente}
            placeholder="Nome do cliente"
            className="inputfield w-full"
            suggestions={this.state.sugestaoclientes}
            onSelect={(e) => super.setFormValue('cliente', e.value)}
            completeMethod={(e) => this.sugerirCliente(e)}
            onChange={(e) => super.handleChange(e)}
            itemTemplate={(data) => `${data.codigo_pes} - ${data.nomraz_pes}`}
            // FiltroModal
            filtrotitle="Pesquisa de cliente"
            service={PessoaService}
            model={PessoaModel}
            primarykeyname="codigo_pes"
            columns={[
              { campo: 'codigo_pes', nome: 'Código' },
              { campo: 'nomraz_pes', nome: 'Nome' }
            ]}
          />
        </div>
        <div className="field col-12">
          <label>Data de cadastro</label>
          <div className="formgrid grid">
            <div className="field col-12 md:col-6 no-margin">
              <DateInput
                name="dataCadastroProcessoInicial"
                placeholder="Data Inicial"
                className="inputfield w-full"
                value={form.dataCadastroProcessoInicial}
                onChange={(e) => super.handleChange(e)}
              />
            </div>
            <div className="field col-12 md:col-6 no-margin">
              <DateInput
                name="dataCadastroProcessoFinal"
                placeholder="Data Final"
                className="inputfield w-full"
                value={form.dataCadastroProcessoFinal}
                onChange={(e) => super.handleChange(e) }
              />
            </div>
          </div>
        </div>
        <div className="field col-12 md:col-6">
          <TextInput
            label="Descrição do veículo"
            placeholder="Descrição do veículo"
            value={form.descri_veiculo}
            className="inputfield w-full"
            onChange={(e) => super.handleChange(e)}
          />
        </div>
        <div className='field col-12 md:col-6'>
          <SancesTreeSelect
            label="Status das pendências"
            groupLabel="Status das pendências"
            campoChildrenLabel="descri_spe"
            modelService={StatusPendenciaService}
            placeholder="Selecione os status"
            comecaSemNada
            selectedOptions={form.selectedOptionsSituacao}
            changeSelectedOptions={async (selectedOptions) => {
              this.setFormValue('selectedOptionsSituacao', selectedOptions)
            }}
            changeSelectedDados={async (statusPendencia) => {
              this.setFormValue('statusPendencia', statusPendencia.map(processo => processo.codigo_spe))
            }}
          />
        </div>
      </>
    )
  }
}

export default ProcessoUsoReportFiltroModal
