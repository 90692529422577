import './_negociosFechados.scss'

import { formatDate, formatToCurrency } from '../../../../helpers/formaters'

import AlteracaoFoPagNegModal from '../alteracaoFoPagNeg/AlteracaoFoPagNegModal'
import AppButton from '../../../../components/layout/AppButton'
import CheckboxInput from '../../../../components/inputs/CheckboxInput'
import CommonHelper from '../../../../helpers/CommonHelper'
import Container from '../../../../components/layout/Container'
import DataList from '../../../../components/layout/DataList'
import DateInput from '../../../../components/inputs/DateInput'
import { NegociacaoModel } from '../../../../models/vendas/negociacao/NegociacaoModel'
import NegociacaoService from '../../../../services/vendas/negociacao/NegociacaoService'
import Page from '../../../../components/layout/Page'
import PreferencesButton from '../../../../components/utils/PreferencesButton'
import React from 'react'
import TablePreferences from '../../../../components/utils/TablePreferences'
import { classNames } from 'primereact/utils'
import { createColumns, getColunasHabilitadas } from '../../../../helpers/tableConfigs'
import { showErrorMessage } from '../../../../components/utils/Message'
import EmpresasAcessoTreeSelect from '../../../../components/inputs/empresasAcessoTreeSelect/EmpresasAcessoTreeSelect'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import useList from '../../../../hook/useList'

function negociacaoFeitaPeloWeb (data) {
  return data.codapp_neg === 2
}

function getDataNegociacao (data) {
  return formatDate(data.data_neg)
}

function getOperacaoNeg (data) {
  let operacaoNeg = 'Compra'
  switch (data.operac_neg) {
  case 2:
    operacaoNeg = 'Venda'
    break
  case 5:
    operacaoNeg = 'Consignação'
    break
  case 98:
    operacaoNeg = 'Troca'
    break
  }

  return operacaoNeg
}

function getOperacaoNegClassName (data) {
  return `operacaoneg-${getOperacaoNeg(data)}`
}

function getValorTotalNeg (data) {
  return formatToCurrency(data.valorTotalNeg, true)
}

const rowExpansionTemplate = (data, props) => {
  return (
    <div className={'negocios-fechados row-expansion grid justify-content-' + (CommonHelper.isDesktop() ? 'end' : 'center')}>
      {negociacaoFeitaPeloWeb(data) && (
        <AppButton
          label="Alterar pagamento"
          onClick={async () => {
            try {
              const negociacaoService = new NegociacaoService()
              const negociacao = await negociacaoService.get(data.codigo_neg)
              await props.setState(
                (prevState) => ({
                  ...prevState,
                  negociacao: negociacao,
                  visibleAlteracaoFoPagNegModal: true
                })
              )
            } catch (error) {
              showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar a negociação!')
            }
          }}
          className={
            (
              CommonHelper.isDesktop() ? 'mr-3' : 'mb-3'
            ) + ' negocios-fechados button'
          }
        />
      )}
      <AppButton
        label="Visualizar negócio"
        onClick={() => props.onView(data)}
        className="negocios-fechados button"
      />
    </div>
  )
}

const expandedFiltersTemplate = (props) => (
  <>
    <div className="field col-12 md:col-12">
      <EmpresasAcessoTreeSelect
        label="Empresas"
        selectedOptions={props.state.selectedOptionsEmpresas}
        changeSelectedOptions={async (selectedOptions) => await props.setState((prevState) => ({ ...prevState, selectedOptionsEmpresas: selectedOptions }))}
        changeSelectedEmpresas={async (empresas) => await props.setState((prevState) => ({ ...prevState, empresas: empresas.map(empresa => empresa.codigo_pes) }))}
      />
    </div>
    <div className="field col-12 md:col-6">
      <label className="label">Data de finalização da negociação</label>
      <div className="formgrid grid">
        <div className={'col-12 md:col-6 mb-3 mt-2'}>
          <DateInput
            name="dataNegInicial"
            placeholder="Data Inicial"
            className="inputfield w-full"
            value={props.state.dataNegInicial}
            onChange={async (e) => await props.setState((prevState) => ({ ...prevState, dataNegInicial: e.target.value }))} />
        </div>
        <div className={'col-12 md:col-6 mb-3 mt-2'}>
          <DateInput
            name="dataNegociacaoFinal"
            placeholder="Data Final"
            className="inputfield w-full"
            value={props.state.filter.dataNegFinal}
            onChange={async (e) => await props.setState((prevState) => ({ ...prevState, dataNegFinal: e.target.value }))} />
        </div>
      </div>
    </div>
    <div className="field col-12 md:col-6">
      <label className="label">Operação</label>
      <div className="col-12 flex flex-wrap">
        <div className="md:field col-12 md:col-3">
          <CheckboxInput
            inputId="chkFiltroVenda"
            value={props.state.operacaoNegVenda}
            label="Venda"
            checked={props.state.operacaoNegVenda}
            onChange={async (e) => await props.setState((prevState) => ({ ...prevState, operacaoNegVenda: !e.target.value }))}
            containerStyle={'flex align-items-center mt-3 '}
          />
        </div>
        <div className="md:field col-12 md:col-3">
          <CheckboxInput
            inputId="chkFiltroCompra"
            value={props.state.operacaoNegCompra}
            label="Compra"
            checked={props.state.operacaoNegCompra}
            onChange={async (e) => await props.setState((prevState) => ({ ...prevState, operacaoNegCompra: !e.target.value }))}
            containerStyle={'flex align-items-center mt-3 '}
          />
        </div>
        <div className="md:field col-12 md:col-3">
          <CheckboxInput
            inputId="chkFiltroTroca"
            value={props.state.operacaoNegTroca}
            label="Troca"
            checked={props.state.operacaoNegTroca}
            onChange={async (e) => await props.setState((prevState) => ({ ...prevState, operacaoNegTroca: !e.target.value }))}
            containerStyle={'flex align-items-center mt-3 '}
          />
        </div>
        <div className="md:field col-12 md:col-3">
          <CheckboxInput
            inputId="chkFiltroConsignacao"
            value={props.state.operacaoNegConsignacao}
            label="Consignação"
            checked={props.state.operacaoNegConsignacao}
            onChange={async (e) => await props.setState((prevState) => ({ ...prevState, operacaoNegConsignacao: !e.target.value }))}
            containerStyle={'flex align-items-center mt-3 '}
          />
        </div>
      </div>
    </div>
    <div className="col-12 flex justify-content-end mb-4">
      <AppButton label="Buscar" icon="pi pi-search" onClick={props.onFilterClick} />
    </div>
  </>
)

const NegociosFechadosList = () => {
  const columns = createColumns([
    { field: 'codigo_neg', header: 'Número', width: '10%' },
    { field: 'descri_emp', header: 'Empresa', width: '15%' },
    { field: 'descri_vei', header: 'Veículo', width: '25%' },
    { field: 'data_neg', header: 'Data', width: '10%', customBody: getDataNegociacao },
    { field: 'operac_neg', header: 'Operação', width: '10%', customBody: getOperacaoNeg, customClassName: getOperacaoNegClassName },
    { field: 'nomraz_pes', header: 'Cliente', width: '20%' },
    { field: 'valorTotalNeg', header: 'Valor', width: '10%', customBody: getValorTotalNeg }
  ])

  const history = useHistory()

  const {
    state,
    setState,
    onPageChange,
    onSelect,
    onFilter,
    handleRowExpansion,
    handleChangeFilter,
    onView
  } = useList(
    NegociacaoModel,
    NegociacaoService,
    'negociacao',
    'codigo_neg',
    undefined,
    'fechados',
    {
      visiblePreferencesModal: false,
      visibleAlteracaoFoPagNegModal: false,
      dataNegInicial: '',
      dataNegFinal: '',
      operacaoNegCompra: 'Compra',
      operacaoNegVenda: 'Venda',
      operacaoNegTroca: 'Troca',
      operacaoNegConsignacao: 'Consignação',
      expandedRows: null,
      negociacao: {},
      selectedOptionsEmpresas: null,
      empresas: [],
      opcaoSelecionada: 'fechadas',
      columns
    }
  )

  const onFilterClick = async () => {
    const filter = state.filter
    filter.dataNegociacaoInicial = CommonHelper.dateToAmerican(state.dataNegInicial)
    filter.dataNegociacaoFinal = CommonHelper.dateToAmerican(state.dataNegFinal)
    filter.operacaoNegCompra = state.operacaoNegCompra ? 'Compra' : 'Sem Compra'
    filter.operacaoNegVenda = state.operacaoNegVenda ? 'Venda' : 'Sem Venda'
    filter.operacaoNegTroca = state.operacaoNegTroca ? 'Troca' : 'Sem Troca'
    filter.operacaoNegConsignacao = state.operacaoNegConsignacao ? 'Consignação' : 'Sem Consignação'
    filter.empresas = state.empresas
    await setState((prevState) => ({ ...prevState, filter }))
    await onFilter(filter)
  }

  const onSelectOption = async (option) => {
    let { filter } = state
    switch (option) {
    case 'fechadas':
      filter.status_neg = '3'
      localStorage.setItem('status_neg', 3)
      break
    case 'consignadas':
      filter.status_neg = '4'
      localStorage.setItem('status_neg', 4)
      break
    case 'canceladas':
      filter.status_neg = '2'
      localStorage.setItem('status_neg', 2)
      break
    }
    await setState((prevState) => ({ ...prevState, filter, opcaoSelecionada: option }))
    await onFilter(filter)
  }

  return (
    <Page>
      <div className="flex justify-content-between align-items-center page-header">
        <h4>Negócios Fechados</h4>
      </div>
      <div className="table-options-container">
        <div className="table-options">
          <div className={classNames({ active: state.opcaoSelecionada === 'fechadas' }, 'filter-option')}
            onClick={() => onSelectOption('fechadas')}>
            <span className="option-label">Fechados</span>
            <div className="option-quantity">{state.meta?.quantidadeFechadas}</div>
          </div>
          <div className={classNames({ active: state.opcaoSelecionada === 'consignadas' }, 'filter-option')}
            onClick={() => onSelectOption('consignadas')}>
            <span className="option-label">Consignados</span>
            <div className="option-quantity">{state.meta?.quantidadeConsignadas}</div>
          </div>
          <div className={classNames({ active: state.opcaoSelecionada === 'canceladas' }, 'filter-option')}
            onClick={() => onSelectOption('canceladas')}>
            <span className="option-label">Cancelados</span>
            <div className="option-quantity">{state.meta?.quantidadeCanceladas}</div>
          </div>
        </div>
        <div className="table-preferences">
          <PreferencesButton
            onClick={async () => await setState((prevState) => ({ ...prevState, visiblePreferencesModal: true }))}
          />
        </div>
      </div>
      <Container>
        <DataList
          data={state.list}
          selected={state.selected}
          onSelect={onSelect}
          onRowClick={(e) => handleRowExpansion(e.data)}
          rows={state.rows}
          totalRecords={state.totalRecords}
          first={state.first}
          onPage={onPageChange}
          expandedRows={state.expandedRows}
          rowExpansionTemplate={rowExpansionTemplate}
          page={state.page}
          showFilter={true}
          responsive={true}
          filterPlaceholder='Digite e pressione "Enter" para procurar negócios'
          filterName="descricaoFiltro"
          onFilter={onFilter}
          filterValue={state.filter.descricaoFiltro}
          expandedFiltersTemplate={expandedFiltersTemplate}
          filterOnChange={handleChangeFilter}
          customMaxHeight={'unset'}

          // * Passe por props as funções que serão usadas no expandedFiltersTemplate/rowExpansionTemplate
          onFilterClick={onFilterClick}
          history={history}
          state={state}
          setState={setState}
          onView={onView}
        >
          {getColunasHabilitadas(columns, 'negociosFechados')}
        </DataList>
        <AlteracaoFoPagNegModal
          visible={state.visibleAlteracaoFoPagNegModal}
          hide={async () => await setState((prevState) => ({ ...prevState, visibleAlteracaoFoPagNegModal: false }))}
          negociacao={state.negociacao}
        />
      </Container>
      <TablePreferences
        title="Preferências da tabela de negócios"
        tableName="negociosFechados"
        columns={columns}
        visible={state.visiblePreferencesModal}
        hide={async () => await setState((prevState) => ({ ...prevState, visiblePreferencesModal: false }))}
      />
    </Page>
  )
}
export default NegociosFechadosList
