import { InputText } from 'primereact/inputtext'
import { ScrollTop } from 'primereact/scrolltop'
import { classNames } from 'primereact/utils'
import React, { useEffect } from 'react'
import AppButton from '../../../../components/layout/AppButton'
import Container from '../../../../components/layout/Container'
import DataList from '../../../../components/layout/DataList'
import Page from '../../../../components/layout/Page'
import Confirm from '../../../../components/utils/Confirm'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../../../../components/utils/Message'
import Modal from '../../../../components/utils/Modal'
import PreferencesButton from '../../../../components/utils/PreferencesButton'
import TablePreferences from '../../../../components/utils/TablePreferences'
import ReservaVeiculoModal from '../../../../forms/vendas/negociacao/gerenciamentoestoque/ReservaVeiculoModal'
import AES from '../../../../helpers/AES'
import CommonHelper from '../../../../helpers/CommonHelper'
import { formatPlacha, formatToCurrency } from '../../../../helpers/formaters'
import { createColumns, getColunasHabilitadas } from '../../../../helpers/tableConfigs'
import { GerenciamentoEstoqueVeiculoModel } from '../../../../models/vendas/negociacao/GerenciamentoEstoqueVeiculoModel'
import { getStore } from '../../../../redux/store'
import VeiculoService from '../../../../services/cadastro/veiculo/VeiculoService'
import ConfigService from '../../../../services/configuracao/ConfigService'
import GerenciamentoEstoqueVeiculoService from '../../../../services/vendas/negociacao/GerenciamentoEstoqueVeiculoService'

import BloquearVeiculoModal from '../../../../forms/vendas/negociacao/gerenciamentoestoque/BloquearVeiculoModal'
import PermissaoService from '../../../../services/cadastro/pessoa/PermissaoService'
import AdicionarPixModal from '../../../integracao/financeiro/GerarPixVaBank'
import { venderVeiculo } from '../../persistencia/VenderVeiculo'
import useList from '../../../../hook/useList'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

function getTipoVeiculo (data) {
  return data.codtiv_vei === 'n' ? 'Novo' : 'Usado'
}

function getTipoVeiculoClassName (data) {
  return `tipo-veiculo-${data.codtiv_vei}`
}

function getTipoVeiculoProprioConsignado (data) {
  return data.consig_nve ? 'Consignado' : 'Próprio'
}

function getConjunto (data) {
  return data.infoConjunto
}

function getPlacha (data) {
  return formatPlacha(data.placa_vei, data.chassi_vei)
}

function getMarcaModelo (data) {
  return ` ${data.descri_mar}  /  ${data.descri_mod} `
}

function getAnoFabAnoMod (data) {
  return data.anofab_vei + '/' + data.anomod_vei
}

function getPreco (data) {
  return formatToCurrency(data.preco_pre, true)
}

function getPrecoPromocional (data) {
  return formatToCurrency(data.prepro_pre, true)
}

function getKilometers (data) {
  return data.km_vei ? data.km_vei.toLocaleString('pt-BR') : ''
}

function getDescricaoDisponibilidadeVeiculo (data) {
  return data.codigo_vdi === 'D' ? 'Disponível' : data.descri_vdi
}

function getDescricaoDisponibilidadeVeiculoClassName (data) {
  return `veiculo-disponibilidade-${data.codigo_vdi}`
}

function getDescricaoSituacaoVeiculoClassName (data) {
  return `veiculo-situacao-perven-${data.perven_ves ? '1' : '0'}`
}

const expandedFiltersTemplate = (props) => {
  return (
    <>
      <div className="field col-12 md:col-4">
        <label className="label">Ano</label>
        <div className="formgrid grid">
          <div className="col-6">
            <InputText placeholder="de" name="anoModeloInicial" value={props.state.filter.anoModeloInicial || ''}

              onChange={(event) => props.filterOnChange(event)} className="inputfield w-full" />
          </div>
          <div className="col-6">
            <InputText placeholder="até" name="anoModeloFinal" value={props.state.filter.anoModeloFinal || ''}

              onChange={(event) => props.filterOnChange(event)} className="inputfield w-full" />
          </div>
        </div>
      </div>
      <div className="field col-12 md:col-4">
        <label className="label">Preço</label>
        <div className="formgrid grid">
          <div className="col-6">
            <InputText placeholder="de" name="precoInicial" value={props.state.filter.precoInicial || ''}

              onChange={(event) => props.filterOnChange(event)} className="inputfield w-full" />
          </div>
          <div className="col-6">
            <InputText placeholder="até" name="precoFinal" value={props.state.filter.precoFinal || ''}

              onChange={(event) => props.filterOnChange(event)} className="inputfield w-full" />
          </div>
        </div>
      </div>
      <div className="field col-12 md:col-4">
        <label className="label">Quilometragem</label>
        <div className="formgrid grid">
          <div className="col-6">
            <InputText placeholder="de" name="kmInicial" value={props.state.filter.kmInicial || ''}

              onChange={(event) => props.filterOnChange(event)} className="inputfield w-full" />
          </div>
          <div className="col-6">
            <InputText placeholder="até" name="kmFinal" value={props.state.filter.kmFinal || ''}

              onChange={(event) => props.filterOnChange(event)} className="inputfield w-full" />
          </div>
        </div>
      </div>
      <div className="col-12 flex justify-content-end mb-4">
        <AppButton label="Buscar" icon="pi pi-search" onClick={props.onFilter} />
      </div>
    </>
  )
}

const rowExpansionTemplate = (data, props) => {
  const empresaLogada = getStore().empresaLogada
  return (
    <div className="row-expansion grid" style={{ textAlign: '' }}>
      <div className="grid col-12">
        <div className="col-12"><h6>Detalhes do veículo:</h6></div>
        <div className="col-12 flex">
          <span className="row-expansion-label">Situação:</span>
          <span className="row-expansion-value">{data.descri_ves}</span>
        </div>
        <div className="col-12 flex">
          <div className="row-expansion-label">Próprio ou consignado:</div>
          <div className={classNames({ 'consignado-color': !data.consig_nve }, 'row-expansion-value')}>
            {data.consig_nve ? 'Consignado' : 'Próprio'}
          </div>
        </div>
        <div className="col-12 flex">
          <div className="row-expansion-label">Repasse:</div>
          <div className="row-expansion-value">{data.repass_vei ? 'Sim' : 'Não'}</div>
        </div>
        <div className="col-12 flex">
          <div className="row-expansion-label">Disponibilidade:</div>
          <div className="row-expansion-value">{data.descri_vdi}</div>
        </div>
        <div className="col-12 flex">
          <div className="row-expansion-label">Acessórios:</div>
          <div className="row-expansion-value underline cursor-pointer"
            onClick={() => props.setState((prevState) => ({ ...prevState, visibleAcessoriosModal: true, acessoriosList: data.descri_ace }))}>
            {data.sigla_ace}
          </div>
        </div>
        {
          props.state.config1575 && (
            <div className="col-12 flex">
              <div className="row-expansion-label">Log intregração Auto Gestor:</div>
              <div className="row-expansion-value">
                {data.infrec_agl ? data.infrec_agl : data.isIntegradoAG === 'Sim' ? '' : props.state.config3252.includes('' + data.codigo_ves) ? `Veículo não integrado, porque a situação ${data.codigo_vei} - ${data.descri_ves} está configurada para não ser enviada ao autogestor. Configuração: 3252 ` : ''}
              </div>
            </div>
          )}
      </div>
      <div className="grid col-12">
        <div className="row-expansion-buttons col-12 flex no-padding">

          {props.state.usaIntegracaoVaBank && (
            <AppButton
              label="PIX"
              className="ml-1 row-expansion-button"
              onClick={() => {
                props.setState((prevState) => ({ ...prevState, visibleGerarPix: true }))
              }}
            />
          )}
          <AppButton
            label="Link do veículo"
            className="ml-1 row-expansion-button"
            onClick={() => {
              const encryptedDatabase = AES.encryptWithAES(getStore().usuarioLogado.databaseConnectionName)
              window.open(
                `${window.location.origin}/landing-veiculo/` +
                `${CommonHelper.formatStringToURL(encryptedDatabase)}/${data.codigo_vei}/${data.ofeemp_vei}`
              )
            }}
          />
          <AppButton
            label="Visualizar cadastro"
            className="ml-1 expansion-button"
            onClick={() => { props.history.push(`/veiculo/${data.codigo_vei}`) }}
          />
          {((data.codigo_vdi === 'B' || (data.codigo_vdi === 'D' && props.state.permissaoParaBloquear)) && data.perblo_ves === 1) && (
            <AppButton label={!!(data.codigo_vdi === 'B') ? 'Ver bloqueio' : 'Bloquear'} className="ml-1 row-expansion-button" onClick={async () => {
              try {
                const dadosBloqueio = await VeiculoService.getDadosBloqueio(data.codigo_vei)
                props.setState((prevState) => ({ ...prevState, dadosBloqueio, visibleBloquearModal: true }))
              } catch (error) {
                showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar os dados do bloqueio do veículo!')
              }
            }} />
          )}
          {data.codigo_vdi === 'D' && (
            <AppButton
              label="Reservar"
              className="ml-1 row-expansion-button"
              onClick={async () => {
                try {
                  if (empresaLogada.codigo_emp !== data.ofeemp_vei) {
                    showWarnMessage(`Veículo em estoque na empresa ${data.ofeemp_vei}!`)
                    return
                  }

                  const reserva = await VeiculoService.getDadosReserva(data.codigo_pre)
                  props.setState((prevState) => ({ ...prevState, reserva: reserva, isVisibleReservaModal: true, visualizarReserva: false }))
                } catch (error) {
                  showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar os dados da reserva do veículo!')
                }
              }} />
          )}
          {(data.codigo_vdi !== 'B' && data.codigo_vdi !== 'V' && data.codigo_vdi !== 'I' && (data.perven_ves === 1)) && (
            <AppButton
              label="Vender"
              className="ml-1 row-expansion-button"
              onClick={async () => {
                if (await venderVeiculo.handleVenderVeiculo(data)) {
                  props.history.push('/negociacao')
                }
              }}
            />
          )}
          {data.codigo_vdi === 'R' && (
            <AppButton
              label="Ver reserva"
              className="ml-1 row-expansion-button"
              onClick={async () => {
                try {
                  const reserva = await VeiculoService.getDadosReserva(data.codigo_pre)
                  props.setState((prevState) => ({ ...prevState, reserva: reserva, isVisibleReservaModal: true, visualizarReserva: true }))
                } catch (error) {
                  showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar os dados da reserva do veículo!')
                }
              }} />
          )}
          {data.codigo_vdi === 'R' && (
            <AppButton
              label="Cancelar reserva"
              className="ml-1 row-expansion-button"
              onClick={() => {
                if (empresaLogada.codigo_emp !== data.ofeemp_vei) {
                  showWarnMessage(`Veículo em estoque na empresa ${data.ofeemp_vei}!`)
                }

                props.setState((prevState) => ({ ...prevState, visibleConfirmacao: true }))
              }}
              disabled={!(data.codigo_vdi === 'R') || empresaLogada.codigo_emp !== data.ofeemp_vei} />
          )}

          <AppButton
            label="Precificar"
            className="ml-1 row-expansion-button"
            onClick={async () => {
              if (empresaLogada.codigo_emp !== data.ofeemp_vei) {
                showWarnMessage(`Veículo em estoque na empresa ${data.ofeemp_vei}!`)
                return
              }
              if (!props.state.permissaoPrecificar) {
                showWarnMessage(`Usuário logado não possui permissão para alterar o preço do veículo: ${formatPlacha(data.placa_vei, data.chassi_vei)}!`)
                return
              }
              props.history.push(`/precificar/${data.codigo_vei}`)
            }} />

          {props.state.permissaoParaSimularVenda && (
            <AppButton
              label="Simular venda"
              className="ml-1 row-expansion-button"
              onClick={async () => {
                if (empresaLogada.codigo_emp !== data.ofeemp_vei) {
                  showWarnMessage(`Veículo em estoque na empresa ${data.ofeemp_vei}!`)
                  return
                }
                if (!props.state.permissaoParaSimularVenda) {
                  showWarnMessage(`Usuário logado não possui permissão para simular a venda do veículo: ${formatPlacha(data.placa_vei, data.chassi_vei)}!`)
                  return
                }
                props.history.push(`/simulacao-venda/${data.codigo_vei}`)
              }} />
          )}
        </div>
      </div>
    </div>
  )
}

export const GerenciamentoEstoqueVeiculos = () => {
  const columns = createColumns([
    { field: 'ofeemp_vei', header: 'Emp', width: '5%' },
    { field: 'codtiv_vei', header: 'N/U', width: '6%', customClassName: getTipoVeiculoClassName, customBody: getTipoVeiculo },
    { field: 'consig_nve', header: 'Próprio/\nConsignado', width: '8.5%', customBody: getTipoVeiculoProprioConsignado },
    { field: 'infoConjunto', header: 'Conjunto', width: '15%', customBody: getConjunto, enabled: false },
    { field: 'placa_vei', header: 'Placa/Chassi', width: '10%', customBody: getPlacha },
    { field: 'descri_mar', header: 'Marca/Modelo', width: '15%', customBody: getMarcaModelo },
    { field: 'descri_cor', header: 'Cor', width: '10%' },
    { field: 'anofab_vei', header: 'Ano', width: '7%', customBody: getAnoFabAnoMod },
    { field: 'preco_pre', header: 'Preço', width: '8%', customBody: getPreco },
    { field: 'prepro_pre', header: 'Preço Promocional', width: '8%', customBody: getPrecoPromocional, enabled: false },
    { field: 'km_vei', header: 'KM', width: '5%', customBody: getKilometers, enabled: false },
    { field: 'dias_est', header: 'Dias', width: '5%' },
    { field: 'descri_vdi', header: 'Disponibilidade', width: '10%', customClassName: getDescricaoDisponibilidadeVeiculoClassName, customBody: getDescricaoDisponibilidadeVeiculo, enabled: false },
    { field: 'descri_ves', header: 'Situação', width: '8%', customClassName: getDescricaoSituacaoVeiculoClassName, enabled: false },
    { field: 'isIntegradoAG', header: 'Integrado com o Auto Gestor?', width: '8%', enabled: false }
  ])

  const history = useHistory()

  const {
    state,
    setState,
    onPageChange,
    onSelect,
    handleChangeFilter,
    onFilter,
    handleRowExpansion
  } = useList(
    GerenciamentoEstoqueVeiculoModel,
    GerenciamentoEstoqueVeiculoService,
    '',
    'codpro_pre',
    undefined,
    undefined,
    {
      expandedRows: null,
      reserva: null,
      visualizarReserva: false,
      visibleConfirmacao: false,
      visiblePreferencesModal: false,
      visibleAcessoriosModal: false,
      acessoriosList: '',
      permissaoParaBloquear: false,
      dadosBloqueio: {},
      columns
    }
  )

  useEffect(async () => {
    const [
      { value: permissaoParaSimularVenda },
      { value: permissaoPrecificar },
      { value: permissaoParaBloquear },
      { value: config1575 },
      { value: config3252 },
      { value: usaIntegracaoVaBank }
    ] = await Promise.allSettled([
      await PermissaoService.getByCodigo('195'),
      await PermissaoService.getByCodigo('60'),
      await PermissaoService.getByCodigo('28'),
      await ConfigService.getValor(1575),
      await ConfigService.getValor(3252),
      await ConfigService.getValor(3401)
    ])

    await setState((prevState) => ({
      ...prevState,
      permissaoParaSimularVenda,
      permissaoParaBloquear,
      config1575,
      config3252,
      usaIntegracaoVaBank,
      permissaoPrecificar
    }))

    localStorage.removeItem('empresaOferta')
    localStorage.removeItem('negociacao')
  }, [])

  const handleComprarVeiculo = async () => {
    try {
      const negociacao = {
        veiculoVenda: { negociacaoVeiculo: {} },
        operacao: 'Compra'
      }

      localStorage.setItem('negociacao', JSON.stringify(negociacao))

      history.push('/negociacao')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado!')
    }
  }

  const handleConsignarVeiculo = async () => {
    const negociacao = {
      veiculoVenda: { negociacaoVeiculo: {} },
      operacao: 'Consignação'
    }

    localStorage.setItem('negociacao', JSON.stringify(negociacao))

    try {
      history.push('/negociacao')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado!')
    }
  }

  const handleReserva = async (reservaForm) => {
    try {
      const { codigo_pre, vendedor, pessoa, motivoReserva, clienteAutogestor, removerAnuncios } = reservaForm.values
      await VeiculoService.reservar(codigo_pre, vendedor.codigo_ven, pessoa.codigo_pes, motivoReserva)
      const { list } = state

      const lista = list.map(row => {
        if (row.codigo_pre === Number(codigo_pre)) {
          return { ...row, codigo_vdi: 'R', descri_vdi: 'Reservado' }
        } else {
          return row
        }
      })

      await setState((prevState) => ({ ...prevState, list: lista }))
      await onFilter({ list: lista })
      showSuccessMessage('Reserva efetuada com sucesso!')
      await VeiculoService.reservarAutoGestor(codigo_pre, vendedor.codigo_ven, clienteAutogestor, removerAnuncios)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar reservar o veículo!')
    }
  }

  const handleCancelarReserva = async (codigo_pre, codigoVen) => {
    try {
      await VeiculoService.cancelarReserva(codigo_pre)

      const { list } = state
      const lista = list.map(row => {
        if (row.codigo_pre === codigo_pre) {
          return { ...row, codigo_vdi: 'D', descri_vdi: 'Disponível para venda' }
        } else {
          return row
        }
      })

      await setState((prevState) => ({ ...prevState, list: lista }))

      showSuccessMessage('Reserva cancelada com sucesso!')
      await VeiculoService.cancelarReservaAutoGestor(codigo_pre, codigoVen)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar cancelar a reserva!')
    }
  }

  const showConfirmacaoModal = (visibleConfirmacao) => {
    if (visibleConfirmacao) {
      return (
        <Confirm
          visible={state.visibleConfirmacao}
          onConfirm={() => {
            handleCancelarReserva(state.selected.codigo_pre, state.selected.venres_vei)
            setState({ visibleConfirmacao: false })
          }}
          onCancel={() => setState({ visibleConfirmacao: false })}
          title="Reserva de veículos"
          description="Confirmar cancelar a reserva?"
        />
      )
    }
  }

  const onSelectOption = async (option) => {
    let { filter } = state
    setState((prevState) => ({ ...prevState, opcaoSelecionada: option }))

    if (option === 't') {
      filter.codigo_vdi = ''
      filter.codtiv_vei = ''
    }

    if (option === 'd') {
      filter.codigo_vdi = 'D'
      filter.codtiv_vei = ''
    }

    if (option === 'b') {
      filter.codigo_vdi = 'B,R'
      filter.codtiv_vei = ''
    }

    if (option === 'n') {
      filter.codigo_vdi = ''
      filter.codtiv_vei = 'n'
    }

    if (option === 'u') {
      filter.codigo_vdi = ''
      filter.codtiv_vei = 'u'
    }

    await setState((prevState) => ({ ...prevState, filter }))
    await onFilter({ filter })
  }

  return (
    <Page>
      {!CommonHelper.isDesktop() && (
        <ScrollTop threshold={1200} />
      )}
      <div className="flex justify-content-between align-items-center page-header">
        <h4>Estoque de veículos</h4>
        <div className="page-header-buttons">
          <AppButton
            label="Consignar veículo"
            icon="pi pi-plus"
            onClick={() => {
              handleConsignarVeiculo()
            }} />
          <AppButton
            label="Comprar veículo"
            icon="pi pi-plus"
            className="ml-3"
            onClick={() => {
              handleComprarVeiculo()
            }}
          />
        </div>
      </div>
      <div className="table-options-container">
        <div className="table-options">
          <div className={classNames({ active: state.opcaoSelecionada === 't' }, 'filter-option')}
            onClick={() => onSelectOption('t')}>
            <span className="option-label">Todos</span>
            <div className="option-quantity">{state.meta?.quantidadeTotal}</div>
          </div>
          <div className={classNames({ active: state.opcaoSelecionada === 'd' }, 'filter-option')}
            onClick={() => onSelectOption('d')}>
            <span className="option-label">Disponíveis</span>
            <div className="option-quantity">{state.meta?.quantidadeDisponiveis}</div>
          </div>
          <div className={classNames({ active: state.opcaoSelecionada === 'b' }, 'filter-option')}
            onClick={() => onSelectOption('b')}>
            <span className="option-label">Bloqueados/Reservados</span>
            <div className="option-quantity">{state.meta?.quantidadeBloqueadosReservados}</div>
          </div>
          <div className={classNames({ active: state.opcaoSelecionada === 'n' }, 'filter-option')}
            onClick={() => onSelectOption('n')}>
            <span className="option-label">Novos</span>
            <div className="option-quantity">{state.meta?.quantidadeNovos}</div>
          </div>
          <div className={classNames({ active: state.opcaoSelecionada === 'u' }, 'filter-option')}
            onClick={() => onSelectOption('u')}>
            <span className="option-label">Usados</span>
            <div className="option-quantity">{state.meta?.quantidadeUsados}</div>
          </div>
        </div>
        <div className="table-preferences">
          <PreferencesButton onClick={() => setState((prevState) => ({ ...prevState, visiblePreferencesModal: true }))} />
        </div>
      </div>
      <Container>
        <DataList
          data={state.list}
          selected={state.selected}
          onSelect={e => onSelect(e)}
          onRowClick={(e) => handleRowExpansion(e.data)}
          rows={state.rows}
          totalRecords={state.totalRecords}
          first={state.first}
          onPage={onPageChange}
          expandedRows={state.expandedRows}
          rowExpansionTemplate={rowExpansionTemplate}
          page={state.page}
          showFilter={true}
          state={state}
          setState={setState}
          history={history}
          filterPlaceholder="Procurar veículos"
          filterName="descricaoFiltro"
          filterOnChange={handleChangeFilter}
          onFilter={onFilter}
          filterValue={state.filter.descricaoFiltro}
          expandedFiltersTemplate={expandedFiltersTemplate}
          className='flex'
          customMaxHeight={'unset'}

        >
          {getColunasHabilitadas(columns, 'gerenciamentoEstoque')}
        </DataList>
      </Container>
      {showConfirmacaoModal(state.visibleConfirmacao)}
      <BloquearVeiculoModal
        visible={state.visibleBloquearModal}
        handleReserva={handleReserva}
        hide={() => setState((prevState) => ({ ...prevState, visibleBloquearModal: false, dadosBloqueio: {} }))}
        motivo={state?.dadosBloqueio?.motivoBloqueio}
        descricaoVeiculo={state?.dadosBloqueio?.descricaoVeiculoCompleta}
        veiculo={state.selected}
        onFilter={onFilter}
        list={state.list}
        setList={(list) => setState((prevState) => ({ ...prevState, list }))}
      />
      <AdicionarPixModal
        visible={state.visibleGerarPix}
        hide={() => setState((prevState) => ({ ...prevState, visibleGerarPix: false }))}
        idNegociacao={state.selected?.nvecom_vei}
        CnpjCpfFranqueado={state.selected?.ofertaEmpresa?.cgccpf_pes}
      />
      <ReservaVeiculoModal
        visible={state.isVisibleReservaModal}
        reserva={state.reserva}
        visualizarReserva={state.visualizarReserva}
        hide={() => setState((prevState) => ({ ...prevState, isVisibleReservaModal: false, visualizarReserva: false }))}
        handleReserva={handleReserva}
      />
      <TablePreferences
        title="Preferências da tabela de gerenciamento de estoque"
        tableName="gerenciamentoEstoque"
        columns={columns}
        visible={state.visiblePreferencesModal}
        hide={() => setState((prevState) => ({ ...prevState, visiblePreferencesModal: false }))}
      />
      <Modal
        header="Lista de acessórios"
        width="40"
        visible={state.visibleAcessoriosModal}
        onHide={() => setState((prevState) => ({ ...prevState, visibleAcessoriosModal: false, acessoriosList: '' }))}
      >
        {state.acessoriosList?.split(', ').map((acessorio, idx) => (
          <div key={idx}>{acessorio}</div>
        ))}
      </Modal>
    </Page>
  )
}
