/* eslint-disable react/display-name */
import React from 'react'
import Modal from '../../../components/utils/Modal'
import { Button } from 'primereact/button'
import TextInput from '../../../components/inputs/TextInput'
import { formatCurrencyToNumber, formatToCurrency } from '../../../helpers/formaters'
import { Column } from 'primereact/column'
import DataList from '../../../components/layout/DataList'
import { FaPix } from 'react-icons/fa6'
import { showErrorMessage, showSuccessMessage } from '../../../components/utils/Message'
import TextAreaInput from '../../../components/inputs/TextAreaInput'
import CommonHelper from '../../../helpers/CommonHelper'
import PixGeradoService from '../../../services/cadastro/financeiro/integracao/PixGeradoService'
import { getStore } from '../../../redux/store'
import AES from '../../../helpers/AES'
import List from '../../../classes/List'
import ResponsiveColumn from '../../../components/utils/ResponsiveColumn'
import Confirm from '../../../components/utils/Confirm'
import Tooltip from '../../../components/layout/Tooltip'
import ConfigService from '../../../services/configuracao/ConfigService'

class AdicionarPixModal extends List {
  constructor (props) {
    super(props, '', PixGeradoService)
    this.state = {
      valorCredito: 0,
      pixList: [],
      expandedRows: null,
      pixGerado: false,
      total: 0,
      exibirDadosPixCobranca: false,
      showConfirmModal: false,
      percentualTaxa: null
    }
    this.handleRemoverPix = this.handleRemoverPix.bind(this)
    this.abrirModalPix = this.abrirModalPix.bind(this)
  }

  async componentDidMount () {
    this.addEventListeners()
  }

  componentDidUpdate (prevProps) {
    const { visible } = this.props

    if (prevProps.visible !== visible && visible) {
      this.handleLoadPixData()
    }
  }

  handleLoadPixData = async () => {
    const { idNegociacao } = this.props

    try {
      const response = await PixGeradoService.listarPix(getStore().usuarioLogado.databaseConnectionName, idNegociacao)
      const percentualTaxa = await ConfigService.getValor(3411)

      if (response) {
        this.setState({
          pixList: response || [],
          pixGerado: response.length > 0,
          total: response?.length,
          percentualTaxa: percentualTaxa
        }
        )
      }
    } catch (error) {
      showErrorMessage(`Erro ao carregar os dados do PIX. ${error}`)
    }
  };

  setValorCredito = (valor_pix) => {
    this.setState({ valorCredito: valor_pix })
  };

  handleAddPix = () => {
    const { valorCredito, pixList } = this.state

    if (valorCredito > 0) {
      // Adicionar um novo item à lista de PIX
      const newPix = {
        id: pixList.length + 1,
        valor_pix: valorCredito
      }

      this.setState({
        pixList: [...pixList, newPix],
        valorCredito: 0
      })
    }
  };

  handleRemoverPix = (id) => {
    const { pixList } = this.state
    const novaPixList = pixList.filter((pix) => pix.id !== id)

    this.setState({
      pixList: novaPixList,
      pixGerado: false
    })
  };

  devolverPix = async (id) => {
    try {
      await PixGeradoService.devolverPix(id)
      showSuccessMessage('PIX devolvido com sucesso!')
      await this.handleLoadPixData()
    } catch (error) {
      showErrorMessage(error.message)
    }
  };

  cancelarPix = async () => {
    try {
      const { idNegociacao } = this.props
      const { pixList } = this.state
      const codigosPix = pixList.map((pix) => pix.codigo_pix)

      await PixGeradoService.cancelarPix(idNegociacao, codigosPix)
      this.setState({
        pixGerado: false,
        exibirDadosPixCobranca: false
      })
      this.resetarDados()
      showSuccessMessage('PIX cancelado com sucesso!')
      await this.handleLoadPixData()
    } catch (error) {
      showErrorMessage(error.message)
    }
  };

  somarValoresPix = () => {
    const { pixList } = this.state

    // Calcula a soma dos valores
    const total = pixList.reduce((acumulador, item) => acumulador + item.valor_pix, 0)

    return total
  };

  resetarDados = () => {
    this.setState({
      valorCredito: 0,
      pixList: [],
      pixGerado: false,
      expandedRows: null,
      total: 0,
      exibirDadosPixCobranca: false
    })
  };

  abrirModalPix = (qrCodePix, pixCopiaCola) => {
    this.setState({
      exibirDadosPixCobranca: true,
      qrCodePix,
      pixCopiaCola
    })
  };

  handleGerarPixCobranca = async () => {
    const { idNegociacao, CnpjCpfFranqueado } = this.props
    const { pixList } = this.state

    if (pixList.length === 0) {
      showErrorMessage('Adicione pelo menos um item antes de gerar o PIX.')
      return
    }

    if (this.somarValoresPix() <= 0) {
      showErrorMessage('O valor total precisa ser maior que zero para gerar o PIX.')
      return
    }

    const payload = {
      idNegociacao,
      CnpjCpfFranqueado,
      vlNegociacao: this.somarValoresPix(),
      chave: pixList.map((item) => ({ qrCodeValue: item.valor_pix }))
    }

    try {
      await PixGeradoService.gerarPix(payload)
      this.setState({
        pixList: [],
        pixGerado: true
      })
      showSuccessMessage('PIX gerado com sucesso!')
      await this.handleLoadPixData()
    } catch (error) {
      showErrorMessage(error.message)
    }
  };

  fecharModal = async () => {
    this.resetarDados()
    this.props.hide()
  }

  abrirModalDevolucao = (pix) => {
    this.setState({
      showConfirmModal: true,
      pixToDevolver: pix
    })
  };

  confirmarDevolucao = async () => {
    const { pixToDevolver } = this.state
    try {
      await this.devolverPix(pixToDevolver.codigo_pix)
      this.setState({ showConfirmModal: false, pixToDevolver: null })
    } catch (error) {
      showErrorMessage(error.message)
      this.setState({ showConfirmModal: false, pixToDevolver: null })
    }
  };

  cancelarDevolucao = () => {
    this.setState({ showConfirmModal: false, pixToDevolver: null })
  };

  render () {
    const { valorCredito, pixList, pixGerado, showConfirmModal, percentualTaxa } = this.state

    const textoTooltip = percentualTaxa
      ? `Ao gerar PIX, será incluída taxa de ${percentualTaxa}% sobre o valor total do pagamento`
      : 'Carregando taxa...'

    // Verifica se o PIX foi cancelado e deve ser devolvido
    const isPixCancelado = (rowData) => {
      return rowData.dathorcan_pix !== null && rowData.codsit_pix === 2
    }

    const columns = pixGerado
      ? [
        {
          header: 'PIX',
          field: 'valor',
          style: { width: '24%' },
          body: (rowData) => formatToCurrency(rowData.valor_pix)
        },
        {
          header: 'Situação',
          field: 'situacao',
          style: { width: '20%' },
          body: (rowData) => rowData.descri_spi
        },
        {
          header: 'Visualizar',
          field: 'acoes',
          style: { width: '22%' },

          body: (rowData) => {
            if (isPixCancelado(rowData)) {
              return (
                <Tooltip text={'Este PIX foi cancelado e requer devolução!'} transparent>
                  <i className="pi pi-exclamation-triangle" style={{ fontSize: '24px', color: 'red' }}></i>
                </Tooltip>
              )
            } else {
              return (
                <Button
                  icon="pi pi-eye"
                  onClick={() => this.abrirModalPix(rowData.qrcode_pix, rowData.copcol_pix)}
                />
              )
            }
          }
        },
        {
          header: 'Devolver',
          field: 'devolver',
          style: { width: '20%' },
          body: (rowData) => (
            <Button
              icon="pi pi-sort-alt"
              onClick={() => this.abrirModalDevolucao(rowData)}
              style={{ transform: 'rotate(90deg)' }}
            />
          )
        },
        {
          header: 'Link',
          field: 'link',
          style: { width: '16%' },
          body: (rowData) => {
            if (!isPixCancelado(rowData)) {
              return (
                <Button
                  icon="pi pi-send"
                  onClick={() => {
                    const encryptedDatabase = AES.encryptWithAES(getStore().usuarioLogado.databaseConnectionName)
                    window.open(
                      `${window.location.origin}/pagamento-pix-vabank/` +
                  `${CommonHelper.formatStringToURL(encryptedDatabase)}/${rowData.codnve_pix}/${rowData.codemp_pix}`
                    )
                  }}
                />
              )
            }
          }
        }
      ]
      : [
        {
          header: 'PIX',
          field: 'valor_pix',
          className: 'text-center pl-4',
          body: (rowData) => formatToCurrency(rowData.valor_pix)
        },
        {
          header: 'Remover',
          field: 'acoes',
          className: 'text-center pr-4',
          body: (rowData) => (
            <Button
              icon="pi pi-times"
              className="p-button-danger"
              onClick={() => this.handleRemoverPix(rowData.id)} // Chamando corretamente
            />
          )
        }
      ]

    return (
      <>
        <Modal
          header={
            <div className="flex align-items-center" style={{ gap: '10px' }}>
              PIX
              <Tooltip text={textoTooltip} position="bottom" width="300px" transparent>
                <i className="pi pi-info-circle"></i>
              </Tooltip>
            </div>
          }
          width="50"
          visible={this.props.visible}
          modal={true}
          onHide={this.fecharModal}
        >
          {!this.state.pixGerado && (
            <div className="flex flex-wrap sm:flex-col justify-content-center align-items-center" style={{ gap: '10px' }}>

              <div className="flex" style={{ gap: '10px' }}>
                <TextInput
                  label="Valor"
                  placeholder="Valor a ser creditado"
                  value={formatToCurrency(valorCredito)}
                  onChange={(e) => this.setValorCredito(formatCurrencyToNumber(e.target.value))}
                />
              </div>
              <div>
                <Button
                  label="Adicionar"
                  icon="pi pi-plus"
                  onClick={this.handleAddPix}
                  disabled={valorCredito <= 0}
                />
              </div>
            </div>
          )}
          <div className="field col-12 grid justify-content-center no-margin pt-4">
            <DataList
              data={pixList}
              hidePaginator={true}
              page={this.state.page}
              showFilter={false}
              responsive={true}
            >
              {columns.map((col, i) => (
                <Column
                  key={i}
                  header={col.header}
                  field={col.field}
                  body={(rowData) => {
                    return (
                      <ResponsiveColumn
                        className="pl-2"
                        column={col.header}
                        value={col.body ? col.body(rowData) : rowData[col.field]}
                      />
                    )
                  }}
                  style={col.style || {}}
                />
              ))}

            </DataList>
          </div >

          {/* Botão para gerar ou cancelar PIX */}
          < div className="flex justify-content-center mt-4" >
            {!this.state.pixGerado ? (
              <Button
                label="Gerar PIX"
                icon={<FaPix />}
                onClick={this.handleGerarPixCobranca}
                disabled={this.somarValoresPix() <= 0}
                style={{ gap: '10px' }}
              />
            ) : (
              <Button
                label="Cancelar PIX"
                icon="pi pi-times"
                onClick={this.cancelarPix}
                className="p-button-danger"
                style={{ gap: '10px' }}
              />
            )
            }
          </div>

          {/* Modal para exibir detalhes do PIX */}
          {
            this.state.exibirDadosPixCobranca && (
              <Modal
                header="Detalhes do PIX"
                width="40"
                visible={this.state.exibirDadosPixCobranca}
                onHide={() => this.setState({ exibirDadosPixCobranca: false })}
                footer={
                  <Button
                    label="Fechar"
                    onClick={() => this.setState({ exibirDadosPixCobranca: false })}
                    className="p-button-danger"
                    icon="pi pi-times"
                  />
                }
              >
                <div className="flex justify-content-around align-items-center">
                  <div>
                    <img
                      src={this.state.qrCodePix}
                      alt="QR Code"
                    />
                  </div>
                  <div>
                    <TextAreaInput
                      value={this.state.pixCopiaCola || ''}
                      className="w-full textArea carentDisabled"
                      disabled={true}
                      rows={1}
                      autoResize={false}
                    />
                    <Button
                      icon="pi pi-copy"
                      type="button"
                      label="Copiar PIX"
                      className="edit-button ml-2 mt-2"
                      onClick={async () => {
                        const sucesso = await CommonHelper.copyToClipboard(
                          this.state.pixCopiaCola
                        )
                        if (sucesso) {
                          showSuccessMessage('Texto copiado para a área de transferência!')
                        } else {
                          showErrorMessage('Erro ao copiar o texto.')
                        }
                      }}
                    />
                  </div>
                </div>
              </Modal>
            )
          }
        </Modal >
        <Confirm
          title="Devolução"
          description="Deseja alterar a situação do PIX dentro do Sances para devolvido? Desta forma será possível gerar novos PIX’s."
          visible={showConfirmModal}
          onConfirm={this.confirmarDevolucao}
          onCancel={this.cancelarDevolucao}
        />
      </>
    )
  }
}

export default AdicionarPixModal
