import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Page from '../../../../components/layout/Page'
import TabMenuContainer from '../../../../components/layout/TabMenuContainer/TabMenuContainer'
import TabMenuPage from '../../../../components/layout/TabMenuContainer/TabMenuPage'
import { baseForm } from '../../../../components/utils/BaseForm'
import Confirm from '../../../../components/utils/Confirm'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../../../../components/utils/Message'
import CommonHelper from '../../../../helpers/CommonHelper'
import { getStore } from '../../../../redux/store'
import PessoaService from '../../../../services/cadastro/pessoa/PessoaService'
import AvaliacaoService from '../../../../services/cadastro/veiculo/AvaliacaoService'
import { AvaliacaoValidator } from './AvaliacaoValidator'
import DadosAvaliacaoInfoAdicional from './formPages/DadosAvaliacaoInfoAdicional'
import DadosPrincipaisAva from './formPages/DadosPrincipaisAva'
import OutrasAvaliacoesList from './formPages/OutrasAvaliacoesList'
import AppButton from '../../../../components/layout/AppButton'
import { getAvaliacaoDTO } from '../../../../dtos/vendas/negociacao/avaliacao/AvaliacaoDTO'

const AvaliacaoForm = ({ isNew, model }) => {
  const [isNovo, setIsNovo] = useState(isNew)
  const [edicaoHabilitada, setEdicaoHabilitada] = useState(false)
  const [visibleConfirm, setVisibleConfirm] = useState(false)
  const [visibleCancelarModal, setVisibleCancelarModal] = useState(false)
  const [defaultValuesForm, setDefaultValuesForm] = useState(getAvaliacaoDTO() || null)
  const history = useHistory()

  useEffect(async () => {
    if (isNovo) {
      setEdicaoHabilitada(true)
      form.setFieldValue('empresa', getStore().empresaLogada)
    } else {
      const pessoaService = new PessoaService()
      const pessoaEmpresa = await pessoaService.get(form.values.codemp_ava)
      form.setFieldValue('empresa', { pessoa: pessoaEmpresa })
      await setDefaultValuesForm(form.values)
    }
  }, [])

  const handleSubmit = async (data) => {
    const avaliacaoService = new AvaliacaoService()

    try {
      const avaliacao = await avaliacaoService.save(data)
      history.push(`/avaliacao/${avaliacao.codigo_ava}`)
      form.setFieldValue('codigo_ava', avaliacao.codigo_ava)
      setIsNovo(false)
      setEdicaoHabilitada(false)
      await setDefaultValuesForm(form.values)
      showSuccessMessage('Registro salvo com sucesso!')
    } catch (e) {
      showErrorMessage(e.message || 'Erro ao salvar registro')
    }
  }

  const form = baseForm({
    initialValues: model,
    validationSchema: AvaliacaoValidator
  })

  const submitForm = async (e) => {
    const errors = await form.validateForm(form.values)

    if (Object.keys(errors).length !== 0) {
      form.handleSubmit(e)
      showWarnMessage('Campos obrigatórios não preenchidos!')
    } else {
      form.handleSubmit(e)
    }
  }

  const deleteForm = async () => {
    const avaliacao = new AvaliacaoService()
    try {
      await avaliacao.delete(form.values.codigo_ava)
      showSuccessMessage('Registro deletado com sucesso!')
      history.push('/avaliacoes')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar deletar o registro!')
    }
  }

  const cancelar = async () => {
    form.setValues(defaultValuesForm)
    setEdicaoHabilitada(false)
    setVisibleCancelarModal(false)
  }
 
  return (
    <Page>
      <form onSubmit={submitForm}>

        <div className="flex justify-content-between align-items-center page-header neg">
          <h4>
         Avaliação
          </h4>
          <div className= "flex justify-content-end mt-3 mb-3 mr-2">
            { isNovo ? <></> :
              !edicaoHabilitada ?
              <Button
                label={CommonHelper.isDesktop() ? 'Excluir' : ''}
                className="p-button-danger mr-2"
                icon="pi pi-trash"
                type="button"
                onClick={() => setVisibleConfirm(true)}
                disabled={edicaoHabilitada}
              />
              :
              <Button
                label={CommonHelper.isDesktop() ? 'Cancelar' : ''}
                className="p-button-danger mr-2"
                icon="pi pi-times"
                type="button"
                onClick={() => {
                setVisibleCancelarModal(true)
                }}
              />
            }
            { !edicaoHabilitada ?
              <Button
              type="button"
              label={CommonHelper.isDesktop() ? 'Editar' : ''}
              className={"mr-2"}
              icon={"pi pi-pencil"}
              onClick={() => setEdicaoHabilitada(!edicaoHabilitada)}
              disabled={edicaoHabilitada}
            />
              :
              <AppButton
                type="submit"
                label={CommonHelper.isDesktop() ? 'Salvar' : ''}
                icon="pi pi-check"
                className="mr-2"
                onClick={async () => await handleSubmit(form.values)}
              />
            }
          </div>
        </div>
        <div className="divider"></div>
        <div>

          <TabMenuContainer
            activeIndexParaNaoMostrarContentHeader={[0]}
          >
            <TabMenuPage header="DADOS PRINCIPAIS">
              <DadosPrincipaisAva
                form={form}
                edicaoHabilitada={edicaoHabilitada}
              />
            </TabMenuPage>
            <TabMenuPage header="OUTRAS AVALIAÇÕES">
              <OutrasAvaliacoesList placa={form.values.placa_ava}/>
            </TabMenuPage>
            <TabMenuPage header="INFORMAÇÕES ADICIONAIS">
              <DadosAvaliacaoInfoAdicional form={form}/>
            </TabMenuPage>
          </TabMenuContainer>
        </div>
        <div className={'field col-12 md:col-12 grid justify-content-' + (form.values.veiculosRestantesCompra ? 'between' : 'end')}>
          <Confirm
            visible={visibleConfirm}
            onConfirm={() => deleteForm()}
            onCancel={() => setVisibleConfirm(false)}
            title="Confirmação"
            description="Deseja realmente excluir este registro?"
          />
        </div>
        <div className={'field col-12 md:col-12 grid justify-content-' + (form.values.veiculosRestantesCompra ? 'between' : 'end')}>
          <Confirm
            visible={visibleCancelarModal}
            onConfirm={async () => cancelar()}
            onCancel={() => setVisibleCancelarModal(false)}
            title="Atenção"
            description="Deseja cancelar as edições feitas?"
          />
        </div>
      </form>
    </Page>
  )
}

export default AvaliacaoForm
