import React, { useState } from 'react'
import Page from '../../../../components/layout/Page'
import Container from '../../../../components/layout/Container'
import DataList from '../../../../components/layout/DataList'
import { createColumns, getColunasHabilitadas } from '../../../../helpers/tableConfigs'
import useList from '../../../../hook/useList'
import { Button } from 'primereact/button'
import { getAcompanhamentoDTO } from '../../../../dtos/processo/AcompanhamentoDTO'
import AcompanhamentoService from '../../../../services/processo/AcompanhamentoService'
import CommonHelper from '../../../../helpers/CommonHelper'
import VendedoresService from '../../../../services/cadastro/pessoa/VendedoresService'
import { showErrorMessage, showWarnMessage } from '../../../../components/utils/Message'
import ExpandedFiltersTemplateForAcompanhamento from './ExpandedFilters'
import TablePreferences from '../../../../components/utils/TablePreferences'
import PreferencesButton from '../../../../components/utils/PreferencesButton'

const AcompanhamentoProcessoList = () => {
  // * Cria as colunas que serão utilizadas no dataList e mo HOOK
  const columns = createColumns([
    { field: 'codigo_pru', header: 'Código', width: '0%' },
    { field: 'nome_empresa', header: 'Empresa', width: '14.28%' },
    { field: 'descri_pru', header: 'Descrição', width: '14.28%' },
    { field: 'descri_prc', header: 'Processo', width: '14.28%' },
    { field: 'negprc_pru', header: 'Negociação', width: '14.28%' },
    { field: 'nome_cliente', header: 'Cliente', width: '14.28%' },
    { field: 'nome_atendente', header: 'Atendente', width: '14.28%' },
    { field: 'descri_situacao', header: 'Situação', width: '14.28%' }
  ])

  // * Utilziação do HOOK "useList" no Function Component para substituir o "Extends List" do Class Component
  const {
    state,
    setState,
    onPageChange,
    onSelect,
    onFilter,
    handleChangeFilter,
    onView,
    onNew
  } = useList(
    getAcompanhamentoDTO,
    AcompanhamentoService,
    'novo-processo',
    'codigo_pru',
    undefined,
    undefined,
    {
      selectedOptionsEmpresas: null,
      empresas: [],
      atendente: null,
      processo: null,
      selectedOptionsSituacao: null,
      situacoes: [],
      processos_por_usuario_logado: false,
      descri_negociacao: '',
      descri_veiculo: '',
      descri_obs: '',
      columns
    }
  )

  // * Constantes
  const [sugestaoAtendete, setSugestaoAtendete] = useState([])
  const [visiblePreferencesModal, setVisiblePreferencesModal] = useState(false)

  // * Funções para o SearchInput de atendente
  const sugerirAtendente = async ({ query }) => {
    try {
      // * Utiliza VendedorService que é a mesma coisa
      const atendenteService = new VendedoresService()
      const atendente = await atendenteService.filterAutoComplete(`nomraz_pes=${query}`)

      if (atendente.length <= 0) {
        showWarnMessage('Atendete não encontrado!')
      }

      setSugestaoAtendete(atendente)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um atendete!')
    }
  }

  return (
    <Page>
      <div className="flex justify-content-between align-items-center page-header">
        <h4>Acompanhamento de processo</h4>
        <div className="page-header-buttons">
          <Button className="mr-2" label={CommonHelper.isDesktop() && 'Novo processo'} icon="pi pi-plus" onClick={onNew} />
        </div>
      </div>

      <div className="flex justify-content-end mr-3">
        <PreferencesButton onClick={() => setVisiblePreferencesModal(true)} />
      </div>

      <Container>
        <DataList
          data={state.list}
          selected={state.selected}
          rows={state.rows}
          onSelect={onSelect}
          totalRecords={state.totalRecords}
          first={state.first}
          onPage={onPageChange}
          page={state.page}
          onRowSelect={(e) => onView(e.data)}
          filterPlaceholder="Pesquisar"
          filterName="descricaoFiltro"
          filterOnChange={handleChangeFilter}
          onFilter={onFilter}
          responsive
          showFilter
          customMaxHeight={'unset'}

          // * Expanded Filters
          expandedFiltersTemplate={ExpandedFiltersTemplateForAcompanhamento}
          setState={setState}
          state={state}

          // * Campo Atendente
          sugestaoAtendete={sugestaoAtendete}
          sugerirAtendente={sugerirAtendente}
        >
          {getColunasHabilitadas(columns, 'acompanhamento-processo')}
        </DataList>
      </Container>

      <TablePreferences
        title="Preferências da tabela de acompanhamento"
        tableName="acompanhamento-processo"
        columns={columns}
        visible={visiblePreferencesModal}
        hide={() => setVisiblePreferencesModal(false)}
      />
    </Page>
  )
}

export default AcompanhamentoProcessoList
