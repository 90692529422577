import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { InputTextarea } from 'primereact/inputtextarea'
import React, { useEffect, useState } from 'react'
import DateInput from '../../../../../components/inputs/DateInput'
import SelectInput from '../../../../../components/inputs/SelectInput'
import TextInput from '../../../../../components/inputs/TextInput'
import CommonHelper from '../../../../../helpers/CommonHelper'
import PaisesService from '../../../../../services/cadastro/pessoa/PaisesService'
import PessoaService from '../../../../../services/cadastro/pessoa/PessoaService'

import { showErrorMessage } from '../../../../../components/utils/Message'
import { getStore } from '../../../../../redux/store'
import { atividades } from '../../../../../utils/static/variables'

const DadosAdicionaisPej = ({ isNew, form, edicaoHabilitada }) => {
  const [atividadeSelecionada, setAtividadeSelecionada] = useState(atividades.find((atividade) => atividade.codigo_pea === form.values.codpea_pej))
  const [paises, setPaises] = useState([])

  useEffect(async () => {
    await getPaises()
  }, [])

  const handleChangeAtividade = (e) => {
    const atividade = e.target.value
    form.setFieldValue('codpea_pej', atividade?.codigo_pea)
    setAtividadeSelecionada(atividade)
  }

  const handleChangePais = (e) => {
    const pais = e.target.value

    form.setFieldValue('codpai_pes', pais?.codigo_pai)
    form.setFieldValue('pais_pes', pais?.descri_pai)
    form.setFieldValue('pais', pais)
  }

  const getPaises = async () => {
    try {
      const paises = await PaisesService.getAll()

      if (!form.values.codpai_pes) {
        const brasil = paises.find(pais => pais.codigo_pai === getStore().codigoBrasil)
        form.setFieldValue('codpai_pes', brasil.codigo_pai)
        form.setFieldValue('pais_pes', brasil.descri_pai)
        form.setFieldValue('pais', brasil)
      }

      setPaises(paises)
    } catch (e) {
      showErrorMessage(e.message || 'Houve um erro ao listar os países!')
    }
  }

  const handleChangeCheckbox = (e) => {
    form.setFieldValue(e.target.name, !form.values[e.target.name])
  }

  const handleClickComoChegar = async () => {
    try {
      const url = await PessoaService.getUrlComoChegar(form.values.codigo_pej)
      window.open(url, '_blank')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado, por favor tente novamente mais tarde!')
    }
  }

  const handleChangeParceiro = () => {
    form.setFieldValue('parcei_pes', !form.values.parcei_pes)
  }

  return (
    <div className="formgrid grid">
      <div className="field col-12 md:col-3 disabled-input">
        <TextInput
          label="Telefone Residencial"
          placeholder="Telefone residencial"
          name="telres_pes"
          value={form.values.telres_pes}
          onChange={(value) => form.setFieldValue('telres_pes', value)}
          mask="(99) 99999-9999"
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-3 disabled-input">
        <SelectInput
          value={form.values.pais}
          options={paises}
          onChange={handleChangePais}
          name="pais"
          label="País"
          filter={CommonHelper.isDesktop()}
          optionLabel="descri_pai"
          dataKey="codigo_pai"
          noFloatLabel
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-3 disabled-input">
        <SelectInput
          label="Atividade"
          value={atividadeSelecionada}
          options={atividades}
          onChange={handleChangeAtividade}
          optionLabel="descri_pea"
          dataKey="codigo_pea"
          noFloatLabel
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
          showClear={atividadeSelecionada?.codigo_pea}
        />
      </div>
      <div className="field col-12 md:col-3 disabled-input">
        <TextInput
          label="Apelido"
          placeholder="Apelido"
          name="apelid_pes"
          maxLength={200}
          className="inputfield w-full"
          value={form.values.apelid_pes}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
        />
      </div>
      <div className="field col-12 md:col-6 disabled-input">
        <TextInput
          label="E-mail financeiro"
          placeholder="E-mail financeiro"
          name="emafin_pes"
          value={form.values.emafin_pes}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          maxLength={240}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-6">
        <TextInput
          label="Ramo de atividade"
          placeholder="Ramo de atividade"
          disabled={!edicaoHabilitada}
          name="ramati_pej"
          value={form.values.ramati_pej}
          onChange={form.handleChange}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-3">
        <TextInput
          label="Versão do contrato social"
          placeholder="Versão do contrato social"
          disabled={!edicaoHabilitada}
          name="vercon_pej"
          value={form.values.vercon_pej}
          onChange={form.handleChange}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-3">
        <DateInput
          label="Data de fundação"
          placeholder="Data de fundação"
          disabled={!edicaoHabilitada}
          name="datfun_pej"
          value={form.values.datfun_pej}
          onChange={form.handleChange}
          className="inputfield w-full"
        />
      </div>
      <div className="field checkbox-container col-12 md:col-6">
        <Checkbox
          inputId="fidfor_pej"
          name="fidfor_pej"
          value={form.values.fidfor_pej}
          onChange={handleChangeCheckbox}
          checked={form.values.fidfor_pej}
          disabled={!edicaoHabilitada}
        />
        <label htmlFor="fidfor_pej" className="p-checkbox-label mb-0 pl-2">
					Empresa possui fidelidade com fornecedor
        </label>
      </div>
      <div className="field col-12 md:col-6">
        <label className="label">Observação</label>
        <InputTextarea
          label="Observação"
          placeholder="Observação"
          name="obs_pej"
          value={form.values.obs_pej}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          autoResize
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-6">
        <label className="label">Referências bancárias</label>
        <InputTextarea
          label="Referências bancárias"
          placeholder="Referências bancárias"
          name="refban_pes"
          value={form.values.refban_pes}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          autoResize
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-10">
        <TextInput
          label="Url do site"
          placeholder="Url do site"
          disabled={!edicaoHabilitada}
          name="urlsit_pej"
          value={form.values.urlsit_pej}
          onChange={form.handleChange}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-2 flex align-items-center justify-content-end mb-0">
        {!isNew
          ? (
            <Button
              type="button"
              label="Como chegar"
              onClick={handleClickComoChegar}
              style={{ width: '100%' }}
            />
          ) : <></>
        }
      </div>
      <div className="col-12 md:col-3">
        <div className={'field checkbox-container col-12 md:mb-3'}>
          <Checkbox
            inputId="parcei_pes"
            value={form.values.parcei_pes}
            onChange={handleChangeParceiro}
            checked={form.values.parcei_pes}
            disabled={!edicaoHabilitada}
          />
          <label htmlFor="parcei_pes" className="p-checkbox-label mb-0 pl-2">
						Parceiro
          </label>
        </div>
        <div className="field checkbox-container col-12 md:mb-3">
          <Checkbox
            inputId="fornec_pej"
            name="fornec_pej"
            value={form.values.fornec_pej}
            onChange={handleChangeCheckbox}
            checked={form.values.fornec_pej}
            disabled={!edicaoHabilitada}
          />
          <label htmlFor="fornec_pej" className="p-checkbox-label mb-0 pl-2">
					Fornecedor
          </label>
        </div>
      </div>
      <div className="col-12 md:col-3">
        <div className="field checkbox-container col-12 md:mb-3">
          <Checkbox
            inputId="financ_pej"
            name="financ_pej"
            value={form.values.financ_pej}
            onChange={handleChangeCheckbox}
            checked={form.values.financ_pej}
            disabled={!edicaoHabilitada}
          />
          <label htmlFor="financ_pej" className="p-checkbox-label mb-0 pl-2">
					Financeira
          </label>
        </div>
        <div className="field checkbox-container col-12 md:mb-3">
          <Checkbox
            inputId="transp_pej"
            name="transp_pej"
            value={form.values.transp_pej}
            onChange={handleChangeCheckbox}
            checked={form.values.transp_pej}
            disabled={!edicaoHabilitada}
          />
          <label htmlFor="transp_pej" className="p-checkbox-label mb-0 pl-2">
					Transportadora
          </label>
        </div>
      </div>
      <div className="col-12 md:col-3">
        <div className="field checkbox-container col-12 md:mb-3">
          <Checkbox
            inputId="despac_pej"
            name="despac_pej"
            value={form.values.despac_pej}
            onChange={handleChangeCheckbox}
            checked={form.values.despac_pej}
            disabled={!edicaoHabilitada}
          />
          <label htmlFor="despac_pej" className="p-checkbox-label mb-0 pl-2">
					Despachante
          </label>
        </div>
        <div className="field checkbox-container col-12 md:mb-3">
          <Checkbox
            inputId="forofi_pej"
            name="forofi_pej"
            value={form.values.forofi_pej}
            onChange={handleChangeCheckbox}
            checked={form.values.forofi_pej}
            disabled={!edicaoHabilitada}
          />
          <label htmlFor="forofi_pej" className="p-checkbox-label mb-0 pl-2">
					Fornecedor oficial
          </label>
        </div>
      </div>
      <div className="col-12 md:col-3">
        <div className="field checkbox-container col-12 md:mb-3">
          <Checkbox
            inputId="intven_pej"
            name="intven_pej"
            value={form.values.intven_pej}
            onChange={handleChangeCheckbox}
            checked={form.values.intven_pej}
            disabled={!edicaoHabilitada}
          />
          <label htmlFor="intven_pej" className="p-checkbox-label mb-0 pl-2">
					Intermediador de vendas
          </label>
        </div>
      </div>
    </div>
  )
}

export default DadosAdicionaisPej
