import React from 'react'
import Page from '../../../../components/layout/Page'
import { createColumns, getColunasHabilitadas } from '../../../../helpers/tableConfigs'
import { getProcessoDTO } from '../../../../dtos/processo/ProcessoDTO'
import useList from '../../../../hook/useList'
import ModeloProcessoService from '../../../../services/processo/ModeloProcessoService'
import { Button } from 'primereact/button'
import Container from '../../../../components/layout/Container'
import DataList from '../../../../components/layout/DataList'
import CommonHelper from '../../../../helpers/CommonHelper'
import { getAtivo } from '../../../../helpers/formaters'

const ModeloProcessoList = (props) => {
  // * Cria as colunas que serão utilizadas no dataList e mo HOOK
  const columns = createColumns([
    { field: 'codigo_prc', header: 'Código', width: '25%' },
    { field: 'descri_prc', header: 'Modelo de Processo', width: '50%' },
    { field: 'ativo_prc', header: 'Ativo', width: '25%', customBody: getAtivo }
  ])

  // * Utilziação do HOOK "useList" no Function Component para substituir o "Extends List" do Class Component
  const {
    state,
    // setState,
    onPageChange,
    onSelect,
    onView,
    handleChangeFilter,
    onFilter,
    onNew
  } = useList(
    getProcessoDTO,
    ModeloProcessoService,
    'form-modelo-processo',
    'codigo_prc',
    undefined,
    undefined,
    {
      columns
    }
  )

  return (
    <Page>
      <div className="flex justify-content-between align-items-center page-header">
        <h4>Modelo de processo</h4>
        <div className="page-header-buttons">
          <Button className="mr-2" label={CommonHelper.isDesktop() && 'Novo modelo'} icon="pi pi-plus" onClick={onNew} />
        </div>
      </div>
      <Container>
        <DataList
          data={state.list}
          selected={state.selected}
          rows={state.rows}
          onSelect={onSelect}
          totalRecords={state.totalRecords}
          first={state.first}
          onPage={onPageChange}
          page={state.page}
          onRowSelect={(e) => onView(e.data)}
          filterPlaceholder="Procurar modelo de processo"
          filterName="descricaoFiltro"
          filterOnChange={handleChangeFilter}
          onFilter={onFilter}
          responsive
          showFilter
          customMaxHeight={'unset'}
        >
          {getColunasHabilitadas(columns, 'modelo-processo')}
        </DataList>
      </Container>
    </Page>
  )
}

export default ModeloProcessoList
