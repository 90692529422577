import { postProcessoDTO } from '../../../dtos/processo/ProcessoDTO'
import ServiceBase from '../../base/ServiceBase'

export default class ModeloProcessoService {
  static async save (processo) {
    return await ServiceBase.postBase('processo/', postProcessoDTO(processo))
  }

  static async get (codigo) {
    return await ServiceBase.getBase(`processo/${codigo}`)
  }

  async save (processo) {
    return await ServiceBase.postBase('processo/', postProcessoDTO(processo))
  }

  async get (codigo) {
    return await ServiceBase.getBase(`processo/${codigo}`)
  }

  static async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`processo?${query}&page=${page}&perPage=${perPage}`)
  }

  static async filter (query, page, perPage) {
    return await ServiceBase.getBase(`processo?${query}&page=${page}&perPage=${perPage}`)
  }

  static async filterAutocomplete () {
    return await ServiceBase.getBase('processo')
  }

  async filterAutocomplete () {
    return await ServiceBase.getBase('processo')
  }

  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`processo?${query}&page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`processo?${query}&page=${page}&perPage=${perPage}`)
  }

  async delete (id) {
    return await ServiceBase.deleteBase(`processo/${id}`)
  }

  static async delete (id) {
    return await ServiceBase.deleteBase(`processo/${id}`)
  }
}
