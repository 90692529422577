import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import queryString from 'query-string'
import React, { Component } from 'react'
import TextInput from '../../components/inputs/TextInput'
import Message from '../../components/utils/Message'
import Modal from '../../components/utils/Modal'
import IntegracaoUsuarioModal from '../../forms/integracao/integracaoUsuario/IntegracaoUsuarioForm'
import { login, setCompany } from '../../helpers/auth'
import CommonHelper from '../../helpers/CommonHelper'
import EmpresasService from '../../services/cadastro/pessoa/EmpresasService'
import IntegracaoUsuarioService from '../../services/Integracao/IntegracaoUsuarioService'
import LoginService from '../../services/login/LoginService'
import RedefinirSenhaModal from './RedefinirSenhaModal'

export class Login extends Component {
  constructor (props) {
    super(props)
    this.loginService = new LoginService()
    this.empresasService = new EmpresasService()
    this.state = {
      password: '',
      email: '',
      loading: false,
      visibleCompanySelect: false,
      visibleRedefinirSenhaModal: false,
      visibleOrganizacaoSelect: false,
      companies: [],
      organizacoes: [],
      selectedCompany: null,
      selectedOrganizacao: null,
      visibleIntegracao: false,
      filtroOrganizacao: '',
      filtroEmpresa: ''
    }
  }

  async componentDidMount () {
    const dadosIntegracao = queryString.parse(this.props.location.search).q

    if (dadosIntegracao) {
      try {
        await IntegracaoUsuarioService.integrarContas(dadosIntegracao)

        this.showSuccesMessage('Seu login e senha foram alterados, você já pode fazer o login!')
      } catch (error) {
        this.showErrorMessage(error.message || 'Houve um erro ao tentar integrar sua conta!')
      }
    }
  }

  handleEntrarClick = async () => {
    try {
      this.startLoading()

      const { email, password } = this.state

      const organizacoes = await LoginService.getAcessoDatabases({ email, password })
      const casoApenasUmaOpcaoParaSelecionar = organizacoes.length === 1

      if (casoApenasUmaOpcaoParaSelecionar) {
        const unicaOrganizacaoDisponivel = organizacoes[0]
        this.setState({ selectedOrganizacao: unicaOrganizacaoDisponivel }, this.onSelectOrganizacao)
      } else {
        this.setState({ organizacoes }, this.openOrganizacaoSelectModal)
      }

      this.stopLoading()
    } catch (e) {
      this.showErrorMessage(e.message || 'Favor confira seus dados e tente novamente', 'Login/Senha inválidos')
      this.stopLoading()
    }
  }

  onSelectOrganizacao = async () => {
    try {
      this.startLoading()

      this.closeOrganizacaoSelectModal()

      const { email, password, selectedOrganizacao } = this.state

      const companies = await this.empresasService.getAll({ email, password, organizacao: selectedOrganizacao.id })

      if (companies.length === 0) {
        this.showWarnMessage('Você não possui acesso a nenhuma empresa dessa organização!')
        return
      }

      const casoApenasUmaOpcaoParaSelecionar = companies.length === 1

      if (casoApenasUmaOpcaoParaSelecionar) {
        const unicaEmpresaDisponivel = companies[0]
        this.setState({ selectedCompany: unicaEmpresaDisponivel.empresa.pessoa }, this.handleConfirm)
      } else {
        this.setState({ companies: companies }, this.openCompanySelectModal)
      }

      this.stopLoading()
    } catch (e) {
      this.showErrorMessage(e.message || 'Favor confira seus dados e tente novamente', 'Erro no login')
      this.stopLoading()
    }
  }

  handleConfirm = async () => {
    try {
      const { email, password, selectedCompany, selectedOrganizacao } = this.state

      const token = await this.loginService.login(
        { email, password, companyId: selectedCompany.codigo_pes, organizacao: selectedOrganizacao?.id }
      )

      login(token)
      setCompany(selectedCompany)

      this.closeCompanySelectModal()

      this.props.history.push('/home')

      this.stopLoading()
    } catch (error) {
      this.showErrorMessage(error.message || 'Favor confira seus dados e tente novamente', 'Erro', 20000)
      this.stopLoading()
    }
  }

  stopLoading () {
    this.setState({ loading: false })
  }

  startLoading () {
    this.setState({ loading: true })
  }

  openCompanySelectModal = () => {
    this.setState({ visibleCompanySelect: true })
  }

  openOrganizacaoSelectModal = () => {
    this.setState({ visibleOrganizacaoSelect: true })
  }

  closeCompanySelectModal = () => {
    this.setState({ visibleCompanySelect: false })
  }

  closeOrganizacaoSelectModal = () => {
    this.setState({ visibleOrganizacaoSelect: false })
  }

  handleSelectCompany = company => {
    this.setState({ selectedCompany: company }, this.handleConfirm)
  }

  handleSelectOrganizacao = organizacao => {
    this.setState({ selectedOrganizacao: organizacao }, this.onSelectOrganizacao)
  }

  showSuccesMessage (message) {
    this.toast.show({
      severity: 'success',
      summary: 'Sucesso!',
      detail: message
    })
  }

  showErrorMessage (message, summary = 'Erro', lifeTimeMessage = 0) {
    this.toast.show({
      severity: 'error',
      summary: summary,
      detail: message,
      life: lifeTimeMessage
    })
  }

  showWarnMessage (message, summary = 'Aviso', lifeTimeMessage = 0) {
    this.toast.show({
      severity: 'warn',
      summary: summary,
      detail: message,
      life: lifeTimeMessage
    })
  }

  handleKeyPress = (event) => {
    const isKeyPressedEnter = event.keyCode === 13

    if (isKeyPressedEnter) {
      this.handleEntrarClick()
    }
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render () {
    const { password, email, loading } = this.state
    const logo = 'assets/layout/images/logo-sancesturboweb.svg'
    const imgcentro = 'assets/layout/images/img-grafico.svg'

    return (
      <div className="login-page">
        <div className="login-body">
          {/* inicio div esquerda */}
          <div className="login-first-area">
            <div className="login-background">
              <div className="login-banner" />
              <div className="login-logo">
                <img alt="Logo" src={logo} />
              </div>
            </div>

            <div className="login-img">
              <img alt="Grafico" src={imgcentro} className='grafico'/>
            </div>
          </div>  {/* fim div esquerda */}
          {/* inicio div direita */}
          <div className="login-form">
            <div className="login-logo desktop">
              <img alt="Logo" src={logo} style={{ width: '70%' }} />
            </div>
            <div className="login-campos">
              <div className="formgrid grid">
                <div className="col-12">
                  <h3 className="login-welcome">{`Bem-vindo ${CommonHelper.isDesktop() ? 'ao SancesTurbo WEB' : ''}`}</h3>
                  <h4 className="faca-login">Faça login na sua conta</h4>
                </div>
                <div className="field col-12">
                  <TextInput
                    label="Usuário"
                    placeholder="Digite seu usuário"
                    data-cy="loginemail"
                    name="email"
                    id="email"
                    type="email"
                    className="inputfield w-full"
                    value={email}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyPress}
                  />
                </div>
                <div className="field col-12">
                  <TextInput
                    label="Senha"
                    placeholder="Digite sua senha"
                    className="inputfield w-full"
                    data-cy="loginpass"
                    name="password"
                    id="password"
                    type="password"
                    value={password}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyPress}
                  />
                </div>
                <div className="flex justify-content-end" style={{ width: '100%' }}>
                  <small className="login-smaller-link  mr-3 mb-4" onClick={() => this.setState({ visibleRedefinirSenhaModal: true })}>Esqueci minha senha</small>
                </div>
                <div className="field col-12">
                  <Button
                    className="login-button inputfield w-full"
                    label="Entrar"
                    icon={loading ? 'pi pi-spin pi-spinner' : 'pi pi-sign-in'}
                    onClick={this.handleEntrarClick}
                    data-cy="btnloginEntrar"
                  />
                </div>
              </div>
            </div>
            <div className="login-background-conta">
              <div className="login-bannerbottom">
                {/* <div className="login-naotemconta"> */}
                <div>

                  <p>
                    Não tem uma conta?<span className='login-fale-conosco' onClick={() =>
                      window.open('https://www.sances.com.br/sances-turbo-dms', '_blank')
                    }> Fale conosco agora!</span>
                  </p>
                </div>

              </div>
            </div>
            {/* </div> */}
            <div className="rodape-form">
              <p onClick={() => this.setState({ visibleIntegracao: true })} className="link-integracao" >
              Integrar contas
              </p>
              <p className='direitoReservado'>
                {`© ${CommonHelper.getCurrentYear()} Sances `} {CommonHelper.isDesktop() ? <>-</> : <br />}  Todos os direitos reservados
              </p>
            </div>
          </div> {/* fim div direita */}

          {/* inicio div rodape */}
          <div className="rodape">
            <p onClick={() => this.setState({ visibleIntegracao: true })} className="link-integracao" >
              Integrar contas
            </p>
            <p className='direitoReservado'>
              {`© ${CommonHelper.getCurrentYear()} Sances `} {CommonHelper.isDesktop() ? <>-</> : <br />}  Todos os direitos reservados
            </p>
          </div>  {/*fim div rodape*/}
        </div>  {/* fim div body */}
        <Modal
          header="Selecionar grupo de empresas"
          visible={this.state.visibleOrganizacaoSelect}
          modal={true}
          onHide={this.closeOrganizacaoSelectModal}
          style={
            CommonHelper.isDesktop() ? { width: '50vw' } : { width: '100vw' }
          }
        >
          <div className="field col-12">
            <TextInput
              value={this.state.filtroOrganizacao}
              placeholder="Filtrar por nome do grupo de empresas"
              className="inputfield w-full"
              onChange={(e) => {
                this.setState({ filtroOrganizacao: e.target.value })
              }}
            />
          </div>
          {this.state.organizacoes.filter(
            (org) => org.name.toLowerCase().includes(
              this.state.filtroOrganizacao.toLowerCase()
            )).map((organizacao, index) =>
            <>
              <div
                key={index}
                className={'list-selection ' + (index === 0 ? 'first' : '')}
                onClick={() => this.handleSelectOrganizacao(organizacao)}
              >
                <div><p>{organizacao?.name || organizacao?.id}</p></div>
              </div>
            </>
          )}
        </Modal>
        <Modal
          header="Selecionar empresa"
          visible={this.state.visibleCompanySelect}
          modal={true}
          onHide={this.closeCompanySelectModal}
          style={
            CommonHelper.isDesktop() ? { width: '50vw' } : { width: '100vw' }
          }
        >
          <div className="field col-12">
            <TextInput
              value={this.state.filtroEmpresa}
              placeholder="Filtrar por nome de empresa"
              className="inputfield w-full"
              onChange={(e) => {
                this.setState({ filtroEmpresa: e.target.value })
              }}
            />
          </div>
          {this.state.companies.filter(
            (acesso) => acesso.empresa.pessoa.apelid_pes.toLowerCase().includes(
              this.state.filtroEmpresa.toLowerCase()
            ))
            .map((acesso, index) =>
              <div
                key={index}
                className={'list-selection ' + (index === 0 ? 'first' : '')}
                onClick={() => this.handleSelectCompany(acesso.empresa.pessoa)}
              >
                <div><p>{acesso.empresa.pessoa.codigo_pes} - {acesso.empresa.pessoa.apelid_pes}</p></div>
              </div>
            )}
        </Modal>

        <RedefinirSenhaModal
          visible={this.state.visibleRedefinirSenhaModal}
          onHide={() => this.setState({ visibleRedefinirSenhaModal: false })}
        />
        <IntegracaoUsuarioModal
          visible={this.state.visibleIntegracao}
          hide={() => this.setState({ visibleIntegracao: false })}
        />
        <Message />
        <Toast ref={(el) => this.toast = el} />

      </div>
    )
  }
}
