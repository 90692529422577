import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Tooltip extends Component {
  constructor (props) {
    super(props)
    this.state = {
      visible: false
    }
  }

  showTooltip = () => {
    this.setState({ visible: true })
  };

  hideTooltip = () => {
    this.setState({ visible: false })
  };

  render () {
    const { visible } = this.state
    const { text, position, width, maxWidth, transparent, children } = this.props

    return (
      <div
        className="tooltip-container"
        onMouseEnter={this.showTooltip}
        onMouseLeave={this.hideTooltip}
        style={{ display: 'inline-block', position: 'relative' }}
      >
        {children}

        {visible && (
          <div
            className={`tooltip-box ${position}`}
            style={{
              position: 'fixed',
              backgroundColor: transparent ? 'rgba(0, 0, 0, 0.6)' : '#333',
              color: '#fff',
              padding: '5px 10px',
              borderRadius: '4px',
              fontSize: '12px',
              zIndex: 2000,
              whiteSpace: 'pre-line',
              wordWrap: 'break-word',
              width: width || 'auto',
              maxWidth: maxWidth || '300px',
              backdropFilter: transparent ? 'blur(4px)' : 'none',
              boxShadow: transparent ? 'none' : '0px 4px 8px rgba(0, 0, 0, 0.2)',
              textAlign: 'justify'
            }}
          >
            {text}
          </div>
        )}
      </div>
    )
  }
}

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  transparent: PropTypes.bool,
  children: PropTypes.node.isRequired
}

Tooltip.defaultProps = {
  position: 'top',
  maxWidth: '300px', // Aumente o valor padrão
  transparent: false
}

export default Tooltip
