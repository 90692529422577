import React, { useEffect, useRef, useState } from 'react'
import AutoCompleteInput from '../../../../components/inputs/AutoCompleteInput'
import CheckboxInput from '../../../../components/inputs/CheckboxInput'
import TextInput from '../../../../components/inputs/TextInput'
import { showErrorMessage, showWarnMessage } from '../../../../components/utils/Message'
import PreferencesButton from '../../../../components/utils/PreferencesButton'
import TablePreferences from '../../../../components/utils/TablePreferences'
import { getGrupoProdutoDTO } from '../../../../dtos/cadastro/produto/GrupoProdutoDTO'
import { getProdutoDTO } from '../../../../dtos/cadastro/produto/ProdutoDTO'
import { getMarcaDTO } from '../../../../dtos/cadastro/veiculo/MarcaDTO'
import CommonHelper from '../../../../helpers/CommonHelper'
import { formatCurrencyToNumber, formatDecimal, formatDecimalToNumber, formatPercentageToNumber, formatToCurrency, formatToInteger, formatToPercentage } from '../../../../helpers/formaters'
import { getTableConfig } from '../../../../helpers/tableConfigs'
import PermissaoService from '../../../../services/cadastro/pessoa/PermissaoService'
import RestricaoService from '../../../../services/cadastro/pessoa/RestricaoService'
import GrupoProdutoService from '../../../../services/cadastro/produto/GrupoProdutoService'
import ProdutoService from '../../../../services/cadastro/produto/ProdutoService'
import MarcaService from '../../../../services/cadastro/veiculo/MarcaService'
import ConfigService from '../../../../services/configuracao/ConfigService'
import PecaBalcaoService from '../../../../services/vendas/orcamentoBalcao/PecaBalcaoService'
import DadosProdutoDasOutrasEmpresas from './DadosProdutoDasOutrasEmpresasModal'
import PrecosEmpresasModal from './PrecosEmpresasModal'

function getActiveColumnsKeysByTablePreferences (preferenciasAtuais) {
  const activeColumns = preferenciasAtuais.filter(preferencia => preferencia.enabled)
  return activeColumns.map(activeColumn => activeColumn.key)
}

const getDefaultActiveColumnsKeys = (columns) => {
  const activeColumnsKeys = []

  columns.forEach(column => {
    const { enabled = true } = column

    if (enabled)
      activeColumnsKeys.push(column.key)
  })

  return activeColumnsKeys
}

const getActiveColumnsKeys = (columns, tableName) => {
  const preferenciasAtuais = getTableConfig(tableName)
  if (preferenciasAtuais) {
    return getActiveColumnsKeysByTablePreferences(preferenciasAtuais)
  }

  return getDefaultActiveColumnsKeys(columns)
}

const getColunasHabilitadas = (columns, tableName) => {
  try {
    const activeColumnsKeys = getActiveColumnsKeys(columns, tableName)
    const colunas = []
    activeColumnsKeys.forEach(activeColumnKey => {
      const foundColumn = columns.find(column => column.key === activeColumnKey)
      if (foundColumn) {
        colunas.push(foundColumn)
      }
    })
    if (colunas.length === 0) {
      return columns
    } else {
      return colunas
    }
  } catch {
    localStorage.removeItem('tablesConfig')
  }
}

function getDescricaoPeca (descri_pro, refere_pro) {
  let descricaoPeca = descri_pro
  let referencaPeca = refere_pro

  if (referencaPeca) {
    if (typeof descricaoPeca !== 'object') {
      return `${descricaoPeca} - (${referencaPeca})`
    }
  } else {
    return typeof descricaoPeca !== 'object' && descricaoPeca
  }
}

function getObservacao (data) {
  return data.slice(0, 200)
}

const SugestaoTemplate = (data) => (
  <span><b>{data.refere_pro}</b> - {data.descri_pro}</span>
)

const SugestaoTemplateMarca = (data) => {
  return <span><b>{data.codigo_mar}</b> - {data.descri_mar}</span>
}

const SugestaoTemplateGrupo = (data) => {
  return <span><b>{data.codigo_grp}</b> - {data.descri_grp}</span>
}

function DadosPecaBalcaoOpv ({
  form,
  orcamentoBalcaoForm,
  edicaoHabilitada,
  camposObrigatorios,
  calculaValorTotalPeca,
  getDadosPrecoProduto,
  permitirDescontoByTipoPreco,
  setProdutoSelecionado,
  produtoSelecionado,
  pecaBalcaoSelecionada,
  controlaModalPesquisa,
  setControlaModalPesquisa
}) {
  // Faz os states em objetos.
  const [nomePrecos, setNomePrecos] = useState({
    personalizado1: '',
    personalizado2: '',
    personalizado3: ''
  })

  const [permissions, setPermissions] = useState({
    permissao45ItensSemEstoque: false,
    permissao197AlterarDescontoQtd: false,
    permitirQuantidadeFracionada: false,
    permitirDesconto: true
  })

  const [visibles, setVisibles] = useState({
    visiblePreferencesModal: false,
    visibleDadosOutrasEmpresasModal: false,
    visiblePrecosEmpresasModal: false
  })

  const [sugestions, setSugestions] = useState({
    sugestaoProdutos: [],
    sugestaoMarcas: [],
    sugestaoGrupo: []
  })

  const [filtrosDefault, setFiltrosDefault] = useState({
    ativo: false,
    apenasPecas: false,
    somenteComEstoque: false,
    apenasDisponiveis: false
  })

  const [produtoParaVisualizarDados, setProdutoParaVisualizarDados] = useState(null)
  const [usuarioModificouPrecoUni, setUsuarioModificouPrecoUni] = useState(false)
  const inputRef = useRef(null)

  const setarPrecoPersonalizado = async () => {
    const nomePrecoPersonalizado1 = await ConfigService.getValor(3091) || 'Personalizado 1'
    const nomePrecoPersonalizado2 = await ConfigService.getValor(3092) || 'Personalizado 2'
    const nomePrecoPersonalizado3 = await ConfigService.getValor(3093) || 'Personalizado 3'

    setNomePrecos((prevState) => ({
      ...prevState,
      personalizado1: nomePrecoPersonalizado1,
      personalizado2: nomePrecoPersonalizado2,
      personalizado3: nomePrecoPersonalizado3
    }))
  }

  const verificaPermissoes = async () => {
    const permissao45 = await PermissaoService.getByCodigo(45)
    const permissao197 = await PermissaoService.getByCodigo(197)
    setPermissions((prevState) => ({
      ...prevState,
      permissao45ItensSemEstoque: permissao45,
      permissao197AlterarDescontoQtd: permissao197
    }))
    return permissao45
  }

  async function setPermitirDescontoByTipoPreco () {
    const permite = await permitirDescontoByTipoPreco()
    if (permite) {
      setPermissions((prev) => ({ ...prev, permitirDesconto: true }))
    } else {
      setPermissions((prev) => ({ ...prev, permitirDesconto: false }))
      if (form.values.usapreqtd_peo === 0 || form.values.usapreqtd_peo === false) {
        handleChangePorcentagemDesconto({ target: { value: '0' } })
      }
    }
  }

  async function sugerirProdutos () {
    try {
      const produtos = await ProdutoService.filterSugestao(
        'apenasPecas=true&' +
        'ativo=true&' +
        `descricaoFiltro=${form.values.produto.descri_pro}` +
        `${!permissions.permissao45ItensSemEstoque || filtrosDefault.somenteComEstoque ? '&somenteComEstoque=true' : ''}` +
        `${filtrosDefault.apenasDisponiveis ? '&apenasDisponiveis=true' : ''}`
      )
      if (produtos?.data.length <= 0) {
        showWarnMessage('Produto não encontrado!')
      }
      setSugestions((prevState) => ({
        ...prevState,
        sugestaoProdutos: produtos.data
      }))
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um produto!')
    }
  }

  async function onSelectProduto (e) {
    try {
      let produto = e.value
      let unidadePro = await ProdutoService.getUnidade(produto.coduni_pro)
      produto.unidade = unidadePro
      await setUsuarioModificouPrecoUni((prev) => false)
      await setProdutoSelecionado(produto)

      if (produto.unidade?.fracio_uni) {
        form.setFieldValue('qtd_peo', 1.000)
      } else {
        form.setFieldValue('qtd_peo', 1)
      }

      const codEmp = orcamentoBalcaoForm.values.codemp_opv
      const codTipoPreco = orcamentoBalcaoForm.values.tipoPreco.codigo_tpr

      await PecaBalcaoService.persistencia(produto.codigo_pro, codTipoPreco)

      const dadosPrecoProduto = await getDadosPrecoProduto(produto, form.values.qtd_peo, codEmp, codTipoPreco)

      await preencherDadosPecaPreco(produto, dadosPrecoProduto, true)

      await form.setFieldValue('produto', produto)
      await form.setFieldTouched('produto.codigo_pro')
      if (inputRef.current) {
        inputRef.current.focus()
      }
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao verificar os dados do produto!')
      return
    }
  }

  function handleChangeProduto (event) {
    form.setFieldValue('produto', event.target.value)
    form.setFieldValue('produto.descri_pro', event.target.value)
  }

  function handleChangeValorUnitario (event) {
    setUsuarioModificouPrecoUni(event.target.value !== produtoSelecionado.valuni_peo)
    form.setFieldValue(event.target.name, formatCurrencyToNumber(event.target.value))
  }

  async function definirUsaPrecoQuantidade (valorPorcentagemDesconto) {
    if (form.values.codpec_peo > 0) {
      // Se permite alterar desconto por quantidade
      if (permissions.permissao197AlterarDescontoQtd) {
        // Busca % de desconto por quantidade da API
        const codEmp = orcamentoBalcaoForm.values.codemp_opv
        const codTipoPreco = orcamentoBalcaoForm.values.tipoPreco.codigo_tpr
        const dadosPrecoProduto = await ProdutoService.getDadosPrecoByTipoPreco(form.values.codpec_peo, form.values.qtd_peo, codEmp, codTipoPreco)

        // Se o % de desconto por quantidade for igual ao informado
        if (dadosPrecoProduto.perdesQtd === valorPorcentagemDesconto) {
          form.setFieldValue('usapreqtd_peo', true)
        } else {
          form.setFieldValue('usapreqtd_peo', false)
        }
      }
    }
  }

  function handleChangePorcentagemDesconto (event) {
    let value = event.target.value

    const isValorMaiorQueUmChar = value.length !== 1
    if (isValorMaiorQueUmChar && !value.includes('%')) {
      value = value.slice(0, value.length - 1)
    }
    const valorPorcentagemDesconto = formatPercentageToNumber(value)
    atualizaDescontoPecaForm(valorPorcentagemDesconto)

    form.setFieldValue('perdes_peo', valorPorcentagemDesconto)

    definirUsaPrecoQuantidade(valorPorcentagemDesconto)
  }

  function handleChangeDesconto (event) {
    const valorTotalBrutoPecaBalcao = form.values.qtd_peo * form.values.valuni_peo
    const valorDesconto = formatCurrencyToNumber(event.target.value)

    if (valorTotalBrutoPecaBalcao !== 0) {
      const valorPorcentagemDesconto = valorDesconto / (valorTotalBrutoPecaBalcao / 100)
      form.setFieldValue('perdes_peo', valorPorcentagemDesconto)
      definirUsaPrecoQuantidade(valorPorcentagemDesconto)
    }

    form.setFieldValue(event.target.name, valorDesconto)
  }

  async function preencherDadosPecaPreco (produto, dadosPrecoProduto, alteraPrecoLiq) {
    // * Não altera o preço unitário quando muda quantidade se preço unitario foi modificado pelo usuario ou alterado o produto selecionado
    if (!usuarioModificouPrecoUni || alteraPrecoLiq) await form.setFieldValue('valuni_peo', dadosPrecoProduto.preco)

    // * Define o desconto apenas se retornou desconto por quantidade ou se o produto atualmente está indicado como usando preço por quantidade
    if (
      dadosPrecoProduto.usaPreQtd ||
      (form.values.usapreqtd_peo === 1 || form.values.usapreqtd_peo === true)
    ) {
      form.setFieldValue('perdes_peo', dadosPrecoProduto.perdesQtd)
      await atualizaDescontoPecaForm(dadosPrecoProduto.perdesQtd)
    }

    // * Atualiza os demais campos de uma única vez
    const campos = {
      usapreqtd_peo: dadosPrecoProduto.usaPreQtd,
      venliq_peo: produto.liquid_pro !== null ? produto.liquid_pro : false,
      siguni_peo: produto.abrevi_uni,
      descri_peo: produto.descri_pro,
      refpro_peo: produto.refere_pro,
      iscan_peo: 0,
      valacr_peo: 0.00,
      valpro_peo: 0.00,
      totacrcop_peo: 0.00,
      usucan_peo: null,
      dahcan_peo: null,
      codppr_peo: null,
      valbru_peo: dadosPrecoProduto.preco,
      codtpr_peo: dadosPrecoProduto.codigoTipoPreco,
      venpro_peo: dadosPrecoProduto.isVendaPromocional,
      cusuni_peo: dadosPrecoProduto.cusmedliq_pre
    }
    Object.entries(campos).forEach(([chave, valor]) => {
      form.setFieldValue(chave, valor)
    })
  }

  async function atualizaDescontoPecaForm (percentualDesconto) {
    const valorTotalBrutoPecaBalcao = form.values.qtd_peo * form.values.valuni_peo

    if (valorTotalBrutoPecaBalcao !== 0) {
      const valorDesconto = (valorTotalBrutoPecaBalcao * percentualDesconto) / 100
      await form.setFieldValue('valdes_peo', valorDesconto)

      const valtotPeo = form.values.qtd_peo * form.values.valuni_peo - valorDesconto
      await form.setFieldValue('valtot_peo', valtotPeo)
    }
  }

  async function validaLimiteDescontoPorcentagem (percentual) {
    if (percentual > 100) {
      showWarnMessage('Valor não pode exceder 100% de desconto')
      return false
    } else {
      const restricaoParaVisualizarLimiteDesconto = await RestricaoService.getByCodigo(25)
      const podeAdicionarPecasComLimiteDescontoExcedido = await PermissaoService.getByCodigo('183')
      let excedeuPercentualDescont = false
      let arrMsgDescExcedido = []
      let msgExibida

      if (!form.values.iscan_peo) {
        const codEmp = orcamentoBalcaoForm.values.codemp_opv
        const parMargem = CommonHelper.getMargem(form.values.cusuni_peo, form.values.valbru_peo)
        const limitePorcentagemDesconto = await PecaBalcaoService.getLimiteDesconto(parMargem, codEmp)
        excedeuPercentualDescont = excedeuPercentualDescont || percentual > limitePorcentagemDesconto

        if (percentual > limitePorcentagemDesconto) {
          if (podeAdicionarPecasComLimiteDescontoExcedido) {
            if (restricaoParaVisualizarLimiteDesconto) {
              msgExibida = `Desconto máximo para a peça (${form.values.descri_peo}) foi excedido!`
            } else {
              msgExibida = `O desconto máximo para a peça (${form.values.descri_peo}), por margem de lucro é de ${formatToPercentage(limitePorcentagemDesconto)}, você solicitou ${formatToPercentage(percentual)}`
            }
            arrMsgDescExcedido.push(msgExibida)
          } else {
            form.setFieldValue('perdes_peo', 0)
            form.setFieldValue('valdes_peo', 0)
          }
        }
      }

      if (podeAdicionarPecasComLimiteDescontoExcedido && excedeuPercentualDescont) {
        if (arrMsgDescExcedido?.length > 4) {
          restricaoParaVisualizarLimiteDesconto ? showWarnMessage('Desconto máximo para a peça excedido!') : showWarnMessage('O desconto solicitado é maior que o permitido nas configurações de margem de lucro!')
        } else {
          arrMsgDescExcedido.forEach((msg, index) => {
            setTimeout(() => {
              showWarnMessage(msg)
            }, index * 300)
          })
        }
      }

      if (!podeAdicionarPecasComLimiteDescontoExcedido && excedeuPercentualDescont) {
        showErrorMessage('Usuário sem permissão para exceder o limite de desconto.')
        return false
      }
      // Se não caiu em validações anteriores, retorna ok
      return true
    }
  }

  async function validaLimiteDescontoValor () {
    let valorTotalBrutoPecas = !form.values.iscan_peo
      ? form.values.qtd_peo * form.values.valuni_peo
      : 0

    let percentualDesconto = 0

    if (form.values.valdes_peo <= valorTotalBrutoPecas) {
      percentualDesconto = form.values.valdes_peo / (valorTotalBrutoPecas / 100)
      const restricaoParaVisualizarLimiteDesconto = await RestricaoService.getByCodigo(25)
      const podeAdicionarPecasComLimiteDescontoExcedido = await PermissaoService.getByCodigo('183')
      let excedeuPercentualDescont = false
      let arrMsgDescExcedido = []
      let msgExibida

      if (!form.values.iscan_peo) {
        const codEmp = orcamentoBalcaoForm.values.codemp_opv
        const parMargem = CommonHelper.getMargem(form.values.cusuni_peo, form.values.valbru_peo)
        const limitePorcentagemDesconto = await PecaBalcaoService.getLimiteDesconto(parMargem, codEmp)
        const perdesPeo = (100 * (form.values.valuni_peo * form.values.qtd_peo)) / valorTotalBrutoPecas
        const valdesPeo = (form.values.valdes_peo / 100) * perdesPeo

        let descontomaximo = form.values.valuni_peo * form.values.qtd_peo * (limitePorcentagemDesconto / 100)
        excedeuPercentualDescont = excedeuPercentualDescont || form.values.valdes_peo > descontomaximo
        if (percentualDesconto > limitePorcentagemDesconto) {
          if (podeAdicionarPecasComLimiteDescontoExcedido) {
            if (restricaoParaVisualizarLimiteDesconto) {
              msgExibida = `Desconto máximo para a peça (${form.values.descri_peo}) foi excedido!`
            } else {
              msgExibida = `O desconto máximo para a peça (${form.values.descri_peo}), por margem de lucro é de ${formatToCurrency(descontomaximo)}, você solicitou ${formatToCurrency(valdesPeo)}`
            }
            arrMsgDescExcedido.push(msgExibida)
          } else {
            form.setFieldValue('perdes_peo', 0)
            form.setFieldValue('valdes_peo', 0)
          }
        }
      }

      if (podeAdicionarPecasComLimiteDescontoExcedido && excedeuPercentualDescont) {
        if (arrMsgDescExcedido?.length > 4) {
          restricaoParaVisualizarLimiteDesconto ? showWarnMessage('Desconto máximo para a peça excedido!') : showWarnMessage('O desconto solicitado é maior que o permitido nas configurações de margem de lucro!')
        } else {
          arrMsgDescExcedido.forEach((msg, index) => {
            setTimeout(() => {
              showWarnMessage(msg)
            }, index * 300)
          })
        }
      }

      if (!podeAdicionarPecasComLimiteDescontoExcedido && excedeuPercentualDescont) {
        showErrorMessage('Usuário sem permissão para exceder o limite de desconto.')
        return false
      }
      // Se não caiu em validações anteriores, retorna ok
      return true
    } else {
      showWarnMessage('Valor do desconto não pode exceder o valor bruto das peças')
      form.setFieldValue('valdes_peo', 0)
      return false
    }
  }

  async function onBlurDescontoPercentual () {
    if (permissions.permitirDesconto) {
      if (form.values.perdes_peo) {
        await validaLimiteDescontoPorcentagem(form.values.perdes_peo)
      }
    }
  }

  async function onBlurDesconto () {
    if (permissions.permitirDesconto) {
      if (form.values.valdes_peo) {
        await validaLimiteDescontoValor(form.values.valdes_peo)
      }
    }
  }

  async function onBlurQuantidade () {
    if (permissions.permitirQuantidadeFracionada) {
      if (form.values.qtd_peo < 0.001) {
        form.setFieldValue('qtd_peo', 0.001)
      }
    } else {
      if (form.values.qtd_peo < 1) {
        form.setFieldValue('qtd_peo', 1)
      }
    }
    form.setFieldTouched('qtd_peo')
    try {
      if (form.values.produto.codigo_pro) {
        const codEmp = orcamentoBalcaoForm.values.codemp_opv
        const codTipoPreco = orcamentoBalcaoForm.values.tipoPreco.codigo_tpr
        const dadosPrecoProduto = await getDadosPrecoProduto(form.values.produto, form.values.qtd_peo, codEmp, codTipoPreco)

        await preencherDadosPecaPreco(form.values.produto, dadosPrecoProduto)
      }
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao verificar os dados do produto!')
      return
    }
  }

  async function onBlurValorUnitario () {
    if (form.values.valbru_peo > form.values.valuni_peo) {
      const permissaoParaReduzirPrecoSemDesconto = await PermissaoService.getByCodigo('141')
      if (!permissaoParaReduzirPrecoSemDesconto) {
        showWarnMessage('Para reduzir o valor utilize a opção desconto!')
        form.setFieldValue('valuni_peo', form.values.valbru_peo)
        form.setFieldTouched('valuni_peo')
      }
    }
  }

  useEffect(async () => {
    // Seta o produto selecionado.
    if (pecaBalcaoSelecionada?.produto?.codigo_pro) {
      setProdutoSelecionado(pecaBalcaoSelecionada?.produto)
    }

    // Seta os preços personalizados.
    await setarPrecoPersonalizado()
    // Verifica e seta as permissões.
    const permissao45 = await verificaPermissoes()
    // Seta os filtros padrões.
    await setFiltrosDefault((prevState) => ({
      ...prevState,
      ativo: true,
      apenasPecas: true,
      apenasDisponiveis: false,
      somenteComEstoque: !permissao45
    }))
  }, [])

  useEffect(async () => {
    await setPermitirDescontoByTipoPreco()

    if ((form.values.usapreqtd_peo === 1 || form.values.usapreqtd_peo === true) && !permissions.permissao197AlterarDescontoQtd) {
      setPermissions((prev) => ({ ...prev, permitirDesconto: false }))
    }

    if (form.values.codtpr_peo === 6) {
      setPermissions((prev) => ({ ...prev, permitirDesconto: false }))
    }

    setPermissions((prev) => ({
      ...prev,
      permitirQuantidadeFracionada: form.values.produto?.unidade?.fracio_uni
    }))
  }, [
    orcamentoBalcaoForm.values.tipoPreco.codigo_tpr,
    form.values.codtpr_peo,
    form.values.produto?.unidade?.fracio_uni,
    form.values.usapreqtd_peo,
    permissions.permissao197AlterarDescontoQtd
  ])

  useEffect(() => {
    if (!Number(form.values.produto?.codigo_pro)) return
    calculaValorTotalPeca(form.values)
    atualizaDescontoPecaForm(form.values.perdes_peo)
  }, [
    form.values.produto?.codigo_pro,
    form.values.qtd_peo,
    form.values.valuni_peo,
    form.values.perdes_peo,
    form.values.valdes_peo
  ])

  const columns = [
    {
      key: 'codigo_pro',
      label: 'Código',
      width: '120px',
      pesquisavel: false,
      enabled: true,
      frozen: true,
      style: {
        width: '100px',
        textAlign: 'center',
        overflowWrap: 'break-word',
        fontSize: '0.8rem'
      }
    },
    {
      key: 'refere_pro',
      campo: 'refere_pro',
      nome: 'Referência',
      label: 'Referência',
      width: '175px',
      pesquisavel: false,
      enabled: true,
      style: {
        width: '175px',
        textAlign: 'center',
        overflowWrap: 'break-word',
        fontSize: '0.8rem'
      }
    },
    {
      key: 'reffab_fpr',
      campo: 'reffab_fpr',
      nome: 'Ref. Fábrica ',
      label: 'Ref. Fábrica',
      width: '175px',
      pesquisavel: false,
      enabled: true,
      style: {
        width: '175px',
        textAlign: 'center',
        overflowWrap: 'break-word',
        fontSize: '0.8rem'
      }
    },
    {
      key: 'descri_pro',
      campo: 'descri_pro',
      nome: 'Descrição',
      label: 'Descrição',
      width: '600px',
      pesquisavel: true,
      enabled: true
    },
    {
      key: 'descri_mar',
      campo: 'descri_mar',
      nome: 'Marca',
      label: 'Marca',
      width: '200px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'descri_grp',
      campo: 'descri_grp',
      nome: 'Grupo',
      label: 'Grupo',
      width: '150px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'estatu_pre',
      campo: 'estatu_pre',
      nome: 'Estoque',
      label: 'Estoque',
      width: '150px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'qtdapl_pre',
      campo: 'qtdapl_pre',
      nome: 'Aplicadas',
      label: 'Aplicadas',
      width: '150px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'qtdres_pre',
      campo: 'qtdres_pre',
      nome: 'Reservadas',
      label: 'Reservadas',
      width: '150px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'qtdtra_pre',
      campo: 'qtdtra_pre',
      nome: 'Em trânsito',
      label: 'Em trânsito',
      width: '150px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'qtddisponivel',
      campo: 'qtddisponivel',
      nome: 'Qtd. disponível',
      label: 'Qtd. disponível',
      width: '150px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'preco_pre',
      campo: 'preco_pre',
      nome: 'Preço varejo',
      label: 'Preço varejo',
      format: formatToCurrency,
      width: '150px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'prepro_ppr',
      campo: 'prepro_ppr',
      nome: 'Preço promocional',
      label: 'Preço promocional',
      format: formatToCurrency,
      width: '150px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'preata_pre',
      campo: 'preata_pre',
      nome: 'Atacado',
      label: 'Atacado',
      format: formatToCurrency,
      width: '150px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'preeco_pre',
      campo: 'preeco_pre',
      nome: 'E-commerce',
      label: 'E-commerce',
      format: formatToCurrency,
      width: '150px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'preper1_pre',
      campo: 'preper1_pre',
      nome: nomePrecos.personalizado1,
      label: nomePrecos.personalizado1,
      format: formatToCurrency,
      width: '200px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'preper2_pre',
      campo: 'preper2_pre',
      nome: nomePrecos.personalizado2,
      label: nomePrecos.personalizado2,
      format: formatToCurrency,
      width: '200px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'preper3_pre',
      campo: 'preper3_pre',
      nome: nomePrecos.personalizado3,
      label: nomePrecos.personalizado3,
      format: formatToCurrency,
      width: '200px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'enderecamento',
      campo: 'enderecamento',
      nome: 'Endereçamento',
      label: 'Endereçamento',
      width: '200px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'obs_pro',
      campo: 'obs_pro',
      nome: 'Observação',
      format: getObservacao,
      label: 'Observação',
      width: '600px',
      pesquisavel: true,
      enabled: true
    },
    {
      key: 'codbar_pro',
      campo: 'codbar_pro',
      nome: 'Código de barras',
      label: 'Código de barras',
      width: '200px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'codean_pro',
      campo: 'codean_pro',
      nome: 'Código EAN',
      label: 'Código EAN',
      width: '200px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'buttonPreco',
      campo: 'buttonPreco',
      nome: 'Preços',
      label: 'Preços',
      width: '250px',
      pesquisavel: false,
      enabled: true,
      buttonClassName: 'pi pi-info-circle info-selection',
      realClassNameParaBotao: 'small-icon-button',
      buttonOnClick: async (data) => {
        setProdutoParaVisualizarDados(data)
        setVisibles((prevState) => ({ ...prevState, visiblePrecosEmpresasModal: true }))
      }
    },
    {
      key: 'button',
      campo: 'button',
      nome: 'Dados das outras empresas',
      label: 'Dados das outras empresas',
      width: '250px',
      pesquisavel: false,
      enabled: true,
      buttonClassName: 'pi pi-info-circle info-selection',
      realClassNameParaBotao: 'small-icon-button',
      buttonOnClick: async (data) => {
        setProdutoParaVisualizarDados(data)
        setVisibles((prevState) => ({ ...prevState, visibleDadosOutrasEmpresasModal: true }))
      }
    }
  ]

  function ProdutoExpandedFiltersTemplate (props) {
    const handleSomenteItensEstoque = () => {
      props.changeFilter({
        ...props.filter,
        somenteComEstoque: !permissions.permissao45ItensSemEstoque || !filtrosDefault.somenteComEstoque
      })
      setFiltrosDefault((prevState) => ({
        ...prevState,
        somenteComEstoque: !permissions.permissao45ItensSemEstoque || !filtrosDefault.somenteComEstoque
      }))
    }
    const handleApenasDisponiveis = () => {
      props.changeFilter({
        ...props.filter,
        apenasDisponiveis: !filtrosDefault.apenasDisponiveis
      })
      setFiltrosDefault((prevState) => ({
        ...prevState,
        apenasDisponiveis: !filtrosDefault.apenasDisponiveis
      }))
    }
    const sugerirMarcas = async () => {
      if (typeof props.filter.marca !== 'string') return
      try {
        const marcas = await MarcaService.filterSugestao(`descricaoFiltro=${props.filter.marca}`)
        setSugestions((prevState) => ({
          ...prevState,
          sugestaoMarcas: marcas.data
        }))
      } catch (error) {
        showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir uma marca!')
      }
    }
    const sugerirGrupo = async () => {
      try {
        if (typeof props.filter.grupo !== 'string') return
        const grupo = await GrupoProdutoService.filterSugestao(`descricaoFiltro=${props.filter.grupo}`)
        setSugestions((prevState) => ({
          ...prevState,
          sugestaoGrupo: grupo.data
        }))
      } catch (error) {
        showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um grupo!')
      }
    }
    return (
      <>
        <div className="w-full optionTable" >
          <PreferencesButton onClick={() => setVisibles((prevState) => ({ ...prevState, visiblePreferencesModal: true }))} />
        </div>
        <div className="field col-12 md:col-6">
          <AutoCompleteInput
            label="Marca"
            name="marca.codigo_mar"
            field="descri_mar"
            value={typeof props.filter.marca === 'string' ? props.filter.marca : form.values.marca?.descri_mar}
            placeholder="Nome da marca"
            onSelect={(e) => props.changeFilter({
              ...props.filter,
              marca: e.value.descri_mar
            })}
            suggestions={sugestions.sugestaoMarcas}
            completeMethod={sugerirMarcas}
            onChange={(e) => {
              if (e.value.length === 0) {
                const { marca, ...rest } = props.filter
                props.changeFilter(rest)
              } else {
                props.changeFilter({
                  ...props.filter,
                  marca: e.value
                })
              }
            }}
            camposObrigatorios={camposObrigatorios}
            itemTemplate={SugestaoTemplateMarca}
            selected={form.values.marca}
            filtrotitle="Pesquisa de marca"
            service={MarcaService}
            model={getMarcaDTO}
            primarykeyname="codigo_mar"
            columns={[
              { campo: 'descri_mar', nome: 'Descrição' }
            ]}
          />

        </div>
        <div className="field col-12 md:col-6">
          <AutoCompleteInput
          //AutoComplete
            label="Grupo"
            name="grupo.codigo_grp"
            field="descri_grp"
            value={typeof props.filter.grupo === 'string' ? props.filter.grupo : form.values.grupo?.descri_grp}
            placeholder="Nome do grupo"
            onSelect={(e) => props.changeFilter({
              ...props.filter,
              grupo: e.value.descri_grp
            })}
            suggestions={sugestions.sugestaoGrupo}
            completeMethod={sugerirGrupo}
            onChange={(e) => {
              if (e.value.length === 0) {
                const { grupo, ...rest } = props.filter
                props.changeFilter(rest)
              } else {
                props.changeFilter({
                  ...props.filter,
                  grupo: e.value
                })
              }
            }}
            camposObrigatorios={camposObrigatorios}
            itemTemplate={SugestaoTemplateGrupo}
            selected={form.values.marca}
            //FiltroModal
            filtrotitle="Pesquisa de grupo"
            service={GrupoProdutoService}
            model={getGrupoProdutoDTO}
            primarykeyname="codigo_grp"
            columns={[
              { campo: 'descri_grp', nome: 'Descrição' }
            ]}
          />
        </div>
        <div className="field col-12 md:col-4">
          <TextInput
            label="Referência"
            placeholder="Referência do produto"
            value={props.filter.referencia}
            className="inputfield w-full"
            onChange={(e) => {
              if (e.target.value.length === 0) {
                const { referencia, ...rest } = props.filter
                props.changeFilter(rest)
              } else {
                props.changeFilter({
                  ...props.filter,
                  referencia: e.target.value
                })
              }
            }}
          />
        </div>
        <div className="field col-12 md:col-4">
          <TextInput
            label="Referência de fábrica"
            placeholder="Referência de fábrica do produto"
            value={props.filter.referenciaFabrica}
            className="inputfield w-full"
            onChange={(e) => {
              if (e.target.value.length === 0) {
                const { referenciaFabrica, ...rest } = props.filter
                props.changeFilter(rest)
              } else {
                props.changeFilter({
                  ...props.filter,
                  referenciaFabrica: e.target.value
                })
              }
            }}
          />
        </div>
        <div className="field col-12 md:col-4">

          <CheckboxInput label={'Somente itens com estoque'}
            value={filtrosDefault.somenteComEstoque}
            checked={filtrosDefault.somenteComEstoque}
            disabled={!permissions.permissao45ItensSemEstoque}
            onChange={handleSomenteItensEstoque} />

          <CheckboxInput label={'Somente disponíveis'}
            value={filtrosDefault.apenasDisponiveis}
            checked={filtrosDefault.apenasDisponiveis}
            onChange={handleApenasDisponiveis} />

        </div>
      </>
    )
  }

  return (
    <div className="formgrid grid md:justify-content-center flex justify-center">
      {/* <div className='w-full md:flex col-12 md:col-12 '> */}
      <div className="field col-12 md:col-8 w-full">
        <AutoCompleteInput
          //AutoComplete
          label="Peças"
          name="produto.codigo_pro"
          field="descri_pro"
          value={
            form.values.produto?.descri_pro &&
            getDescricaoPeca(
              form.values.produto.descri_pro,
              form.values.produto.refere_pro
            )
          }
          placeholder="Nome da peça"
          disabled={!edicaoHabilitada}
          onSelect={async (e) => await onSelectProduto(e)}
          suggestions={sugestions.sugestaoProdutos}
          completeMethod={sugerirProdutos}
          onChange={handleChangeProduto}
          camposObrigatorios={camposObrigatorios}
          form={form}
          itemTemplate={SugestaoTemplate}
          //FiltroModal
          filtrotitle="Pesquisa de peças"
          filtersDefault={filtrosDefault}
          expandedFiltersTemplate={ProdutoExpandedFiltersTemplate}
          service={ProdutoService}
          model={getProdutoDTO}
          panelStyle={{ width: `${CommonHelper.isDesktop() ? '300px' : ''}`, left: `${CommonHelper.isDesktop() ? '607px' : ''}` }}
          primarykeyname="codigo_pro"
          horizontalScroll={CommonHelper.isDesktop()}
          columns={getColunasHabilitadas(columns, 'produtos')}
          visibleFiltroModal={controlaModalPesquisa}
          setVisibleFiltroModal={setControlaModalPesquisa}
          semGetInicial
          full
        />
      </div>
      <TablePreferences
        title="Preferências da tabela de peças"
        tableName="produtos"
        columns={columns}
        visible={visibles.visiblePreferencesModal}
        hide={() => setVisibles((prevState) => ({ ...prevState, visiblePreferencesModal: false }))}
      />
      {/* </div> */}
      <div className='w-full md:flex'>

        <div className="field col-12 md:col-6 ">
          <TextInput
            name="qtd_peo"
            label="Quantidade"
            placeholder="Quantidade do produto"
            value={!permissions.permitirQuantidadeFracionada ? form.values.qtd_peo : formatDecimal(form.values.qtd_peo, 3)}
            onChange={(e) => form.setFieldValue('qtd_peo', !permissions.permitirQuantidadeFracionada ? formatToInteger(e.target.value) : formatDecimalToNumber(e.target.value, 3))}
            onBlur={onBlurQuantidade}
            disabled={!edicaoHabilitada}
            camposObrigatorios={camposObrigatorios}
            form={form}
            referencia={inputRef}
          />
        </div>
        <div className="field col-12 md:col-6 ">
          <TextInput
            name="valuni_peo"
            label="Valor unitário"
            placeholder="Valor das despesas"
            value={formatToCurrency(form.values.valuni_peo)}
            onChange={handleChangeValorUnitario}
            onBlur={onBlurValorUnitario}
            disabled={!edicaoHabilitada}
            camposObrigatorios={camposObrigatorios}
            form={form}
          />
        </div>
      </div>
      <div className='w-full md:flex'>

        <div className="field col-12 md:col-6">
          <TextInput
            name="perdes_peo"
            label="Desconto (%)"
            value={formatToPercentage(form.values.perdes_peo)}
            onChange={handleChangePorcentagemDesconto}
            onBlur={onBlurDescontoPercentual}
            disabled={!permissions.permitirDesconto || !edicaoHabilitada}
            camposObrigatorios={camposObrigatorios}
            form={form}
          />
        </div>
        <div className="field col-12 md:col-6">
          <TextInput
            name="valdes_peo"
            label="Desconto"
            value={formatToCurrency(form.values.valdes_peo)}
            onChange={handleChangeDesconto}
            onBlur={onBlurDesconto}
            disabled={!permissions.permitirDesconto || !edicaoHabilitada}
            camposObrigatorios={camposObrigatorios}
            form={form}
          />
        </div>
      </div>
      <div className='w-full md:flex'>
        <div className="field col-12 md:col-6">
          <TextInput
            name="valtot_peo"
            label="Valor total"
            placeholder="Valor das despesas"
            value={formatToCurrency(form.values.valtot_peo)}
            disabled
            camposObrigatorios={camposObrigatorios}
            form={form}
          />
        </div>
      </div>
      {!!visibles.visibleDadosOutrasEmpresasModal && <DadosProdutoDasOutrasEmpresas
        visible={visibles.visibleDadosOutrasEmpresasModal}
        onHide={() => setVisibles((prevState) => ({ ...prevState, visibleDadosOutrasEmpresasModal: false }))}
        produto={produtoParaVisualizarDados}
      />}
      {!!visibles.visiblePrecosEmpresasModal && <PrecosEmpresasModal
        visible={visibles.visiblePrecosEmpresasModal}
        onHide={() => setVisibles((prevState) => ({ ...prevState, visiblePrecosEmpresasModal: false }))}
        produto={produtoParaVisualizarDados}
      />}
    </div>
  )
}

export default DadosPecaBalcaoOpv
