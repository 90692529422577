
export const getProcessoDTO = (data = {}) => {
  return {
    codigo_prc: data.codigo_prc || null,
    descri_prc: data.descri_prc || '',
    ativo_prc: !!data.codigo_prc ? !!data.ativo_prc : true,
    etapas: data.etapas || []
  }
}

export const postProcessoDTO = (data = {}) => {
  // * Formata Objeto responsavel dentro dos objetos etapas para n levar milhares de dados para o payload
  if (data?.etapas) {
    (data?.etapas || []).map((objPessoa) => {
      if (!!objPessoa?.responsavel) {
        return {
          ...objPessoa,
          responsavel: {
            codigo_pes: objPessoa?.responsavel?.codigo_pes,
            nomraz_pes: objPessoa?.responsavel?.nomraz_pes,
            cgccpf_pes: objPessoa?.responsavel?.cgccpf_pes
          }
        }
      }
      return objPessoa
    })
  }

  return {
    codigo_prc: data.codigo_prc || null,
    descri_prc: data.descri_prc || '',
    ativo_prc: data.ativo_prc || false,
    etapas: data.etapas || []
  }
}
