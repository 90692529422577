export const atividades = [
  { codigo_pea: 2, descri_pea: 'Distribuidora' },
  { codigo_pea: 1, descri_pea: 'Fabricante' },
  { codigo_pea: 4, descri_pea: 'Loja de peça' },
  { codigo_pea: 3, descri_pea: 'Outra' }
]

export const estadosCivis = [
  { label: 'Casado(a)', estciv_pef: 2 },
  { label: 'Desquitado(a)', estciv_pef: 3 },
  { label: 'Divorciado(a)', estciv_pef: 4 },
  { label: 'Ignorado(a)', estciv_pef: 7 },
  { label: 'Separado(a) judicialmente', estciv_pef: 6 },
  { label: 'Solteiro(a)', estciv_pef: 1 },
  { label: 'União estável', estciv_pef: 8 },
  { label: 'Viúvo(a)', estciv_pef: 5 }
]

export const generos = [
  { label: 'Masculino', sex_pef: 1 },
  { label: 'Feminino', sex_pef: 2 },
  { label: 'Ignorado', sex_pef: 3 }
]
