import { ScrollTop } from 'primereact/scrolltop'
import { classNames } from 'primereact/utils'
import React, { useEffect } from 'react'
import AppButton from '../../../../components/layout/AppButton'
import Container from '../../../../components/layout/Container'
import DataList from '../../../../components/layout/DataList'
import Page from '../../../../components/layout/Page'
import Confirm from '../../../../components/utils/Confirm'
import { showErrorMessage, showSuccessMessage } from '../../../../components/utils/Message'
import PreferencesButton from '../../../../components/utils/PreferencesButton'
import TablePreferences from '../../../../components/utils/TablePreferences'
import CommonHelper from '../../../../helpers/CommonHelper'
import { formatDate, formatDateTime, formatPlacha } from '../../../../helpers/formaters'
import { createColumns, getColunasHabilitadas } from '../../../../helpers/tableConfigs'
import { GerenciamentoEstoqueVeiculoModel } from '../../../../models/vendas/negociacao/GerenciamentoEstoqueVeiculoModel'

import DateInput from '../../../../components/inputs/DateInput'
import ReportService from '../../../../services/reports/ReportService'
import EntregaVeiculoService from '../../../../services/vendas/negociacao/EntregaVeiculoService'
import useList from '../../../../hook/useList'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

function getDataAprovacao (data) {
  return data.dataAprovacaoNeg ? formatDate(data.dataAprovacaoNeg) : ''
}

function getDataEntrega (data) {
  return data.dahent_nve ? formatDateTime(data.dahent_nve) : ''
}

function getDataPrevisao (data) {
  return data.dahpreent_nve ? formatDateTime(data.dahpreent_nve) : ''
}

function getAutorizado (data) {
  return data.entregaAutorizada === 1 ? 'À autorizar' : formatDateTime(data.dahaen_nve)
}

function getDescricaoAutorizacaoClassName (data) {
  return `entrega-autorizada-${data.entregaAutorizada === 1 ? '1' : '2'}`
}

const expandedFiltersTemplate = (props) => {
  return (
    <>
      <div className="field col-12">
        <label className="label">Período de aprovação</label>
        <div className="flex flex-wrap col-12 p-0">
          <div className="col-12 md:col-6 mb-2 md:mb-0 p-0 md:p-1 md:pl-0">
            <DateInput placeholder="de" name="dataDe" value={props.state.filter.dataDe || ''}
              onChange={props.filterOnChange} className="inputfield w-full" />
          </div>
          <div className="col-12 md:col-6 p-0 md:p-1 md:pr-0">
            <DateInput placeholder="até" name="dataAte" value={props.state.filter.dataAte || ''}
              onChange={props.filterOnChange} className="inputfield w-full" />
          </div>
        </div>
      </div>
      <div className="col-12 flex justify-content-end mb-4">
        <AppButton label="Buscar" icon="pi pi-search" onClick={props.onFilter} />
      </div>
    </>
  )
}

const rowExpansionTemplate = (data, props) => {
  return (
    <div className="row-expansion grid" style={{ textAlign: '' }}>
      <div className="grid col-12 md:col-4">
      </div>
      <div className="grid col-12 md:col-8 mt-2">
        <div className="row-expansion-buttons col-12 flex mt-2 mb-0">
          {!props.state.mostrarBotaoEntregue &&
          <AppButton
            label="Autorizar"
            className="ml-1 row-expansion-button"
            disabled={data.entregaAutorizada === 2 ? true : false}
            onClick={() => props.autorizarEntrega(data)}
          />
          }
          {!props.state.mostrarBotaoEntregue &&
          <AppButton
            label="Cancelar autorização"
            className="ml-1 row-expansion-button "
            disabled={data.entregaAutorizada === 1 ? true : false}
            onClick={() => props.cancelarAutorizacaoEntrega(data)}
          />
          }
          {props.state.mostrarBotaoEntregue &&
          <AppButton
            label="Cancelar"
            className="ml-1 row-expansion-button "
            disabled={data.entregaAutorizada === 1 ? true : false}
            onClick={() => props.cancelarEntrega(data)}
          />
          }
          {!props.state.mostrarBotaoEntregue &&
          <AppButton
            label="Entregar"
            className="ml-1 row-expansion-button"
            disabled={data.entregaAutorizada === 1 ? true : false}
            onClick={() => props.entregarVeiculo(data)}
          />
          }
          <AppButton
            label="Visualizar negociação"
            className="ml-1 row-expansion-button"
            onClick={() => { props.history.push(`/negociacao/${data.codigo_neg}`) }}
          />
          {props.state.mostrarBotaoEntregue &&
          <AppButton
            label="Imprimir"
            className="ml-1 row-expansion-button"
            onClick={async () => await props.downloadTermoEntregaSaida(data)}
            disabled={data.entregaAutorizada === 1 ? true : false}
          />
          }
        </div>
      </div>
    </div>
  )
}

export const EntregaVeiculoList = (propsComp) => {
  const columns = createColumns([
    { field: 'codigo_neg', header: 'Neg' },
    { field: 'nomecliente', header: 'Cliente' },
    { field: 'desmo1_vei', header: 'Veículo' },
    { field: 'situacao', header: 'Financeiro' },
    { field: 'entregaautorizada', header: 'Autorização', customBody: getAutorizado, customClassName: getDescricaoAutorizacaoClassName },
    { field: 'nomevendedor', header: 'Vendedor', enabled: false },
    { field: 'dataAprovacaoNeg', header: 'Aprovação', customBody: getDataAprovacao, enabled: false },
    { field: 'dahent_nve', header: 'Entrega', customBody: getDataEntrega },
    { field: 'dahpreent_nve', header: 'Previsão', customBody: getDataPrevisao }
  ])

  const history = useHistory()

  const {
    state,
    setState,
    onPageChange,
    onSelect,
    onFilter,
    handleRowExpansion
  } = useList(
    GerenciamentoEstoqueVeiculoModel,
    EntregaVeiculoService,
    '',
    'codigo_neg',
    undefined,
    undefined,
    {
      expandedRows: null,
      dadosVeiculo: [],
      visibleConfirmacao: false,
      mostrarBotaoEntregue: false,
      id: null,
      columns
    }
  )

  useEffect(async () => {
    const { id } = propsComp.match.params
    let { filter } = state

    if (id) {
      filter.descricaoFiltro = id
      await setState((prevState) => ({ ...prevState, filter, id }))
      await onFilter(filter)
    }
  }, [propsComp.match.params])

  const showConfirmacaoModal = (visibleConfirmacao) => {
    if (visibleConfirmacao) {
      return (
        <Confirm
          visible={state.visibleConfirmacao}
          onConfirm={async () => {
            await downloadTermoEntregaSaida(state.dadosVeiculo)
            await setState((prevState) => ({ ...prevState, visibleConfirmacao: false }))
          }}
          onCancel={async () => await setState((prevState) => ({ ...prevState, visibleConfirmacao: false }))}
          title="Imprimir termo de entrega"
          description="Gostaria de realizar a impressão do termo de entrega?"
        />
      )
    }
  }

  const onSelectOption = async (option) => {
    let { filter, mostrarBotaoEntregue } = state
    const { id } = propsComp.match.params
    await setState((prevState) => ({ ...prevState, opcaoSelecionada: option }))

    if (option === 't') {
      filter.entregue = false
      mostrarBotaoEntregue = false
      if (id) {
        filter.descricaoFiltro = id
      }
    }

    if (option === 'd') {
      filter.entregue = true
      mostrarBotaoEntregue = true
      if (id) {
        filter.descricaoFiltro = id
      }
    }

    await setState((prevState) => ({ ...prevState, filter, mostrarBotaoEntregue }))
    await onFilter()
  }

  async function downloadTermoEntregaSaida (data) {
    const placha = formatPlacha(data.placa_vei, data.chassi_vei)
    let pdf

    try {
      pdf = await ReportService.getTermoEntregaSaida(data.codigo_neg, data.codigo_nve)

      CommonHelper.openFile('application/pdf', pdf, `termo-entrega-saida-${placha}.pdf`)
    } catch (error) {
      showErrorMessage(error.message || `Ocorreu um erro inesperado ao tentar imprimir o termo de entrega para o veículo: ${placha}!`)
    }
  }

  async function entregarVeiculo (data) {
    try {
      await EntregaVeiculoService.entregarVeiculo(data)
      await onFilter()
      showSuccessMessage('Veículo entregue!')
      await setState((prevState) => ({ ...prevState, visibleConfirmacao: true, dadosVeiculo: data }))
      limparIdDaRota()
    } catch (error) {
      showErrorMessage(error.message || `Ocorreu um erro inesperado ao tentar entrega o veículo: ${data.placha}`)
    }
  }

  async function cancelarEntrega (data) {
    try {
      await EntregaVeiculoService.cancelarEntrega(data)
      await onFilter()
      showSuccessMessage('Entrega cancelada!')
    } catch (error) {
      showErrorMessage(error.message || `Ocorreu um erro inesperado ao tentar cancelar a entrega para o veículo: ${data.placha}`)
    }
  }

  async function autorizarEntrega (data) {
    try {
      let { filter, id } = state
      await EntregaVeiculoService.autorizarEntrega(data)
      filter.descricaoFiltro = id
      await setState((prevState) => ({ ...prevState, filter }))
      await onFilter()
      showSuccessMessage('Entrega autorizada!')
    } catch (error) {
      showErrorMessage(error.message || `Ocorreu um erro inesperado ao tentar autorizar a entrega para o veículo: ${data.placha}`)
    }
  }

  async function cancelarAutorizacaoEntrega (data) {
    try {
      let { filter, id } = state
      await EntregaVeiculoService.cancelarAutorizacaoEntrega(data)
      filter.descricaoFiltro = id
      await setState((prevState) => ({ ...prevState, filter }))
      await onFilter()
      showSuccessMessage('Autorização de entrega cancelada!')
    } catch (error) {
      showErrorMessage(error.message || `Ocorreu um erro inesperado ao tentar cancelar a autorização de entrega para o veículo: ${data.placha}`)
    }
  }

  const limparIdDaRota = () => {
    history.push('/entrega-veiculo')
  }

  const onChangeBusca = async (e) => {
    let { filter } = state
    const valor = e.target.value
    const name = e.target.name
    filter[name] = valor

    await setState((prevState) => ({ ...prevState, filter }))
  }

  return (
    <Page>
      {!CommonHelper.isDesktop() && (
        <ScrollTop threshold={1200} />
      )}
      <div className="flex justify-content-between align-items-center page-header">
        <h4>Entrega de veículos</h4>
      </div>
      <div className="table-options-container">
        <div className="table-options">
          <div className={classNames({ active: state.opcaoSelecionada === 't' }, 'filter-option')}
            onClick={() => onSelectOption('t')}>
            <span className="option-label">Pendentes</span>
            <div className="option-quantity">{state?.meta?.quantidadePendentes}</div>
          </div>
          <div className={classNames({ active: state.opcaoSelecionada === 'd' }, 'filter-option')}
            onClick={() => onSelectOption('d')}>
            <span className="option-label">Entregues</span>
            <div className="option-quantity">{state?.meta?.quantidadeEntregue}</div>
          </div>
        </div>
        <div className="table-preferences">
          <PreferencesButton onClick={async () => await setState((prevState) => ({ ...prevState, visiblePreferencesModal: true }))} />
        </div>
      </div>
      <Container>
        <DataList
          data={state.list}
          selected={state.selected}
          onSelect={e => onSelect(e)}
          onRowClick={(e) => handleRowExpansion(e.data)}
          rows={state.rows}
          totalRecords={state.totalRecords}
          first={state.first}
          onPage={onPageChange}
          expandedRows={state.expandedRows}
          rowExpansionTemplate={rowExpansionTemplate}
          page={state.page}
          showFilter={true}
          filterPlaceholder='Digite e pressione "Enter" para procurar veículos'
          filterName="descricaoFiltro"
          onFilter={onFilter}
          filterValue={state.filter.descricaoFiltro}
          expandedFiltersTemplate={expandedFiltersTemplate}
          filterOnChange={onChangeBusca}
          customMaxHeight={'unset'}

          // * Passe por props as funções que serão usadas no expandedFiltersTemplate/rowExpansionTemplate
          history={history}
          state={state}
          setState={setState}
          autorizarEntrega={autorizarEntrega}
          cancelarAutorizacaoEntrega={cancelarAutorizacaoEntrega}
          cancelarEntrega={cancelarEntrega}
          entregarVeiculo={entregarVeiculo}
          downloadTermoEntregaSaida={downloadTermoEntregaSaida}
        >
          {getColunasHabilitadas(columns, 'entregaVeiculoTable')}
        </DataList>
      </Container>
      {showConfirmacaoModal(state.visibleConfirmacao)}
      <TablePreferences
        title="Preferências da tabela de entrega de veículos"
        tableName="entregaVeiculoTable"
        columns={columns}
        visible={state.visiblePreferencesModal}
        hide={async () => await setState((prevState) => ({ ...prevState, visiblePreferencesModal: false }))}
      />
    </Page>
  )
}

export default EntregaVeiculoList
