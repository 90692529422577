import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import { baseForm } from '../../../../../../../components/utils/BaseForm'
import { getPendenciaDTO, postPendenciaDTO } from '../../../../../../../dtos/processo/PendenciaDTO'
import { PendenciaProcessoValidator } from '../validator'
import PendenciasModal from '..'
import DataListForm from '../../../../../../../components/layout/DataListForm/DataListForm'

function DadosPendencias ({
  form,
  edicaoHabilitada,
  camposObrigatorios
}) {
  const [visiblePendenciaModal, setVisiblePendenciaModal] = useState(false)
  const [pendenciaSelecionada, setPendenciaSelecionada] = useState(null)

  function insertOrUpdatePendencia () {
    if (pendenciaSelecionada) {
      editarPendencia()
    } else {
      adicionarPendencia()
    }

    pendenciaForm.resetForm()
    hidePendenciaModal()
  }

  function adicionarPendencia () {
    const pendencias = form.values.pendencias

    pendencias.push(postPendenciaDTO(pendenciaForm.values))

    form.setFieldValue('pendencias', pendencias)
  }

  function editarPendencia () {
    const pendencias = form.values.pendencias

    const indexPendencia = pendencias.indexOf(pendenciaSelecionada)

    pendencias.splice(indexPendencia, 1, postPendenciaDTO(pendenciaForm.values))

    form.setFieldValue('pendencias', pendencias)
  }

  const pendenciaForm = baseForm({
    initialValues: getPendenciaDTO(),
    validationSchema: PendenciaProcessoValidator,
    onSubmit: insertOrUpdatePendencia
  })

  useEffect(async () => {
    if (pendenciaSelecionada) {
      pendenciaForm.setValues(getPendenciaDTO(pendenciaSelecionada))
    } else {
      pendenciaForm.setValues(getPendenciaDTO())
    }
  }, [pendenciaSelecionada])

  const abrirModalAdicionarPendenciaAtalho = (event) => {
    if (event.altKey && event.key === 'a' && edicaoHabilitada) {
      setVisiblePendenciaModal(true)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', abrirModalAdicionarPendenciaAtalho)
    return () => {
      window.removeEventListener('keydown', abrirModalAdicionarPendenciaAtalho)
    }
  }, [edicaoHabilitada])

  const columns = [
    {
      field: 'descri_etp',
      header: 'Pendência',
      className: 'text-left pl-4'
    },
    {
      field: 'exiarq_etp',
      header: 'Exige anexo?',
      className: 'text-left pl-4',
      format: (data) => data ? 'Sim' : 'Não'
    },
    {
      field: 'perign_etp',
      header: 'Permite ignorar',
      className: 'text-left pl-4',
      format: (data) => data ? 'Sim' : 'Não'
    },
    {
      field: 'descri_are',
      header: 'Origem do anexo',
      className: 'text-left pl-4'
    }
  ]

  function hidePendenciaModal () {
    setPendenciaSelecionada(null)
    setVisiblePendenciaModal(false)
    pendenciaForm.resetForm()
  }

  return (
    <div className="formgrid grid fluid">
      <div className='w-full flex justify-content-end'>
        <Button
          label="Adicionar pendência"
          className='mb-2 mr-2'
          icon="pi pi-plus"
          onClick={() => setVisiblePendenciaModal(true)}
          disabled={!edicaoHabilitada}
          tooltip='ALT + "A" para abrir o cadastro'
          tooltipOptions={{ position: 'bottom' }}
        />
      </div>
      <div className="field col-12">
        <DataListForm
          value={form.values.pendencias}
          columns={columns}
          emptyMessage="Nenhuma pendência cadastrada."
          onRowSelect={async (e) => {
            await setPendenciaSelecionada(e.data)
            setVisiblePendenciaModal(true)
          }}
        />
      </div>
      {!!visiblePendenciaModal && <PendenciasModal
        visible={visiblePendenciaModal}
        onHide={() => hidePendenciaModal()}
        camposObrigatorios={camposObrigatorios}
        pendenciaForm={pendenciaForm}
        pendenciaSelecionada={pendenciaSelecionada}
        edicaoHabilitada={edicaoHabilitada}
        form={form}
        setPendencias={(pendencias) => {
          form.setFieldValue('pendencias', pendencias)
        }}
      />}
    </div>
  )
}

export default DadosPendencias
