import * as Yup from 'yup'

export const PendenciaProcessoValidator = Yup.object().shape({
  descri_etp: Yup.string()
    .nullable()
    .required('Campo descrição é obrigatório!'),
  origem_anexo: Yup.object()
    .shape({
      codigo_are: Yup.number()
        .nullable()
        .required('Campo origem do anexo é obrigatório!')
    })
    .nullable()
})
