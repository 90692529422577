import './_pagarConsignados.scss'

import { formatDate, formatToCurrency } from '../../../../helpers/formaters'
import AppButton from '../../../../components/layout/AppButton'
import Container from '../../../../components/layout/Container'
import DataList from '../../../../components/layout/DataList'
import List from '../../../../classes/List'
import { NegociacaoModel } from '../../../../models/vendas/negociacao/NegociacaoModel'
import PagarConsignadosService from '../../../../services/vendas/negociacao/PagarConsignadosService'
import Page from '../../../../components/layout/Page'
import PreferencesButton from '../../../../components/utils/PreferencesButton'
import React from 'react'
import TablePreferences from '../../../../components/utils/TablePreferences'
import classNames from 'classnames'
import { createColumns, getColunasHabilitadas } from '../../../../helpers/tableConfigs'
import ConjuntoVeiculoService from '../../../../services/cadastro/veiculo/ConjuntoVeiculoService'
import CommonHelper from '../../../../helpers/CommonHelper'
import VeiculoModal from '../../../../forms/cadastro/veiculo/veiculo/VeiculoModal'
import { showErrorMessage } from '../../../../components/utils/Message'

export class PagarConsignadosList extends List {
  constructor (props) {
    super(
      props,
      NegociacaoModel,
      PagarConsignadosService,
      'negociacao',
      'codigo_neg'
    )

    this.state = {
      ...this.state,
      expandedRows: null,
      visiblePreferencesModal: false,
      conjuntoVeiculo: null,
      visibleVeiculoModal: false,
      veiculoSelecionado: null
    }

    this.setColumns(createColumns([
      { field: 'codigo_neg', header: 'Número', width: '10%' },
      { field: 'nomemp_neg', header: 'Empresa', width: '15%' },
      { field: 'descrivei_neg', header: 'Veículo Consignado', width: '25%' },
      { field: 'nompro_neg', header: 'Proprietário', width: '15%' },
      { field: 'datnegven_neg', header: 'Data da Venda', width: '10%', customBody: this.getDataDaVenda },
      { field: 'valnegven_neg', header: 'Vendido Por', width: '10%', customBody: this.getValorVenda },
      { field: 'valnegcon_neg', header: 'Consignado Por', width: '10%', customBody: this.getValorConsig }
    ]))
  }

  getDataDaVenda (data) {
    return formatDate(data.datnegven_neg)
  }

  getValorVenda (data) {
    return formatToCurrency(data.valnegven_neg, true)
  }

  getValorConsig (data) {
    return formatToCurrency(data.valnegcon_neg, true)
  }

  handlePagarConsignado = (data) => {
    localStorage.setItem('negociacao_' + data.codigo_neg, JSON.stringify({ pagandoConsignado: true, vendidoPor: data.valnegven_neg }))
    this.onView(data)
  }

  async onRowClick (data) {
    try {
      const negociacaoSelecionada = data

      const conjuntoVeiculo = data.codigoConjunto ? await ConjuntoVeiculoService.getByCodigo(data.codigoConjunto) : null

      this.setState({ negociacaoSelecionada, conjuntoVeiculo })
      this.handleRowExpansion(negociacaoSelecionada)
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao buscar o conjunto do veículo')
    }
  }

  render () {
    const state = this.state
    const columns = this.columns

    const rowExpansionTemplate = (data) => {
      const { conjuntoVeiculo } = this.state

      return (
        <div className={'pagar-consignados row-expansion grid justify-content-' + (CommonHelper.isDesktop() ? 'between' : 'center')}>
          {conjuntoVeiculo && (
            <span>
              <div className="pagar-consignados veiculos-conjunto">Veículos do conjunto:</div>
              {conjuntoVeiculo.itensConjunto.map((itemConjunto, index) => {
                return (
                  <div
                    key={index}
                    className="pagar-consignados venda-link underline"
                    onClick={() => this.setState({ visibleVeiculoModal: true, veiculoSelecionado: itemConjunto.veiculo })}
                  >
                    {itemConjunto.veiculo.descricaoVeiculo}
                  </div>
                )
              })}
            </span>
          )}
          <span className="pagar-consignados venda-link underline mt-2" onClick={() => this.onView({ codigo_neg: data.codnegven_neg })}>
            Venda {data.codnegven_neg} realizada para {data.nomclinegven_neg}
          </span>
          <AppButton
            label="Pagar Consignação"
            className="pagar-consignados button mt-2"
            onClick={() => this.handlePagarConsignado(data)}
          />
        </div>
      )
    }

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Pagar Consignados</h4>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: state.opcaoSelecionada === 't' }, 'filter-option')}>
              <span className="option-label">Pendentes</span>
              <div className="option-quantity">{state.totalRecords}</div>
            </div>
          </div>
          <div className="table-preferences">
            <PreferencesButton
              onClick={() => this.setState({ visiblePreferencesModal: true })}
            />
          </div>
        </div>
        <Container>
          <DataList
            data={state.list}
            selected={state.selected}
            rows={state.rows}
            onSelect={this.onSelect}
            onRowClick={(e) => this.onRowClick(e.data)}
            totalRecords={state.totalRecords}
            first={state.first}
            onPage={this.onPageChange}
            page={state.page}
            showFilter={true}
            filterPlaceholder="Procurar consignação pendente"
            filterName="descricaoFiltro"
            responsive={true}
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
            expandedRows={state.expandedRows}
            rowExpansionTemplate={rowExpansionTemplate}
            customMaxHeight={'unset'}
          >
            {getColunasHabilitadas(columns, 'pagarConsignados')}
          </DataList>
        </Container>
        <TablePreferences
          title="Preferências da tabela de negócios"
          tableName="pagarConsignados"
          columns={columns}
          visible={state.visiblePreferencesModal}
          hide={() => this.setState({ visiblePreferencesModal: false })}
        />
        <VeiculoModal
          visible={state.visibleVeiculoModal}
          hide={() => this.setState({ visibleVeiculoModal: false })}
          primaryKey={state.veiculoSelecionado?.codigo_vei}
          apenasVisualizacao
        />
      </Page>
    )
  }
}
