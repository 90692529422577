import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import React, { useState } from 'react'
import DataList from '../../../../../components/layout/DataList'
import { showWarnMessage } from '../../../../../components/utils/Message'
import ResponsiveColumn from '../../../../../components/utils/ResponsiveColumn'
import { postConveniadoDTO } from '../../../../../dtos/cadastro/pessoa/ConveniadoDTO'
import ModalPessoaConvenio from './ModalPessoaConvenio'
import { Checkbox } from 'primereact/checkbox'
const DadosConvenioPej = ({
  form,
  edicaoHabilitada,
  camposObrigatorios
}) => {
  // * Estados para controle do modal e do conveniado selecionado para edição ou inserção.
  const [visibleModalPessoaConvenio, setVisibleModalPessoaConvenio] = useState(false)
  const [conveniadoSelecionado, setConveniadoSelecionado] = useState(null)
  const [edicao, setEdicao] = useState(null)

  // * Add conveniado se não esta na listagem
  function adicionarConveniado () {
    const conveniados = form.values.conveniados
    const jaAdicionado = conveniados.some(
      conveniado => conveniado.codigo_pes === conveniadoSelecionado.codigo_pes
    )

    // * Insere
    if (!jaAdicionado) {
      conveniados.push(postConveniadoDTO(conveniadoSelecionado))
    } else {
      showWarnMessage('Já existe essa pessoa adicionada!')
    }
  }

  // * Altera conveniado baseado no estado edicao
  function editarConveniado () {
    const conveniados = form.values.conveniados

    // * Busca o indice no array do objeto que está sendo editado
    // * baseado no codigo_pes
    // * que é setado ao abrir o modal pela listagem!
    const indexConveniadoSelecionado = conveniados.findIndex(
      (valor) => valor.codigo_pes === edicao.codigo_pes
    )

    // * Edita
    if (indexConveniadoSelecionado !== -1) {
      conveniados.splice(
        indexConveniadoSelecionado,
        1,
        postConveniadoDTO(conveniadoSelecionado)
      )
    } else {
      showWarnMessage('Erro ao atualizar representante!')
    }
  }

  // * Insere ou altera representando baseado se está na listagem ou não
  function insertOrUpdateConveniado () {
    !!edicao
      ? editarConveniado()
      : adicionarConveniado()

    // * Sempre reseta valores após edição ou inserção
    handleCloseModal()
  }

  // * Abre modal editar setando valores
  function handleOpenModalEditar (e) {
    setEdicao(e?.data)
    setConveniadoSelecionado(e?.data)
    setVisibleModalPessoaConvenio(true)
  }

  // * Fecha modal resetando valores
  function handleCloseModal () {
    setVisibleModalPessoaConvenio(false)
    setConveniadoSelecionado(null)
    setEdicao(null)
  }

  const handleChangeCheckbox = (e) => {
    form.setFieldValue(e.target.name, !form.values[e.target.name])
  }

  return (
    <div className="formgrid grid fluid">

      {/* LINHA DO BOTÃO ADICIONAR */}
      <div className='flex w-full justify-content-between items-center md:mb-3'>
        <div className="field checkbox-container col-5 md:mb-2 mt-0" >
          <Checkbox
            inputId="isconv_pej"
            name="isconv_pej"
            value={form.values.isconv_pej}
            onChange={handleChangeCheckbox}
            checked={form.values.isconv_pej}
            disabled={!edicaoHabilitada}
          />
          <label htmlFor="isconv_pej" className="p-checkbox-label mb-0 pl-2">
            Possui convenio com a empresa
          </label>
        </div>
        <Button
          label="Adicionar conveniado"
          className=" mr-2"
          icon="pi pi-plus"
          onClick={() => setVisibleModalPessoaConvenio(true)}
          disabled={!edicaoHabilitada}
        />
      </div>

      {/* LISTAGEM */}
      <div className="field col-12">
        <DataList
          data={form.values.conveniados}
          rows={form.values.conveniados.length}
          totalRecords={form.values.conveniados.length}

          page={1}
          first={1}
          responsive={true}
          showFilter={false}
          hidePaginator={true}

          selected={form.values.conveniados?.find(rep => rep.codigo_pes === edicao?.codigo_pes)}
          onRowSelect={handleOpenModalEditar}
          // * Tem que passar essa prop sem nada pra poder usar a de cima
          onSelect={() => { }}
        >
          <Column
            header="Código"
            body={(data) => (
              <ResponsiveColumn
                className="pl-2"
                column="Código"
                value={data.codigo_pes}
              />
            )}
            headerClassName="HeaderDataList"
            sortable={true}
            field="codigo_pes"
          />
          <Column
            header="Conveniados"
            body={(data) => (
              <ResponsiveColumn
                className="pl-2"
                column="Conveniados"
                value={data.nomraz_pes}
              />
            )}
            headerClassName="HeaderDataList"
            sortable={true}
            field="nomraz_pes"
          />
          <Column
            header="Ativo?"
            body={(data) => (
              <ResponsiveColumn
                className="pl-2"
                column="Ativo?"
                value={data.ativo_cvp ? 'Sim' : 'Não'}
              />
            )}
            headerClassName="HeaderDataList"
            sortable={true}
            field="ativo_cpv"
          />
        </DataList>

        {/* MODAL PARA ADD / EDITAR / EXCLUIR CONVENIADO */}
        <ModalPessoaConvenio
          camposObrigatorios={camposObrigatorios}
          visible={visibleModalPessoaConvenio}
          onHide={handleCloseModal}
          edicaoHabilitada={edicaoHabilitada}
          edicao={edicao}
          insertOrUpdate={insertOrUpdateConveniado}
          form={form}
          selected={conveniadoSelecionado}
          setSelected={setConveniadoSelecionado}
        />
      </div>

    </div>
  )
}

export default DadosConvenioPej
