import { postPendenciaUsoDTO } from '../../../dtos/processo/PendenciaDTO'
import ServiceBase from '../../base/ServiceBase'

export default class EtapaTipoPendenciaUsoService {
  static async save (pendencia) {
    return await ServiceBase.postBase('pendencia-uso/', postPendenciaUsoDTO(pendencia))
  }

  static async get (codigo) {
    return await ServiceBase.getBase(`pendencia-uso/${codigo}`)
  }

  async save (pendencia) {
    return await ServiceBase.postBase('pendencia-uso/', postPendenciaUsoDTO(pendencia))
  }

  async get (codigo) {
    return await ServiceBase.getBase(`pendencia-uso/${codigo}`)
  }

  static async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`pendencia-uso?${query}&page=${page}&perPage=${perPage}`)
  }

  static async filter (query, page, perPage) {
    return await ServiceBase.getBase(`pendencia-uso?${query}&page=${page}&perPage=${perPage}`)
  }

  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`pendencia-uso?${query}&page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`pendencia-uso?${query}&page=${page}&perPage=${perPage}`)
  }

  async baixar (codigo) {
    return await ServiceBase.postBase(`pendencia-uso/baixar/${codigo}`)
  }

  async ignorar (codigo) {
    return await ServiceBase.postBase(`pendencia-uso/ignorar/${codigo}`)
  }

  static async baixar (codigo) {
    return await ServiceBase.postBase(`pendencia-uso/baixar/${codigo}`)
  }

  static async ignorar (codigo) {
    return await ServiceBase.postBase(`pendencia-uso/ignorar/${codigo}`)
  }
}
