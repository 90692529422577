import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import AppButton from '../../../../../components/layout/AppButton'
import DropdownMenu from '../../../../../components/layout/DropdownButton/DropdownMenu'
import Page from '../../../../../components/layout/Page'
import TabMenuContainer from '../../../../../components/layout/TabMenuContainer/TabMenuContainer'
import TabMenuPage from '../../../../../components/layout/TabMenuContainer/TabMenuPage'
import { baseForm } from '../../../../../components/utils/BaseForm'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../../../../../components/utils/Message'
import { getFormaPagamentoDTO } from '../../../../../dtos/vendas/negociacao/FormaPagamentoDTO'
import { getNegociacaoDTO } from '../../../../../dtos/vendas/negociacao/NegociacaoDTO'
import CommonHelper from '../../../../../helpers/CommonHelper'
import { formatDateTime, formatDateToAmerican, formatPlaca, formatPlacha } from '../../../../../helpers/formaters'
import { decrementActiveLoadingRequests, generateMenuSideBar, incrementActiveLoadingRequests, startLoading, stopLoading } from '../../../../../redux/actions'
import { dispatch, getStore } from '../../../../../redux/store'
import DocumentosService from '../../../../../services/cadastro/documento/DocumentosService'
import ContaBancariaService from '../../../../../services/cadastro/financeiro/ContaBancariaService'
import PixGeradoService from '../../../../../services/cadastro/financeiro/integracao/PixGeradoService'
import RestricaoService from '../../../../../services/cadastro/pessoa/RestricaoService'
import AvaliacaoService from '../../../../../services/cadastro/veiculo/AvaliacaoService'
import VeiculoService from '../../../../../services/cadastro/veiculo/VeiculoService'
import ConfigService from '../../../../../services/configuracao/ConfigService'
import FormaPagamentosService from '../../../../../services/vendas/negociacao/FormaPagamentosService'
import NegociacaoService from '../../../../../services/vendas/negociacao/NegociacaoService'
import { venderVeiculo } from '../../../../../views/vendas/persistencia/VenderVeiculo'
import PessoaFisicaModal from '../../../../cadastro/pessoa/pessoaFisica/PessoaFisicaModal'
import PessoaJuridicaModal from '../../../../cadastro/pessoa/pessoaJuridica/PessoaJuridicaModal'
import VeiculoModal from '../../../../cadastro/veiculo/veiculo/VeiculoModal'
import MotivoCanModal from '../../../motivocan/MotivoCanModal'
import DadosPrincipaisNeg from '../NegociacaoPages/DadosPrincipaisNeg'
import ProcuradoresNeg from '../NegociacaoPages/DadosProcuradores'
import DadosVeiculosConjuntoNeg from '../NegociacaoPages/DadosVeiculosConjuntoNeg'
import DocumentosNeg from '../NegociacaoPages/DocumentosNeg'
import ImpressoesNegModal from '../NegociacaoPages/ImpressoesNegModal'
import ObservacoesNeg from '../NegociacaoPages/ObservacoesNeg'
import PagamentoNeg from '../NegociacaoPages/PagamentoNeg'
import { NegociacaoValidator } from '../NegociacaoValidator'
import './_negociacaoForm.scss'

const NegociacaoForm = ({ model, isNew }) => {
  // * Essa função deve ficar aqui em cima por conta do onSubmit do form
  const salvarNegociacao = async () => {
    try {
      const negociacaoService = new NegociacaoService()
      const negociacaoSalva = await negociacaoService.save(form.values)
      const negAtualizada = getNegociacaoDTO(await negociacaoService.get(negociacaoSalva.codigo_neg))
      setNegociacao(negAtualizada)
      if (negociacaoSalva.codigo_neg && isNew) {
        history.push(`/negociacao/${negAtualizada.codigo_neg}`)

        form.setFieldValue('codigo_neg', negAtualizada.codigo_neg)
      }

      if (pagandoConsignado) {
        //dispatch(await atualizarQuantidadeConsignacoesPendentePagamento())
        dispatch(generateMenuSideBar())
      }

      //dispatch(await atualizarQuantidadeNegociacoesPendenteAprovacao())
      dispatch(generateMenuSideBar())

      localStorage.removeItem('negociacao_' + form.values.codigo_neg)
      setReaberta(false)
      setPagandoConsignado(false)
      setEdicaoHabilitada(false)

      form.setFieldValue('status_neg', negAtualizada.status_neg)
      form.setFieldValue('descri_sne', negAtualizada.descri_sne)
      form.setFieldValue('datfin_neg', negAtualizada.datfin_neg)
      form.setFieldValue('dat_neg', negAtualizada.dat_neg)
      form.setFieldValue('codapp_neg', negAtualizada.codapp_neg)

      showSuccessMessage('Negociação fechada com sucesso!')
      setVisibles((prev) => ({ ...prev, visibleBtnAcoes: true }))
      setVisibles((prev) => ({ ...prev, visibleImpressoesNegModal: true }))
      await form.setValues(negAtualizada)
      return negAtualizada
    } catch (error) {
      showErrorMessage(error.message || 'Erro ao fechar negociação')
    }
  }

  //#region * VARIAVEIS

  const form = baseForm({
    initialValues: model,
    onSubmit: salvarNegociacao,
    validationSchema: NegociacaoValidator
  })

  const [edicaoHabilitada, setEdicaoHabilitada] = useState(isNew)
  const [operacaoNeg, setOperacaoNeg] = useState(form.values.operacaoNeg)
  const [isRepasse, setIsRepasse] = useState(form.values.negociacaoVeiculo?.repass_nve)

  const [visibles, setVisibles] = useState({
    visibleVeiculoModal: false,
    visiblePessoaFisicaModal: false,
    visiblePessoaJuridicaModal: false,
    visibleImpressoesNegModal: false,
    visibleMotivoCanModal: false,
    visibleBtnAcoes: false
  })

  const [pagandoConsignado, setPagandoConsignado] = useState(false)
  const [reaberta, setReaberta] = useState(false)
  const [desativarBotaoFecharVenda, setDesativarBotaoFecharVenda] = useState(false)

  const [totalValorDocumentos, setTotalValorDocumentos] = useState(0)
  const [motivoCancelamento, setMotivoCancelamento] = useState('')
  const [statusNegociacao, setStatusNegociacao] = useState('')

  const [veiculoKeyModal, setVeiculoKeyModal] = useState(undefined)

  const [negociacao, setNegociacao] = useState([])
  const [proprietario, setProprietario] = useState([])

  const history = useHistory()

  const veiculoNve = form.values.negociacaoVeiculo?.veiculo
  const placha = veiculoNve?.placa_vei ? formatPlaca(veiculoNve?.placa_vei) : (veiculoNve?.chassi_vei)?.substring((veiculoNve?.chassi_vei.length) - 8)
  const marcaModelo = `${veiculoNve?.modelo?.marca?.descri_mar}${veiculoNve?.modelo?.descri_mod ? '/' : ''}${veiculoNve?.modelo?.descri_mod}`
  const anofabAnomod = `${veiculoNve?.anofab_vei}${veiculoNve?.anomod_vei ? '/' : ''}${veiculoNve?.anomod_vei}`

  const NEG_CANCELADA = 2
  const NEG_FECHADA = 3
  const NEG_CONSIGNADA = 4

  //#endregion

  //#region * FUNÇÕES HANDLES

  const isConsignado = () => {
    return operacaoNeg === 'Consignação'
  }

  const isVenda = () => {
    return operacaoNeg === 'Venda'
  }

  const isTroca = () => {
    return form.values.operac_neg === 98
  }

  const isNegCancelada = () => {
    return form.values.status_neg === 2
  }

  const isNegFechada = () => {
    return form.values.status_neg === 3
  }

  const isNegWeb = () => {
    return form.values.codapp_neg === 2
  }

  const isClientePessoaFisica = () => {
    if (form.values.pessoa?.cgccpf_pes?.length === 14)
      return false
    return true
  }

  const handleMostrarTodasAbas = () => {
    return (!isConsignado() || pagandoConsignado || form.values.status_neg === 3)
  }

  const handlePodeAcessarTelaEntrega = async () => {
    form.setFieldValue('podeAcessarMenu', await RestricaoService.getPermissaoMenu(53))
  }

  const handleRedirecionarParaEntregaVeiculo = () => {
    history.push(`/entrega-veiculo/${form.values.codigo_neg}`)
  }

  const handleSelectVeiculo = (primaryKey) => {
    setVeiculoKeyModal(primaryKey)
    setVisibles((prev) => ({ ...prev, visibleVeiculoModal: true }))
  }

  const handleAtualizaTotalDocumentos = () => {
    let valorTotal = 0.00

    form.values.documents.forEach(documento => {
      if (documento.respag_ndo === 1)
        valorTotal += documento.valor_ndo
    })

    setTotalValorDocumentos(valorTotal)
  }

  const handleCancelarEdicao = async () => {
    localStorage.removeItem('negociacao_' + form.values.codigo_neg)
    setReaberta(false)
    setEdicaoHabilitada(false)
  }

  const adicionarDocumentoAutomatico = async (negociacao) => {
    const { documents } = form.values
    const diasSomarDataAtual = await ConfigService.getValor(3377)
    for (const documentos of documents) {
      if (documentos.addaut_ndo) return false
    }

    // * Se não for neg compra, já retorna para não executar a request abaixo.
    if (!negociacao.veiculoCompra) return

    // * Adicionar documento referente a pagamento de royalties na compra de veículos?
    ConfigService.getValor(3325).then(config => {
      if (config === true) {
        let data = new Date()
        let dataAtual = data.toDateString() // adiciona a data de emissão
        let dataPagamentoTit = CommonHelper.ajustarDataParaDiaUtil(diasSomarDataAtual) // adiciona a data de pagamento
        let documentoAutomatica = {
          coddes_ndo: negociacao.documentoAutomatico.pessoaRecebimentoTitulo?.codigo_pes || form.values?.pessoa.codigo_pes,
          descri_tdo: negociacao.documentoAutomatico.descri_tdo,
          codtdo_ndo: negociacao.documentoAutomatico.codigo_tdo,
          codvei_ndo: negociacao.veiculoCompra.codigo_vei,
          datemi_ndo: dataAtual,
          datpag_ndo: dataPagamentoTit,
          destinoPagar: negociacao.documentoAutomatico.pessoaPagamentoTitulo,
          destinoReceber: negociacao.documentoAutomatico.pessoaRecebimentoTitulo,
          idx: 0,
          obrigaCusto: negociacao.documentoAutomatico.obrcus_tdo,
          obslan_ndo: 'Royalties',
          obsrec_ndo: '',
          respag_ndo: 2,
          tipoDocumento: { ...negociacao.documentoAutomatico },
          valcus_ndo: negociacao.valorDocumentoAutomatico,
          valor_ndo: 0.00,
          addaut_ndo: true,
          veiculo: negociacao.veiculoCompra || veiculosConjunto
        }
        const { documents } = form.values
        documents.push({ ...documentoAutomatica, idx: documents.length })
      }
    }).catch(error => {
      console.error(error)
    })
  }

  const adicionarPixAutomatico = async (veiculo) => {
    const usaIntegracaoVaBank = await ConfigService.getValor(3401)
    if (!usaIntegracaoVaBank) return

    let pixPagos = []
    pixPagos = await PixGeradoService.getPixByNveESituacao(veiculo.nvecom_vei, 2)

    // * Acessa as formas de pagamento existentes no formulário
    const { foPagNegs } = form.values

    const contaVaBank = await ConfigService.getValor(3407)
    if (contaVaBank) {
      const filtro = `codigo_con=${contaVaBank}`
      const contasBancarias = await ContaBancariaService.filterContasBancarias(filtro)
      const tipoFormaPagamento = await FormaPagamentosService.filterTiposPagamentos('descri_tfp=PIX')

      // * Remove PIX com pagAutomatico antes de inserir novos
      const foPagNegsFiltrados = foPagNegs.filter(fopag => !fopag.autpag_fpn)
      form.setFieldValue('foPagNegs', foPagNegsFiltrados)

      // * Cria e adiciona os novos PIX
      const novosPix = pixPagos.map((pix) => {
        const novoFoPag = getFormaPagamentoDTO({ retornoOptions: [] })

        novoFoPag.veiculo = veiculo
        novoFoPag.banco = contasBancarias[0]
        novoFoPag.bancos = contasBancarias[0]
        novoFoPag.contasBancarias = contasBancarias[0]
        novoFoPag.conta_fpn = contasBancarias[0].numero_cob
        novoFoPag.ispix_fpn = true
        novoFoPag.valor_fpn = pix.valor_pix
        novoFoPag.tipoFormaPagamento = tipoFormaPagamento[0]
        novoFoPag.descricaoFormaPagamento = tipoFormaPagamento[0]?.descri_tfp
        novoFoPag.valorVeiculo = pix.valor_pix
        novoFoPag.numage_fpn = contasBancarias[0].numage_cob
        novoFoPag.nomtit_fpn = contasBancarias[0].nomeTitular
        novoFoPag.pagvei_fpn = pix.valor_pix
        novoFoPag.numban_fpn = contasBancarias[0].codban_cob
        novoFoPag.autpag_fpn = true
        novoFoPag.revpag_fpn = false
        novoFoPag.datcom_fpn = formatDateToAmerican(new Date())
        novoFoPag.data_fpn = formatDateToAmerican(new Date())
        novoFoPag.codvei_fpn = veiculo.codigo_vei
        return novoFoPag
      })

      // Atualiza o estado com os novos PIX
      form.setFieldValue('foPagNegs', [...foPagNegsFiltrados, ...novosPix])
    } else {
      throw new Error('Informe uma conta financeira na configuração 3407!')
    }
  }

  const showPessoaModal = () => {
    if (isClientePessoaFisica()) {
      return setVisibles((prev) => ({ ...prev, visiblePessoaFisicaModal: true }))
    }

    setVisibles((prev) => ({ ...prev, visiblePessoaJuridicaModal: true }))
  }

  const showRestanteVeiculos = (veiculosRestantes) => {
    return veiculosRestantes.map((veiculoNve, index) => {
      return (
        <>
          <span className="neg-row-value underline cursor-pointer pl-1" onClick={() => handleSelectVeiculo(veiculoNve.codvei_nve)}>
            {veiculoNve.veiculo.placa_vei
              ? formatPlaca(veiculoNve.veiculo?.placa_vei)
              : (veiculoNve.veiculo?.chassi_vei)?.substring(veiculoNve.veiculo?.chassi_vei?.length - 8)}
          </span>
          {(index < (veiculosRestantes.length - 1)) && (
            <>
              ,
            </>
          )}
        </>
      )
    })
  }

  const validateTabChange = () => {
    if ((!veiculoNve?.codigo_vei && !(form.values.veiculosConjunto.length > 0)) || !form.values.pessoa?.codigo_pes) {
      showWarnMessage('Informe o cliente e o veículo para prosseguir')
      return false
    }

    return true
  }

  const itensAcao = (isNegWeb() && form.values.status_neg)
    ? isNegCancelada()
      ? [{
        label: 'Reabrir',
        command: () => reabrirNegociacao()
      }]
      : [{
        label: 'Cancelar',
        command: () => setVisibles((prev) => ({ ...prev, visibleMotivoCanModal: true }))
      },
      isVenda() && form.values?.podeAcessarMenu ? {
        label: 'Entregar',
        command: () => handleRedirecionarParaEntregaVeiculo()
      } : '',
      {
        label: 'Imprimir',
        command: () => setVisibles((prev) => ({ ...prev, visibleImpressoesNegModal: true }))
      }]
    : isNegFechada()
      ? [{
        label: 'Imprimir',
        command: () => setVisibles((prev) => ({ ...prev, visibleImpressoesNegModal: true }))
      },
      isVenda() && form.values?.podeAcessarMenu ? {
        label: 'Entregar',
        command: () => handleRedirecionarParaEntregaVeiculo()
      } : '']
      : [{
        label: 'Imprimir',
        command: () => setVisibles((prev) => ({ ...prev, visibleImpressoesNegModal: true }))
      }]

  //#endregion

  //#region * FUNÇÕES SUBMIT

  const empresaLogada = JSON.parse(localStorage.getItem('company'))

  const finalizarNegociacao = async () => {
    setDesativarBotaoFecharVenda(true)

    try {
      const errors = await form.validateForm(form.values)
      let mensagem
      if (Object.keys(errors).length !== 0) {
        const fields = Object.values(errors)[0]
        switch (typeof fields) {
        case 'string':
          showWarnMessage(fields)
          return
        case 'object':
          const field = Object.values(fields)[0]
          mensagem = typeof field === 'object' ? Object.values(field)[0] : Object.values(field)
          showWarnMessage(mensagem)
          return
        default:
          mensagem = Object.values(field)
          showWarnMessage(mensagem)
          return
        }
      } else {
        const empresaOferta = localStorage.getItem('empresaOferta')
        const ofertaMesmaEmpresa = empresaOferta ? Number(empresaLogada.codigo_pes) !== Number(empresaOferta) : false
        if (ofertaMesmaEmpresa) {
          showWarnMessage('Retornar ao estoque de veículos e recarregar a página, dados não sincronizados!')
          return false
        } else {
          localStorage.removeItem('empresaOferta')
          if (isVenda()) {
            if (form.values.veiculosConjunto.length > 0) {
              form.setFieldValue('vehiclesToSell', form.values.veiculosConjunto)
              for (const veiculo of form.values.veiculosConjunto) {
                await VeiculoService.saveVeiculo(veiculo.veiculo)
              }
            } else {
              form.setFieldValue('vehiclesToSell', form.values.negociacaoVeiculo)
              form.setFieldValue('negociacaoVeiculo', form.values.negociacaoVeiculo)
              await VeiculoService.saveVeiculo(form.values.negociacaoVeiculo.veiculo)
            }
          } else {
            if (form.values.veiculosConjunto.length > 0) {
              form.setFieldValue('vehiclesToBuy', form.values.veiculosConjunto)
              for (const veiculo of form.values.veiculosConjunto) {
                if (!veiculo.valor_nve) {
                  showWarnMessage(`Veículo: ${veiculo.placha} não possui valor!`)
                  return
                }
                await VeiculoService.saveVeiculo(veiculo.veiculo)
              }
            } else {
              form.setFieldValue('vehiclesToBuy', form.values.negociacaoVeiculo)
              await VeiculoService.saveVeiculo(form.values.negociacaoVeiculo.veiculo)
            }
          }
        }

        form.handleSubmit()
      }
    } catch (error) {
      showErrorMessage(error.message)
    } finally {
      setDesativarBotaoFecharVenda(false)
    }
  }

  const cancelarNegociacao = async () => {
    try {
      const negCancelada = await NegociacaoService.cancel(form.values.codigo_neg, motivoCancelamento)
      setEdicaoHabilitada(false)
      form.setFieldValue('status_neg', negCancelada.status_neg)
      form.setFieldValue('descri_sne', negCancelada.descri_sne)
      form.setFieldValue('dahcan_neg', negCancelada.dahcan_neg)
      showSuccessMessage('Registro cancelado com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Erro ao cancelar negociação')
    }
  }

  const reabrirNegociacao = async () => {
    try {
      //const adicionarDocumentoAutomatico = await ConfigService.getValor(3325)
      //const naoTemDocumento = verificaSeNaoTemDocumentoAutomatico(form.values?.documents || [])

      if (isTroca()) {
        showErrorMessage('Negociação de troca cancelada não pode ser reaberta!')
      } else {
        //if (!isVenda() && adicionarDocumentoAutomatico && naoTemDocumento) {
        //  showErrorMessage('Negociação sem documento de royaltie não pode ser reaberta, crie uma nova negociação!!')
        //  return false
        //}
        await NegociacaoService.validarReabertura(form.values.codigo_neg)
        showSuccessMessage('Registro reaberto com sucesso')
        localStorage.setItem('negociacao_' + form.values.codigo_neg, JSON.stringify({ reaberta: true }))
        form.setFieldValue('usuapr_neg', null)
        form.setFieldValue('dahapr_neg', null)
        setReaberta(true)
        setVisibles((prev) => ({ ...prev, visibleBtnAcoes: false }))
        setEdicaoHabilitada(true)
      }
    } catch (error) {
      showErrorMessage(error.message || 'Erro ao reabrir negociação')
    }
  }

  //#endregion

  //#region * FUNÇÕES VEICULO

  const buscaCodAvaCoc = async (placa) => {
    const avaliacao = await AvaliacaoService.getAvaliacaoCompletaPorPlaca(
      placa
    )
    return avaliacao === null || avaliacao?.codavacoc_ava === null
  }

  const adicionaDocAutomatico = async (veiculo) => {
    let config3326 = await ConfigService.getValor(3326, false)
    let config3325 = await ConfigService.getValor(3325, false)
    let config3334 = await ConfigService.getValor(3334)
    let negociacao
    let documentoRoayltes = []
    if (config3325) {
      documentoRoayltes = await DocumentosService.getTipoDocumento(Number(config3326))
    }
    negociacao = {
      veiculoCompra: veiculo,
      operacao: 'Compra',
      documentoAutomatico: documentoRoayltes,
      valorDocumentoAutomatico: Number(config3334)
    }
    await adicionarDocumentoAutomatico(negociacao, negociacao.codigo_vei)
  }

  const validateCompraVeiculo = async (veiculoParaValidar) => {
    try {
      const veiculosValidosParaCompra = []
      const veiculoService = new VeiculoService()
      dispatch(incrementActiveLoadingRequests())
      dispatch(startLoading())

      const veiculoValidado = await NegociacaoService.validateCompraVeiculo(veiculoParaValidar.codigo_vei, form.values.vehiclesToBuy?.codigo_nve)

      if (veiculoValidado.message) {
        form.setFieldValue('negociacaoVeiculo', {})
        showWarnMessage(veiculoValidado.message)
        dispatch(decrementActiveLoadingRequests())
        dispatch(stopLoading())
        return
      }

      const veiculo = await veiculoService.get(veiculoValidado.codigo_vei)

      if (veiculo.itemConjuntoVeiculo && veiculo.itemConjuntoVeiculo.conjuntoVeiculo.ativo_cjv) {
        const itensConjunto = veiculo.itemConjuntoVeiculo.conjuntoVeiculo.itensConjunto

        for (const itemConjunto of itensConjunto) {
          const veiculoConjunto = await veiculoService.get(itemConjunto.codvei_icj)

          const veiculoValidado = await NegociacaoService.validateCompraVeiculo(veiculoConjunto.codigo_vei, form.values.vehiclesToBuy?.codigo_nve)

          if (veiculoValidado.message) {
            form.setFieldValue('negociacaoVeiculo', {})
            showWarnMessage(veiculoValidado.message)
            continue
          }

          if (veiculoConjunto.acessorios[0]) {
            veiculoConjunto.acessorios = veiculoConjunto.acessorios[0].acessorio
          }

          veiculoConjunto.produto.produtoEmpresas = veiculoConjunto.produto.produtoEmpresas[0]
          veiculoConjunto.produto.produtoEmpresas.preco_pre = 0
          veiculoConjunto.produto.produtoEmpresas.prepro_pre = 0

          const veiculoNegociacaoConjunto = {
            placha: formatPlacha(veiculoConjunto.placa_vei, veiculoConjunto.chassi_vei),
            veiculo: {
              ...veiculoConjunto,
              placha: formatPlacha(veiculoConjunto.placa_vei, veiculoConjunto.chassi_vei)
            },
            repass_nve: veiculoConjunto.repass_vei,
            kmatu_nve: veiculoConjunto.km_vei,
            codpro_nve: veiculoConjunto.codpes_vei,
            codfoa_nve: isConsignado() ? 3 : operacaoNeg === 'Compra' ? 2 : 1,
            consig_nve: isConsignado(),
            codvei_nve: veiculoConjunto.codigo_vei,
            valor_nve: 0,
            atvimo_nve: 0
          }

          veiculosValidosParaCompra.push(veiculoNegociacaoConjunto)
        }
        if (itensConjunto.length !== veiculosValidosParaCompra.length) {
          dispatch(decrementActiveLoadingRequests())
          dispatch(stopLoading())
          return
        }

        form.setFieldValue('veiculosConjunto', veiculosValidosParaCompra)
        form.setFieldValue('negociacaoVeiculo', veiculosValidosParaCompra[0])
        await adicionaDocAutomatico(veiculosValidosParaCompra[0].veiculo)
      } else {
        if (veiculo.acessorios[0]) {
          veiculo.acessorios = veiculo.acessorios[0].acessorio
        }

        veiculo.produto.produtoEmpresas = veiculo.produto.produtoEmpresas[0]
        veiculo.produto.produtoEmpresas.preco_pre = 0
        veiculo.produto.produtoEmpresas.prepro_pre = 0

        setIsRepasse(veiculo.repass_vei)
        await form.setFieldValue('negociacaoVeiculo.veiculo', veiculo)
        await form.setFieldValue('negociacaoVeiculo.repass_nve', veiculo.repass_vei)
        await form.setFieldValue('negociacaoVeiculo.kmatu_nve', veiculo.km_vei)
        await form.setFieldValue('negociacaoVeiculo.codpro_nve', veiculo.codpes_vei)
        await form.setFieldValue('negociacaoVeiculo.codfoa_nve', isConsignado() ? 3 : operacaoNeg === 'Compra' ? 2 : 1)
        await form.setFieldValue('negociacaoVeiculo.consig_nve', isConsignado())
        await form.setFieldValue('negociacaoVeiculo.codvei_nve', veiculo.codigo_vei)
        await adicionaDocAutomatico(veiculo)
        form.setFieldValue('negociacaoVeiculo.proprietario', veiculo.proprietario)
        form.setFieldValue('proprietario', veiculo.proprietario)
        form.setFieldTouched('proprietario.codigo_pes')
        setProprietario(veiculo.proprietario)
      }

      dispatch(decrementActiveLoadingRequests())
      dispatch(stopLoading())
    } catch (error) {
      showErrorMessage(error.message || `Ocorreu um erro inesperado ao validar a compra do veículo: ${formatPlacha(veiculoParaValidar.placa_vei, veiculoParaValidar.chassi_vei)}!`)
      dispatch(decrementActiveLoadingRequests())
      dispatch(stopLoading())
    }
  }

  const trocarVeiculoDocumento = (veiculo) => {
    const { documents } = form.values
    if (documents.length > 0) {
      documents.map((doc) => {
        doc.codvei_ndo = veiculo.codigo_vei
        doc.veiculoNve = veiculo
      })
    }
    form.setFieldValue('documents', documents)
  }

  const trocarVeiculoFopagNeg = (veiculo) => {
    const { foPagNegs } = form.values
    if (foPagNegs.length > 0) {
      foPagNegs.map((fopag) => {
        fopag.codvei_fpn = veiculo.codigo_vei
        fopag.veiculo = veiculo
      })
    }

    form.setFieldValue('foPagNegs', foPagNegs)
  }

  const trocarProcuradorVeiculoConjunto = (codigoVei, negVeiculo = []) => {
    const { procurador } = form.values
    const { procuradorVeiculo } = form.values
    if (procurador.length > 0) {
      procurador.map((proc) => {
        proc.codvei_nve = codigoVei
        proc.veiculo = negVeiculo.veiculo
      })
    }
    if (procuradorVeiculo.length > 0) {
      procuradorVeiculo.map((procVei) => {
        procVei.veiculo = negVeiculo
        procVei.codvei_nve = codigoNve
      })
    }
  }

  const validarVendaVeiculo = async (data) => {
    try {
      if (await venderVeiculo.handleVenderVeiculo(data)) {
        const negociacao = JSON.parse(localStorage.getItem('negociacao'))

        if (negociacao.veiculoVenda) {
          const negociacaoVeiculo = {
            codvei_nve: negociacao.veiculoVenda.codigo_vei,
            valbru_nve: form.values.negociacaoVeiculo.valbru_nve ||
            negociacao.veiculoVenda.preco_pre ||
            0,
            valdes_nve: form.values.negociacaoVeiculo.valdes_nve || 0,
            valor_nve: form.values.negociacaoVeiculo.valor_nve || 0,
            kmatu_nve: negociacao.veiculoVenda.km_vei,
            dahpreent_nve: 0,
            repass_nve: negociacao.veiculoVenda.repass_vei,
            atvimo_nve: 0,
            hastesdri_nve: 0,
            veiculo: negociacao.veiculoVenda
          }

          if (negociacao.operacao === 'Venda') {
            if (negociacaoVeiculo.veiculo?.acessorios[0]) {
              negociacaoVeiculo.veiculo.acessorios = negociacaoVeiculo.veiculo.acessorios[0].acessorio
            }

            negociacaoVeiculo.codpro_nve = negociacaoVeiculo.veiculo.codpes_vei
            negociacaoVeiculo.venda_nve = true
            negociacaoVeiculo.veiculo.produto.produtoEmpresas = negociacaoVeiculo.veiculo.produto.produtoEmpresas[0]
            trocarVeiculoDocumento(negociacao.veiculoVenda)
            trocarVeiculoFopagNeg(negociacao.veiculoVenda)
            adicionarPixAutomatico(negociacao.veiculoVenda)
          }
          form.setFieldValue('negociacaoVeiculo', negociacaoVeiculo)
        } else {
          const veiculosConjunto = []
          for (const veiculo of negociacao.conjuntoVeiculos) {
            const negociacaoVeiculo = {
              codvei_nve: veiculo.codigo_vei,
              placha: formatPlacha(veiculo.placa_vei, veiculo.chassi_vei),
              valbru_nve: Number(veiculo.produto.produtoEmpresas[0].prepro_pre) || Number(veiculo.produto.produtoEmpresas[0].preco_pre),
              valdes_nve: 0,
              valor_nve: Number(veiculo.produto.produtoEmpresas[0].prepro_pre) || Number(veiculo.produto.produtoEmpresas[0].preco_pre),
              kmatu_nve: veiculo.km_vei,
              dahpreent_nve: 0,
              repass_nve: veiculo.repass_vei,
              atvimo_nve: 0,
              hastesdri_nve: 0,
              veiculo: {
                ...veiculo,
                placha: formatPlacha(veiculo.placa_vei, veiculo.chassi_vei)
              }
            }

            if (negociacaoVeiculo.veiculo?.acessorios[0]) {
              negociacaoVeiculo.veiculo.acessorios = negociacaoVeiculo.veiculo.acessorios[0].acessorio
            }

            negociacaoVeiculo.codpro_nve = negociacaoVeiculo.veiculo.codpes_vei
            negociacaoVeiculo.venda_nve = true
            negociacaoVeiculo.veiculo.produto.produtoEmpresas = negociacaoVeiculo.veiculo.produto.produtoEmpresas[0]

            veiculosConjunto.push(negociacaoVeiculo)
          }
          trocarVeiculoDocumento(veiculosConjunto[0])
          trocarProcuradorVeiculoConjunto(veiculosConjunto[0]?.veiculo.codigo_vei, veiculosConjunto[0])
          trocarVeiculoFopagNeg(veiculosConjunto[0]?.veiculo)
          form.setFieldValue('veiculosConjunto', veiculosConjunto)
          form.setFieldValue('negociacaoVeiculo', veiculosConjunto[0])
        }
        form.setFieldValue('negociacaoVeiculo.veiculo.proprietario', data.proprietario)
        // form.setFieldValue('negociacaoVeiculo.veiculo.codpes_vei', data.proprietario.codigo_pes)
        form.setFieldValue('vehiclesToSell.veiculo.proprietario', data.proprietario)
        form.setFieldValue('negociacaoVeiculo.proprietario', data.proprietario)
        // form.setFieldValue('proprietario.apelid_pes', data.proprietario.apelid_pes)
        form.setFieldValue('proprietario', data.proprietario)
        form.setFieldTouched('proprietario.codigo_pes')
        setProprietario(data.proprietario)
      }
    } catch (error) {
      showWarnMessage(error.message)
      dispatch(decrementActiveLoadingRequests())
      dispatch(stopLoading())
    }
  }

  const onSelectVeiculo = async (e) => {
    if (isVenda()) {
      await validarVendaVeiculo(e.value)
    } else {
      const naoPermitirCompraSemAvaliacao = await ConfigService.getValor(3389)
      const { placa_vei } = e.value
      if (naoPermitirCompraSemAvaliacao && placa_vei) {
        if (await buscaCodAvaCoc(placa_vei)) {
          showErrorMessage('Veículo não possui avaliação finalizada no ConectaCarros!')
          return false
        }
      }
      await validateCompraVeiculo(e.value)
    }
    form.setFieldTouched('negociacaoVeiculo.codvei_nve')
  }

  //#endregion

  //#region * USEEFFECT

  useEffect(async () => {
    //Setando o tipo da negociação e o veiculo.
    //Caso seja uma nova negociação
    const usuarioLogado = getStore().usuarioLogado.usuario
    if (usuarioLogado?.pessoa?.vendedor?.ativo_ven && !Boolean(form.values.vendedor.nomraz_pes)) {
      form.setFieldValue('vendedor', { ...usuarioLogado.pessoa.vendedor, ...usuarioLogado.pessoa })
    }

    if (history.location.pathname === '/negociacao') {
      const negociacao = JSON.parse(localStorage.getItem('negociacao'))

      setOperacaoNeg(negociacao.operacao)

      if (negociacao.veiculoVenda) {
        const negociacaoVeiculo = {
          codvei_nve: negociacao.veiculoVenda.codigo_vei,
          valbru_nve: 0,
          valdes_nve: 0,
          valor_nve: 0,
          kmatu_nve: negociacao.veiculoVenda.km_vei,
          dahpreent_nve: 0,
          repass_nve: negociacao.veiculoVenda.repass_vei,
          atvimo_nve: 0,
          hastesdri_nve: 0,
          veiculo: negociacao.veiculoVenda
        }

        if (negociacao.operacao === 'Venda') {
          if (negociacaoVeiculo.veiculo?.acessorios[0]) {
            negociacaoVeiculo.veiculo.acessorios = negociacaoVeiculo.veiculo.acessorios[0].acessorio
          }

          negociacaoVeiculo.codpro_nve = negociacaoVeiculo.veiculo.codpes_vei
          negociacaoVeiculo.venda_nve = true
          negociacaoVeiculo.veiculo.produto.produtoEmpresas = negociacaoVeiculo.veiculo.produto.produtoEmpresas[0]
          form.setFieldValue('negociacaoVeiculo', negociacaoVeiculo)
          await adicionarDocumentoAutomatico(negociacao)

          try {
            await adicionarPixAutomatico(negociacao.veiculoVenda)
          } catch (error) {
            showErrorMessage(`Erro ao adicionar PIX automaticamente: ${error.message}`)
          }
        }
      } else {
        const veiculosConjunto = []

        for (const veiculo of negociacao.conjuntoVeiculos) {
          const negociacaoVeiculo = {
            codvei_nve: veiculo.codigo_vei,
            placha: formatPlacha(veiculo.placa_vei, veiculo.chassi_vei),
            valbru_nve: Number(veiculo.produto.produtoEmpresas[0].prepro_pre) || Number(veiculo.produto.produtoEmpresas[0].preco_pre),
            valdes_nve: 0,
            valor_nve: Number(veiculo.produto.produtoEmpresas[0].prepro_pre) || Number(veiculo.produto.produtoEmpresas[0].preco_pre),
            kmatu_nve: veiculo.km_vei,
            dahpreent_nve: 0,
            repass_nve: veiculo.repass_vei,
            atvimo_nve: 0,
            hastesdri_nve: 0,
            veiculo: {
              ...veiculo,
              placha: formatPlacha(veiculo.placa_vei, veiculo.chassi_vei)
            }
          }

          if (negociacaoVeiculo.veiculo?.acessorios[0]) {
            negociacaoVeiculo.veiculo.acessorios = negociacaoVeiculo.veiculo.acessorios[0].acessorio
          }

          negociacaoVeiculo.codpro_nve = negociacaoVeiculo.veiculo.codpes_vei
          negociacaoVeiculo.venda_nve = true
          negociacaoVeiculo.veiculo.produto.produtoEmpresas = negociacaoVeiculo.veiculo.produto.produtoEmpresas[0]

          veiculosConjunto.push(negociacaoVeiculo)
        }
        await adicionarDocumentoAutomatico(negociacao)

        form.setFieldValue('veiculosConjunto', veiculosConjunto)
        form.setFieldValue('negociacaoVeiculo', veiculosConjunto[0])
      }

      form.setFieldValue('codope_neg', negociacao.operacao === 'Compra' ? 1 : negociacao.operacao === 'Venda' ? 2 : 5)
    } else {
      setOperacaoNeg(form.values.operacaoNeg)
      const negociacaoVeiculo = form.values.operacaoNeg === 'Venda' ? form.values.vehiclesToSell : form.values.vehiclesToBuy
      const negociacao = JSON.parse(localStorage.getItem('negociacao_' + form.values.codigo_neg))

      if (negociacao?.pagandoConsignado) {
        setPagandoConsignado(true)
        setEdicaoHabilitada(true)
        negociacaoVeiculo.veiculo.produto.produtoEmpresas.preco_pre = 0
      }

      if (negociacao?.reaberta) {
        setReaberta(true)
        setEdicaoHabilitada(true)
      }

      form.setFieldValue('negociacaoVeiculo', negociacaoVeiculo)
      handleAtualizaTotalDocumentos()
    }

    if (form.values.codigo_neg) { setVisibles((prev) => ({ ...prev, visibleBtnAcoes: true })) }
    handlePodeAcessarTelaEntrega()
  }, [])

  useEffect(() => {
    switch (form.values.status_neg) {
    case NEG_CANCELADA:
      setStatusNegociacao(`${form.values.descri_sne} em ${formatDateTime(negociacao.dahcan_neg || form.values.dahcan_neg)}`)
      break
    case NEG_FECHADA:
      setStatusNegociacao(`${form.values.descri_sne} em ${formatDateTime(negociacao.datfin_neg || form.values.datfin_neg)}`)
      break
    case NEG_CONSIGNADA:
      setStatusNegociacao(`${form.values.descri_sne} em ${formatDateTime(negociacao.dahcon_neg || form.values.dahcon_neg)}`)
      break
    default:
      break
    }
  }, [
    form.values.dahcan_neg,
    form.values.dahcon_neg,
    form.values.datfin_neg,
    form.values.descri_sne
  ])

  //#endregion

  //#region RETORNO

  return (
    <Page>
      {(!isNegWeb() && !isNew) && (
        <>Não é permitido alterar negociações feitas pelo SancesTurbo DMS!</>
      )}
      <div className="flex justify-content-between align-items-center page-header neg">
        <h4>
          {isVenda() && 'Venda de Veículo'}
          {operacaoNeg === 'Compra' && 'Compra de Veículo'}
          {isConsignado() && 'Consignação de Veículo'}
          {form.values.codigo_neg ? ` - ${form.values.codigo_neg}` : ''}
        </h4>
        {reaberta && (
          <label className="situacao-neg">Em edição</label>
        )}
        {(form.values.codigo_neg && !reaberta) && (
          <label className="situacao-neg">
            {statusNegociacao}
          </label>

        )}
        <div className={'btn-acoes ' + (CommonHelper.isDesktop() ? '' : 'flex justify-content-end mt-3 mb-3 mr-2')}>
          {(visibles.visibleBtnAcoes && !reaberta) && (
            <DropdownMenu
              items={itensAcao}
              icon="pi pi-bars"
              label={CommonHelper.isDesktop() ? 'Ações' : ''}
            />
          )}
          {(reaberta) && (
            <AppButton
              type="button"
              label={'Cancelar edição'}
              className="mt-4"
              onClick={async () => await handleCancelarEdicao()}
            />
          )}
        </div>
      </div>
      <div className="divider"></div>
      <div>
        <TabMenuContainer
          contentHeader={
            <div className="formgrid grid fluid">
              {!(form.values.veiculosConjunto?.length > 0) && (
                <>
                  <label className="ml-4">Veículo:</label>
                  <span className="ml-2 underline cursor-pointer" onClick={() => handleSelectVeiculo(veiculoNve?.codigo_vei)}>
                    {marcaModelo} {veiculoNve?.descri_cor} {anofabAnomod} {placha}
                  </span>
                </>
              )}
              <label className="ml-5">Cliente:</label>
              <span className="ml-2 underline cursor-pointer" onClick={() => showPessoaModal()}>
                {form.values.pessoa.nomraz_pes}
              </span>
              <div className="form-divider mt-3"></div>
            </div>
          }
          activeIndexParaNaoMostrarContentHeader={[0]}
          tabChangeValidator={validateTabChange}
        >
          <TabMenuPage header="DADOS PRINCIPAIS">
            {form.values.veiculosConjunto?.length > 0 ? (
              <DadosVeiculosConjuntoNeg
                // adicionarDocumentoAutomatico={adicionarDocumentoAutomatico}
                form={form}
                edicaoHabilitada={edicaoHabilitada}
                pagandoConsignado={pagandoConsignado}
                operacaoNeg={operacaoNeg}
              />
            ) : (
              <DadosPrincipaisNeg
                form={form}
                edicaoHabilitada={edicaoHabilitada}
                operacaoNeg={operacaoNeg}
                pagandoConsignado={pagandoConsignado}
                proprietario={proprietario}
                setProprietario={setProprietario}
                onSelectVeiculo={onSelectVeiculo}
                isRepasse={isRepasse}
                setIsRepasse={setIsRepasse}
              />
            )}
          </TabMenuPage>
          {handleMostrarTodasAbas() && (
            <TabMenuPage header="DOCUMENTO">
              <DocumentosNeg
                form={form}
                edicaoHabilitada={edicaoHabilitada}
                handleAtualizaTotalDocumentos={handleAtualizaTotalDocumentos}
                totalValorDocumentos={totalValorDocumentos}
              />
            </TabMenuPage>
          )}
          {handleMostrarTodasAbas() && (
            <TabMenuPage header="PAGAMENTO">
              <PagamentoNeg
                form={form}
                edicaoHabilitada={edicaoHabilitada}
                operacaoNeg={operacaoNeg}
                totalValorDocumentos={totalValorDocumentos}
              />
            </TabMenuPage>
          )}
          {(handleMostrarTodasAbas() || (isConsignado() && !pagandoConsignado && form.values.status_neg !== 3)) && (
            <TabMenuPage header="OBSERVAÇÕES">
              <ObservacoesNeg
                form={form}
                edicaoHabilitada={edicaoHabilitada}
              />
            </TabMenuPage>
          )}
          <TabMenuPage header="PROCURADORES">
            <ProcuradoresNeg
              form={form}
              edicaoHabilitada={edicaoHabilitada}
              handleAtualizaTotalDocumentos={handleAtualizaTotalDocumentos}
              totalValorDocumentos={totalValorDocumentos}
            />
          </TabMenuPage>
        </TabMenuContainer>
      </div>
      <div className={'field col-12 md:col-12 grid justify-content-' + (form.values.veiculosRestantesCompra ? 'between' : 'end')}>
        {form.values.veiculosRestantesCompra && (
          <>
            {form.values.veiculosRestantesVenda[0] && (
              <div className="col-5 flex neg-row veiculos-restantes pt-5 pr-5">
                <div className="neg-row-label">Veículos de venda:</div>
                {showRestanteVeiculos(form.values.veiculosRestantesVenda)}
              </div>
            )}
            {form.values.veiculosRestantesCompra[0] && (
              <div className="col-5 flex neg-row veiculos-restantes pt-5 pr-5">
                <div className="neg-row-label">Veículos de compra:</div>
                {showRestanteVeiculos(form.values.veiculosRestantesCompra)}
              </div>
            )}
          </>
        )}
        <AppButton
          type="button"
          label={pagandoConsignado ? 'Fechar consignação' : (isVenda() ? 'Fechar venda' : isConsignado() ? 'Consignar' : 'Fechar compra')}
          className="mt-4"
          onClick={async () => await finalizarNegociacao()}
          disabled={!edicaoHabilitada || desativarBotaoFecharVenda}
        />
      </div>
      <ImpressoesNegModal
        form={form}
        operacaoNeg={operacaoNeg}
        visibleImpressoesNegModal={visibles.visibleImpressoesNegModal}
        hide={() => setVisibles((prev) => ({ ...prev, visibleImpressoesNegModal: false }))}
      />
      <VeiculoModal
        onSaveModal={async (veiculo) => await onSelectVeiculo({ value: veiculo })}
        visible={visibles.visibleVeiculoModal}
        hide={() => setVisibles((prev) => ({ ...prev, visibleVeiculoModal: false }))}
        primaryKey={veiculoKeyModal}
        apenasVisualizacao={!edicaoHabilitada}
      />
      <PessoaFisicaModal
        visible={visibles.visiblePessoaFisicaModal}
        hide={() => setVisibles((prev) => ({ ...prev, visiblePessoaFisicaModal: false }))}
        primaryKey={isClientePessoaFisica() ? form.values.pessoa.codigo_pes : undefined}
        apenasVisualizacao={!edicaoHabilitada}
      />
      <PessoaJuridicaModal
        visible={visibles.visiblePessoaJuridicaModal}
        hide={() => setVisibles((prev) => ({ ...prev, visiblePessoaJuridicaModal: false }))}
        primaryKey={!isClientePessoaFisica() ? form.values.pessoa.codigo_pes : undefined}
        apenasVisualizacao={!edicaoHabilitada}
      />
      <MotivoCanModal
        form={form}
        visible={visibles.visibleMotivoCanModal}
        hide={() => {
          setVisibles((prev) => ({ ...prev, visibleMotivoCanModal: false }))
          setMotivoCancelamento('')
        }}
        cancelarNegociacao={cancelarNegociacao}
        motivoCancelamento={motivoCancelamento}
        setMotivoCancelamento={setMotivoCancelamento}
      />
    </Page>
  )

  //#endregion
}

export default NegociacaoForm
