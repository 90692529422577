import { server } from '../../helpers/server'
import ServiceBase from '../base/ServiceBase'

export default class ArquivoTPUService {
  static async getArquivos (codtpu_atp, codarq_atp, database) {
    if (database) {
      return await ServiceBase.getBase(`/arquivo-tpu/${codtpu_atp}/${codarq_atp}` + (database ? `/${database}` : ''))
    }
    return await ServiceBase.getBase(`/arquivo-tpu/${codtpu_atp}/${codarq_atp}`)
  }

  static async uploadArquivo (file, codtpu_atp, codarq_atp) {
    try {
      const fd = new FormData()

      if (file.objectURL) {
        const blob = await fetch(file.objectURL).then((r) => r.blob())
        const fileConverted = new File([blob], file.name, { type: file.type })

        fd.append('image_name', file.name)
        fd.append('image', fileConverted)
      } else {
        fd.append('file_name', file.name)
        fd.append('file', file)
      }

      const response = await server().post(
        `arquivo-tpu/${codtpu_atp}/${codarq_atp}`,
        fd
      )

      return response.data
    } catch (error) {
      throw Error(error.response?.data?.message)
    }
  }

  static async uploadMultiArquivos (files, codtpu_atp, codarq_atp) {
    const convertFileListToArray = Array.from(files)
    const uploadPromises = []

    for (const file of convertFileListToArray) {
      try {
        const fd = new FormData()

        if (file.objectURL) {
          const blob = await fetch(file.objectURL).then((r) => r.blob())
          const fileConverted = new File([blob], file.name, { type: file.type })

          fd.append('file_name', file.name)
          fd.append('file', fileConverted)
        } else {
          fd.append('file_name', file.name)
          fd.append('file', file)
        }

        return server().post(`arquivo-tpu/${codtpu_atp}/${codarq_atp}`, fd)
      } catch (error) {
        console.error(`Erro ao fazer upload do arquivo ${file.name}:`, error)
        return null
      }
    }

    const results = await Promise.all(uploadPromises)

    return results
      .filter((res) => res !== null)
      .flatMap((res) => res.data)
  }

  static async deleteArquivos (arquivos) {
    await ServiceBase.postBase('arquivo-tpu/deleteArquivos', { arquivos })
  }

  static async deleteAllArquivos (codtpu_atp, codarq_atp, ignore_codarq_atp) {
    return await ServiceBase.postBase('arquivo-tpu/delete-all', { codtpu_atp, codarq_atp, ignore_codarq_atp })
  }
}
