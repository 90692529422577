import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import { baseForm } from '../../../../../../components/utils/BaseForm'
import { getEtapaDTO, postEtapaDTO } from '../../../../../../dtos/processo/EtapaDTO'
import { EtapaProcessoValidator } from '../validator'
import EtapasEPendenciasModal from '..'
import DataListForm from '../../../../../../components/layout/DataListForm/DataListForm'

function DadosEtapas ({
  form,
  edicaoHabilitada,
  camposObrigatorios
}) {
  const [visibleEtapaModal, setVisibleEtapaModal] = useState(false)
  const [etapaSelecionada, setEtapaSelecionada] = useState(null)

  // * Função para reordenar as etapas
  function reordenarEtapas (etapas) {
    etapas.forEach((etapa, index) => {
      etapa.ordem_eta = index + 1
    })
  }

  function insertOrUpdateEtapa () {
    if (etapaSelecionada) {
      editarEtapa()
    } else {
      adicionarEtapa()
    }

    etapaForm.resetForm()
    hideEtapaModal()
  }

  function adicionarEtapa () {
    const etapas = form.values.etapas
    etapas.push(postEtapaDTO(etapaForm.values))

    reordenarEtapas(etapas)
    form.setFieldValue('etapas', etapas)
  }

  function editarEtapa () {
    const etapas = form.values.etapas

    const indexEtapa = etapas.indexOf(etapaSelecionada)

    etapas.splice(indexEtapa, 1, postEtapaDTO(etapaForm.values))

    reordenarEtapas(etapas)
    form.setFieldValue('etapas', etapas)
  }

  const etapaForm = baseForm({
    initialValues: getEtapaDTO(),
    validationSchema: EtapaProcessoValidator,
    onSubmit: insertOrUpdateEtapa
  })

  useEffect(async () => {
    if (etapaSelecionada) {
      etapaForm.setValues(getEtapaDTO(etapaSelecionada))
    } else {
      etapaForm.setValues(getEtapaDTO())
    }
  }, [etapaSelecionada])

  const abrirModalAdicionarEtapaAtalho = (event) => {
    if (event.altKey && event.key === 'a' && edicaoHabilitada) {
      setVisibleEtapaModal(true)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', abrirModalAdicionarEtapaAtalho)
    return () => {
      window.removeEventListener('keydown', abrirModalAdicionarEtapaAtalho)
    }
  }, [edicaoHabilitada])

  const columns = [
    {
      field: 'ordem_eta',
      header: 'Ordem',
      className: 'text-left'
    },
    {
      field: 'descri_tet',
      header: 'Etapa',
      className: 'text-left'
    },
    {
      field: 'nomraz_pes',
      header: 'Responsável',
      className: 'text-left'
    }
  ]

  function hideEtapaModal () {
    setEtapaSelecionada(null)
    setVisibleEtapaModal(false)
    etapaForm.resetForm()
  }

  function handleRowReorder (parametros) {
    const { value } = parametros
    const etapas = [...value]
    reordenarEtapas(etapas)
    etapas.sort((a, b) => a.ordem_eta - b.ordem_eta)
    form.setFieldValue('etapas', etapas)
  };

  return (
    <div className="formgrid grid fluid">
      <div className='w-full flex justify-content-end'>
        <Button
          label="Adicionar etapa"
          className='mb-2 mr-2'
          icon="pi pi-plus"
          onClick={() => setVisibleEtapaModal(true)}
          disabled={!edicaoHabilitada}
          tooltip='ALT + "A" para abrir o cadastro'
          tooltipOptions={{ position: 'bottom' }}
        />
      </div>
      <div className="field col-12">
        <DataListForm
          value={form.values.etapas}
          columns={columns}
          emptyMessage="Nenhuma etapa cadastrada."
          onRowSelect={async (e) => {
            await setEtapaSelecionada(e.data)
            setVisibleEtapaModal(true)
          }}
          handleRowReorder={handleRowReorder}
        />
      </div>
      {!!visibleEtapaModal && <EtapasEPendenciasModal
        visible={visibleEtapaModal}
        onHide={() => hideEtapaModal()}
        camposObrigatorios={camposObrigatorios}
        etapaForm={etapaForm}
        etapaSelecionada={etapaSelecionada}
        edicaoHabilitada={edicaoHabilitada}
        form={form}
        setEtapas={(etapas) => {
          reordenarEtapas(etapas)
          form.setFieldValue('etapas', etapas)
        }}
      />}
    </div>
  )
}

export default DadosEtapas
