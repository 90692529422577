import React, { useEffect, useState } from 'react'
import { Crud } from '../../../../classes/NewCrud'
import ProcessoUsoService from '../../../../services/processo/ProcessoUsoService'
import { ProcessoUsoValidator } from './validator'
import AccordionContainer from '../../../../components/layout/AccordionContainer'
import AccordionPage from '../../../../components/layout/AccordionPage'
import { getProcessoUsoDTO } from '../../../../dtos/processo/ProcessoUsoDTO'
import DadosPrincipaisProcessoUso from './main'
import Confirm from '../../../../components/utils/Confirm'
import { showErrorMessage, showSuccessMessage } from '../../../../components/utils/Message'
import DadosEtapasProcessoUso from './etapas/ListWithModal'
import AppButton from '../../../../components/layout/AppButton'

const ProcessoUsoForm = ({
  form, edicaoHabilitada,
  camposObrigatorios,
  setActions,
  refreshForm,
  setApenasBotaoAcoes,
  onSave
}) => {
  useEffect(() => {
    if (form.values?.status?.codigo_spr === 1) {
      setActions([
        { label: 'Cancelar', command: () => setVisibleConfirmarCancelar(true) },
        { label: 'Finalizar', command: () => setVisibleConfirmarFinalizar(true) }
      ])
    } else if (form.values?.status?.codigo_spr === 2) {
      setActions([{ label: 'Cancelar', command: () => setVisibleConfirmarCancelar(true) }])
    } else if (form.values?.status?.codigo_spr === 3) {
      setActions([{ label: 'Reabrir', command: () => setVisibleConfirmarReabrir(true) }])
    } else {
      setActions([])
    }
  }, [form.values.codigo_pru, form.values?.status?.codigo_spr])

  const [visibleConfirmarFinalizar, setVisibleConfirmarFinalizar] = useState(false)
  const [visibleConfirmarCancelar, setVisibleConfirmarCancelar] = useState(false)
  const [visibleConfirmarReabrir, setVisibleConfirmarReabrir] = useState(false)

  async function finalizarAposConfirmar () {
    try {
      await ProcessoUsoService.finalizar(
        form.values.codigo_pru
      )
      setVisibleConfirmarFinalizar(false)
      await refreshForm(form.values.codigo_pru)
      setApenasBotaoAcoes(true)
      showSuccessMessage('Processo finalizado com sucesso!')
    } catch (error) {
      showErrorMessage(
        error.message ||
        'Ocorreu um erro inesperado ao tentar finalizar o processo!'
      )
    }
  }

  async function cancelarAposConfirmar () {
    try {
      await ProcessoUsoService.cancelar(
        form.values.codigo_pru
      )
      setVisibleConfirmarCancelar(false)
      await refreshForm(form.values.codigo_pru)
      setApenasBotaoAcoes(true)
      showSuccessMessage('Processo cancelado com sucesso!')
    } catch (error) {
      showErrorMessage(
        error.message ||
        'Ocorreu um erro inesperado ao tentar cancelar o processo!'
      )
    }
  }

  async function reabrirAposConfirmar () {
    try {
      await ProcessoUsoService.reabrir(
        form.values.codigo_pru
      )
      setVisibleConfirmarReabrir(false)
      await refreshForm(form.values.codigo_pru)
      setApenasBotaoAcoes(false)
      showSuccessMessage('Processo reaberto com sucesso!')
    } catch (error) {
      showErrorMessage(
        error.message ||
        'Ocorreu um erro inesperado ao tentar reabrir o processo!'
      )
    }
  }

  return (
    <>
      <Confirm
        visible={visibleConfirmarFinalizar}
        onCancel={() => setVisibleConfirmarFinalizar(false)}
        onConfirm={async () => await finalizarAposConfirmar()}
        title="Confirma finalizar o processo?"
        description="Deseja mesmo finalizar o processo?"
      />
      <Confirm
        visible={visibleConfirmarCancelar}
        onCancel={() => setVisibleConfirmarCancelar(false)}
        onConfirm={async () => await cancelarAposConfirmar()}
        title="Confirma cancelar o processo?"
        description="Deseja mesmo cancelar o processo?"
      />
      <Confirm
        visible={visibleConfirmarReabrir}
        onCancel={() => setVisibleConfirmarReabrir(false)}
        onConfirm={async () => await reabrirAposConfirmar()}
        title="Confirma reabrir o processo?"
        description="Deseja mesmo reabrir o processo?"
      />
      <AccordionContainer>
        <AccordionPage
          header={'Informações Principais'}
          active
        >
          <DadosPrincipaisProcessoUso
            form={form}
            edicaoHabilitada={edicaoHabilitada}
            camposObrigatorios={camposObrigatorios}
          />
        </AccordionPage>
        {!!form.values?.codigo_pru && (
          <AccordionPage
            header="Etapas e pendências"
            active
          >
            <DadosEtapasProcessoUso
              form={form}
              edicaoHabilitada={edicaoHabilitada}
            />
          </AccordionPage>
        )}
      </AccordionContainer>
      {!form.values?.codigo_pru && (
        <div className='flex w-full justify-content-center'>
          <AppButton
            type="submit"
            label={'Gerar etapas e pendências'}
            onClick={async () => await onSave(form.values)}
          />
        </div>
      )}
    </>
  )
}

const HeaderProcessoUso = ({ form, pk }) => {
  const statusDesc = form.values?.status?.descri_spr || ''
  let textColorStatus = ''
  if (statusDesc === 'Em andamento') {
    textColorStatus = '#304FFD'
  } else if (statusDesc === 'Finalizado') {
    textColorStatus = '#49C96D'
  } else if (statusDesc === 'Cancelado') {
    textColorStatus = '#F05C54'
  }

  return <h1 className='w-full flex'>
    <div className='w-full'>
        Novo Processo  {
        pk
          ? `- ${pk}`
          : ''
      }
    </div>
    <div
      className='w-full flex justify-content-end md:justify-content-start'
      style={{
        color: textColorStatus,
        fontWeight: 500
      }}
    >
      {statusDesc}
    </div>
  </h1>
}

const ProcessoUsoCrud = (props) => {
  return (
    <Crud
      {...props}
      formContent={ProcessoUsoForm}
      valida={ProcessoUsoValidator}
      modelGetDTO={getProcessoUsoDTO}
      service={ProcessoUsoService}
      resource="processo-uso"
      primarykeyname="codigo_pru"
      formTitle="Novo Processo"
      removerBotaoExcluir
      manterEmEdicaoAposInsertNew
      manterSemExcluir
      HeaderComponent={HeaderProcessoUso}
    />
  )
}

export default ProcessoUsoCrud
