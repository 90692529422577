import { Button } from 'primereact/button'
import { FileUpload } from 'primereact/fileupload'
import React, { useEffect, useRef, useState } from 'react'
import { showErrorMessage } from '../../../../../../../components/utils/Message'
import Modal from '../../../../../../../components/utils/Modal'
import ArquivoTPUService from '../../../../../../../services/ArquivoTPUService'

const ArquivosPendenciaUso = ({
  edicaoHabilitada,
  visible,
  pendenciaForm,
  onHide,
  form,
  setAnexos,
  pendenciaSelecionada
}) => {
  const [arquivosExcluidas, setArquivosExcluidos] = useState([])
  const [arquivosAtuais, setArquivosAtuais] = useState([])

  const chooseOptions = { icon: 'pi pi-plus', label: 'Adicionar', className: 'custom-choose-btn p-button-rounded' }
  const uploadOptions = { icon: 'pi pi-save', label: 'Salvar', className: 'custom-choose-btn p-button-rounded' }
  const cancelOptions = { icon: 'pi pi-times', label: 'Cancelar', className: 'custom-choose-btn p-button-rounded' }

  const fileUploadRef = useRef(null)

  async function handleSubmit () {
    await fileUploadRef.current?.upload()
    onHide()
  }

  useEffect(async () => {
    if (!arquivosAtuais.length) await getArquivos()
  }, [])

  const getArquivos = async () => {
    await ArquivoTPUService.getArquivos(pendenciaSelecionada.codigo_tpu)
      .then((arquivosAtuais) =>
        setArquivosAtuais(arquivosAtuais)
      ).catch((error) => {
        showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar os arquivos da pendência!')
      })
  }

  const handleUpload = async (uploadEvent) => {
    await handleSalvarArquivos(uploadEvent)

    if (arquivosExcluidas.length > 0) {
      await handleExcluirArquivos()
    }
  }

  const handleSalvarArquivos = async (uploadEvent) => {
    try {
      const { data: arquivosSalvas } = await ArquivoTPUService.uploadMultiArquivos(
        uploadEvent.files,
        pendenciaForm.values.codigo_tpu
      )

      await addToArquivosAtuais(arquivosSalvas)

      await fileUploadRef.current?.clear()
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao salvar as fotos do veículo!')
    }
  }

  const handleExcluirArquivos = async () => {
    try {
      await ArquivoTPUService.deleteArquivos(arquivosExcluidas)
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao excluir as fotos do veículo!')
    }
  }

  const itemTemplate = (file, props) => {
    return (
      <div className="flex align-itemscenter flex-wrap">
        <div className="imagem-container">
          <span>{(file.name || '')}</span>
          <i className="pi pi-times excluir-imagem" onClick={props.onRemove} />
        </div>
      </div>
    )
  }

  function showArquivosAtuais () {
    if (arquivosAtuais.length === 0) return

    return arquivosAtuais.map(arquivo => {
      const imagenEstaExcluida = arquivosExcluidas.find(arquivoExcluida => arquivoExcluida.codigo_atp === arquivo.codigo_atp)

      if (!imagenEstaExcluida)
        return (
          <div
            key={arquivo.codigo_atp}
            className="arquivo-container flex flex-column align-items-center"
            style={{ margin: '0.5rem' }}
          >
            <div
              className="imagem-container"
            >
              <img
                className="imagem"
                src={
                  !!arquivo?.arquivo?.typarq_arq?.includes('image')
                    ? arquivo?.arquivo?.url
                    : 'https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg'}
                onClick={() => window.open(arquivo?.arquivo?.url, '_blank')}
                style={{ cursor: 'pointer' }}
              />
              <Button
                icon="pi pi-times"
                className="excluir-imagem saved"
                onClick={() => addToArquivosExcluidas(arquivo)}
                disabled={!edicaoHabilitada}
                tooltip='Será deletado após "Atualizar anexos"'
                tooltipOptions={{
                  position: 'bottom'
                }}
              />
            </div>
            <div
              className='flex flex-column align-items-center'
              style={{ margin: '0.5rem 0' }}
            >
              <span
                style={{
                  'white-space': 'nowrap',
                  overflow: 'hidden',
                  'text-overflow': 'ellipsis',
                  'max-width': '125px'
                }}
                title={arquivo?.arquivo?.nomarq_arq}
              >
                {(arquivo?.arquivo?.nomarq_arq || '')}
              </span>

            </div>
          </div>
        )
    })
  }

  const addToArquivosAtuais = (arquivosNovas) => {
    setArquivosAtuais((prevState) => ([...prevState, ...arquivosNovas]))
  }

  const addToArquivosExcluidas = (arquivoNova) => {
    setArquivosExcluidos((prevState) => ([...prevState, arquivoNova]))
  }

  return (
    <>
      <Modal
        visible={visible}
        onHide={onHide}
        header={'Anexos'}
        btnSalvarLabel={'Atualizar anexos'}
        icon={''}
        width={75}
        btnSalvar={edicaoHabilitada ? handleSubmit : undefined}
        btnExcluir={undefined}
        btnExcluirLabel={null}
      >
        <div className="flex fileupload-pendencia-uso">
          {showArquivosAtuais()}
        </div>
        <FileUpload
          className="fileupload-pendencia-uso"
          disabled={!edicaoHabilitada}
          ref={fileUploadRef}
          style={{ marginTop: '30px' }}
          contentStyle={{ display: 'block', width: '100%' }}
          name="arquivos"
          multiple={true}
          accept="*"
          maxFileSize={20000000}
          emptyTemplate={<></>}
          previewWidth={200}
          chooseOptions={chooseOptions}
          uploadOptions={uploadOptions}
          cancelOptions={cancelOptions}
          itemTemplate={itemTemplate}
          onValidationFail={(file) => showErrorMessage(`${file.name}: Tamanho de arquivo inválido, o tamanho máximo de envio é de 20MB.`)}
          customUpload
          uploadHandler={handleUpload}
        />
      </Modal>
    </>
  )
}

export default ArquivosPendenciaUso
