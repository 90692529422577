import React, { useEffect, useState } from 'react'
import AutoCompleteInput from '../../../../components/inputs/AutoCompleteInput'
import SelectInput from '../../../../components/inputs/SelectInput'
import TextInput from '../../../../components/inputs/TextInput'
import { showErrorMessage, showWarnMessage } from '../../../../components/utils/Message'
import CommonHelper from '../../../../helpers/CommonHelper'
import { formatDecimal, formatDecimalToNumber, formatDocument, formatToInteger } from '../../../../helpers/formaters'
import { getStore } from '../../../../redux/store'
import EstadosService from '../../../../services/cadastro/pessoa/EstadosService'
import PessoaJuridicaService from '../../../../services/cadastro/pessoa/PessoaJuridicaService'
import { PessoaFisica } from '../../../../views/cadastro/pessoa/fisica/PessoaFisica'
import { PessoaJuridica } from '../../../../views/cadastro/pessoa/juridica/PessoaJuridica'
import PessoaJuridicaModal from '../../../cadastro/pessoa/pessoaJuridica/PessoaJuridicaModal'
import OrcamentoBalcaoService from '../../../../services/vendas/orcamentoBalcao/OrcamentoBalcaoService'

function DadosTransporteOpv({ form, edicaoHabilitada, camposObrigatorios }) {
  const [estados, setEstados] = useState([])
  const [modalidadesFrete, setModalidadesFrete] = useState([])
  const [sugestaoTransportador, setSugestaoTransportador] = useState([])

  useEffect(() => {
    requisicoesIniciais()
  }, [])

  async function requisicoesIniciais() {
    await Promise.all([
      getEstadosPorPais(getStore().codigoBrasil)])
    getModalidadeFrete()
  }

  const getModalidadeFrete = async () => {
    try {
      await OrcamentoBalcaoService.getAllModalidadeFrete().then(modalidades => {
        setModalidadesFrete(modalidades)
      })
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar a modalidade de frete!')
    }
  }

  async function sugerirTransportador() {
    try {
      const transportadora = await PessoaJuridicaService.filterAutoComplete(`descricaoFiltro=${form.values.transportadora.nomraz_pes}`)
      if (transportadora.length <= 0) {
        showWarnMessage('Transportadora não encontrado!')
        return false
      }
      setSugestaoTransportador(transportadora)

    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um cliente!')
    }
  }

  async function onSelectTransportador(e) {
    const transportadora = e.value
    await form.setFieldValue('transportadora', transportadora)
    await form.setFieldValue('transportadora.nomraz_pes', transportadora?.nomraz_pes)
    await form.setFieldValue('transportadora.codigo_pes', transportadora?.codigo_pej)
    await form.setFieldValue('transportadora.cgccpf_pes', transportadora?.cgccpf_pes)
    await form.setFieldValue('transporte.codtrp_top', transportadora?.codigo_pej)
  }

  function onSaveModalTransportadora(pessoaj) {
    const objPes = {
      codigo_pes: pessoaj.pessoa.codigo_pes,
      nomraz_pes: pessoaj.pessoa.nomraz_pes,
      cgccpf_pes: pessoaj.pessoa.cgccpf_pes,
      codigo_pej: pessoaj.codigo_pej
    }
    onSelectTransportador({ value: objPes })
  }

  function handleChangeTranportadora(event) {
    if(event.target.value){
      form.setFieldValue('transportadora', { nomraz_pes: event.target.value })
    }else{
      form.setFieldValue('transportadora', {})
      form.setFieldValue('transporte.codtrp_top', null)
    }
  }

  function handleChangeDecimal(event) {
    form.setFieldValue(event.target.name, formatDecimalToNumber(event.target.value, 3))
  }

  function handleChangeIntenger(event) {
    form.setFieldValue(event.target.name, formatToInteger(event.target.value, 3))
  }

  function handleChangeInfoFrete(event) {
    form.setFieldValue(event.target.name, event.target.value)
  }

  async function handleChangeModalidade(event) {
    if (event.target.value) {
      await form.setFieldValue('modalidadeFrete', event.target.value)
      await form.setFieldValue('transporte.resfre_top', event.target.value?.codigo_ref)
    } else {
      await form.setFieldValue('modalidadeFrete', {})
      await form.setFieldValue('transporte.resfre_top', 9)
    }
  }

  function handleChangeRNTC(event) {
    const rntc = event.target.value
    form.setFieldValue('transporte.rntc_top', rntc)
  }

  const handleChangePlaca = (placa) => {
    form.setFieldValue('transporte.plavei_top', placa)
  }

  const handleChangeUF = (e) => {
    form.setFieldValue('transporte.ufvei_top', e.value.sigla_uf)
    form.setFieldValue('estado', e.value)
  }

  const getEstadosPorPais = async (id) => {
    try {
      await EstadosService.getAllByCountry(id).then(estados => setEstados(estados))
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar os estados por pais!')
    }
  }
  
  return (
    <div className="formgrid grid fluid">
      <div className='w-full'>
        <p className='font-semibold'>Informações do Transportador</p>
      </div>
      <div className="field col-12 md:col-4">
        <AutoCompleteInput
          //AutoComplete
          name="transportadora.codigo_pes"
          field="nomraz_pes"
          label="Transportadora"
          value={form.values.transportadora && form.values.transportadora.nomraz_pes}
          placeholder="Transportadora"
          disabled={!edicaoHabilitada}
          onSelect={async (e) => await onSelectTransportador(e)}
          suggestions={sugestaoTransportador}
          completeMethod={sugerirTransportador}
          onChange={handleChangeTranportadora}
          camposObrigatorios={camposObrigatorios}
          form={form}
          //RegisterModal
          selected={form.values.transportadora}
          onSaveModal={onSaveModalTransportadora}
          onDeleteModal={async () => await onSelectTransportador({})}
          ComponenteCadastro={PessoaJuridica}
          modalHeader={form.values.transportadora.codigo_pes ? 'Pessoa jurídica - ' + form.values.transportadora.codigo_pes : 'Cadastro de pessoa jurídica'}
          //FiltroModal
          filtrotitle="Pesquisa de Transportador"
          service={PessoaJuridicaService}
          model={PessoaJuridicaModal}
          primarykeyname="codigo_pes"
          columns={[
            { campo: 'codigo_pej', nome: 'Código' },
            { campo: 'nomraz_pes', nome: 'Nome' },
            { campo: 'cgccpf_pes', nome: 'CNPJ', format: formatDocument, width: '15%' },
            { campo: 'cidade_pes', nome: 'Cidade', width: '20%' },
            { campo: 'uf_pes', nome: 'UF', width: '8%' },
          ]}
        />
      </div>
      <div className="field col-12 md:col-8">
        <SelectInput
          value={form.values.modalidadeFrete}
          options={modalidadesFrete}
          onChange={handleChangeModalidade}
          label="Modalidade do frete"
          name="modalidadeFrete"
          filter={CommonHelper.isDesktop()}
          optionLabel="descri_ref"
          dataKey="codigo_ref"
          noFloatLabel
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
          showClear={form.values.transporte?.resfre_top}
        />
      </div>
      <div className="field col-12 md:col-2">
        <TextInput
          label="Placa"
          name="transporte.placa_top"
          placeholder="Placa"
          value={form.values.transporte.plavei_top}
          onChange={handleChangePlaca}
          disabled={!edicaoHabilitada}
          mask="aaa-9*99"
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 md:col-2">
        <SelectInput
          value={form.values.estado}
          options={estados}
          onChange={handleChangeUF}
          label="UF"
          name="estado"
          filter={CommonHelper.isDesktop()}
          optionLabel="sigla_uf"
          dataKey="codigo_uf"
          noFloatLabel
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-8">
        <TextInput
          name="transporte.rntc_top"
          label="RNTC"
          placeholder="RNTC"
          value={form.values.transporte.rntc_top}
          onChange={handleChangeRNTC}
          disabled={!edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="form-divider mt-3"></div>

      <div className='w-full'>
        <p className='font-semibold'>Informações dos volumes transportados</p>
      </div>
      <div className="field col-12 md:col-2">
        <TextInput
          name="transporte.qtdvol_top"
          label="Quantidade"
          placeholder="Quantidade"
          value={form.values.transporte.qtdvol_top ? formatDecimal(form.values.transporte.qtdvol_top, 3) : form.values.transporte.qtdvol_top}
          onChange={handleChangeDecimal}
          disabled={!edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>

      <div className="field col-12 md:col-2">
        <TextInput
          name="transporte.espvol_top"
          label="Espécie"
          placeholder="Espécie"
          value={form.values.transporte?.espvol_top}
          onChange={handleChangeInfoFrete}
          disabled={!edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 md:col-2">
        <TextInput
          name="transporte.marvol_top"
          label="Marca"
          placeholder="Marca"
          value={form.values.transporte?.marvol_top}
          onChange={handleChangeInfoFrete}
          disabled={!edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 md:col-2">
        <TextInput
          name="transporte.numvol_top"
          label="Número"
          placeholder="Número"
          value={form.values.transporte?.numvol_top}
          onChange={handleChangeIntenger}
          disabled={!edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 md:col-2">
        <TextInput
          name="transporte.pebvol_top"
          label="Peso bruto"
          placeholder="Peso bruto"
          value={form.values.transporte?.pebvol_top ? formatDecimal(form.values.transporte?.pebvol_top, 3) : form.values.transporte?.pebvol_top}
          onChange={handleChangeDecimal}
          disabled={!edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 md:col-2">
        <TextInput
          name="transporte.pelvol_top"
          label="Peso líquido"
          placeholder="Peso líquido"
          value={form.values.transporte?.pelvol_top ? formatDecimal(form.values.transporte?.pelvol_top, 3) : form.values.transporte?.pelvol_top}
          onChange={handleChangeDecimal}
          disabled={!edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="mb-4 w-full"></div>

    </div>
  )
}

export default DadosTransporteOpv
