import ServiceBase from '../../base/ServiceBase'

export default class EntregaVeiculoService {
  static async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`entregarveiculo?${query}&page=${page}&perPage=${perPage}`)
  }

  static async filter (query, page, perPage) {
    return await ServiceBase.getBase(`entregarveiculo?${query}&page=${page}&perPage=${perPage}`)
  }

  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`entregarveiculo?${query}&page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`entregarveiculo?${query}&page=${page}&perPage=${perPage}`)
  }

  static async entregarVeiculo (data) {
    return await ServiceBase.postBase('entregarveiculo/entrega-veiculo', data)
  }

  static async cancelarEntrega (data) {
    return await ServiceBase.postBase('entregarveiculo/cancela-entrega', data)
  }

  static async autorizarEntrega (data) {
    return await ServiceBase.postBase('entregarveiculo/autorizar-entrega', data)
  }

  static async cancelarAutorizacaoEntrega (data) {
    return await ServiceBase.postBase('entregarveiculo/cancela-autorizacao-entrega', data)
  }
}
