import { Dialog } from 'primereact/dialog'
import React from 'react'
import AppButton from '../layout/AppButton'

const Modal = ({
  ref,
  nodeRef,
  noderef,
  btnExcluir,
  btnExcluirLabel,
  btnSalvar,
  btnSalvarLabel,
  icon,
  footer,
  width = '65',
  height,
  children,
  ...props
}) => {
  return (
    <Dialog
      className={`modal-vw-${width} h-${height}`}
      blockScroll
      resizable={false}
      footer={footer || <React.Fragment />}
      {...props}
    >
      {children}
      <div className="p-dialog-buttons">
        {btnExcluir !== undefined && (
          <AppButton
            label={btnExcluirLabel || 'Excluir'}
            icon="pi pi-times"
            type="button"
            onClick={async () => await btnExcluir()}
            className="p-button-danger mr-2"
          />
        )}
        {btnSalvar !== undefined && (
          <AppButton
            type="button"
            label={btnSalvarLabel || 'Salvar'}
            icon={icon || ''}
            className="p-button mr-2"
            onClick={async () => await btnSalvar()}
          />
        )}
      </div>
    </Dialog>
  )
}

export default Modal
