import { postProcessoUsoDTO } from '../../../dtos/processo/ProcessoUsoDTO'
import ServiceBase from '../../base/ServiceBase'

export default class ProcessoUsoService {
  static async save (processouso) {
    return await ServiceBase.postBase('processo-uso/', postProcessoUsoDTO(processouso))
  }

  static async get (codigo) {
    return await ServiceBase.getBase(`processo-uso/${codigo}`)
  }

  async save (processouso) {
    return await ServiceBase.postBase('processo-uso/', postProcessoUsoDTO(processouso))
  }

  async get (codigo) {
    return await ServiceBase.getBase(`processo-uso/${codigo}`)
  }

  static async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`processo-uso?${query}&page=${page}&perPage=${perPage}`)
  }

  static async filter (query, page, perPage) {
    return await ServiceBase.getBase(`processo-uso?${query}&page=${page}&perPage=${perPage}`)
  }

  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`processo-uso?${query}&page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`processo-uso?${query}&page=${page}&perPage=${perPage}`)
  }

  static async reabrir (codigo) {
    return await ServiceBase.postBase(`processo-uso/reabrir/${codigo}`)
  }

  static async cancelar (codigo) {
    return await ServiceBase.postBase(`processo-uso/cancelar/${codigo}`)
  }

  static async finalizar (codigo) {
    return await ServiceBase.postBase(`processo-uso/finalizar/${codigo}`)
  }
}
