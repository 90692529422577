import React, { useState } from 'react'
import AutoCompleteInput from '../../../../../components/inputs/AutoCompleteInput'
import PessoaAutocompleteTemplate from '../../../../../components/inputs/options/PessoaAutocompleteTemplate'
import { showWarnMessage } from '../../../../../components/utils/Message'
import Modal from '../../../../../components/utils/Modal'
import { formatDocument } from '../../../../../helpers/formaters'
import { PessoaModel } from '../../../../../models/cadastro/pessoa/PessoaModel'
import PessoaService from '../../../../../services/cadastro/pessoa/PessoaService'
import { PessoaFisica } from '../../../../../views/cadastro/pessoa/fisica/PessoaFisica'
import { PessoaJuridica } from '../../../../../views/cadastro/pessoa/juridica/PessoaJuridica'

const ModalPessoaRepresentante = ({
  visible,
  onHide,
  selected,
  edicaoHabilitada,
  insertOrUpdate,
  camposObrigatorios,
  setSelected,
  form,
  edicao
}) => {
  // * Estado e função para sugerir pessoas para o autocomplete
  const [sugestaoRepresentante, setSugestaoRepresentante] = useState([])
  async function sugerirRepresentante () {
    try {
      const representante = await PessoaService.filterAutocomplete(selected)

      if (representante.length <= 0) {
        showWarnMessage('Representante não encontrado!')
      }

      setSugestaoRepresentante(representante)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um representante!')
    }
  }

  // * Exclui representante da listagem e fecha modal
  function excluirRepresentante () {
    if (!edicao?.codigo_pes) {
      showWarnMessage('Selecione um representante da listagem!')
      return false
    }

    const listagemRepresentantes = form?.values?.representantes
    const representanteIgual = listagemRepresentantes?.filter(
      representante => representante.codigo_pes === edicao.codigo_pes
    )

    for (const representante of representanteIgual) {
      const indexRepresentante = listagemRepresentantes.indexOf(representante)
      listagemRepresentantes.splice(indexRepresentante, 1)
    }

    onHide()
  }

  // * Chama função do pai de inserir ou editar
  function adicionarRepresentante () {
    if (!selected.codigo_pes) {
      showWarnMessage('Selecione um representante!')
      return false
    }
    insertOrUpdate()
  }

  // * Seta string ou objeto
  function handleChangeOrSelectRepresentante (e) {
    // * valor em string para função sugerirRepresentante
    if (typeof e.value === 'string') {
      setSelected(e.value)
      return
    }

    // * Confere se o objeto ja existe na listagem
    // * Seta objeto ja existente ou altera para novo
    const listagemRepresentantes = form?.values?.representantes
    const representanteJaEstaNaListagem = listagemRepresentantes?.find(
      representante => representante.codigo_pes === e.value.codigo_pes
    )

    setSelected(
      !!representanteJaEstaNaListagem
        ? representanteJaEstaNaListagem
        : {
          codigo_pes: e.value.codigo_pes,
          nomraz_pes: e.value.nomraz_pes
        }
    )
  }

  return (
    <>
      <Modal
        visible={visible}
        onHide={onHide}
        header={'Representante'}
        btnSalvarLabel={!!edicao ? 'Atualizar representante' : 'Adicionar representante'}
        icon={!!edicao ? '' : 'pi pi-plus'}
        width={40}
        btnSalvar={(edicaoHabilitada) ? adicionarRepresentante : undefined}
        btnExcluir={(edicaoHabilitada && !!edicao) ? excluirRepresentante : undefined}
      >
        <div className='md:flex'>
          <div className="field col-12">
            <AutoCompleteInput
              // * AutoComplete Props Padrões
              label="Representante"
              name="representante.codigo_pes"
              field="nomraz_pes"
              placeholder="Nome do representante"
              disabled={!edicaoHabilitada}
              camposObrigatorios={camposObrigatorios}

              // * Template pro campo valor
              itemTemplate={PessoaAutocompleteTemplate}
              value={typeof selected === 'string' ? selected : selected?.nomraz_pes }

              // * Controle do texto e sugestões
              onSelect={handleChangeOrSelectRepresentante}
              minLength={1}
              onChange={handleChangeOrSelectRepresentante}
              completeMethod={sugerirRepresentante}
              suggestions={sugestaoRepresentante}

              // * RegisterModal
              form={form}
              selected={typeof selected === 'string' ? null : selected}
              onSaveModal={(representante) => handleChangeOrSelectRepresentante({ value: representante })}
              onDeleteModal={() => handleChangeOrSelectRepresentante({})}
              ComponenteCadastro={[
                {
                  label: 'Pessoa física',
                  component: PessoaFisica,
                  header: selected?.codigo_pes
                    ? `Pessoa Física - ${selected?.codigo_pes}`
                    : 'Cadastro de pessoa física'
                },
                {
                  label: 'Pessoa jurídica',
                  component: PessoaJuridica,
                  header: selected?.codigo_pes
                    ? `Pessoa jurídica - ${selected?.codigo_pes}`
                    : 'Cadastro de pessoa jurídica'
                }
              ]}

              // * FiltroModal
              filtrotitle="Pesquisa de representante"
              service={PessoaService}
              model={PessoaModel}
              columns={[
                { campo: 'codigo_pes', nome: 'Código' },
                { campo: 'nomraz_pes', nome: 'Nome' },
                { campo: 'cgccpf_pes', nome: 'CPF/CNPJ', format: formatDocument }
              ]}

              // * RegisterModal && FiltroModal
              primarykeyname="codigo_pes"
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalPessoaRepresentante
