import * as Yup from 'yup'

export const EtapaProcessoValidator = Yup.object().shape({
  tipo_etapa: Yup.object()
    .shape({
      codigo_tet: Yup.number()
        .nullable()
        .required('Campo tipo de etapa é obrigatório!')
    })
    .nullable(),
  etapa_responsabilidade: Yup.object()
    .shape({
      codigo_etr: Yup.number()
        .nullable()
        .required('Campo responsável da etapa é obrigatório!')
    })
    .nullable()
})
