import React, { useEffect, useState } from 'react'
import SelectInput from '../../../../../../components/inputs/SelectInput'
import CommonHelper from '../../../../../../helpers/CommonHelper'
import { showErrorMessage } from '../../../../../../components/utils/Message'
import Modal from '../../../../../../components/utils/Modal'
import RequiredLabel from '../../../../../../components/utils/RequiredLabel'
import FieldErrorMessage from '../../../../../../components/utils/FieldErrorMessage'
import { useValidateInput } from '../../../../../../helpers/useValidateInput'
import { classNames } from 'primereact/utils'
import ArquivoReferenteService from '../../../../../../services/processo/ArquivoReferenteService'
import TextInput from '../../../../../../components/inputs/TextInput'
import { Checkbox } from 'primereact/checkbox'

const PendenciasUsoModal = ({
  visible,
  onHide,
  pendenciaForm,
  pendenciaSelecionada,
  form,
  edicaoHabilitada,
  camposObrigatorios,
  setPendencias
}) => {
  // * busca index da pendencia selecionada na listagem
  async function getIndexPendenciaSelecionada () {
    const pendencias = form.values.pendencias
    const indexPendencia = pendencias.indexOf(pendenciaSelecionada)
    return indexPendencia
  }

  // * Exclui pendencia da listagem
  async function excluirPendencia () {
    const pendencias = form.values.pendencias
    await getIndexPendenciaSelecionada().then(result => {
      pendencias.splice(result, 1)
    })
    setPendencias(pendencias)
    onHide()
  }

  // * Submita para add Pendencia na listagem
  async function adicionarPendencias () {
    await pendenciaForm.submitForm()
  }

  // * Constantes
  const [arquivoReferente, setArquivoReferente] = useState([])

  // * Realiza busca no primeiro render
  useEffect(async () => {
    await getArquivoReferente()
  }, [])

  // * Gets para Selects Inpúts
  const getArquivoReferente = async () => {
    try {
      await ArquivoReferenteService.getAll().then(ArquivoReferente => setArquivoReferente(ArquivoReferente))
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao buscar as origens de anexo')
    }
  }

  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(pendenciaForm)

  useEffect(() => {
    if (!!pendenciaSelecionada || !visible) return
    if (!pendenciaForm.values?.origem_anexo?.codigo_tet && arquivoReferente.length) {
      pendenciaForm.setFieldValue('origem_anexo', arquivoReferente[0])
      pendenciaForm.validateField('origem_anexo.codigo_are')
    }
  }, [visible, arquivoReferente, pendenciaSelecionada])

  return (
    <>
      <Modal
        visible={visible}
        onHide={onHide}
        header={'Pendência'}
        btnSalvarLabel={pendenciaSelecionada ? 'Atualizar pendência' : 'Adicionar pendência'}
        icon={pendenciaSelecionada ? '' : 'pi pi-plus'}
        width={75}
        btnSalvar={edicaoHabilitada ? adicionarPendencias : undefined}
        btnExcluir={
          (pendenciaSelecionada && edicaoHabilitada)
            ? excluirPendencia
            : undefined}
        btnExcluirLabel={pendenciaForm.values?.codeus_tpu ? 'Remover pendência' : null}
      >
        <div className="formgrid grid">
          <div className="field col-12">
            <RequiredLabel label="Descrição" valid={isFormFieldValid('descri_tpu')} />
            <TextInput
              placeholder="Descrição"
              name="descri_tpu"
              className={classNames({ 'p-invalid': isFormFieldValid('descri_tpu') }, 'inputfield w-full')}
              value={pendenciaForm.values.descri_tpu}
              onChange={pendenciaForm.handleChange}
              disabled={!edicaoHabilitada}
              onBlur={() => pendenciaForm.setFieldTouched('descri_tpu')}
            />
            <FieldErrorMessage message={getFormErrorMessage('descri_tpu')} />
          </div>

          <div className="field col-6">
            <RequiredLabel label="Origem do anexo" valid={isFormFieldValid('origem_anexo.codigo_are')} />
            <SelectInput
              value={ pendenciaForm.values.origem_anexo.codigo_are && pendenciaForm.values.origem_anexo }
              options={arquivoReferente}
              onChange={(e) => {
                pendenciaForm.setFieldValue('origem_anexo', e.target.value || {})
                pendenciaForm.validateField('origem_anexo.codigo_are')
              }}
              form={pendenciaForm}
              name="origem_anexo"
              placeholder="Selecione a origem do anexo"
              filter={CommonHelper.isDesktop()}
              optionLabel="descri_are"
              dataKey="codigo_are"
              className={classNames({ 'p-invalid': isFormFieldValid('origem_anexo.codigo_are') }, 'inputfield w-full')}
              noFloatLabel
              disabled={!edicaoHabilitada}
              onBlur={() => pendenciaForm.setFieldTouched('origem_anexo.codigo_are')}
            />
            <FieldErrorMessage message={getFormErrorMessage('origem_anexo.codigo_are')} />
          </div>
          <div className="checkbox-container col-12 md:col-2 flex justify-content-center">
            <Checkbox
              value={pendenciaForm.values.exiarq_tpu}
              onChange={(e) => {
                pendenciaForm.setFieldValue('exiarq_tpu', !e.target.value)
              }}
              checked={pendenciaForm.values.exiarq_tpu}
              name="exiarq_tpu"
              disabled={!edicaoHabilitada}
            />
            <label htmlFor="exiarq_tpu" className="p-checkbox-label mb-0 pl-2">Exige arquivo?</label>
          </div>
          <div className="checkbox-container col-12 md:col-2 flex justify-content-center">
            <Checkbox
              value={pendenciaForm.values.perign_tpu}
              onChange={(e) => {
                pendenciaForm.setFieldValue('perign_tpu', !e.target.value)
              }}
              checked={pendenciaForm.values.perign_tpu}
              name="perign_tpu"
              disabled={!edicaoHabilitada}
            />
            <label htmlFor="perign_tpu" className="p-checkbox-label mb-0 pl-2">Permite ignorar</label>
          </div>
        </div>
      </Modal>
    </>

  )
}

export default PendenciasUsoModal
