import { TreeSelect } from 'primereact/treeselect'
import React, { Component } from 'react'
import { getStore } from '../../../redux/store'
import UsuarioService from '../../../services/cadastro/pessoa/UsuarioService'
import './_empresasAcessoTreeSelect.scss'

class EmpresasAcessoTreeSelect extends Component {
  constructor (props) {
    super(props)

    this.state = {
      opcoesTreeSelect: []
    }
  }

  async componentDidMount () {
    if (!this.props.selectedOptions && !this.props.comecaSemNada) {
      this.props.changeSelectedOptions({
        0: { checked: false, partialChecked: true },
        '0-0': { checked: true, partialChecked: false }
      })
    }

    const empresasAcessoUsuarioLogado = await UsuarioService.getEmpresasAcessoUsuarioLogado()

    this.moveEmpresaLogadaParaComecoArray(empresasAcessoUsuarioLogado)

    const childrenTreeSelect = this.montaChildrenParaTreeSelect(empresasAcessoUsuarioLogado)

    const opcoesTreeSelect = this.montaOpcoesTreeSelect(childrenTreeSelect)

    this.setState({ opcoesTreeSelect })
  }

  montaOpcoesTreeSelect (childrenTreeSelect) {
    return [{
      key: 0,
      label: 'Empresas',
      children: [...childrenTreeSelect]
    }]
  }

  montaChildrenParaTreeSelect (empresasAcesso) {
    return empresasAcesso.map((empresa, idx) => {
      return {
        key: `0-${idx}`,
        label: empresa.apelid_pes,
        data: empresa
      }
    })
  }

  moveEmpresaLogadaParaComecoArray (empresas) {
    const empresaLogada = empresas.filter((empresa) => empresa.codigo_pes === getStore().empresaLogada.codigo_emp).shift()
    empresas.splice(empresas.indexOf(empresaLogada), 1)
    empresas.unshift(empresaLogada)
  }

  atualizaEmpresasSelecionadas (e) {
    const empresas = []

    for (const propertyName of Object.getOwnPropertyNames(e.value)) {
      const indexEmpresa = propertyName.substring(2)

      if (indexEmpresa)
        empresas.push(this.state.opcoesTreeSelect[0].children[indexEmpresa].data)
    }

    this.props.changeSelectedEmpresas(empresas)
  }

  render () {
    const props = this.props

    return (
      <>
        {props.label && (
          <label>{props.label}</label>
        )}
        <TreeSelect
          value={this.props.selectedOptions}
          options={this.state.opcoesTreeSelect}
          onChange={(e) => {
            this.atualizaEmpresasSelecionadas(e)

            this.props.changeSelectedOptions(e.value)
          }}
          display="chip"
          selectionMode="checkbox"
          className="inputfield w-full"
          placeholder="Selecione as empresas"
          filter
        />
      </>
    )
  }
}

export default EmpresasAcessoTreeSelect
