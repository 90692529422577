import React from 'react'
import MarcaDeParaService from '../../services/Integracao/MarcaDeParaService'
import CommonHelper from '../../helpers/CommonHelper'
import Page from '../../components/layout/Page'
import MarcaAutoGestorService from '../../services/Integracao/autogestor/MarcaAutoGestorService'
import { Column } from 'primereact/column'
import SelectInput from '../../components/inputs/SelectInput'
import DataList from '../../components/layout/DataList'
import List from '../../classes/List'
import { MarcaDeParaModel } from '../../models/cadastro/veiculo/MarcaDeParaModel'
import AppButton from '../../components/layout/AppButton'
import CheckboxInput from '../../components/inputs/CheckboxInput'

export class DeParaMarcaForm extends List {
  constructor (props) {
    super(props, MarcaDeParaModel, MarcaDeParaService, 'marcadepara', 'codigo_mdp')

    this.state = {
      ...this.state,
      deParaMarcas: [],
      marcasAG: [],
      loadingDePara: [true],
      somentePendentes: false
    }
  }

  async componentDidMount () {
    await super.componentDidMount()
    await this.buscaMarcasAG()
  }

  async buscaMarcasAG () {
    const marcasAG = await MarcaAutoGestorService.getAllMarcasAG()
    this.setState({ marcasAG: marcasAG })
  }

   handleChangeMarcaAG = async (key, props, value) => {
     const marca = value
     props.rowData.codmaraug_mdp = marca
     props.rowData.codmartur_mdp = props.rowData.codigo_mar
     await MarcaDeParaService.save(props.rowData)
     let updatedMarcasAG = [...props.value]
     updatedMarcasAG[props.rowIndex][props.field] = value
     this.setState({ [`${key}`]: updatedMarcasAG })
     await this.onFilter()
   }

  marcaAutoGestorSearch = (key, props) => {
    const { marcasAG } = this.state
    return (
      <SelectInput
        value={props.rowData['codmaraug_mdp']}
        options={marcasAG}
        onChange={(e) => this.handleChangeMarcaAG(key, props, e.value)}
        dataKey="codigo_mag"
        name="marcaAG"
        filter={CommonHelper.isDesktop()}
        optionLabel="descri_mag"
        optionValue="codigo_mag"
        noFloatLabel
        className={'inputfield w-full'}
      />
    )
  }

  statusBodyTemplate = (rowData) => {
    return rowData.descri_mag
  }

  onFilterClick = () => {
    const filter = this.state.filter
    filter.somentePendentes = this.state.somentePendentes
    this.setState({ filter })
    this.onFilter()
  }

  expandedFiltersTemplate = () => (
    <>
      <div className="field col-12 md:col-3">
        <CheckboxInput
          inputId="chkSomentePendente"
          value={this.state.somentePendentes}
          label="Apenas não configurados"
          checked={this.state.somentePendentes}
          onChange={(e) => this.setState({ somentePendentes: !e.target.value })} />
      </div>
      <div className="col-12 flex justify-content-end mb-4">
        <AppButton label="Buscar" icon="pi pi-search" onClick={this.onFilterClick} />
      </div>
    </>
  )

  render () {
    const { rows, totalRecords, first } = this.state

    return (

      <Page>
        <DataList
          data={this.state.list}
          selected={this.state.selected}
          editMode="cell"
          className="datalist p-datatable-responsive"
          rows={rows}
          totalRecords={totalRecords}
          first={first}
          onPage={this.onPageChange}
          page={this.state.page}
          showFilter={true}
          filterPlaceholder="Procurar Marcas"
          filterName="descricaoFiltro"
          responsive={true}
          filterOnChange={this.handleChangeFilter}
          onFilter={this.onFilter}
          expandedFiltersTemplate={this.expandedFiltersTemplate}
          customMaxHeight={'unset'}
        >
          <Column field="codigo_mar" header="Código" style={{ width: '8%', textAlign: 'left' }} ></Column>
          <Column field="descri_mar" header="Marca" style={{ width: '20%', textAlign: 'left' }} ></Column>
          <Column field="codmaraug_mdp" header="Marca Auto Gestor" style={{ width: '72%', textAlign: 'left' }} body={this.statusBodyTemplate} editor={(props) => this.marcaAutoGestorSearch('list', props)}></Column>
        </DataList>

      </Page>
    )
  }
}
export default DeParaMarcaForm
