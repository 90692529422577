import React, { useEffect, useState } from 'react'
import SelectInput from '../../../../../components/inputs/SelectInput'
import EtapaResponsabilidade from '../../../../../services/processo/EtapaResponsabilidade'
import AutoCompleteInput from '../../../../../components/inputs/AutoCompleteInput'
import { PessoaFisica } from '../../../../cadastro/pessoa/fisica/PessoaFisica'
import { PessoaJuridica } from '../../../../cadastro/pessoa/juridica/PessoaJuridica'
import { formatDocument } from '../../../../../helpers/formaters'
import PessoaService from '../../../../../services/cadastro/pessoa/PessoaService'
import { PessoaModel } from '../../../../../models/cadastro/pessoa/PessoaModel'
import PessoaAutocompleteTemplate from '../../../../../components/inputs/options/PessoaAutocompleteTemplate'
import CommonHelper from '../../../../../helpers/CommonHelper'
import { showErrorMessage } from '../../../../../components/utils/Message'
import Modal from '../../../../../components/utils/Modal'
import RequiredLabel from '../../../../../components/utils/RequiredLabel'
import FieldErrorMessage from '../../../../../components/utils/FieldErrorMessage'
import { useValidateInput } from '../../../../../helpers/useValidateInput'
import { classNames } from 'primereact/utils'
import DadosPendencias from './Pendencias/ListWithModal'
import TipoEtapaService from '../../../../../services/processo/TipoEtapaService'

const EtapasEPendenciasModal = ({
  visible,
  onHide,
  etapaForm,
  etapaSelecionada,
  form,
  edicaoHabilitada,
  camposObrigatorios,
  setEtapas
}) => {
  // * busca index da etapa selecionada na listagem
  async function getIndexEtapaSelecionada () {
    const etapas = form.values.etapas
    const indexEtapa = etapas.indexOf(etapaSelecionada)
    return indexEtapa
  }

  // * Exclui etapa da listagem
  async function excluirEtapa () {
    const etapas = form.values.etapas
    await getIndexEtapaSelecionada().then(result => {
      etapas.splice(result, 1)
    })
    setEtapas(etapas)
    onHide()
  }

  // * Submita para add Etapa na listagem
  async function adicionarEtapas () {
    await etapaForm.submitForm()
  }

  // * Constantes
  const [sugestaoResponsavel, setSugestaoResponsavel] = useState([])
  const [tipoEtapa, setTipoEtapa] = useState([])
  const [etapaResponsabilidade, setEtapaResponsabiidade] = useState([])

  // * Realiza busca no primeiro render
  useEffect(async () => {
    await getEtapasResponsabilidade()
    await getTipoEtapa()
  }, [])

  // * Gets para Selects Inpúts
  const getEtapasResponsabilidade = async () => {
    try {
      await EtapaResponsabilidade.getAll().then(etapasResponsabilidades => setEtapaResponsabiidade(etapasResponsabilidades))
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao buscar as etapas de responsabilidade')
    }
  }
  const getTipoEtapa = async () => {
    try {
      await TipoEtapaService.filterAutocomplete().then(tiposEtapas => setTipoEtapa(tiposEtapas))
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao buscar os tipos de etapas')
    }
  }

  // * Functions para Autocomplete Responsável
  async function onSelectResponsavel (e) {
    await etapaForm.setFieldValue('responsavel', e.value)
    await etapaForm.setFieldTouched('responsavel.codigo_pes')
  }
  function handleChangeResponsavel (event) {
    etapaForm.setFieldValue('responsavel', { nomraz_pes: event.target.value })
  }
  async function sugerirResponsavel ({ query }) {
    try {
      const responsaveis = await PessoaService.filterAutocomplete(query)

      if (responsaveis.length <= 0) {
        showWarnMessage('Conveniado não encontrado!')
      }
      setSugestaoResponsavel(responsaveis)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um responsável!')
    }
  }

  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(etapaForm)

  useEffect(() => {
    if (!!etapaSelecionada || !visible) return
    if (!etapaForm.values?.etapa_responsabilidade?.codigo_tet && etapaResponsabilidade.length) {
      etapaForm.setFieldValue('etapa_responsabilidade', etapaResponsabilidade[0])
      etapaForm.validateField('etapa_responsabilidade.codigo_etr')
    }
  }, [visible, etapaResponsabilidade, etapaSelecionada])

  return (
    <>
      <Modal
        visible={visible}
        onHide={onHide}
        header={`Etapa${etapaForm.values?.ordem_eta ? ` - ${etapaForm.values?.ordem_eta}` : ''}`}
        btnSalvarLabel={etapaSelecionada ? 'Atualizar etapa' : 'Adicionar etapa'}
        icon={etapaSelecionada ? '' : 'pi pi-plus'}
        width={75}
        btnSalvar={(edicaoHabilitada) ? adicionarEtapas : undefined}
        btnExcluir={
          (etapaSelecionada && edicaoHabilitada)
            ? excluirEtapa
            : undefined}
        btnExcluirLabel={etapaForm.values?.ordem_eta ? 'Remover etapa' : null}
      >
        <div className="formgrid grid">
          <div className="field col-6">
            <RequiredLabel label="Tipo da etapa" valid={isFormFieldValid('tipo_etapa.codigo_tet')} />
            <SelectInput
              value={ etapaForm.values?.tipo_etapa?.codigo_tet && etapaForm.values?.tipo_etapa }
              options={tipoEtapa}
              onChange={(e) => {
                etapaForm.setFieldValue('tipo_etapa', e.target.value || {})
                etapaForm.validateField('tipo_etapa.codigo_tet')
              }}
              form={etapaForm}
              name="tipo_etapa"
              placeholder="Selecione o tipo da etapa"
              filter={CommonHelper.isDesktop()}
              optionLabel="descri_tet"
              dataKey="codigo_tet"
              className={classNames({ 'p-invalid': isFormFieldValid('tipo_etapa.codigo_tet') }, 'inputfield w-full')}
              noFloatLabel
              disabled={!edicaoHabilitada}
              onBlur={() => etapaForm.setFieldTouched('tipo_etapa.codigo_tet')}
            />
            <FieldErrorMessage message={getFormErrorMessage('tipo_etapa.codigo_tet')} />
          </div>
          <div className="field col-6">
            <RequiredLabel label="Responsável da etapa" valid={isFormFieldValid('etapa_responsabilidade.codigo_etr')} />
            <SelectInput
              value={ etapaForm.values.etapa_responsabilidade.codigo_etr && etapaForm.values.etapa_responsabilidade }
              options={etapaResponsabilidade}
              onChange={(e) => {
                etapaForm.setFieldValue('etapa_responsabilidade', e.target.value || {})
                etapaForm.validateField('etapa_responsabilidade.codigo_etr')
              }}
              form={etapaForm}
              name="etapa_responsabilidade"
              placeholder="Selecione o responsável da etapa"
              filter={CommonHelper.isDesktop()}
              optionLabel="descri_etr"
              dataKey="codigo_etr"
              className={classNames({ 'p-invalid': isFormFieldValid('etapa_responsabilidade.codigo_etr') }, 'inputfield w-full')}
              noFloatLabel
              disabled={!edicaoHabilitada}
              onBlur={() => etapaForm.setFieldTouched('etapa_responsabilidade.codigo_etr')}
            />
            <FieldErrorMessage message={getFormErrorMessage('etapa_responsabilidade.codigo_etr')} />
          </div>
          {etapaForm.values.etapa_responsabilidade?.descri_etr === 'Pessoa específica' && (
            <div className="field col-12">
              <AutoCompleteInput
                // * AutoComplete Props Padrões
                label="Responsavel"
                name="responsavel.codigo_pes"
                field="nomraz_pes"
                placeholder="Nome do responsável"
                disabled={!edicaoHabilitada}
                camposObrigatorios={camposObrigatorios}

                // * Template pro campo valor
                itemTemplate={PessoaAutocompleteTemplate}
                value={etapaForm.values.responsavel && etapaForm.values.responsavel?.nomraz_pes}

                // * Controle do texto e sugestões
                onSelect={async (e) => await onSelectResponsavel(e)}
                minLength={1}
                suggestions={sugestaoResponsavel}
                completeMethod={sugerirResponsavel}
                onChange={handleChangeResponsavel}

                // * RegisterModal
                form={etapaForm}
                selected={etapaForm.values.responsavel}
                onSaveModal={() => {}}
                onDeleteModal={async () => await onSelectResponsavel({})}
                ComponenteCadastro={[
                  {
                    label: 'Pessoa física',
                    component: PessoaFisica,
                    header: etapaForm.values.responsavel?.codigo_pes ? 'Pessoa Física - ' + etapaForm.values.responsavel?.codigo_pes : 'Cadastro de pessoa física'
                  },
                  {
                    label: 'Pessoa jurídica',
                    component: PessoaJuridica,
                    header: etapaForm.values.responsavel?.codigo_pes ? 'Pessoa jurídica - ' + etapaForm.values.responsavel?.codigo_pes : 'Cadastro de pessoa jurídica'
                  }
                ]}

                // * FiltroModal
                filtrotitle="Pesquisa de responsável"
                semGetInicial
                service={PessoaService}
                model={PessoaModel}
                columns={[
                  { campo: 'codigo_pes', nome: 'Código' },
                  { campo: 'nomraz_pes', nome: 'Nome' },
                  { campo: 'cgccpf_pes', nome: 'CPF/CNPJ', format: formatDocument }
                ]}

                // * RegisterModal && FiltroModal
                primarykeyname="codigo_pes"
              />
            </div>
          )}
          <div className="field col-12">
            <DadosPendencias
              form={etapaForm}
              edicaoHabilitada={edicaoHabilitada}
            />
          </div>
        </div>
      </Modal>
    </>

  )
}

export default EtapasEPendenciasModal
