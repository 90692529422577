import * as queryString from 'query-string'
import { Component } from 'react'
import { showErrorMessage, showWarnMessage } from '../components/utils/Message'

export default class List extends Component {
  constructor (props, Model, Service, resource, primaryKey = 'id', rows = 20, opcaoSelecionada = 't') {
    super(props)
    this.model = Model
    this.modelService = new Service()
    this.resource = resource
    this.primaryKey = primaryKey
    this.state = {
      visible: false,
      visibleConfirm: false,
      confirmDescription: '',
      confirmTitle: '',
      onConfirmCallback: () => this.cancelConfirm(),
      filterCollapsed: true,
      selected: this.model,
      filter: this.model,
      list: [],
      rows: rows,
      page: 1,
      first: 1,
      totalRecords: 0,
      totalPages: 0,
      opcaoSelecionada: opcaoSelecionada,
      meta: {}
    }

    this.columns = null
  }

  async componentDidMount () {
    await this.getAll()
    this.addEventListeners()
    this.resetDescricaoFiltro()
  }

  addEventListeners = () => {
    if (!this.columns) return

    const campos = this.columns.map(column => column.key)

    for (const campo of campos) {
      const elemento = document.querySelector(`.${campo}`)

      if (!elemento || !campo) continue

      elemento.addEventListener('click', () => {
        const { filter } = this.state
        filter.ordemCampo = filter.ordemCampo === `${campo}|asc` ? `${campo}|desc` : `${campo}|asc`
        this.setState({ filter }, this.onFilter)
      })
    }
  }

  resetDescricaoFiltro () {
    const { filter } = this.state
    filter.descricaoFiltro = undefined
    filter.descricaoCodigo = undefined
    this.setState({ filter })
  }

  getAll = async () => {
    try {
      let response = await this.modelService.getAll(this.toFilter(this.state.filter), this.state.page, this.state.rows)
      await this.setRetorno(response)
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao listar os registros!')
    }
  }

  onFilter = async () => {
    try {
      // Verifica se o hidePaginator está true e se os filtros adicionais são iguais aos filtros default
      const isOnlyDefaultFilters = this.props.hidePaginator && this.areFiltersEqual(this.state.filter, this.props.filtersDefault)

      if (isOnlyDefaultFilters) {
        showWarnMessage('Por favor, informe ao menos um filtro para realizar a pesquisa.')
        return
      }

      if (this.state.semGetInicial) {
        this.setState({ semGetInicial: false })
        return
      }

      // Caso a validação passe, faz a requisição de filtro
      let response = await this.modelService.filter(
        this.toFilter({ ...this.state.filter, ...this.props.filtersDefault }),
        this.state.page,
        this.state.rows
      )

      await this.setRetorno(response)
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao filtrar os registros!')
    }
  }

  // Método auxiliar para verificar igualdade entre os filtros
  areFiltersEqual = (filter, defaultFilter) => {
    // Verifica se todas as chaves e valores do defaultFilter estão no filter
    const defaultKeys = Object.keys(defaultFilter)

    // Garante que nenhum filtro adicional foi preenchido
    const allKeys = Object.keys(filter)

    // Filtros adicionais preenchidos além dos padrões
    const additionalKeys = allKeys.filter(key => !defaultKeys.includes(key))

    return additionalKeys.length === 0
  };

  setRetorno = async (response) => {
    await this.setState({
      list: response.data,
      totalRecords: response.meta.total,
      totalPages: response.meta.last_page,
      meta: response.meta,
      ...response
    })
  }

  toFilter (obj) {
    const validFields = Object
      .keys(obj)
      .filter(it => !!obj[it])
      .map(it => ({ [it]: obj[it] }))

    let fields = {}

    validFields.forEach(it => {
      fields = { ...it, ...fields }
    })

    return queryString.stringify(fields)
  }

  onPageChange = async data => {
    const first = data.first
    const page = data.page + 1
    await this.setState({ first, page })
    this.onFilter()
  }

  onView = async (selected) => {
    this.props.history.push(`/${this.resource}/${selected[this.primaryKey]}`)
  }

  handleChangeFilter = event => {
    const filter = this.state.filter
    if (event.target.value.length === 0) {
      delete filter[event.target.name] // Remove se o valor for vazio
    } else {
      filter[event.target.name] = event.target.value
    }

    if (!!this.state?.deleteFilters?.length) {
      this.state?.deleteFilters?.map((palavra) =>
        delete filter[palavra]
      )
    }

    this.setState({ filter })
  }

  onSelect = selected => {
    this.setState({ selected })
  }

  onNew = () => {
    this.props.history.push(`/${this.resource}`)
  }

  setColumns = (columns) => {
    this.columns = columns
  }

  handleRowExpansion = (e) => {
    let { expandedRows } = this.state

    if (expandedRows && expandedRows[0] === e) {
      expandedRows = null
    } else {
      expandedRows = [e]
    }

    this.setState({ expandedRows })
  }

  showConfirm = () => {
    this.setState({ visibleConfirm: true })
  }

  showCustomConfirm = (title, description, onConfirmCallback) => {
    this.setState({ confirmTitle: title, confirmDescription: description, onConfirmCallback }, this.showConfirm)
  }

  cancelConfirm = () => {
    this.setState({ visibleConfirm: false })
  }
}
