import { Checkbox } from 'primereact/checkbox'
import { classNames } from 'primereact/utils'
import React, { useEffect, useState } from 'react'
import DateTimeInput from '../../../../components/inputs/DateTimeInput'
import NumberInput from '../../../../components/inputs/NumberInput'
import TextInput from '../../../../components/inputs/TextInput'
import PessoaAutocompleteTemplate from '../../../../components/inputs/options/PessoaAutocompleteTemplate'
import SearchInput from '../../../../components/inputs/searchInput/SearchInput'
import FieldErrorMessage from '../../../../components/utils/FieldErrorMessage'
import { showWarnMessage } from '../../../../components/utils/Message'
import Modal from '../../../../components/utils/Modal'
import CommonHelper from '../../../../helpers/CommonHelper'
import { formatCurrencyToNumber, formatDocument, formatPlaca, formatToCurrency } from '../../../../helpers/formaters'
import { useValidateInput } from '../../../../helpers/useValidateInput'
import { PessoaModel } from '../../../../models/cadastro/pessoa/PessoaModel'
import PermissaoService from '../../../../services/cadastro/pessoa/PermissaoService'
import PessoaService from '../../../../services/cadastro/pessoa/PessoaService'
import { PessoaFisica } from '../../../../views/cadastro/pessoa/fisica/PessoaFisica'
import { PessoaJuridica } from '../../../../views/cadastro/pessoa/juridica/PessoaJuridica'
import VeiculoModal from '../../../cadastro/veiculo/veiculo/VeiculoModal'

function VeiculoConjuntoModal ({ visible, onHide, veiculoNve, form, edicaoHabilitada, operacaoNeg, pagandoConsignado }) {
  const [visibleVeiculoModal, setVisibleVeiculoModal] = useState(false)
  const [visibleAcessoriosModal, setVisibleAcessoriosModal] = useState(false)
  const [acessoriosList, setAcessoriosList] = useState('')
  const [isRepasse, setIsRepasse] = useState(veiculoNve?.repass_nve)
  const [isAtivoImobilizado, setIsAtivoImobilizado] = useState(veiculoNve?.atvimo_nve)
  const [realizadoTestDrive, setRealizadoTestDrive] = useState(veiculoNve?.hastesdri_nve)
  const [permissaoAlterarPreco, setPermissaoAlterarPreco] = useState(true)
  const [sugestaoPessoas, setSugestaoPessoas] = useState([])
  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)

  const isVenda = () => {
    return operacaoNeg === 'Venda'
  }

  const isCompra = () => {
    return operacaoNeg === 'Compra'
  }

  const isConsignado = () => {
    return operacaoNeg === 'Consignação'
  }

  useEffect(() => {
    if (veiculoNve.codvei_nve) {
      setIsRepasse(veiculoNve.repass_nve)
      setIsAtivoImobilizado(veiculoNve.atvimo_nve)
      setRealizadoTestDrive(veiculoNve.hastesdri_nve)
      form.setFieldValue('proprietario', veiculoNve.veiculo?.proprietario)
      form.setFieldValue('negociacaoVeiculo.proprietario', veiculoNve.veiculo?.proprietario)
      form.setFieldValue('negociacaoVeiculo.codvei_nve', veiculoNve.codvei_nve)
    }
  }, [veiculoNve])

  useEffect(() => {
    if (veiculoNve.codvei_nve && isVenda()) {
      changeVeiculoConjunto('valor_nve', (veiculoNve?.valbru_nve - veiculoNve?.valdes_nve))
    }
  }, [veiculoNve?.valbru_nve, veiculoNve?.valdes_nve])

  useEffect(async () => {
    const permissaoAlterarPrecoFuturo = await PermissaoService.getByCodigo('60')
    setPermissaoAlterarPreco(permissaoAlterarPrecoFuturo)
  }, [])

  function changeVeiculoConjunto (propertyName, propertyValue) {
    const { veiculosConjunto } = form.values
    if (veiculosConjunto.length > 0) {
      const indexVeiculo = getVeiculoConjuntoIndex()
      veiculosConjunto[indexVeiculo][propertyName] = propertyValue
      form.setFieldValue('veiculosConjunto', veiculosConjunto)
      form.setFieldValue('negociacaoVeiculo.valor_nve', propertyValue?.valor_nve)
    }
  }

  function getVeiculoConjuntoIndex () {
    const { veiculosConjunto } = form.values

    const veiculo = veiculosConjunto.find(veiculo => veiculo.codvei_nve === veiculoNve.codvei_nve)
    const indexVeiculo = veiculosConjunto.indexOf(veiculo)
    return indexVeiculo
  }

  function handleChangeRepasse () {
    changeVeiculoConjunto('repass_nve', !isRepasse)
    setIsRepasse(!isRepasse)
  }

  function handleChangeAtivoImobilizado () {
    changeVeiculoConjunto('atvimo_nve', !isAtivoImobilizado)
    setIsAtivoImobilizado(!isAtivoImobilizado)
  }

  function handleChangeRealizadoTestDrive () {
    changeVeiculoConjunto('hastesdri_nve', !realizadoTestDrive)
    setRealizadoTestDrive(!realizadoTestDrive)
  }

  function handleChangeKmAtual (event) {
    changeVeiculoConjunto('kmatu_nve', event.value)
  }

  function handleChangePrevisaoEntrega (event) {
    changeVeiculoConjunto('dahpreent_nve', event.target.value)
  }

  function handleChangeValorBruto (event) {
    changeVeiculoConjunto('valbru_nve', formatCurrencyToNumber(event.target.value))
  }

  function handleChangeValorDesconto (event) {
    changeVeiculoConjunto('valdes_nve', formatCurrencyToNumber(event.target.value))
  }

  function handleChangeValorCompra (event) {
    changeVeiculoConjunto('valor_nve', formatCurrencyToNumber(event.target.value))
  }

  function handleChangeValorVenda (event) {
    const { veiculosConjunto } = form.values

    if (veiculosConjunto.length > 0) {
      const indexVeiculo = getVeiculoConjuntoIndex()
      veiculosConjunto[indexVeiculo].valfut_nve = formatCurrencyToNumber(event.target.value)
      form.setFieldValue('veiculosConjunto', veiculosConjunto)
    }
  }

  function onBlurValorBruto (event) {
    const valorBruto = formatCurrencyToNumber(event.target.value)

    const produtoEmpresa = veiculoNve.veiculo.produto.produtoEmpresas

    if ((valorBruto - (produtoEmpresa.prepro_pre || produtoEmpresa.preco_pre)) < 0) {
      showWarnMessage('Valor bruto não pode ser menor que o valor de oferta! O valor bruto foi resetado para o valor de oferta!')
      changeVeiculoConjunto('valbru_nve', produtoEmpresa.prepro_pre || produtoEmpresa.preco_pre)
    }
  }

  function onBlurDesconto (event) {
    const valorDesconto = formatCurrencyToNumber(event.target.value)

    if ((veiculoNve.valbru_nve - valorDesconto) < 0) {
      showWarnMessage('Desconto não pode ser maior que o valor bruto! O valor do desconto foi resetado!')
      changeVeiculoConjunto('valdes_nve', 0)
    }
  }

  const onSelectProprietario = async (e) => {
    const { veiculosConjunto } = form.values
    if (veiculosConjunto.length > 0) {
      const indexVeiculo = getVeiculoConjuntoIndex()
      veiculosConjunto[indexVeiculo].veiculo.codpes_vei = e.value.codigo_pes
      veiculosConjunto[indexVeiculo].veiculo.proprietario = e.value
      form.setFieldValue('veiculosConjunto', veiculosConjunto)
    }
    changeVeiculoConjunto('proprietario', e.value)
    veiculoNve.veiculo.proprietario = e.value
    veiculoNve.veiculo.codpes_vei = e.value.codigo_pes
    await form.setFieldValue('proprietario', e.value)
    form.setFieldTouched('proprietario.codigo_pes')
  }

  const sugerirPessoas = async () => {
    try {
      const pessoas = await PessoaService.filterAutocomplete(form.values.pessoa.nomraz_pes)

      if (pessoas.length <= 0) {
        showWarnMessage('Pessoa não encontrada!')
      }
      setSugestaoPessoas(pessoas)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir uma pessoa!')
    }
  }

  const handleChangeProprietario = (e) => {
    changeVeiculoConjunto('veiculo.proprietario', e.target.value)
    changeVeiculoConjunto('veiculo.codpes_vei', e.target.value.codigo_pes)
  }

  function onSaveModalPessoa (pessoa) {
    onSelectPessoa({ value: pessoa.pessoa })
  }

  const onSelectPessoa = async (e) => {
    await form.setFieldValue('pessoa', e.value)
    form.setFieldTouched('pessoa.codigo_pes')
  }

  function dadosVenda () {
    return (
      <>
        <div className="field col-12 md:col-2 neg-checkbox-container">
          <div className={(CommonHelper.isDesktop() ? 'neg-checkbox-first ' : 'neg-checkbox') + ' field col-12 md:col-12 disabled-input checkbox-container'}>
            <Checkbox
              id="repasse"
              value={isRepasse}
              checked={isRepasse}
              onChange={handleChangeRepasse}
              disabled={!edicaoHabilitada} />
            <label htmlFor="repasse" className="p-checkbox-label mb-0 pl-2">Repasse</label>
          </div>
          <div className="field col-12 md:col-12 disabled-input checkbox-container neg-checkbox">
            <Checkbox
              id="ativoImobilizado"
              value={isAtivoImobilizado}
              checked={isAtivoImobilizado}
              onChange={handleChangeAtivoImobilizado}
              disabled={!edicaoHabilitada} />
            <label htmlFor="ativoImobilizado" className="p-checkbox-label mb-0 pl-2">Ativo Imobilizado</label>
          </div>
          <div className="field col-12 md:col-12 disabled-input checkbox-container neg-checkbox">
            <Checkbox
              id="test-drive"
              value={realizadoTestDrive}
              checked={realizadoTestDrive}
              onChange={handleChangeRealizadoTestDrive}
              disabled={!edicaoHabilitada} />
            <label htmlFor="test-drive" className="p-checkbox-label mb-0 pl-2">Realizado Test-Drive</label>
          </div>
        </div>
        <div className="field col-12 md:col-3 flex-column justify-content-center">
          <div className={(CommonHelper.isDesktop() ? '' : 'no-padding') + ' field col-12 sm:col-12 md:col-10'}>
            <SearchInput
              // * AutoComplete Props Padrões
              label="Proprietario"
              name="veiculoNve.veiculo.proprietario.codigo_pes"
              field="nomraz_pes"
              placeholder="Nome do proprietario"
              disabled={!edicaoHabilitada}

              // * Form Control
              className={classNames({ 'p-invalid': isFormFieldValid('veiculoNve.veiculo.proprietario.codigo_pes') }, 'inputfield w-full')}
              isFormFieldValid={isFormFieldValid('veiculoNve.veiculo.proprietario.codigo_pes')}
              // onBlur={() => form.setFieldTouched('veiculoNve.veiculo.proprietario.codigo_pes')}

              // * Template pro campo valor
              itemTemplate={PessoaAutocompleteTemplate}
              value={veiculoNve.veiculo?.proprietario && veiculoNve.veiculo?.proprietario.apelid_pes}

              // * Selecionar e sugesstions
              onSelect={async (e) => await onSelectProprietario(e)}
              minLength={1}
              onChange={handleChangeProprietario}
              completeMethod={sugerirPessoas}
              suggestions={sugestaoPessoas}

              // * RegisterModal
              form={form}
              selected={form.values.pessoa}
              onSaveModal={onSaveModalPessoa}
              onDeleteModal={async () => await onSelectPessoa({})}
              ComponenteCadastro={[
                {
                  label: 'Pessoa física',
                  component: PessoaFisica,
                  header: form.values.pessoa.codigo_pes ? 'Pessoa Física - ' + form.values.pessoa.codigo_pes : 'Cadastro de pessoa física'
                },
                {
                  label: 'Pessoa jurídica',
                  component: PessoaJuridica,
                  header: form.values.pessoa.codigo_pes ? 'Pessoa jurídica - ' + form.values.pessoa.codigo_pes : 'Cadastro de pessoa jurídica'
                }
              ]}

              // * FiltroModal
              filtrotitle="Pesquisa de pessoa"
              service={PessoaService}
              model={PessoaModel}
              columns={[
                { campo: 'codigo_pes', nome: 'Código' },
                { campo: 'nomraz_pes', nome: 'Nome razão' },
                { campo: 'nomfan_pej', nome: 'Nome fantasia', preload: 'pessoaJuridica' },
                { campo: 'cgccpf_pes', nome: 'CPF/CNPJ', format: formatDocument }
              ]}

              // * RegisterModal && FiltroModal
              primarykeyname="codigo_pes"
            />
            <FieldErrorMessage message={getFormErrorMessage('proprietario.codigo_pes')} />
          </div>
          <div className={(CommonHelper.isDesktop() ? '' : 'no-padding') + ' field col-12 sm:col-12 md:col-10'}>
            <NumberInput
              label="Km Atual"
              placeholder="Km Atual"
              value={veiculoNve?.kmatu_nve}
              onChange={handleChangeKmAtual}
              disabled={!edicaoHabilitada}
              className="inputfield w-full"
              useGrouping={false}
              min="0"
            />
          </div>
          <div className={(CommonHelper.isDesktop() ? '' : 'no-padding ') + 'field col-12 md:col-10'}>
            <DateTimeInput
              label="Previsão de entrega"
              placeholder="Previsão de entrega"
              value={veiculoNve?.dahpreent_nve}
              onChange={handleChangePrevisaoEntrega}
              disabled={!edicaoHabilitada}
              className="inputfield w-full"
            />
          </div>
        </div>
        <div className="field col-12 md:col-3 no-padding">
          <div className="field col-12 md:col-12">
            <TextInput
              label="Valor Bruto"
              name="negociacaoVeiculo.valbru_nve"
              disabled={!edicaoHabilitada}
              onChange={handleChangeValorBruto}
              value={formatToCurrency(veiculoNve?.valbru_nve || 0)}
              onBlur={onBlurValorBruto}
              className="inputfield w-full"
            />
          </div>
          <div className="field col-12 md:col-12">
            <TextInput
              label="Desconto"
              name="negociacaoVeiculo.valdes_nve"
              disabled={!edicaoHabilitada}
              onChange={(e) => {
                if (parseFloat(formatCurrencyToNumber(e.target.value)) > 0) {
                  handleChangeValorDesconto(e)
                } else {
                  e.target.value = 0
                  handleChangeValorDesconto(e)
                }
              }}
              value={formatToCurrency(veiculoNve?.valdes_nve || 0)}
              onBlur={onBlurDesconto}
              className="inputfield w-full"
            />
          </div>
          <div className="field col-12 md:col-12">
            <label>Valor líquido</label>
            <TextInput
              disabled
              value={formatToCurrency(veiculoNve?.valor_nve || 0)}
              className="inputfield w-full"
            />
          </div>
        </div>
      </>
    )
  }

  const dadosCompra = () => {
    return (
      <>
        <div className="field col-12 md:col-3">
          <div className="field col-12 sm:col-12 md:col-10 no-padding">
            <NumberInput
              label="Km Atual"
              placeholder="Km Atual"
              value={veiculoNve?.kmatu_nve}
              onChange={handleChangeKmAtual}
              disabled={!edicaoHabilitada}
              className="inputfield w-full"
              useGrouping={false}
              min="0"
            />
            <div className="field col-12 md:col-12 disabled-input neg-checkbox mt-4">
              <Checkbox
                id="ativoImobilizado"
                value={isAtivoImobilizado}
                checked={isAtivoImobilizado}
                onChange={handleChangeAtivoImobilizado}
                disabled={!edicaoHabilitada} />
              <label htmlFor="ativoImobilizado" className="p-checkbox-label mb-0 pl-2">Ativo Imobilizado</label>
            </div>
            <div className="field col-12 md:col-12 disabled-input neg-checkbox">
              <Checkbox
                id="repasse"
                value={isRepasse}
                checked={isRepasse}
                onChange={handleChangeRepasse}
                disabled={!edicaoHabilitada} />
              <label htmlFor="repasse" className="p-checkbox-label mb-0 pl-2">Repasse</label>
            </div>
          </div>
        </div>
        <div className="field col-12 md:col-3">
          <div className="field col-12 md:col-12">
            <TextInput
              label="Valor de Compra"
              disabled={!edicaoHabilitada}
              onChange={handleChangeValorCompra}
              value={formatToCurrency(veiculoNve?.valor_nve || 0)}
              className="inputfield w-full"
            />
          </div>
          <div className="field col-12 md:col-12">
            <TextInput
              label="Valor de Venda"
              disabled={(!edicaoHabilitada) || !(edicaoHabilitada && permissaoAlterarPreco)}
              onChange={handleChangeValorVenda}
              value={formatToCurrency(veiculoNve.valfut_nve || 0)}
              className="inputfield w-full"
            />
          </div>
        </div>
      </>
    )
  }

  const dadosConsignacao = () => {
    return (
      <>
        <div className='field md:col-6 col-12'>
          <div className='field col-12 md:col-12 md:ml-2'>

            <SearchInput
              // * AutoComplete Props Padrões
              label="Proprietario"
              name="veiculoNve.veiculo.proprietario.codigo_pes"
              field="nomraz_pes"
              placeholder="Nome do proprietario"
              disabled={!edicaoHabilitada}

              // * Form Control
              className={classNames({ 'p-invalid': isFormFieldValid('proprietario.codigo_pes') }, 'inputfield w-full')}
              isFormFieldValid={isFormFieldValid('proprietario.codigo_pes')}
              // onBlur={() => form.setFieldTouched('negociacaoVeiculo.proprietario.codigo_pes')}

              // * Template pro campo valor
              itemTemplate={PessoaAutocompleteTemplate}
              value={veiculoNve.veiculo?.proprietario && veiculoNve.veiculo?.proprietario.apelid_pes}

              // * Selecionar e sugesstions
              onSelect={async (e) => await onSelectProprietario(e)}
              minLength={1}
              suggestions={sugestaoPessoas}
              completeMethod={sugerirPessoas}
              onChange={handleChangeProprietario}

              // * RegisterModal
              form={form}
              selected={form.values.pessoa}
              onSaveModal={onSaveModalPessoa}
              onDeleteModal={async () => await onSelectPessoa({})}
              ComponenteCadastro={[
                {
                  label: 'Pessoa física',
                  component: PessoaFisica,
                  header: form.values.pessoa.codigo_pes ? 'Pessoa Física - ' + form.values.pessoa.codigo_pes : 'Cadastro de pessoa física'
                },
                {
                  label: 'Pessoa jurídica',
                  component: PessoaJuridica,
                  header: form.values.pessoa.codigo_pes ? 'Pessoa jurídica - ' + form.values.pessoa.codigo_pes : 'Cadastro de pessoa jurídica'
                }
              ]}

              // * FiltroModal
              filtrotitle="Pesquisa de pessoa"
              service={PessoaService}
              model={PessoaModel}
              columns={[
                { campo: 'codigo_pes', nome: 'Código' },
                { campo: 'nomraz_pes', nome: 'Nome' },
                { campo: 'cgccpf_pes', nome: 'CPF/CNPJ', format: formatDocument }
              ]}

              // * RegisterModal && FiltroModal
              primarykeyname="codigo_pes"
            />
            <FieldErrorMessage message={getFormErrorMessage('proprietario.codigo_pes')} />
          </div>

          <div className={'field col-12 md:col-6 md:flex'}>
            <div className={'field col-12 md:col-10'}>

              <TextInput
                label="Valor consignação"
                disabled={!edicaoHabilitada}
                onChange={handleChangeValorCompra}
                value={formatToCurrency(veiculoNve?.valor_nve || 0)}
                className="inputfield w-full"
              />
            </div>

            {pagandoConsignado && (
              <div className={'field col-12 md:col-10'}>

                <TextInput
                  label="Vendido por"
                  disabled={true}
                  value={formatToCurrency(JSON.parse(localStorage.getItem('negociacao_' + form.values?.codigo_neg)).vendidoPor)}
                  className="inputfield w-full" />
              </div>
            )}
            {!pagandoConsignado && (
              <div className={'field col-12 md:col-9'}>
                <TextInput
                  label="Valor de Venda"
                  disabled={!edicaoHabilitada}
                  onChange={handleChangeValorVenda}
                  value={formatToCurrency(veiculoNve.valfut_nve || 0)}
                  className="inputfield w-full"
                />
              </div>
            )}
            <div className={'field col-12 md:col-6'}>

              <NumberInput
                label="Km Atual"
                placeholder="Km Atual"
                value={veiculoNve?.kmatu_nve}
                onChange={handleChangeKmAtual}
                disabled={!edicaoHabilitada}
                className="inputfield w-full"
                useGrouping={false}
                min="0"
              />
            </div>

          </div>
        </div>

      </>
    )
  }

  return (
    <Modal
      visible={visible}
      onHide={onHide}
      width={75}
      header={'Veículo - ' + veiculoNve.codvei_nve}
    >
      <div className="formgrid grid fluid">
        <div className={(isVenda() ? 'md:col-4' : 'md:col-6') + ' field col-12 text-left no-padding'}>
          <div><h1 className="titulo-veiculo">Veículo</h1></div>
          {veiculoNve.veiculo?.modelo?.marca?.descri_mar && (
            <div className="first col-12 flex neg-row">
              <span className="neg-row-label">Marca/Modelo:</span>
              <span className="neg-row-value">
                {veiculoNve.veiculo?.modelo?.marca?.descri_mar}/{veiculoNve.veiculo?.modelo?.descri_mod}
              </span>
            </div>
          )}
          {veiculoNve.veiculo?.anofab_vei && (
            <div className="col-12 flex neg-row">
              <span className="neg-row-label">Ano:</span>
              <span className="neg-row-value">
                {veiculoNve.veiculo?.anofab_vei}/{veiculoNve.veiculo?.anomod_vei}
              </span>
            </div>
          )}
          {veiculoNve.veiculo?.cor?.descri_cor && (
            <div className="col-12 flex neg-row">
              <div className="neg-row-label">Cor:</div>
              <span className="neg-row-value">
                {veiculoNve.veiculo?.cor?.descri_cor}
              </span>
            </div>
          )}
          {veiculoNve.veiculo?.codigo_vei && (
            <div className="col-12 flex neg-row placa">
              <div className="neg-row-label">Placa/Chassi:</div>
              <span className="neg-row-value underline cursor-pointer" onClick={() => setVisibleVeiculoModal(true)}>
                {veiculoNve.veiculo?.placa_vei ? formatPlaca(veiculoNve.veiculo?.placa_vei) : (veiculoNve.veiculo?.chassi_vei)?.substring(veiculoNve.veiculo?.chassi_vei?.length - 8)}
              </span>
              {veiculoNve.veiculo?.codtiv_vei && (
                <span className={`tipo-veiculo-${veiculoNve.veiculo?.codtiv_vei} neg-tipo-veiculo`}>
                  {veiculoNve.veiculo?.codtiv_vei === 'n' ? 'Novo' : 'Usado'}
                </span>
              )}
            </div>
          )}
          {veiculoNve.veiculo?.acessorios?.sigla_ace && (
            <div className="col-12 flex neg-row">
              <div className="neg-row-label">Acessórios:</div>
              <div
                className="neg-row-value underline cursor-pointer"
                onClick={() => { setVisibleAcessoriosModal(true); setAcessoriosList(veiculoNve.veiculo?.acessorios?.descri_ace) }}
              >
                {veiculoNve.veiculo?.acessorios?.sigla_ace}
              </div>
            </div>
          )}
        </div>
        {isVenda() && (
          dadosVenda()
        )}
        {isCompra() && (
          dadosCompra()
        )}
        {isConsignado() && (
          dadosConsignacao()
        )}
      </div>
      <Modal
        header="Lista de acessórios"
        width="40"
        visible={visibleAcessoriosModal}
        onHide={() => { setVisibleAcessoriosModal(false); setAcessoriosList('') }}
      >
        {acessoriosList?.split(', ').map((acessorio, idx) => (
          <div key={idx}>{acessorio}</div>
        ))}
      </Modal>
      <VeiculoModal
        visible={visibleVeiculoModal}
        hide={() => setVisibleVeiculoModal(false)}
        primaryKey={veiculoNve?.codvei_nve}
        apenasVisualizacao={!edicaoHabilitada}
      />
    </Modal>
  )
}

export default VeiculoConjuntoModal
