import React, { useEffect, useState } from 'react'
import CheckboxInput from '../../../../../components/inputs/CheckboxInput'
import SelectInput from '../../../../../components/inputs/SelectInput'
import TextInput from '../../../../../components/inputs/TextInput'
import { showErrorMessage, showSuccessMessage } from '../../../../../components/utils/Message'
import CommonHelper from '../../../../../helpers/CommonHelper'
import { getStore } from '../../../../../redux/store'
import TipoPrecoService from '../../../../../services/cadastro/financeiro/TipoPrecoService'
import CEPService from '../../../../../services/cadastro/pessoa/CEPService'
import CidadesService from '../../../../../services/cadastro/pessoa/CidadesService'
import ConsultarCnpjService from '../../../../../services/cadastro/pessoa/ConsultarCnpjService'
import EstadosService from '../../../../../services/cadastro/pessoa/EstadosService'
import PaisesService from '../../../../../services/cadastro/pessoa/PaisesService'
import PermissaoService from '../../../../../services/cadastro/pessoa/PermissaoService'
import AvaliacaoService from '../../../../../services/cadastro/veiculo/AvaliacaoService'
import ConfigService from '../../../../../services/configuracao/ConfigService'

const DadosPrincipaisPej = ({ form, edicaoHabilitada, camposObrigatorios }) => {
  const [isIsento, setIsIsento] = useState(
    form.values.ie_pes === 'ISENTO' ? true : false
  )
  const [ieOriginal] = useState('')
  const [estados, setEstados] = useState([])
  const [cidades, setCidades] = useState([])
  const [endereco, setEndereco] = useState([])
  const [tiposPreco, setTiposPreco] = useState([])
  const [permissao176, setPermissao176] = useState(false)
  const [permissao123, setPermissao123] = useState(false)
  const [paises, setPaises] = useState({})

  useEffect(() => {
    onLoad()
  }, [])

  useEffect(() => {
    if (form.values.pais.codigo_pai === getStore().codigoBrasil) {
      onLoad()
    }
  }, [form.values.pais])

  const onLoad = async () => {
    const { pessoa } = getStore().empresaLogada
    const estado = await getEstadoPorCidade(pessoa.codmun_pes)
    const pais = await getPaisPorEstado(estado.codigo_uf)
    const paises = await getTodosPaises()
    setPaises(paises)

    if (form.values.codpai_pes || pais?.codigo_pai) {
      await getEstadosPorPais(form.values.codpai_pes || pais?.codigo_pai)
    }

    if (!form.values.codpai_pes) {
      form.setFieldValue('codpai_pes', pais?.codigo_pai)
      form.setFieldValue('pais_pes', pais?.descri_pai)
      form.setFieldValue('pais', pais)
    }

    if (form.values.uf_pes || estado.codigo_uf) {
      await getCidadesPorEstado(form.values.uf_pes || estado.sigla_uf)
    }

    if (form.values?.estado && Object.keys(form.values.estado).length === 0 && form.values.codmun_pes) {
      const estadoDaPessoa = await getEstadoPorCidade(form.values.codmun_pes)
      form.setFieldValue('estado', estadoDaPessoa)
    }

    if (!form.values.uf_pes) {
      form.setFieldValue('uf_pes', estado.sigla_uf)
      form.setFieldValue('estado', estado)
    }

    if (
      form.values.codmun_pes &&
      Object.keys(form.values.municipio).length === 0
    ) {
      const cidade = await getCidade(form.values.codmun_pes)
      form.setFieldValue('cidade_pes', cidade?.description)
      form.setFieldValue('codmun_pes', cidade?.id)
      form.setFieldValue('municipio', cidade)
    }
    if (!form.values.codmun_pes && form.values.codpai_pes === getStore().codigoBrasil) {
      const cidade = await getCidade(pessoa.codmun_pes)
      form.setFieldValue('cidade_pes', cidade.description)
      form.setFieldValue('codmun_pes', cidade.id)
      form.setFieldValue('municipio', cidade)
    }

    if (form.values.codmun_pes === 9999999) {
      let cidades = await CidadesService.getAll()
      let exterior = cidades.filter((city) => city.id === 9999999)
      setCidades(exterior)
    }
    const config1270 = await ConfigService.getValor(1270)
    form.setFieldValue('obrigaEmail', config1270)
    const permissao176 = await PermissaoService.getByCodigo(176)
    setPermissao176(permissao176)
    const permissao123 = await PermissaoService.getByCodigo(123)
    setPermissao123(permissao123)
    await getTiposPreco()
  }

  async function getTiposPreco () {
    const tiposPreco = await TipoPrecoService.getTiposPrecoPersonalizadoOrcamentoBalcao()
    setTiposPreco(tiposPreco)
    return tiposPreco
  }

  const handleChangeIsencao = () => {
    setIsIsento(!isIsento)
    form.setFieldValue('ie_pes', form.values.isIsento ? ieOriginal : 'ISENTO')
  }

  const setApelidoComNome = () => {
    form.setFieldValue('apelid_pes', form.values.nomraz_pes)
  }

  const getEstadosPorPais = async (countryId) => {
    try {
      const estados = await EstadosService.getAllByCountry(countryId)
      await setEstados(estados)
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao buscar os estados do pais!')
    }
  }

  const getTodosPaises = async () => {
    try {
      return await PaisesService.getAll()
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao buscar os paises!')
    }
  }

  const getPaisPorEstado = async (stateId) => {
    try {
      return await PaisesService.getCountryByState(stateId)
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao buscar o pais pelo estado!')
    }
  }

  const getCidadesPorEstado = async (stateInitials) => {
    try {
      const cidades = await CidadesService.getAllByStateInitials(stateInitials)
      await setCidades(cidades)
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao buscar as cidades pelo estado!')
    }
  }

  const getEstadoPorCidade = async (cityId) => {
    try {
      return await EstadosService.getStateByCity(cityId)
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao buscar o estado pela cidade!')
    }
  }

  const getCidade = async (cidadeId) => {
    try {
      return await CidadesService.getCity(cidadeId)
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao buscar a cidade!')
    }
  }

  const handleChangeEstado = async (e) => {
    const estado = e.target.value

    form.setFieldValue('uf_pes', estado?.sigla_uf)
    form.setFieldValue('cidade_pes', null)
    form.setFieldValue('codmun_pes', null)
    form.setFieldValue('estado', estado)
    form.setFieldValue('municipio', {})

    await getCidadesPorEstado(estado?.sigla_uf)
  }

  const handleChangeCidade = (e) => {
    const cidade = e.target.value

    form.setFieldValue('cidade_pes', cidade?.description)
    form.setFieldValue('codmun_pes', cidade?.id)
    form.setFieldValue('municipio', cidade)
  }
  const handleChangePaises = async (e) => {
    const pais = e.target.value
    form.setFieldValue('pais', pais)
    form.setFieldValue('codpai_pes', pais?.codigo_pai)
    form.setFieldValue('pais_pes', pais?.descri_pai)

    if (pais?.codigo_pai !== getStore().codigoBrasil) {
      let cidades = await CidadesService.getAll()
      let exterior = cidades.filter((city) => city.id === 9999999)
      setCidades(exterior)
      form.setFieldValue('cidade_pes', exterior[0]?.description)
      form.setFieldValue('codmun_pes', exterior[0]?.id)
      form.setFieldValue('municipio', exterior[0])
      form.setFieldValue('cep_pes', null)
      form.setFieldValue('cgccpf_pes', null)
      form.setFieldValue('ie_pes', null)
      form.setFieldValue('uf_pes', null)
      form.setFieldValue('estado', null)
      return
    } else {
      form.setFieldValue('cidade_pes', null)
      form.setFieldValue('codmun_pes', null)
      form.setFieldValue('municipio', null)
    }
  }

  const handleChangeCep = async (value) => {
    try {
      const cep = value

      form.setFieldValue('cep_pes', cep)

      if (CommonHelper.clearDigitsSpecialChars(cep).length === 8) {
        const endereco = await CEPService.getEndereco(cep)
        form.setFieldValue('estado', { sigla_uf: endereco.uf })
        if (form.values.estado) {
          form.setFieldValue('uf_pes', form.values.estado.sigla_uf)
        }

        await getCidadesPorEstado(endereco.uf)
        form.setFieldValue(
          'municipio',
          {
            id: Number(endereco.ibge),
            description: endereco.localidade
          }
        )
        form.setFieldValue('codmun_pes', Number(endereco.ibge))
        form.setFieldValue('cidade_pes', endereco.localidade)
        if (endereco.bairro) {
          form.setFieldValue('bairro_pes', String(endereco.bairro).toUpperCase())
        }
        if (endereco.logradouro) {
          form.setFieldValue('end_pes', String(endereco.logradouro).toUpperCase())
        }
        form.setFieldValue('uf_pes', endereco.uf)
      }
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar endereço via CEP!')
    }
  }

  const handleChangeIE = (e) => {
    form.setFieldValue('ie_pes', e.target.value)
    form.setFieldValue('ierg_pes', e.target.value)
  }
  const consultarCNPJ = async (e) => {
    try {
      const consulta = await ConsultarCnpjService.getInformacaoCnpj(e)
      let data = new Date(consulta?.datfun)
      if (!form.values.nomraz_pes) {
        form.setFieldValue('nomraz_pes', consulta?.nomraz ?? '')
      }
      if (!form.values.nomfan_pej) {
        form.setFieldValue('nomfan_pej', consulta?.nomfan ?? '')
      }
      if (!form.values.telcom_pes) {
        form.setFieldValue('telcom_pes', consulta?.telcel ?? '')
      }
      if (!form.values.email_pes) {
        form.setFieldValue('email_pes', consulta?.emacli ?? '')
      }
      if (!form.values.numend_pes) {
        form.setFieldValue('numend_pes', consulta?.numend ?? '')
      }
      if (!form.values.comend_pes) {
        form.setFieldValue('comend_pes', String(consulta?.comend ?? '').toUpperCase())
      }
      if (!form.values.datfun_pej) {
        form.setFieldValue('datfun_pej', data)
      }
      if (!form.values.cnae_pej) {
        form.setFieldValue('cnae_pej', consulta?.cnae ?? '')
      }

      setEndereco({
        end_pes: consulta?.end,
        bairro_pes: consulta?.bairro,
        municipio: consulta?.municipio,
        cep: consulta.cep,
        estado: consulta?.estado
      })
      form.setFieldValue('end_pes', String(consulta?.end).toUpperCase())
      form.setFieldValue('bairro_pes', String(consulta?.bairro).toUpperCase())

      if (!form.values.cep_pes) {
        handleChangeCep(consulta.cep)
      }
    } catch (error) {
      showErrorMessage(error.message)
    }
  }

  const buscarPessoaAvaliacao = async () => {
    try {
      const avaliacao = await AvaliacaoService.getAvaliacaoCompletaCpfCnpj(
        CommonHelper.clearDigitsSpecialChars(form.values.cgccpf_pes)
      )

      if (avaliacao) {
        showSuccessMessage(
          `Os dados foram obtidos da avaliação ${avaliacao.codigo_ava}`
        )

        form.setFieldValue('nomraz_pes', avaliacao?.nomraz_pes)
        form.setFieldValue('telcel_pes', avaliacao?.telcel_ava)
        form.setFieldValue('email_pes', avaliacao?.emacli_ava)
      }
    } catch (error) { }
  }

  const handleChangeTipoPreco = (e) => {
    const tipoPreco = e.target.value
    form.setFieldValue('tipoPreco', tipoPreco)
  }

  return (
    <div className="formgrid grid">
      <div className="field col-12 md:col-12 disabled-input">
        <TextInput
          label="Nome/Razão"
          placeholder="Nome/Razão"
          name="nomraz_pes"
          value={form.values.nomraz_pes}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          onBlur={() => setApelidoComNome()}
          maxLength={200}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 md:col-6 disabled-input">
        <TextInput
          label="Nome fantasia"
          placeholder="Nome fantasia"
          name="nomfan_pej"
          maxLength={150}
          value={form.values.nomfan_pej}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 md:col-3 disabled-input">
        <TextInput
          label="CNPJ"
          placeholder="CNPJ"
          name="cgccpf_pes"
          value={form.values.cgccpf_pes}
          onChange={(value) => {
            form.setFieldValue('cgccpf_pes', value)
            if (value.length === 14) {
              buscarPessoaAvaliacao()
              consultarCNPJ(value)
            }
          }}
          disabled={!edicaoHabilitada || (form.values.pais?.codigo_pai && form.values.pais?.codigo_pai !== getStore().codigoBrasil)}
          mask="99.999.999/9999-99"
          camposObrigatorios={form.values.pais?.codigo_pai !== getStore().codigoBrasil ? '.' : camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-8 md:col-2 disabled-input">
        <TextInput
          label="I.E"
          placeholder="I.E."
          name="ie_pes"
          value={form.values.ie_pes}
          maxLength={20}
          onChange={(e) => handleChangeIE(e)}
          disabled={!edicaoHabilitada || isIsento || (form.values.pais?.codigo_pai && form.values.pais?.codigo_pai !== getStore().codigoBrasil)}
          camposObrigatorios={form.values.pais?.codigo_pai !== getStore().codigoBrasil ? '.' : camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-4 md:col-1 disabled-input checkbox-container">
        <CheckboxInput
          value={isIsento}
          label="Isento"
          onChange={handleChangeIsencao}
          checked={isIsento}
          id="isento"
          disabled={!edicaoHabilitada}
        />
      </div>
      <div className="field col-12 md:col-2 disabled-input">
        <TextInput
          label="CEP"
          placeholder="CEP"
          name="cep_pes"
          value={form.values.cep_pes}
          className="inputfield w-full"
          onChange={handleChangeCep}
          mask="99999-999"
          disabled={!edicaoHabilitada || form.values.pais?.codigo_pai !== getStore().codigoBrasil}
          camposObrigatorios={form.values.pais?.codigo_pai !== getStore().codigoBrasil ? '.' : camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 md:col-10 disabled-input">
        <TextInput
          label="Endereço"
          placeholder="Endereço"
          name="end_pes"
          value={form.values.end_pes || endereco.end_pes}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          maxLength={100}
          className="inputfield w-full"
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-5 md:col-2">
        <TextInput
          label="Numero"
          placeholder="Número"
          name="numend_pes"
          maxLength={10}
          className="inputfield w-full"
          value={form.values.numend_pes}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-7 md:col-4 disabled-input">
        <TextInput
          label="Complemento"
          placeholder="Complemento"
          name="comend_pes"
          maxLength={60}
          value={form.values.comend_pes}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 md:col-6 disabled-input">
        <TextInput
          label="Bairro"
          placeholder="Bairro"
          name="bairro_pes"
          maxLength={70}
          className="inputfield w-full"
          value={form.values.bairro_pes || endereco.bairro_pes}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 md:col-2 disabled-input">
        <SelectInput
          value={form.values.pais}
          options={paises}
          onChange={handleChangePaises}
          name="pais"
          label="País"
          filter={CommonHelper.isDesktop()}
          optionLabel="descri_pai"
          dataKey="codigo_pai"
          noFloatLabel
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-2 disabled-input">
        <SelectInput
          value={form.values.estado || endereco.estado}
          options={estados}
          onChange={handleChangeEstado}
          name="estado"
          label="Estado"
          filter={CommonHelper.isDesktop()}
          optionLabel="descri_uf"
          dataKey="sigla_uf"
          noFloatLabel
          disabled={!edicaoHabilitada || form.values.pais?.codigo_pai !== getStore().codigoBrasil}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-2 disabled-input">
        <SelectInput
          value={form.values.municipio || endereco.municipio}
          options={cidades}
          onChange={handleChangeCidade}
          label="Município"
          name="municipio"
          filter={CommonHelper.isDesktop()}
          optionLabel="description"
          dataKey="id"
          noFloatLabel
          disabled={!edicaoHabilitada || form.values.pais?.codigo_pai !== getStore().codigoBrasil}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-6 disabled-input">
        <TextInput
          camposObrigatorios={form.values?.obrigaEmail ? camposObrigatorios : '.'}

          form={form}
          label="E-mail"
          placeholder="E-mail"
          name={'email_pes'}
          value={form.values.email_pes}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          maxLength={240}
        />
      </div>
      <div className="field col-12 md:col-3 disabled-input">
        <TextInput
          label="Telefone Celular"
          placeholder="Telefone celular"
          name="telcel_pes"
          value={form.values.telcel_pes}
          onChange={(value) => form.setFieldValue('telcel_pes', value)}
          mask="(99) 99999-9999"
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 md:col-3 disabled-input">
        <TextInput
          label="Telefone Comercial"
          placeholder="Telefone comercial"
          name="telcom_pes"
          value={form.values.telcom_pes}
          onChange={(value) => form.setFieldValue('telcom_pes', value)}
          mask="(99) 99999-9999"
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 md:col-6 disabled-input">
        <TextInput
          label="Telefone SAC"
          placeholder="Telefone SAC"
          name="telsac_pes"
          value={form.values.telsac_pes}
          onChange={(value) => form.setFieldValue('telsac_pes', value)}
          mask="(99) 99999-9999"
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 md:col-3">
        <SelectInput
          value={form.values?.tipoPreco}
          options={tiposPreco}
          onChange={handleChangeTipoPreco}
          label="Tabela de preço"
          name="descri_tpr"
          filter={CommonHelper.isDesktop()}
          optionLabel="descri_tpr"
          dataKey="codigo_tpr"
          noFloatLabel
          disabled={!edicaoHabilitada || !permissao176}
          className="inputfield w-full"
          showClear={form.values.tipoPreco?.codigo_tpr}
          onHide={() => form.setFieldTouched('tipoPreco.codigo_tpr')}
        />

      </div>
      <div className="field col-12 md:col-3 field checkbox-container">
        <CheckboxInput label={'Cliente atacadista'}
          name='cliata_pes'
          value={form.values?.cliata_pes}
          checked={form.values?.cliata_pes}
          disabled={!edicaoHabilitada || !permissao123}
          onChange={form.handleChange}
        />

      </div>
    </div>
  )
}

export default DadosPrincipaisPej
