import React from 'react'
import { Button } from 'primereact/button'
import List from '../../../../classes/List'
import Container from '../../../../components/layout/Container'
import Page from '../../../../components/layout/Page'
import DataList from '../../../../components/layout/DataList'
import TablePreferences from '../../../../components/utils/TablePreferences'
import { createColumns, getColunasHabilitadas } from '../../../../helpers/tableConfigs'
import PreferencesButton from '../../../../components/utils/PreferencesButton'
import ModeloService from '../../../../services/cadastro/veiculo/ModeloService'
import { getModeloDTO } from '../../../../dtos/cadastro/veiculo/ModeloDTO'
import CommonHelper from '../../../../helpers/CommonHelper'
import { classNames } from 'primereact/utils'

export class ModeloList extends List {
  constructor (props) {
    super(props, getModeloDTO, ModeloService, 'modelo', 'codigo_mod')

    this.state = {
      ...this.state,
      visiblePreferencesModal: false
    }

    this.setColumns(createColumns([
      { field: 'codigo_mod', header: 'Código', width: '5%' },
      { field: 'reffab_mod', header: 'Referência', width: '5%' },
      { field: 'codden_mod', header: 'Cód. DENATRAN', width: '5%' },
      { field: 'descri_mod', header: 'Descrição', width: '13%' },
      { field: 'descri_mar', header: 'Marca', width: '13%', customBody: this.getMarca }
    ]))
  }

  getMarca (data) {
    return data.marca.descri_mar
  }

  render () {
    const { rows, totalRecords, first } = this.state
    const columns = this.columns

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Modelos</h4>
          <div className="page-header-buttons">
            <Button
              className="mr-2"
              label={CommonHelper.isDesktop() && 'Novo'}
              icon="pi pi-plus"
              onClick={this.onNew}
            />
          </div>
        </div>
        <div className="table-options-container">
          <div className="tabel-options">
            <div
              className={classNames({ active: this.state.opcaoSelecionada === 't' }, 'filter-option')}
            >
              <span className="option-label">Todos</span>
              <div className="option-quantity">
                {this.state.totalRecords}
              </div>
            </div>
          </div>
          <div className="table-preferences">
            <PreferencesButton
              onClick={() => this.setState({ visiblePreferencesModal: true })}
            />
          </div>
        </div>
        <Container>
          <DataList
            data={this.state.list}
            selected={this.state.selected}
            rows={rows}
            onSelect={this.onSelect}
            totalRecords={totalRecords}
            first={first}
            onPage={this.onPageChange}
            page={this.state.page}
            onRowSelect={(e) => this.onView(e.data)}
            showFilter={true}
            filterPlaceholder="Procurar Modelos"
            filterName="descricaoFiltro"
            responsive={true}
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
            customMaxHeight={'unset'}
          >
            {getColunasHabilitadas(columns, 'modelo')}
          </DataList>
        </Container>
        <TablePreferences
          title="Preferências da tabela de modelo"
          tableName="modelo"
          columns={columns}
          visible={this.state.visiblePreferencesModal}
          hide={() => this.setState({ visiblePreferencesModal: false })}
        />
      </Page>
    )
  }
}
