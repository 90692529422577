import { TreeSelect } from 'primereact/treeselect'
import React, { useEffect, useState } from 'react'
import './_treeSelect.scss'

const SancesTreeSelect = ({
  selectedOptions,
  comecaSemNada,
  changeSelectedOptions,
  modelService,
  groupLabel,
  campoChildrenLabel,
  changeSelectedDados,
  label,
  placeholder
}) => {
  const [opcoesTreeSelect, setOpcoesTreeSelect] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedOptions && !comecaSemNada) {
        changeSelectedOptions({
          0: { checked: false, partialChecked: true },
          '0-0': { checked: true, partialChecked: false }
        })
      }

      const { data: arrayDados } = await modelService.getAll()
      const childrenTreeSelect = montaChildrenParaTreeSelect(arrayDados)
      const opcoesTreeSelect = montaOpcoesTreeSelect(childrenTreeSelect)
      setOpcoesTreeSelect(opcoesTreeSelect)
    }

    fetchData()
  }, [])

  const montaOpcoesTreeSelect = (childrenTreeSelect) => {
    return [{
      key: 0,
      label: groupLabel,
      children: [...childrenTreeSelect]
    }]
  }

  const montaChildrenParaTreeSelect = (array) => {
    return array.map((obj, idx) => ({
      key: `0-${idx}`,
      label: obj[campoChildrenLabel],
      data: obj
    }))
  }

  const atualizaDadosSelecionados = (e) => {
    const dados = []

    for (const propertyName of Object.getOwnPropertyNames(e.value)) {
      const index = propertyName.substring(2)
      if (index) dados.push(opcoesTreeSelect[0].children[index].data)
    }

    changeSelectedDados(dados)
  }

  return (
    <>
      {label && <label>{label}</label>}
      <TreeSelect
        value={selectedOptions}
        options={opcoesTreeSelect}
        onChange={(e) => {
          atualizaDadosSelecionados(e)
          changeSelectedOptions(e.value)
        }}
        display="chip"
        selectionMode="checkbox"
        className="inputfield w-full"
        placeholder={placeholder}
        filter
      />
    </>
  )
}

export default SancesTreeSelect
