import React, { useEffect, useState } from 'react'
import CheckboxInput from '../../../../components/inputs/CheckboxInput'
import TextInput from '../../../../components/inputs/TextInput'
import { showErrorMessage, showWarnMessage } from '../../../../components/utils/Message'
import Modal from '../../../../components/utils/Modal'
import CommonHelper from '../../../../helpers/CommonHelper'
import { formatCurrencyToNumber, formatPercentageToNumber, formatToCurrency, formatToPercentage } from '../../../../helpers/formaters'
import PermissaoService from '../../../../services/cadastro/pessoa/PermissaoService'
import RestricaoService from '../../../../services/cadastro/pessoa/RestricaoService'
import PecaBalcaoService from '../../../../services/vendas/orcamentoBalcao/PecaBalcaoService'

function AplicarDescontoModal ({ visible, onHide, form, edicaoHabilitada, ratearDesconto, ratearPorcentagemDesconto, permitirDescontoByTipoPreco }) {
  const [valor, setValor] = useState(false)
  const [percentual, setPercentual] = useState(true)
  const [label, setLabel] = useState('percentual')
  const [valorDesconto, setValorDesconto] = useState(0)
  const [valorPercentual, setValorPercentual] = useState(0)
  const [permitirDesconto, setPermitirDesconto] = useState(true)
  const [permissao197AlterarDescontoQtd, setPermissao197AlterarDescontoQtd] = useState(false)
  const [valorTotalBrutoPecas, setValorTotalBrutoPecas] = useState(0)

  useEffect(async () => {
    await setPermitirDescontoByTipoPreco()

    if (form.values.codtpr_peo === 6) {
      setPermitirDesconto(false)
    }

    // Se não possui permissão para alterar desconto de preço por quantidade
    if (!permissao197AlterarDescontoQtd) {
      let possuiPecaUsaPreQtd = false
      for (const peca of form.values.pecasBalcao) {
        if (!peca.iscan_peo) {
          if (peca.usapreqtd_peo === 1 || peca.usapreqtd_peo === true) {
            possuiPecaUsaPreQtd = true
          }
        }
      }

      // Se algum dos produtos possui preço por quantidade
      if (possuiPecaUsaPreQtd) {
        setPermitirDesconto(false)
      }
    }
  }, [form.values.tipoPreco.codigo_tpr, form.values.codtpr_peo, form.values.produto?.unidade?.fracio_uni, visible, permissao197AlterarDescontoQtd])

  useEffect(async () => {
    const permissao197 = await PermissaoService.getByCodigo(197)
    setPermissao197AlterarDescontoQtd(permissao197)

    let valorTotalBrutoPecaBalcao = 0
    form.values.pecasBalcao.forEach((peca) => {
      valorTotalBrutoPecaBalcao += !peca.iscan_peo ? peca.qtd_peo * peca.valuni_peo : 0
    })
    setValorTotalBrutoPecas(valorTotalBrutoPecaBalcao)
  }, [visible])

  const labelTipoDesconto = (tipoDesconto) => {
    const label = {
      percentual: 'O desconto informado será aplicado em todas as peças do orçamento',
      valor: 'O desconto informado será dividido entre todas as peças do orçamento'
    }

    return (<p className='text-gray-600 line-height-2'>{label[tipoDesconto]}</p>)
  }

  async function setPermitirDescontoByTipoPreco () {
    const permite = await permitirDescontoByTipoPreco()
    if (permite) {
      setPermitirDesconto(true)
    } else {
      setPermitirDesconto(false)
    }
  }

  function handleChangeValor () {
    if (percentual) {
      setPercentual(false)
      form.setFieldValue('perdes_peo', 0)
      setValorDesconto(0)
    }
    setValor(!valor)
    setLabel('valor')
  }

  function handleChangePercentual () {
    if (valor) {
      setValor(false)
      form.setFieldValue('valdes_peo', 0)
      setValorPercentual(0)
    }
    setPercentual(!percentual)
    setLabel('percentual')
  }

  async function validaLimiteDescontoPorcentagem (percentual) {
    if (percentual > 100) {
      showWarnMessage('Valor não pode exceder 100% de desconto')
      return false
    } else {
      const restricaoParaVisualizarLimiteDesconto = await RestricaoService.getByCodigo(25)
      const podeAdicionarPecasComLimiteDescontoExcedido = await PermissaoService.getByCodigo('183')
      let excedeuPercentualDescont = false
      let arrMsgDescExcedido = []
      let msgExibida

      for (const peca of form.values.pecasBalcao) {
        if (!peca.iscan_peo) {
          const codEmp = form.values.codemp_opv
          const parMargem = CommonHelper.getMargem(peca.cusuni_peo, peca.valbru_peo)
          const limitePorcentagemDesconto = await PecaBalcaoService.getLimiteDesconto(parMargem, codEmp)
          excedeuPercentualDescont = excedeuPercentualDescont || percentual > limitePorcentagemDesconto

          if (percentual > limitePorcentagemDesconto) {
            if (podeAdicionarPecasComLimiteDescontoExcedido) {
              if (restricaoParaVisualizarLimiteDesconto) {
                msgExibida = `Desconto máximo para a peça (${peca.descri_peo}) foi excedido!`
              } else {
                msgExibida = `O desconto máximo para a peça (${peca.descri_peo}), por margem de lucro é de ${formatToPercentage(limitePorcentagemDesconto)}, você solicitou ${formatToPercentage(percentual)}`
              }
              arrMsgDescExcedido.push(msgExibida)
            } else {
              setValorPercentual(0)
              setValorDesconto(0)
            }
          }
        }
      }

      if (podeAdicionarPecasComLimiteDescontoExcedido && excedeuPercentualDescont) {
        if (arrMsgDescExcedido?.length > 4) {
          restricaoParaVisualizarLimiteDesconto ? showWarnMessage('Desconto máximo para a peça excedido!') : showWarnMessage('O desconto solicitado é maior que o permitido nas configurações de margem de lucro!')
        } else {
          arrMsgDescExcedido.forEach((msg, index) => {
            setTimeout(() => {
              showWarnMessage(msg)
            }, index * 300)
          })
        }
      }

      if (!podeAdicionarPecasComLimiteDescontoExcedido && excedeuPercentualDescont) {
        showErrorMessage('Usuário sem permissão para exceder o limite de desconto.')
        return false
      }
      // Se não caiu em validações anteriores, retorna ok
      return true
    }
  }

  async function validaLimiteDescontoValor () {
    let percentualDesconto = 0
    if (valorDesconto <= valorTotalBrutoPecas) {
      percentualDesconto = valorDesconto / (valorTotalBrutoPecas / 100)
      const restricaoParaVisualizarLimiteDesconto = await RestricaoService.getByCodigo(25)
      const podeAdicionarPecasComLimiteDescontoExcedido = await PermissaoService.getByCodigo('183')
      let excedeuPercentualDescont = false
      let arrMsgDescExcedido = []
      let msgExibida

      for (const peca of form.values.pecasBalcao) {
        if (!peca.iscan_peo) {
          const codEmp = form.values.codemp_opv
          const parMargem = CommonHelper.getMargem(peca.cusuni_peo, peca.valbru_peo)
          const limitePorcentagemDesconto = await PecaBalcaoService.getLimiteDesconto(parMargem, codEmp)
          const perdesPeo = (100 * (peca.valuni_peo * peca.qtd_peo)) / valorTotalBrutoPecas
          const valdesPeo = (valorDesconto / 100) * perdesPeo

          let descontomaximo = peca.valuni_peo * peca.qtd_peo * (limitePorcentagemDesconto / 100)
          excedeuPercentualDescont = excedeuPercentualDescont || peca.valdes_peo > descontomaximo
          if (percentualDesconto > limitePorcentagemDesconto) {
            if (podeAdicionarPecasComLimiteDescontoExcedido) {
              if (restricaoParaVisualizarLimiteDesconto) {
                msgExibida = `Desconto máximo para a peça (${peca.descri_peo}) foi excedido!`
              } else {
                msgExibida = `O desconto máximo para a peça (${peca.descri_peo}), por margem de lucro é de ${formatToCurrency(descontomaximo)}, você solicitou ${formatToCurrency(valdesPeo)}`
              }
              arrMsgDescExcedido.push(msgExibida)
            } else {
              setValorPercentual(0)
              setValorDesconto(0)
            }
          }
        }
      }

      if (podeAdicionarPecasComLimiteDescontoExcedido && excedeuPercentualDescont) {
        if (arrMsgDescExcedido?.length > 4) {
          restricaoParaVisualizarLimiteDesconto ? showWarnMessage('Desconto máximo para a peça excedido!') : showWarnMessage('O desconto solicitado é maior que o permitido nas configurações de margem de lucro!')
        } else {
          arrMsgDescExcedido.forEach((msg, index) => {
            setTimeout(() => {
              showWarnMessage(msg)
            }, index * 300)
          })
        }
      }

      if (!podeAdicionarPecasComLimiteDescontoExcedido && excedeuPercentualDescont) {
        showErrorMessage('Usuário sem permissão para exceder o limite de desconto.')
        return false
      }
      // Se não caiu em validações anteriores, retorna ok
      return true
    } else {
      showWarnMessage('Valor do desconto não pode exceder o valor bruto das peças')
      setValorDesconto(0)
      return false
    }
  }

  async function defineCalculoDesconto () {
    if (permitirDesconto) {
      let isDescontoValidado = false
      if (valor) {
        isDescontoValidado = await validaLimiteDescontoValor(valorPercentual)
        if (isDescontoValidado) {
          ratearDesconto(valorDesconto)
          onHide()
        }
      }
      if (percentual) {
        isDescontoValidado = await validaLimiteDescontoPorcentagem(valorPercentual)
        if (isDescontoValidado) {
          ratearPorcentagemDesconto(valorPercentual)
          onHide()
        }
      }
    } else {
      onHide()
    }
  }

  function handleChangePorcentagemDesconto (event) {
    let value = event.target.value

    const isValorMaiorQueUmChar = value.length !== 1

    if (isValorMaiorQueUmChar && !value.includes('%')) {
      value = value.slice(0, value.length - 1)
    }

    const valorPorcentagemDesconto = formatPercentageToNumber(value)

    if (valorTotalBrutoPecas !== 0) {
      const valorDesconto = (valorTotalBrutoPecas * valorPorcentagemDesconto) / 100
      form.setFieldValue('valdes_peo', valorDesconto)
    }
    setValorPercentual(valorPorcentagemDesconto)
    form.setFieldValue(event.target.name, valorPorcentagemDesconto)
  }

  return (
    <>
      <Modal
        visible={visible}
        onHide={onHide}
        header={'Aplicar desconto'}
        width={35}
        btnSalvar={e => defineCalculoDesconto()}
        btnSalvarLabel={'Aplicar desconto'}
      >
        <div className="formgrid grid fluid">
          <div className="field col-12 md:w-6 mr-4 flex md:justify-content-end">
            <CheckboxInput
              label="Percentual"
              name="Percentual"
              checked={percentual}
              onChange={handleChangePercentual}
              disabled={!edicaoHabilitada}
            />
          </div>
          <div className="field col-12 md:w-5">
            <CheckboxInput
              label="Valor"
              name="valor"
              checked={valor}
              onChange={handleChangeValor}
              disabled={!edicaoHabilitada}
            />
          </div>
          <div className="field col-12 text-center">
            {labelTipoDesconto(label)}
          </div>
          <div className="field col-12 w-4">
            {percentual &&
              <TextInput
                name="perdes_peo"
                label="Desconto (%)"
                placeholder="Porcentagem de desconto"
                value={formatToPercentage(form.values.perdes_peo)}
                onChange={handleChangePorcentagemDesconto}
                // onBlur={onBlurPorcentagemDesconto}
                disabled={!permitirDesconto || !edicaoHabilitada}
              />
            }
            {valor &&
              <TextInput
                name="valdes_peo"
                label="Desconto (R$)"
                placeholder="Valor de desconto"
                value={formatToCurrency(valorDesconto)}
                onChange={e => setValorDesconto(formatCurrencyToNumber(e.target.value))}
                // onBlur={onBlurDesconto}
                disabled={!permitirDesconto || !edicaoHabilitada}
              />
            }
          </div>
        </div>
      </Modal>
    </>
  )
}

export default AplicarDescontoModal
