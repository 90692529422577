import React from 'react'
import Page from '../../../../components/layout/Page'
import { createColumns, getColunasHabilitadas } from '../../../../helpers/tableConfigs'
import useList from '../../../../hook/useList'
import { Button } from 'primereact/button'
import Container from '../../../../components/layout/Container'
import DataList from '../../../../components/layout/DataList'
import CommonHelper from '../../../../helpers/CommonHelper'
import TipoEtapaService from '../../../../services/processo/TipoEtapaService'
import { getTipoEtapaDTO } from '../../../../dtos/processo/TipoEtapaDTO'

const TipoEtapaList = (props) => {
  // * Cria as colunas que serão utilizadas no dataList e mo HOOK
  const columns = createColumns([
    { field: 'codigo_tet', header: 'Código', width: '25%' },
    { field: 'descri_tet', header: 'Descrição', width: '75%' }
  ])

  // * Utilziação do HOOK "useList" no Function Component para substituir o "Extends List" do Class Component
  const {
    state,
    // setState,
    onPageChange,
    onSelect,
    onView,
    handleChangeFilter,
    onFilter,
    onNew
  } = useList(
    getTipoEtapaDTO,
    TipoEtapaService,
    'form-tipo-etapa-processo',
    'codigo_tet',
    undefined,
    undefined,
    {
      columns
    }
  )

  return (
    <Page>
      <div className="flex justify-content-between align-items-center page-header">
        <h4>Tipo de Etapa</h4>
        <div className="page-header-buttons">
          <Button className="mr-2" label={CommonHelper.isDesktop() && 'Novo cadastro'} icon="pi pi-plus" onClick={onNew} />
        </div>
      </div>
      <Container>
        <DataList
          data={state.list}
          selected={state.selected}
          rows={state.rows}
          onSelect={onSelect}
          totalRecords={state.totalRecords}
          first={state.first}
          onPage={onPageChange}
          page={state.page}
          onRowSelect={(e) => onView(e.data)}
          filterPlaceholder="Procurar tipo de etapa"
          filterName="descricaoFiltro"
          filterOnChange={handleChangeFilter}
          onFilter={onFilter}
          responsive
          showFilter
          customMaxHeight={'unset'}
        >
          {getColunasHabilitadas(columns, 'tipo-etapa-processo')}
        </DataList>
      </Container>
    </Page>
  )
}

export default TipoEtapaList
