import { AutoComplete } from 'primereact/autocomplete'
import { classNames } from 'primereact/utils'
import React from 'react'
import AppButton from '../../layout/AppButton'
import FiltroModal from '../../utils/FiltroModal'
import RegisterModal from '../../utils/RegisterModal'
import InputBase from '../base/InputBase'
import { createRef } from 'react/cjs/react.production.min'

class SearchInput extends InputBase {
  constructor (props) {
    super(props)

    this.state = {
      visibleFiltro: false
    }

    this.autoCompleteRef = createRef()
    this.handleKeyDown = this.handleKeyDown.bind(this)
  }

  handleKeyDown (event) {
    if (event.key === 'F3') {
      event.preventDefault()
      this.setState({ visibleFiltro: true })
    }
  }

  handleFocus = () => {
    window.addEventListener('keydown', this.handleKeyDown)
  }

  handleBlur = () => {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  render () {
    const { props } = this

    const isCampoObrigatorio = Object.getOwnPropertyNames(props).includes('isFormFieldValid')

    const classNameLabel = isCampoObrigatorio ? classNames({ 'p-error': props.isFormFieldValid }, 'required') : ''

    return (
      <>
        {!props.ComponenteCadastro && (
          <label className={classNameLabel}>{props.label}</label>
        )}
        {props.ComponenteCadastro && (
          <RegisterModal
            ComponenteCadastro={props.ComponenteCadastro}
            isCampoObrigatorio={isCampoObrigatorio}
            onRegister={props.onRegister}
            label={props.label}
            isFormFieldValid={props.isFormFieldValid}
            onSaveModal={props.onSaveModal}
            onDeleteModal={props.onDeleteModal}
            modalHeader={props.modalHeader}
            selected={props.selected}
            primarykeyname={props.primarykeyname}
          />
        )}
        {!props.filtrotitle && (
          <AutoComplete
            ref={this.autoCompleteRef}
            field={props.field}
            value={props.value}
            minLength={props.minLength || 3}
            suggestions={props.suggestions}
            completeMethod={props.completeMethod}
            onChange={props.onChange}
            onSelect={async (e) => props.onSelect(e)}
            itemTemplate={props.itemTemplate}
            placeholder={props.placeholder || 'Digite para pesquisar.'}
            disabled={props.disabled}
            className={props.className}
            onBlur={(e) => {
              !!props.onBlur && props?.onBlur(e)
              this.handleBlur()
            }}
            onFocus={this.handleFocus}
            {...props}
          />
        )}
        {props.filtrotitle && (
          <>
            <div className="p-inputgroup">
              <AutoComplete
                {...props}
                ref={this.autoCompleteRef}
                field={props.field}
                value={props.value}
                minLength={props.minLength || 3}
                suggestions={props.suggestions}
                completeMethod={props.completeMethod}
                onChange={props.onChange}
                onSelect={async (e) => props.onSelect(e)}
                itemTemplate={props.itemTemplate}
                placeholder={props.placeholder || 'Digite para pesquisar.'}
                disabled={props.disabledAutoComplete !== undefined ? props.disabledAutoComplete : props.disabled}
                className={props.className}
                onBlur={(e) => {
                  !!props.onBlur && props?.onBlur(e)
                  this.handleBlur()
                }}
                onFocus={this.handleFocus}
              />
              <AppButton
                type="button"
                className="search-input__button-search"
                icon="pi pi-search"
                onClick={() => this.setState({ visibleFiltro: true })}
                disabled={props.disabled}
                onBlur={props.searchOnBlur}
                tooltip='Enquanto está selecionado, "F3" abre a pesquisa.'
                tooltipOptions={{
                  position: 'bottom'
                }}
              />
            </div>
            <FiltroModal
              title={props.filtrotitle}
              visible={this.state.visibleFiltro}
              hide={() => this.setState({ visibleFiltro: false })}
              service={props.service}
              model={props.model}
              primarykeyname={props.primarykeyname}
              columns={props.columns}
              onSelect={props.onSelect}
              filtersDefault={props.filtersDefault}
              semGetInicial={props.semGetInicial}
            />
          </>
        )}
      </>
    )
  }
}

export default SearchInput
