import React from 'react'
import ReportBase from '../../../components/utils/report/ReportBase'
import { createColumns } from '../../../helpers/tableConfigs'
import ProcessoUsoReportService from '../../../services/reports/ProcessoUsoReportService'
import ProcessoUsoReportFiltroModal from './FiltroModal'
import './style.scss'

class ProcessoUsoReport extends ReportBase {
  constructor (props) {
    super(props, 'Relatório de processos', ProcessoUsoReportService, ProcessoUsoReportFiltroModal, false, false)
  }

  async componentDidMount () {
    await super.componentDidMount()

    super.setGrouping({
      getHeader: this.getGroupHeader,
      propertyName: 'etapas',
      group: {
        getHeader: this.getSubGroupHeader,
        propertyName: 'pendencias'
      }
    })

    super.setColumns(createColumns([
      {
        field: 'descricao',
        header: 'Pendências',
        alignText: 'text-left',
        headerClassName: 'text-left',
        className: 'pl-2',
        reportColumn: true,
        sortable: false,
        customBody: function customBody (data) {
          console.log(data)
          return (
            <span
              style={
                {
                  ...(
                    data.cod_status === 3
                      ? { 'text-decoration': 'line-through' }
                      : data.cod_status === 2 ? { color: '#49C96D' }
                        : {}
                  ),
                  marginLeft: '0.25rem'
                }
              }
            >{data.descricao}</span>
          )
        }
      }
    ], true))
  }

  getGroupHeader (processo) {
    return (
      <div className='flex flex-wrap justify-content-between'>
        <div className='flex flex-column flex-wrap justify-content-between col-12 md:col-6 p-0'>
          <div className='col-12 md:p-2 md:pl-0 md:pr-0'>
            {`(${processo.codigo}) - ${processo.descricao.toUpperCase()} ${
              processo.negociacao
                ? `- Negociação: ${processo.negociacao}`
                : '-'} ${processo.data_cadastro}`}
          </div>
          <div className='col-12 md:pb-2 md:pl-0'>
            {`Veículo: ${processo.veiculo.toUpperCase()}`}
          </div>
        </div>
        <div className='flex flex-column flex-wrap justify-content-between col-12 md:col-6 p-0'>
          <div className='col-12 md:p-2 md:pl-0 md:pr-0 md:text-right'>
            {`Atendente: ${processo.atendente.toUpperCase()}`}
          </div>
          <div className='col-12 md:pb-2 md:pr-0 md:text-right'>
            {`Cliente: ${processo.cliente.toUpperCase()}`}
          </div>
        </div>
      </div>
    )
  }

  getSubGroupHeader (etapa, idx) {
    return (
      <div>
        {`Etapa ${idx + 1} - ${etapa.descricao.toUpperCase()}`}
      </div>
    )
  }
}

export default ProcessoUsoReport
