import { postNegociacaoDTO } from '../../../dtos/vendas/negociacao/NegociacaoDTO'
import ServiceBase from '../../base/ServiceBase'

export default class NegociacaoService {
  static async save (data) {
    return await ServiceBase.postBase('negociacao', postNegociacaoDTO(data))
  }

  async save (data) {
    return await ServiceBase.postBase('negociacao', postNegociacaoDTO(data))
  }

  static async get (id) {
    return await ServiceBase.getBase(`negociacao/${id}`)
  }

  async get (id) {
    return await ServiceBase.getBase(`negociacao/${id}`)
  }

  static async podeVerNegociacaoOutroVendedor (id) {
    return await ServiceBase.getBase(`negociacao/getnegOutroVendedor/${id}`)
  }

  static async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`negociacoes?${query}&page=${page}&perPage=${perPage}`)
  }

  static async filter (query, page, perPage) {
    return await ServiceBase.getBase(`negociacoes?${query}&page=${page}&perPage=${perPage}`)
  }

  static async filterAutoComplete (query) {
    return await ServiceBase.getBase(`negociacoes?${query}`, false)
  }

  static async alterarFoPagNeg (id, newFoPagNegs) {
    return await ServiceBase.putBase(`negociacao/alterarFoPagNeg/${id}`, newFoPagNegs)
  }

  static async cancel (id, motivoCancelamento) {
    return await ServiceBase.putBase(`negociacao/cancelar/${id}`, { motcan_neg: motivoCancelamento })
  }

  static async consignar (id) {
    return await ServiceBase.putBase(`negociacao/consignar/${id}`)
  }

  static async validarReabertura (id) {
    return await ServiceBase.putBase(`negociacao/validarReabertura/${id}`)
  }

  static async validateVendaVeiculo (codigo_vei, codpesNeg) {
    return await ServiceBase.getBase(`negociacaoveiculos/venda/${codigo_vei}/${codpesNeg}`)
  }

  static async validateCompraVeiculo (codigo_vei, codigoNve) {
    return await ServiceBase.getBase(`negociacaoveiculos/compra?codigo_vei=${codigo_vei}${codigoNve ? `&codigo_nve=${codigoNve}` : ''}`)
  }

  static async getNveCom (codigo_vei, codigoNve) {
    return await ServiceBase.getBase(`negociacaoveiculos/getNveCom?codigo_vei=${codigo_vei}${codigoNve ? `&codigo_nve=${codigoNve}` : ''}`)
  }

  static async getFormaAquisicao () {
    return await ServiceBase.getBase('negociacaoveiculos/formaaaquisicao')
  }

  static async getTiposMidia (ativos = undefined) {
    return await ServiceBase.getBase(`tiposmidia${ativos ? '?ativos=true' : ''}`)
  }

  static async setObservacao (obs_neg, codigo_neg) {
    return await ServiceBase.postBase(`negociacao/observacoes/${codigo_neg}`, { observacao: obs_neg })
  }

  static async getSqlTesteReport () {
    return await ServiceBase.getBase('getSqlTesteReport')
  }

  static async getUltimaCompraVeiculo (codVei) {
    return await ServiceBase.getBase(`negociacao/ultimaCompraVeiculo/${codVei}`)
  }

  static async getUltimaVendaVeiculo (codVei) {
    return await ServiceBase.getBase(`negociacao/ultimaVendaVeiculo/${codVei}`)
  }

  static async getNegociacaoEmAberto (codVei, loading = true) {
    return await ServiceBase.getBase(`negociacao/getNegociacaoEmAberto/${codVei}`, loading)
  }

  static async validaVeiculosDemonstracao (loading = true) {
    return await ServiceBase.getBase('negociacao/validaVeiculosDemonstracao', loading)
  }
}
